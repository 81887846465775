import React, { useRef } from "react";
import Modal from "@mui/material/Modal";
import { PaymentOptions } from "../../Pages/PaymentOptions/payment-options";
import { useLocation } from "react-router-dom";
import PlayCircleFilledWhite from "@mui/icons-material/PlayCircleFilledWhite";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";

const IndividualNftPreviewCard = (props) => {
  const location = useLocation();
  const [paymentOptions, setShowPaymentOptions] = React.useState(false);
  const [nftDetail, setNftDetail] = React.useState(props.nftDetail);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const videoRef = useRef(null);
  const playVideo = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };
  const pauseVideo = () => {
    setIsPlaying(false);
    videoRef.current.pause();
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "black",
    border: "2px solid #f67622",
    boxShadow: "0px 0px 20px 0px #f67622",
    p: 4,
    "@media(max-width:500px)": {
      width: 350,
    },
  };

  const showPaymentOptions = () => {
    setShowPaymentOptions(true);
  };

  ////console.log("ppropss", props);
  return (
    <>
      <div style={{ position: "relative" }} className="individualNFTPage">
        <div className="before-shape before-shape-noHover">
          <div className="banner-slider" style={{ cursor: "pointer" }}>
            <div className="banner-item">
              {props.nftDetail.mediaType === "video/mp4" ? (
                <div className="thumb-img for-video">
                  <div className="video-player individual-video-playerContainer">
                    <video
                      controls
                      style={{ widht: "100%" }}
                      controlsList="nodownload"
                    >
                      <source
                        src={props.nftDetail.mediaLocation}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              ) : (
                <div className="thumb-img for-image">
                  <img
                    src={nftDetail.mediaLocation}
                    alt=""
                    style={{ maxHeight: "70vh" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="price-buynow-btn">
          <div className="current-price flex-fill">
            <span className="nftPriceLabel">
              {nftDetail.price} {nftDetail.currency}
            </span>
            <span className="priceBreakLine"></span>
            <h3 className="currentPriceLabel">
              CURRENT
              <br />
              PRICE
            </h3>
          </div>
          <div className="right-block">
            <div className="timer-block d-inline-block d-block d-md-none">
              <span>
                {props.timerHours} hours {props.timerSeconds} sec
              </span>
            </div>
          </div>
        </div>
        <div className="timer-block text-end d-none d-md-block">
          <span>{props.timeRemaining}</span>
        </div>
      </div>
      <Modal
        // hideBackdrop
        open={paymentOptions}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {paymentOptions && (
          <div className="col-lg-12 mb-5">
            <PaymentOptions paymentOptions={setShowPaymentOptions} />
          </div>
        )}
      </Modal>
    </>
  );
};

export default IndividualNftPreviewCard;
