import { useSelector } from "react-redux";
import { SocialIcon } from "react-social-icons";
import { useStyles } from "../../Pages/Profile/profile.style";

const SocialIcons = () => {
    const user = useSelector((state) => state.user);
    const { classes } = useStyles()
    return (
        <div className={classes.additionalDetails}>
            {(user.additionalDetails && user.additionalDetails.website === true && user.website !== null && user.website !== 'https://') ? (
                <SocialIcon
                    target="_blank"
                    style={{
                        height: "30px",
                        width: "30px",
                        "&:hover": { boxShadow: "5px orange" },
                    }}
                    bgColor="coral"
                    fgColor="#131623"
                    url={user.website}
                />
            ) : null}
            {user.additionalDetails && user.additionalDetails.instagram === true && user.instagram !== null &&
                user.instagram.match(
                    "https\:\/\/instagram.com\/[a-zA-z0-9.:_@~!#$%*]{1,20}"
                ) ? (
                <SocialIcon
                    target="_blank"
                    style={{
                        height: "30px",
                        width: "30px",
                        "&:hover": { boxShadow: "5px orange" },
                    }}
                    bgColor="coral"
                    fgColor="#131623"
                    url={user.instagram}
                />
            ) : null}
            {user.additionalDetails && user.additionalDetails.twitter === true && user.twitter !== null &&
                user.twitter.match(
                    "https\:\/\/twitter.com\/[a-zA-z0-9.:_@~!#$%*]{1,20}"
                ) ? (
                <SocialIcon
                    target="_blank"
                    style={{
                        height: "30px",
                        width: "30px",
                        "&:hover": { boxShadow: "5px orange" },
                    }}
                    bgColor="coral"
                    fgColor="#131623"
                    url={user.twitter}
                />
            ) : null}
            {user.additionalDetails && user.additionalDetails.discord === true && user.discord !== null &&
                user.discord.match(
                    "https\:\/\/discordapp.com\/users\/[a-zA-z0-9.:_@~!#$%*]{1,20}"
                ) ? (
                <SocialIcon
                    target="_blank"
                    style={{
                        height: "30px",
                        width: "30px",
                        "&:hover": { boxShadow: "5px orange" },
                    }}
                    bgColor="coral"
                    fgColor="#131623"
                    url={user.discord}
                />
            ) : null}
            {user.additionalDetails && user.additionalDetails.tiktok === true && user.tiktok !== null &&
                user.tiktok.match(
                    "https\:\/\/tiktok\.com\/\@[a-zA-z0-9.:_@~!#$%*]{1,20}"
                ) ? (
                <SocialIcon
                    target="_blank"
                    style={{
                        height: "30px",
                        width: "30px",
                        "&:hover": { boxShadow: "5px orange" },
                    }}
                    bgColor="coral"
                    fgColor="#131623"
                    url={user.tiktok}
                />
            ) : null}
            {user.additionalDetails && user.additionalDetails.youtube === true && user.youtube !== null &&
                user.youtube.match(
                    "https\:\/\/youtube\.com\/[a-zA-z0-9.:_@~!#$%*]{1,20}"
                ) ? (
                <SocialIcon
                    target="_blank"
                    style={{
                        height: "30px",
                        width: "30px",
                        "&:hover": { boxShadow: "5px orange" },
                    }}
                    bgColor="coral"
                    fgColor="#131623"
                    url={user.youtube}
                />
            ) : null}
            {user.additionalDetails && user.additionalDetails.facebook === true && user.facebook !== null &&
                user.facebook.match(
                    "https\:\/\/facebook\.com\/[a-zA-z0-9.:_@~!#$%*]{1,20}"
                ) ? (
                <SocialIcon
                    target="_blank"
                    style={{
                        height: "30px",
                        width: "30px",
                        "&:hover": { boxShadow: "5px orange" },
                    }}
                    bgColor="coral"
                    fgColor="#131623"
                    url={user.facebook}
                />
            ) : null}
            {user.additionalDetails && user.additionalDetails.snapchat && user.snapchat !== null &&
                user.snapchat.match(
                    "https\:\/\/snapchat\.com\/add\/[a-zA-z0-9.:_@~!#$%*]{1,20}"
                ) ? (
                <SocialIcon
                    target="_blank"
                    style={{
                        height: "30px",
                        width: "30px",
                        "&:hover": { boxShadow: "5px orange" },
                    }}
                    bgColor="coral"
                    fgColor="#131623"
                    url={user.snapchat}
                />
            ) : null}
            {user.additionalDetails && user.additionalDetails.reddit === true && user.reddit !== null &&
                user.reddit.match(
                    "https\:\/\/reddit\.com\/[a-zA-z0-9.:_@~!#$%*]{1,20}"
                ) ? (
                <SocialIcon
                    target="_blank"
                    style={{
                        height: "30px",
                        width: "30px",
                        "&:hover": { boxShadow: "5px orange" },
                    }}
                    bgColor="coral"
                    fgColor="#131623"
                    url={user.reddit}
                />
            ) : null}
            {user.additionalDetails && user.additionalDetails.medium === true && user.medium !== null &&
                user.medium.match(
                    "https\:\/\/medium\.com\/[a-zA-z0-9.:_@~!#$%*]{1,20}"
                ) ? (
                <SocialIcon
                    target="_blank"
                    style={{
                        height: "30px",
                        width: "30px",
                        "&:hover": { boxShadow: "5px orange" },
                    }}
                    bgColor="coral"
                    fgColor="#131623"
                    url={user.medium}
                />
            ) : null}
        </div>
    )
}

export default SocialIcons