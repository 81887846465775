import { createSlice, createAsyncThunk, isPending } from "@reduxjs/toolkit";
import collectionService from "../services/collection.service";
const initialState = { isCollectionFetched: false };

//Login initiated during sign up
export const getAllCollections = createAsyncThunk(
  "collections/all",
  async ({ }, thunkAPI) => {
    try {
      const collectionResponse = await collectionService.getCollections();
      const collectionIDs = await collectionService.getCollectionIDs(collectionResponse)
      return { collections: collectionResponse, collectionIDs: collectionIDs, isCollectionFetched: true };
    } catch (error) {
      ////console.log("collection", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);



const collectionSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {
    getCollections: (state, action) => {
      return state;
    }
  },
  extraReducers: {
    //Login actions
    [getAllCollections.fulfilled]: (state, action) => {
      const allCollection = action.payload;
      return { ...allCollection, isCollectionFetched: true };
    },
    [getAllCollections.rejected]: (state, action) => {
      state = []
    }
  },
});

const { reducer, actions } = collectionSlice;
export default reducer;