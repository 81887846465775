import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()({
    parentContainer: {
        width: '75.5%',
        margin: 'auto',
        marginTop: '100px',
        display: 'grid',
        gridTemplateColumns: '66.43% 32.52%',
        gridColumnGap: '1.3%',
        maxHeight: '1373px'
    },
    mainBanner: {

    },
    mainBannerImageContainer: {
        gridColumn: '1',
        gridRow: '1',
        position: 'relative',
        maxHeight: '545px',
        margin: 'auto',
        border: '1px solid rgba(242 ,131 ,58, 0.5)',
        borderRadius: '10px',
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            right: '-8px',
            bottom: '-8px',
            // background: 'rgb(216,216,216)',
            // background: '-moz-linear-gradient(132deg, rgba(216,216,216,1) 0%, rgba(246,118,34,1) 100%)',
            // background: '-webkit-linear-gradient(132deg, rgba(216,216,216,1) 0%, rgba(246,118,34,1) 100%)',
            background: 'linear-gradient(132deg, rgba(216,216,216,1) 0%, rgba(246,118,34,1) 100%)',
            borderRadius: '10px',
            zIndex: '-1'
        }
    },
    belowBannerContainer: {
        marginTop: '30px',
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridTemplateRows: 'auto auto',
    },
    nftDescription: {
        gridColumn: '2',
        gridRow: '1 / 2 span',
        position: 'relative',
        maxHeight: '545px',

        border: '1px solid rgba(242 ,131 ,58, 0.5)',
        borderRadius: '10px',
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            right: '-8px',
            bottom: '-8px',
            // background: 'rgb(216,216,216)',
            // background: '-moz-linear-gradient(132deg, rgba(216,216,216,1) 0%, rgba(246,118,34,1) 100%)',
            // background: '-webkit-linear-gradient(132deg, rgba(216,216,216,1) 0%, rgba(246,118,34,1) 100%)',
            background: 'linear-gradient(132deg, rgba(216,216,216,1) 0%, rgba(246,118,34,1) 100%)',
            borderRadius: '10px',
            zIndex: '-1'
        }
    },
    nftCollectionCards: {
        gridColumn: '1',
        gridRow: '2',
        marginTop: '135px',
        maxHeight: '528px',
        maxWidth: '960px',
        position: 'relative',
        border: '1px solid rgba(242 ,131 ,58, 0.5)',
        borderRadius: '10px',
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            right: '-8px',
            bottom: '-8px',
            // background: 'rgb(216,216,216)',
            // background: '-moz-linear-gradient(132deg, rgba(216,216,216,1) 0%, rgba(246,118,34,1) 100%)',
            // background: '-webkit-linear-gradient(132deg, rgba(216,216,216,1) 0%, rgba(246,118,34,1) 100%)',
            background: 'linear-gradient(132deg, rgba(216,216,216,1) 0%, rgba(246,118,34,1) 100%)',
            borderRadius: '10px',
            zIndex: '-1'
        }
    },
    bannerTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '12%',
        left: '6.92%',
        color: 'white'
    },
    solisMarketHeading: {
        marginTop: '100px',
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: '64px',
        lineHeight: '64px',
        textAlign: 'center',
        textTransform: 'uppercase',
        // background: 'linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)',
        // background: '-moz-linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)',
        // background: '-webkit-linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)',
        background: 'linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    },
    whiteBoldHeading: {
        fontWeight: '900',
        fontSize: '36px',
        lineHeight: '60px',
        textTransform: 'uppercase',
        color: '#F4F4F4'
    },
    filmContainer: {
        width: '75.5%',
        margin: 'auto'
    },
    memoribiliaContainer: {
        width: '75.5%',
        margin: 'auto'
    },
    experiencesContainer: {
        width: '75.5%',
        margin: 'auto'
    },
    headingAndNavigationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    orangeGradientHeading: {
        textTransform: 'uppercase',
        fontSize: '48px',
        lineHeight: '60px',
        // background: '-moz-linear-gradient(314.45deg, #C90099 -70.21%, #F67622 58.16%, #D8D8D8 143.73%)',
        // background: '-webkit-linear-gradient(314.45deg, #C90099 -70.21%, #F67622 58.16%, #D8D8D8 143.73%)',
        background: 'linear-gradient(314.45deg, #C90099 -70.21%, #F67622 58.16%, #D8D8D8 143.73%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        position: 'relative',
        fontWeight: '900'
    },
    navigationArrows: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridColumnGap: '10px'

    },
    navigationUnvisited: {
        border: '1px solid rgba(242 ,131 ,58, 0.5)',
        padding: '5px',
        '&:hover': {
            cursor: 'pointer'
        },
        borderRadius: '6px'
    },
    navigationVisited: {
        border: '1px solid rgba(242 ,131 ,58, 0.5)',
        padding: '5px',
        '&:hover': {
            cursor: 'pointer'
        },
        background: 'linear-gradient(277.72deg, #C9009A -32.97%, #FC6401 55.57%, #F4F4F4 144.11%)',
        borderRadius: '6px'
    },
    cardContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'auto auto auto auto',
        gridColumnGap: '21px'
    },
    purpleButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '18px 65px',
        maxWidth: '260px',
        width: '100%',
        fontWeight: '900',
        fontSize: '24px',
        lineHeight: '24px',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        background: 'linear-gradient(277.72deg, #C9009A -32.97%, #FC6401 55.57%, #F4F4F4 144.11%)',

        border: 'none',
        borderRadius: '6px',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    borderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 65px',
        maxWidth: '264px',
        border: '2px solid #FA6401',
        boxSizing: 'border-box',
        borderRadius: '6px',
        fontWeight: '900',
        fontSize: '24px',
        lineHeight: '24px',
        textTransform: 'uppercase',
        color: 'white'
    },
    timeContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '250px',
        padding: '10px 65px',
        color: 'white',
        fontSize: '24px',
        lineHeight: '29px',
        fontFamily: 'actor',
        letterSpacing: '0.1em',
        zIndex: '1'
    },
    nftName: {
        maxHeight: '256px ',
        background: 'linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)',
        width: '100%',
        borderRadius: '10px 10px 0px 0px',
        padding: '30px'
    },
    becauseReasonsText: {
        fontWeight: '900',
        fontSize: '17px',
        lineHeight: '17px',
        textTransform: 'uppercase',
        color: '#F4F4F4'
    },
    daPoetryText: {
        marginTop: '7.8%',
        fontWeight: '900',
        fontSize: '24px',
        lineHeight: '24px',
        textTransform: 'uppercase',
        color: '#F4F4F4'
    },
    iconContainer: {
        marginTop: '6%',
        display: 'flex',
        justifyContent: 'space-between',
        maxHeight: '60px',
        alignItems: 'center'
    },
    nftDetails: {
        margin: '20px',
        padding: '10px',
        maxWidth: '400px',
        maxHeight: '182px',
        fontSize: '12px',
        lineHeight: '26px',
        letterSpacing: '0.1em',
        color: 'white',
        background: '#131623'
    },
    nftProperties: {

    },
    inputContainer: {
        fontFamily: 'Aldrich',
        background: 'black',
        color: '#F67622',
        border: '1px solid #F67622',
        height: '50px',
        borderRadius: '2px',
        backgroundColor: '#131623',
        textAlign: 'center',

    },
    listForSaleButtonDiv: {
        display: 'flex',
        alignItemsL: 'center',
        marginTop: '10px',
        marginBottom: '10px'
    },
    listForSaleParent: {
        border: '1px solid #f67622',
        marginTop: '50px',
        padding: '20px',
        minHeight: '250px',
        display: 'grid',
        gridTemplateRows: 'auto auto',
    },
    radioGroupsDiv: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    inputField: {
        color: "#f67622",
        fontFamily: "Saira",
        backgroundColor: "transparent",
        border: "1px solid #f67622",
        padding: "11.5px 20px",
        maxWidth: '200px',
        maxHeight: "50px"

    },
    dropdownAndInputDiv: {
        marginTop: '30px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    detailsDiv: {
        display: 'grid',
        gridTemplateColumns: "35% 55%",
        gridGap: '10%'
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center'
    },
    listForSaleModal: {
        width: '30vw',
        minheight: '50vh',
        maxHeight: '150vh',
        margin: 'auto',
        marginTop: '12vh',
        border: '1px solid #f67622',
        boxShadow: '0px 0px 30px 0px #f67622',
        background: '#131623',
        fontFamily: 'Saira',
        position:'relative',
        padding: '20px', 
        '@media(max-width:542px)':{
          width:'90%',
          marginTop:'20vh!important',
          fontSize:'0.8rem'
        },
        '@media(min-width:543px)':{
          width:'70%',
          marginTop:'20vh!important'
        },
        '@media(min-width:787px)':{
          width:'60%',
          marginTop:'20vh!important'
        },
        '@media(min-width:1199px)':{
          width:'35%',
          marginTop:'20vh!important'
        },
        
      },
      listForSaleComponentHeading:{
        fontSize: '1.3rem',
        fontWeight: '600',
        fontFamily: 'Aldrich',
        textAlign: 'center',
        color:'#f67622',
        textAlign:'center',
        margin:'auto',
        marginBottom: '20px',

      },
      currencyRadioGroup:{
        display:'flex',
        justifyContent:'center',
        flexDirection:'row',
        gap:'20px',
        alignItems:'center',
        marginBottom:'20px',
        marginTop:'10px',
        flexGrow:'wrap'
      },
      priceInputField:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        margin:'auto',
        color: "#f67622",
        fontFamily: "Saira",
        backgroundColor: "transparent",
        border: "1px solid #f67622",
        padding: "11.5px 20px",
        maxWidth: '200px',
        maxHeight: "50px",
        boxShadow:'0px 0px 10px 0px #f67622'
      },
      listForSaleButtonDiv:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        marginTop:'20px'
      },
      walletAddressDiv:{
        textAlign:'center' ,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        gap:'20px',
        marginBottom:'10px'
      },
      walletAddressDivParent:{
        marginTop:'20px'
      },
      warningText:{
        textAlign:'center'
      },
      confirmWarningText:{
        color:'#f67622',
        fontSize:'1.2rem'
      },
      confirmWarningTextDescription:{
        marginBottom:'10px'
      },
      confirmButtonDiv:{
        marginTop:'10px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
      },
      claimModalParent:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        position:'relative', 
        margin:'auto',
        padding:'30px',
        border:'2px solid #f67622',
        "@media (min-width: 601px) and (max-width: 960px)": {
          height: "40vh",
          width: "60vw",
          marginTop:'20vh',
    
        },
        "@media (min-width: 961px) and (max-width: 1280px)": {
          height: "40vh",
          width: "40vw",
          marginTop:'20vh',
    
        },
        "@media (min-width: 1281px)": {
          height: "40vh",
          width: "30vw",
          marginTop:'20vh',
        },
        "@media (max-width: 600px)": {
          height: "45vh",
          width: "80vw",
          marginTop:'20vh',
    
        },
        border:'1px solid #f67622',
        boxShadow:'0px 0px 20px 0px #f67622',
        backgroundColor:'rgb(20,22,35)'
      }  ,
      circularLoading:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        flexDirection:'column',
        gap:'20px'
      },
      claimModalContent:{
        width:'100%',
        height:'100%'
      },
      thumbnailDiv:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        margin:'auto',
        '@media(max-width:1199px)':{
          flexDirection:'column',
          alignItems:'center'
        }
      },
      thumbnailImageDiv:{
        height:'100px',
        width:'100px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        borderRadius:'5px'
      },
      thumbnailDetailsDiv:{
        '@media(max-width:1199px)':{
          textAlign:'center',
          marginBottom:'20px'
        }
      },
      paymentWindowHeadingSuccess:{
        textAlign: "left",
        fontSize: '1.3rem',
        color: '#f67622',
        fontWeight:'900',
      },
      paymentWindowHeadingError:{
        textAlign: "left",
        fontSize: '2.4rem',
        color: '#f67622',
        fontWeight:'900',
      },
      explorerUrlSpan:{
        color:'white',
        fontWeight:'600',
        cursor:'pointer',
        '&hover':{
          cursor:'pointer',
          fontWeight:'600',
          color:'white'
        },
        alignSelf: 'flex-end',
        marginBottom: '20%', 
      },
 
      explorerUrlSpanError:{
        color:'white',
        fontWeight:'600',
        cursor:'pointer',
        '&hover':{
          cursor:'pointer',
          fontWeight:'600',
          color:'white'
        },
        alignSelf: 'flex-end',
        marginBottom: '20%', 
      },
      errorDiv:{
        display:'flex',
        justifyContent:'center',
        alignContent:'center',
        gap:'20px',
        marginTop:'20px',
        flexDirection:'column',
        textAlign:'center'
      }
})
export { useStyles }
