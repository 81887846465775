import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useStyles } from "./header.style";
import SolisLogo from "../../assets/images/solis-logo.svg";
import avatarPlaceholder from "../../assets/images/avator-placeholder.svg";
import { logoutUser, logoutCleanup } from "../../slices/auth.slice";
import DoneIcon from "@mui/icons-material/Done";
// import { useWeb3React } from "@web3-react/core";

//import { useEagerConnect, useInactiveListener } from "../../hooks";
import pdf from "../../assets/SOLIS_KYC_Guide.pdf";
import clsx from "clsx";
import { useAccount } from "wagmi";
import {
  useConnectModal,
  useAccountModal,
  useChainModal,
} from "@rainbow-me/rainbowkit";
import { useDisconnect } from "wagmi";
import ImageComponent from "../Loader/ImageComponent";
import { getUserDetail } from "../../slices/user.slice";
import { CircularProgress, Modal } from "@mui/material";

const Header = () => {
  const { isLoggedIn, email } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [status, setStatus] = useState("");
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const userState = useSelector((state) => state.user);
  const authState = useSelector((state) => state.auth);
  const [isTimeout, setIsTimeout] = useState(true);
  //Sub menu open close workaround
  const [communityExpandedButton, setCommunityExpandedButton] = useState(false);
  const [communityShowSubmenu, setCommunityShowSubmenu] = useState(false);
  const [ecosystemExpandedButton, setEcosystemExpandedButton] = useState(false);
  const [ecosystemShowSubmenu, setEcosystemShowSubmenu] = useState(false);
  const [helpExpandedButton, setHelpExpandedButton] = useState(false);
  const [helpShowSubmenu, setHelpShowSubmenu] = useState(false);
  const [profileExpandedButton, setProfileExpandedButton] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [profileShowSubmenu, setProfileShowSubmenu] = useState(false);
  const [activatingConnector, setActivatingConnector] = useState();
  // const { active, account, connector, library, activate, deactivate, error } =
  //   useWeb3React();

  const { openConnectModal } = useConnectModal();
  const { address, isConnecting } = useAccount();
  const { disconnect } = useDisconnect();
  // useEffect(() => {
  //   if (activatingConnector && activatingConnector === connector) {
  //     setActivatingConnector(undefined);
  //   }
  // }, [activatingConnector, connector]);
  //const triedEager = useEagerConnect();
  //useInactiveListener(!triedEager || !!activatingConnector);

  const logout = async () => {
    disconnect();
    dispatch(logoutUser({}));
    dispatch(logoutCleanup());
    history.push("/");
  };
  useEffect(() => {
    async function performAsync() {
      // const { address, status } = await getCurrentWalletConnected();
      if (isLoggedIn) {
        dispatch(getUserDetail({ email: userState.email }))
      }
      setStatus(status);
      // addWalletListener();
    }
    performAsync()
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTimeout(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isTimeout]);
  var ConnectorNames;
  (function (ConnectorNames) {
    ConnectorNames["Injected"] = "Injected";
    ConnectorNames["Network"] = "Network";
    ConnectorNames["WalletConnect"] = "WalletConnect";
    ConnectorNames["WalletLink"] = "WalletLink";
    ConnectorNames["Ledger"] = "Ledger";
    ConnectorNames["Trezor"] = "Trezor";
    ConnectorNames["Lattice"] = "Lattice";
    ConnectorNames["Frame"] = "Frame";
    ConnectorNames["Authereum"] = "Authereum";
    ConnectorNames["Fortmatic"] = "Fortmatic";
    ConnectorNames["Magic"] = "Magic";
    ConnectorNames["Portis"] = "Portis";
    ConnectorNames["Torus"] = "Torus";
  })(ConnectorNames || (ConnectorNames = {}));

  const connected = false;
  //const disabled = !triedEager || !!activatingConnector || connected;
  //const disabled = !triedEager || !!activatingConnector || connected || !!error;

  const { classes } = useStyles();
  return (
    <>


      {
        !isLoggedIn ? null :

          <header
            className={isSideBarOpen ? "main-header is-visible" : "main-header"}
            style={{
              boxShadow: "(0px 6px 20px rgba(0, 0, 0, 0.08))",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
          >
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container">
                <div className="header-first-block">
                  <div className="row align-items-center noPaddingMob">

                    <div className="col-auto noPaddingMobRight">
                      <a className="navbar-brand" href="/">
                        <img src={SolisLogo} alt="SOLIS Inc" />
                      </a>
                    </div>
                    <div className="col-auto flex-fill noPaddingMobLeft">
                      <div className="menu-login-btn">
                        <div className="menu-list" style={{ zIndex: "999" }}>
                          <ul className="navbar-nav">
                            <li className="nav-item">
                              <Link to="/" onClick={() => setIsSideBarOpen(false)}>
                                Home
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to="/market" onClick={() => setIsSideBarOpen(false)}>
                                Market
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/profile"
                                onClick={() => setIsSideBarOpen(false)}
                              >
                                Profile
                              </Link>
                            </li>
                            <li className="nav-item has-sub-menu">
                              <a href="#!">Community</a>
                              <em
                                data-bs-toggle="collapse"
                                data-bs-target="#hasmenu3"
                                aria-expanded={
                                  communityExpandedButton ? "true" : "false"
                                }
                                aria-controls="hasmenu3"
                                onClick={() => {
                                  setCommunityExpandedButton(
                                    !communityExpandedButton
                                  );
                                  setCommunityShowSubmenu(!communityShowSubmenu);
                                }}
                              ></em>
                              <ul
                                className={
                                  communityShowSubmenu
                                    ? "dropdown-menu-list collapse show"
                                    : "dropdown-menu-list collapse"
                                }
                                role="menu"
                                id="hasmenu3"
                              >
                                <li>
                                  <a
                                    href="https://twitter.com/solis_io/"
                                    target="_blank"
                                    onClick={() => {
                                      setCommunityShowSubmenu(false);
                                      setCommunityExpandedButton(false);
                                      setIsSideBarOpen(false);
                                    }}
                                  >
                                    Twitter
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="https://www.instagram.com/solis.io/"
                                    target="_blank"
                                    onClick={() => {
                                      setCommunityShowSubmenu(false);
                                      setCommunityExpandedButton(false);
                                      setIsSideBarOpen(false);
                                    }}
                                  >
                                    Instagram
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="https://discord.io/Solis_Inc"
                                    target="_blank"
                                    onClick={() => {
                                      setCommunityShowSubmenu(false);
                                      setCommunityExpandedButton(false);
                                      setIsSideBarOpen(false);
                                    }}
                                  >
                                    Discord
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="https://medium.com/@SolisLabs"
                                    target="_blank"
                                    onClick={() => {
                                      setCommunityShowSubmenu(false);
                                      setCommunityExpandedButton(false);
                                      setIsSideBarOpen(false);
                                    }}
                                  >
                                    Medium
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="https://www.solis.io/press.html"
                                    target="_blank"
                                    onClick={() => {
                                      setCommunityShowSubmenu(false);
                                      setCommunityExpandedButton(false);
                                      setIsSideBarOpen(false);
                                    }}
                                  >
                                    Press
                                  </a>
                                </li>
                              </ul>
                            </li>
                            {/* <li className="nav-item has-sub-menu">
                          <a href="#!">Ecosystem</a>
                          <em
                            data-bs-toggle="collapse"
                            data-bs-target="#hasmenu3"
                            aria-expanded={
                              ecosystemExpandedButton ? "true" : "false"
                            }
                            aria-controls="hasmenu3"
                            onClick={() => {
                              setEcosystemExpandedButton(
                                !ecosystemExpandedButton
                              );
                              setEcosystemShowSubmenu(!ecosystemShowSubmenu);
                            }}
                          ></em>
                          <ul
                            className={
                              ecosystemShowSubmenu
                                ? "dropdown-menu-list collapse show"
                                : "dropdown-menu-list collapse"
                            }
                            role="menu"
                            id="hasmenu3"
                          >
                            <li>
                              <a
                                href="https://www.solislabs.io/"
                                target="_blank"
                                onClick={() => {
                                  setEcosystemShowSubmenu(false);
                                  setEcosystemExpandedButton(false);
                                  setIsSideBarOpen(false);
                                }}
                              >
                                Solis Labs
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.solisstudios.com/"
                                target="_blank"
                                onClick={() => {
                                  setEcosystemShowSubmenu(false);
                                  setEcosystemExpandedButton(false);
                                  setIsSideBarOpen(false);
                                }}
                              >
                                Solis Studios
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://hmblhouse.com/"
                                target="_blank"
                                onClick={() => {
                                  setEcosystemShowSubmenu(false);
                                  setEcosystemExpandedButton(false);
                                  setIsSideBarOpen(false);
                                }}
                              >
                                HMBL House
                              </a>
                            </li>
                          </ul>
                        </li> */}
                            <li className="nav-item has-sub-menu">
                              <a href="#!">Help</a>
                              <em
                                data-bs-toggle="collapse"
                                data-bs-target="#hasmenu3"
                                aria-expanded={
                                  communityExpandedButton ? "true" : "false"
                                }
                                aria-controls="hasmenu3"
                                onClick={() => {
                                  setCommunityExpandedButton(
                                    !communityExpandedButton
                                  );
                                  setCommunityShowSubmenu(!communityShowSubmenu);
                                }}
                              ></em>
                              <ul
                                className={
                                  communityShowSubmenu
                                    ? "dropdown-menu-list collapse show"
                                    : "dropdown-menu-list collapse"
                                }
                                role="menu"
                                id="hasmenu3"
                              >
                                <li>
                                  <Link
                                    to="/faq"
                                    onClick={() => setIsSideBarOpen(false)}
                                  >
                                    General FAQs
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/kyc"
                                    onClick={() => setIsSideBarOpen(false)}
                                  >
                                    KYC Guide
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/buyWithFiat"
                                    onClick={() => setIsSideBarOpen(false)}
                                  >
                                    Buying with FIAT $
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/buyWithCrypto"
                                    onClick={() => setIsSideBarOpen(false)}
                                  >
                                    Buying with Crypto
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/addingFunds"
                                    onClick={() => setIsSideBarOpen(false)}
                                  >
                                    Adding funds
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                        {
                          address ? (
                            <span className={classes.connectedTimeout}>
                              {isTimeout ? (
                                <>
                                  Connected
                                  <DoneIcon color="success" />
                                </>
                              )
                              : null}
                              <a className="btn-primary" onClick={disconnect}>
                              <strong>
                                <span>Disconnect Wallet </span>
                              </strong>
                            </a>
                            </span>
                          ) : (
                            <a className="btn-primary" onClick={openConnectModal}>
                              <strong>
                                <span>Connect Wallet </span>
                              </strong>
                            </a>
                          )}
                        <div className="menu-list-show" style={{ margin: "0px" }}>
                          <ul className="navbar-nav">
                            <li
                              className={
                                isMobile
                                  ? "nav-item has-sub-menu-profile"
                                  : "nav-item has-sub-menu"
                              }
                            >
                              {authState.isLoggedIn ? (
                                <>
                                  <div className="headerUserImageDiv">
                                    <img
                                      src={
                                        userState.profilePicture === "#" ||
                                          userState.preSignedProfileUrl === undefined ||
                                          userState.preSignedProfileUrl === null
                                          ? avatarPlaceholder
                                          : userState.preSignedProfileUrl
                                      }
                                      className={classes.avatar}
                                      onClick={() => {
                                        setProfileShowSubmenu(!profileShowSubmenu);
                                      }}
                                      onLoad={(e) => { setIsImageLoaded(true) }}
                                      style={{ display: isImageLoaded ? "block" : "none" }}
                                    >
                                    </img>
                                    <CircularProgress className="headerCircularProgress" style={{ display: isImageLoaded ? "none" : "block", color: '#f67622' }} />
                                  </div>
                                </>

                              ) : null}
                              <ul
                                className={
                                  profileShowSubmenu
                                    ? "dropdown-menu-list collapse show"
                                    : "dropdown-menu-list collapse"
                                }
                                role="menu"
                                id="hasmenu2"
                              >
                                {authState.isProfileComplete ? (
                                  <li
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setProfileShowSubmenu(false);
                                    }}
                                  >
                                    <Link to={"/edit/"}>Edit Profile</Link>{" "}
                                  </li>
                                ) : null}{" "}
                                <li
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    logout();
                                    setProfileShowSubmenu(false);
                                  }}
                                >
                                  {" "}
                                  <a>Logout </a>{" "}
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                        <div
                          className={
                            isSideBarOpen
                              ? "sidebar-icon toggle-sidebar d-block d-lg-none is-visible"
                              : "sidebar-icon toggle-sidebar d-block d-lg-none"
                          }
                          onClick={() => setIsSideBarOpen(!isSideBarOpen)}
                        >
                          <span className="line1"></span>
                          <span className="line2"></span>
                          <span className="line3"></span>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </nav>
          </header>
      }
      <div className="bg-overly"></div>
    </>
  );
};
export default Header;
