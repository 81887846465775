import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./individualNftPage.style";
import { Link, useLocation, generatePath, useHistory } from "react-router-dom";
import IndividualNftCard from "../../Components/NftCollection/IndividualNftCard";
import { useParams } from "react-router-dom";
import { pusher } from "../../services/Vendor/Pusher/pusherService"
import { Channels } from "../../services/Vendor/Pusher/pusherChannels"
import { Events } from "../../services/Vendor/Pusher/pusherChannels"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { axiosCustomInstance } from "../../services/api";
import getAPIHostName from "../../utils/utils";
import axiosInstance from "../../services/api";

import Web3 from "web3";

import BuyNowNftCarousels from "../../Components/Carousel/BuyNowNftCarousels";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import clsx from "clsx";
import Loader from "../../Components/Loader/Loader";

import NftInfoComponent from "../../Components/NftCollection/NftInfoComponent";
import { useDispatch } from "react-redux";
import { setMessage } from "../../slices/message.slice";
const Market = () => {

  const [isDomLoaded, setIsDomLoaded] = useState(false);
  const [nftData, setNFtData] = React.useState()
  const [refreshNft, setRefreshNft] = useState(false)
  const [currentCollectionName, setCurrentCollectionName] = useState()
  const [currentCollectionID, setCurrentCollectionID] = useState()
  const [collectionData, setCollectionData] = useState()
  const [collectionArray, setCollectionArray] = useState([])
  const messageState = useSelector((state) => state.message)
  const route = useParams();
  const dispatch = useDispatch();
  const allCollections = useSelector((state) => state.collections)
  const collectionID = allCollections.collectionIDs;


  var channel = pusher.subscribe(Channels.COLLECTIBLE);
  channel.bind(Events.COLLECTIBLE.REFRESH, (data) => {
    // Method to be dispatched on trigger.  
    if (data.message === route.id) {
      // setRefreshNft(true) 
      console.log("NFT has been sold already")
      dispatch(setMessage({ type: "error", message: "NFT has been sold! Refreshing now", timeStamp: Date.now() }))
      window.location.reload()
    }
  });


  useEffect(() => {
    async function performAsync() {
      setIsDomLoaded(false);
      try {
        const { data } = await axiosInstance.get(process.env.REACT_APP_BASEURL + `/api/collectible/${route.id}`);
        setNFtData(data.data)
        ////console.log("nftData", data)
        setCurrentCollectionID(data.data.collectionId)
        setCurrentCollectionName(route.collections)
        const res = await axiosInstance.get(process.env.REACT_APP_BASEURL + `/api/collection/${data.data.collectionId}`);
        res && setCollectionData(res.data.data)
        res && setCollectionArray(res && res.data.data.categories[0].collectibleList)

      } catch (error) {
        ////console.log(error)
      }
      setIsDomLoaded(true);
    }

    performAsync()
  }, [currentCollectionID, route.id]);


  async function fetchNextAvailableNft() {
    const res = await axiosCustomInstance.get(getAPIHostName() + `/api/collection/${nftData.collectionId}`)
    return res.data
  }

  useEffect(() => {
    let collectibleArray = [];
    if (refreshNft) {
      fetchNextAvailableNft().then((collectibleList) => {
        collectibleArray = collectibleList.data.categories[0].collectibleList.map((collectible) => {
          return collectible
        })
        const randomCollectible = collectibleArray.sort(() => 0.5 - Math.random())[0];
        history.push(`/AFFECT_CHANGE/collectible/${randomCollectible.id}`)
      })
    }

  }, [refreshNft])

  useEffect(() => {
    if (messageState.message && messageState.message === "Oops! NFT has been sold. Refreshing new NFT") {
      ////console.log("set refresh nft")
      setRefreshNft(true)
    }
  }, [messageState.timeStamp])



  const history = useHistory();



  //mock data for properties 
  const propertiesData = {
    // "rarity": [
    //   {
    //     "name": "property 1",
    //     "value": "10%"
    //   },
    //   {
    //     "name": "property 2",
    //     "value": "8%"
    //   },
    //   {
    //     "name": "property 3",
    //     "value": "27%"
    //   }
    // ]
  }
  const revertBack = () => {
    history.push(`/collection/${currentCollectionID}`)
  }
  if (!isDomLoaded) return <Loader />
  else
    return (
      <>
        <main className="main-content-wrapper">
          <div className="container" style={{ marginTop: '25px', marginBottom: '20px', cursor: 'pointer' }}>
            <div className="link-div" onClick={() => history.push({
              pathname: generatePath("/collections/:id", { id: nftData.collectionId }),
            })}>
              <KeyboardBackspaceIcon sx={{ cursor: 'pointer' }} />
              Back to Collections
            </div>
          </div>
          <section className="section-block individual-block main-banner">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <IndividualNftCard nftDetail={nftData} currentCollectionID={currentCollectionID} currentCollectionName={currentCollectionName} />
                  {/* <div className="arrow-left-right arrow-inner viewCollectionSection">
                    <div className="main-title mb-3">
                      <h2 className="font-md smallHeaderStyle" style={{ cursor: "pointer" }}>
                        <span onClick={() => history.push({
                          pathname: generatePath("/collections/:id", { id: currentCollectionID }),
                        })}>VIEW THE COLLECTION</span>
                      </h2>
                    </div>
                    {
                      collectionArray && <div className="overflow-hidden overflow-hidden-padding" style={{ position: 'relative', right: 10 }}>
                        <div className="category-items gridthree-slider">
                          <BuyNowNftCarousels
                            array={collectionArray ? collectionArray : []}
                            currentId={nftData.id}
                            collectionName={currentCollectionName}
                          />
                        </div>
                      </div>
                    }
                  </div> */}
                </div>
                <NftInfoComponent nftData={nftData} currentCollectionID={currentCollectionID} currentCollectionName={currentCollectionName} description={collectionData.description} about={nftData.description} />
              </div>
            </div>
          </section>

        </main>

      </>
    );
};
export default Market;
