import React from 'react'
import Card from "../Card/Card";
const ListWithoutFilter = ({data}:any) => {
  return (
    <>
    <div className='category-items memoribilia-slider scrollable'>
    <div className="default-collection-container">
      {data && 
       data.map((item: any, i:any) => {
          return (
            <>
              <Card
                key={i}
                id={item.id}
                imageURL={item.mediaLocation}
                cardTitle={item.displayName}
                cardArtistName={item.name}
                price={item.price}
                timeRemaining={item.count}
                mediaType={item.mediaType}
                currency={item.currency}
                collectionName={item.collectionName}
                status={item.status}
              />
            </>
          );
        })}
    </div>
          </div>     
    </>
  )
}

export default ListWithoutFilter