import { MagicAuthConnector } from '../services/Vendor/Magic/magicAuthConnector';
import { MAGIC_API_KEY } from "../services/Vendor/Magic/magic";
import { CURRENT_NETWORK } from './networks';
import { Chain } from 'wagmi';
import { createMagicInstance } from '../services/Vendor/Magic/magic';

export interface MyWalletOptions {
    chains: Chain[];
  }


export const rainbowMagicWallet = ({ chains }: MyWalletOptions) => ({
  id: 'magic',
  name: 'Magic',
  iconUrl: 'https://svgshare.com/i/iJK.svg',
  iconBackground: '#fff',
  createConnector: () => {
    const magicSdk = createMagicInstance();
    const connector = new MagicAuthConnector({
      chains,
      options: {   
        apiKey: MAGIC_API_KEY,
        magicSdkConfiguration: {
          network: {
            rpcUrl: CURRENT_NETWORK.rpcUrl, // your ethereum, polygon, or optimism mainnet/testnet rpc URL
            chainId: CURRENT_NETWORK.id,
          },
        },
        //...Other options (check out full API below)
      },
    }, magicSdk);
    //REVISIT here to pass magicSdk
    ////console.log("Connector", connector);
    return {
      connector,
    };
  },
});
