import { useState, useEffect, useContext, useRef } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, setIsLoading } from "../../slices/auth.slice";
import { getUserDetail, setUser } from "../../slices/user.slice";
import { Loader } from "../../Components/Loader/Loader";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import solisPromotionVideo from "../../assets/images/solisPromotionVideo.mp4"
import tooltipBlack from "../../assets/images/tooltipBlack.png";

import {
  magic,
  googleOauthLogin,
  appleOauthLogin,
  facebookOauthLogin,
} from "../../services/auth.service";
import { Box } from "@mui/system";
import { useStyles } from "./sign-in.style";
import SolisLogo from "../../assets/images/solis-logo.svg";
import CircularProgress from "@mui/material/CircularProgress";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import AppleIcon from "@mui/icons-material/Apple";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from '@mui/icons-material/Email';
import { createBrowserHistory } from "history";
import { isCommunityResourcable } from "@ethersproject/providers";
import { Magic } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";
import {
  EmailContext,
  EmailDispatchContext,
} from "../../global/context/emailContext";
import { ConnectBtn } from "../RainbowKit/connectBtn";
import { createMagicInstance } from "../../services/Vendor/Magic/magic";

const Signin = (props) => {
  const history = createBrowserHistory();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const emailContextValue = useContext(EmailContext);
  const setEmailContextValue = useContext(EmailDispatchContext);
  const [licenseAgreement, setLicenseAgreement] = useState();
  const [emailLoginOption, setEmailLoginOption] = useState(false)
  const dispatch = useDispatch();
  const [videoEnded, setVideoEnded] = useState(false)
  const { isLoading } = useSelector((state) => state.auth);
  const videoRef = useRef(null)

  const location = useLocation();

  const magic = createMagicInstance()

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading])

  useEffect(() => {
    let timer;
    if (!isLoading) {
      // if (videoRef.current.muted) {
      //   videoRef.current.muted = false;
      // }
      timer = setTimeout(() => {
        props.setHeaderTextModalOpenFunction(true)
        setVideoEnded(true);
      }, 13000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isLoading]);

  const handleChange = (event) => {
    setEmail(event.target.value);
    setEmailContextValue(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      // dispatch(setIsLoading(false));
      ////console.log("email is invalid");
      return;
    }

    try {
      // if (queryParam !== undefined) {
      // await dispatch(login({ email, tokenParam, postData })); 
      //}
      await dispatch(login({ email }));
      if (login.isLoggedIn) {
        await dispatch(getUserDetail({ email }));
      }
      // history.push("/");
      return <Redirect to={history}></Redirect>;
    } catch (error) {
      ////console.log(error);
    }
  };

  const handleSocialLogin = async (event, provider) => {
    event.preventDefault();
    await magic.oauth.loginWithRedirect({
      provider,
      redirectURI: process.env.REACT_APP_MAGIC_REDIRECT_URI + "/magic/callback",
    });
  };

  const handleLicenseAgreementCheckbox = (event) => {
    setLicenseAgreement(event.target.checked);
    console.log(event.target.checked)
  };
  const { classes } = useStyles();

  const style = {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "black",
    border: "1px solid #f67622",
    boxShadow: "0px 0px 30px 0px #f67622",
    letterSpacing: 3,
    p: 4,
    "@media(max-width:500px)": {
      width: 350,
    },
  };

  const handleDisabledSubmit = (e) => {
    e.preventDefault();
    // if (email) {
    //   setLicenseAgreement(true);
    // }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) :
        videoEnded ?
          (
            <>

              <Box className={classes.formContainer} sx={style}>

                <img src={SolisLogo} className={classes.logo}></img>

                <div className={classes.loginHeadingText}>SignUp or Login with</div>

                <div className={classes.loginOptions} onClick={() => setEmailLoginOption(!emailLoginOption)}><span>Email  </span><EmailIcon data-testid="emailLogin" className={classes.socialIcons} fontSize="large" /> </div>
                {
                  emailLoginOption ?
                    <>

                      <form>
                        <div className={classes.emailContainer}>
                          <input
                            data-testid="emailAddress"
                            label="Email Address"
                            onChange={handleChange}
                            placeholder="Email Address"
                            className={classes.emailField}
                          />
                          <div className={classes.checkboxContainer}>
                            <Checkbox
                              checked={licenseAgreement}
                              onChange={handleLicenseAgreementCheckbox}
                              inputProps={{ "aria-label": "controlled" }}
                              label="License Agreement"
                              sx={{
                                color: "#f67622",
                                "&.Mui-checked": {
                                  color: "#f67622",
                                },
                                padding: "0px",
                                margin: "0px",
                                marginRight: "2px",
                              }}
                            />
                            <FormLabel
                              id="demo-radio-buttons-group-label"
                              style={{ color: "#f67622", fontFamily: "Saira" }}
                            >
                              I agree to
                              <StyledTooltip
                                placement="bottom"
                                title={
                                  <>
                                    <div className={classes.tooltipParent}>
                                      By logging in to your SOLIS.Market user account, you
                                      are agreeing to all the Terms and Conditions,
                                      Policies, License Agreements and all other
                                      conditions outlined on the SOLIS.Market website (and
                                      its affiliate{" "}
                                      <a
                                        href="https://solis.io"
                                        target="_blank"
                                        style={{
                                          color: "#f67622",
                                          textDecoration: "underline 2px #f67622",
                                        }}
                                      >
                                        website
                                      </a>{" "}
                                      and applications).
                                    </div>
                                  </>
                                }
                              >
                                <span className={classes.termsAndConditions}>
                                  {" "}
                                  Terms and Conditions
                                </span>
                              </StyledTooltip>
                            </FormLabel>
                          </div>
                          {licenseAgreement ? (
                            <button
                              type="submit"
                              onClick={handleSubmit}
                              className="btn-primary"
                              style={{ width: "50%", margin: "auto" }}
                            >
                              Submit
                            </button>
                          ) : (
                            <button
                              className="btn-primary-disabled"
                              style={{
                                width: "50%",
                                margin: "auto",
                              }}
                              onClick={handleDisabledSubmit}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </form>
                    </>
                    :
                    <>
                      <div className={classes.loginOptions} onClick={(e) => handleSocialLogin(e, "google")}><span>Google </span><GoogleIcon data-testid="googleLogin" className={classes.socialIcons} fontSize="large" /></div>
                      <div className={classes.loginOptions} onClick={(e) => handleSocialLogin(e, "twitter")}><span>Twitter</span><TwitterIcon data-testid="twitterLogin" className={classes.socialIcons} fontSize="large" /></div>
                      <div className={classes.loginOptions} onClick={(e) => handleSocialLogin(e, "apple")}><span>Apple  </span><AppleIcon data-testid="appleLogin" className={classes.socialIcons} fontSize="large" /></div>
                    </>
                }






                {/* <GoogleIcon
                data-testid="googleLogin"
                onClick={(e) => handleSocialLogin(e, "google")}
                className={classes.socialIcons}
                fontSize="large"
              ></GoogleIcon> */}
                {/* <FacebookIcon
                onClick={(e) => handleSocialLogin(e, 'facebook')}
                className={classes.socialIcons}
                fontSize="large"
              ></FacebookIcon> */}
                {/* <AppleIcon
                onClick={(e) => handleSocialLogin(e, "apple")}
                className={classes.socialIcons}
                fontSize="large"
              ></AppleIcon>
              <TwitterIcon
                onClick={(e) => handleSocialLogin(e, "twitter")}
                className={classes.socialIcons}
                fontSize="large"
              ></TwitterIcon> */}

              </Box>
            </>
          )
          :
          <video width="100%" height="100%" onEnded={() => setVideoEnded(true)} autoPlay muted
          >
            <source src={solisPromotionVideo} type="video/mp4" />
          </video>
      }
    </>
  );
};
export default Signin;

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: black;
    color: #f67622;
    box-shadow: 0px 0px 10px 0px #f67622;
    border: 2px solid #f67622;
    width: 900px;
    height: 200px;
    font-size: 14px;
  }
`;
