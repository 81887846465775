import * as atatus from 'atatus-spa';
import { Provider } from "react-redux";
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react'
import {
  persistStore
} from 'redux-persist';

import App from './App';
import './assets/css/main.scss'

import store from './store';
// import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';
import { Web3Provider } from '@ethersproject/providers'

atatus.config(process.env.REACT_APP_ATATUS_KEY).install();

let persistor = persistStore(store)
function getLibrary(provider) {
  return new Web3Provider(provider)
}

ReactDOM.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <Web3ReactProvider getLibrary={getLibrary}> */}
        <App />
        {/* </Web3ReactProvider> */}
      </PersistGate>
    </Provider>
  </>,
  document.getElementById('root')
);
