import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()({
  gradientHeading: {
    //fontFamily: "Saira",
    fontStyle: "normal",
    fontWeight: "600",
    textAlign: "center",
    //textTransform: "uppercase",
    background: "linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    "&:hover": {
      cursor: "pointer",
    },
    //opacity: "0.5",
    //borderBottom: "2px solid #ffffff",
    paddingTop: 5,
    paddingBottom: 5,
  },
  profileContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    minHeight: "70vh",
    "@media(max-width:1150px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "90%",
      margin: "auto",
    },
    "@media(max-width:500px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      margin: "auto",
    },
  },
  imageContainer: {
    paddingTop: '20px',
    width: "15%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media(max-width:1150px)": {
      width: "100%",
    },
  },
  aboutContainer: {
    width: "20%",
    paddingTop: "20px",
    "@media(max-width:1150px)": {
      textAlign: "center",
      width: "100%",
    },
  },
  imageAndAboutContainer: {
    width: '25%',
    "@media(max-width:1150px)": {
      textAlign: "center",
      width: "100%",
    },
    "@media(min-width:1150px)": {
      paddingTop: '0px'
    },
  },
  profilePictureContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    justifyContent: 'center',
    justifyItems: 'center',
    gridRowGap: 15,
    "@media(max-width:1150px)": {
      display: 'flex',
      flexDirection: 'column'
    },
  },
  displayContainer: {
    width: "75%",
    "@media(max-width:1150px)": {
      textAlign: "center",
      width: "100%",
    },
  },
  formFieldContainer: {
    width: "80%",
    margin: "auto",

    "@media(max-width:1000px)": {
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
  orangeGradientData: {
    color: "#F67622",
    marginTop: "0",
    marginBottom: "0",
  },
  socialMediaIcons: {
    height: "25px",
    width: "25px",
    marginLeft: "2px",
    marginRight: "2px",
  },
  aboutContent: {
    //fontFamily: "saira",
    marginTop: '10px',
    padding: '10px',
    paddingRight: '0px',
    paddingLeft: '0px',
    textAlign: 'center',
    // overflowY: 'scroll',  
    "@media(max-width:1150px)": {
      marginBottom: '40px'
    }
  },
  activeClassUnderline: {
    opacity: "1",
  },
  cardsContainer: {
    padding: "20px",
    marginTop: "40px",
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(220px, 1fr))",
    gridColumnGap: "20px",
    gridRowGap: "40px",
    "@media(max-width:768px)": {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  inputProps: {
    marginBottom: "40px !important",
  },
  formParentDiv: {
    width: "80%",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "50% 50%",
    "@media(max-width:1000px)": {
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
  textFieldContainer: {
    display: "flex",
    flexDirection: "column",
  },
  socialMediaFieldDivContainer: {
    display: "grid",
    gridTemplateColumns: "49% 49% ",
    gridColumnGap: "2%",
    "@media(max-width:500px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  imageInputContainer: {
    display: "flex",
    justifyContent: "center",
  },
  changeAvatar: {
    display: "flex",
    justifyContent: "center",
    cursor: 'pointer'
  },
  errorField: {
    color: "#f67622",
    fontFamily: "Saira",
    backgroundColor: "transparent",
    border: "1px solid red",
    boxShadow: "0px 0px 5px 0px red",
    padding: "12px 20px",
    marginBottom: "20px",
  },
  inputFields: {
    color: "#f67622",
    fontFamily: "Saira",
    backgroundColor: "transparent",
    border: "1px solid #f67622",
    boxShadow: "0px 0px 5px 0px #f67622",
    padding: "12px 20px",
    marginBottom: "20px",

  },
  checkBoxWithText: {
    display: 'flex',
  },
  formAndCheckBoxContainer: {
    display: 'flex'
  },
  individualCheckBox: {

  },
  tooltipParent: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  tooltipDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  displayName: {
    //fontFamily: "Saira",
    fontStyle: "normal",
    fontWeight: "normal",
    background: "linear-gradient(90deg, #f67622 0.01%, #CB0594 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    marginTop: '10px',
    textAlign: 'center',
    "&:hover": {
      cursor: "pointer",
    },
  },
  profilePicture: {
    position: 'relative',
    borderRadius: "50%",
    width: "150px",
    height: "150px",
    border: "2px solid #ffffff66",
    "@media(min-width:1150px)and (max-width:1350px)": {
      width: '100px',
      height: '100px'
    }
  },
  additionalDetails: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    gridColumnGap: 10,
    gridRowGap: 10,
    justifyItems: 'center',
    marginTop: 10,
    "@media(min-width:1150px)": {
      paddingRight: '30px',
      paddingLeft: '30px'
    },
    "@media(max-width:1150px)": {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginTop: '20px'
    },
  },
  editButton: {
    position: 'absolute',
    right: '5px',
    bottom: '0px',
    height: '25px',
    width: '25px',
    cursor: 'pointer',
    "@media(max-width:1350px)and (min-width:1151px)": {
      right: '100px'
    },
    "@media(max-width:1150px)": {
      right: '20px'
    },
  },
  saveButton: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Aldrich'
  },
  profilePictureDiv: {
    "@media(max-width:1150px)": {
      margin: 'auto'
    }
  },
  modal: {
    display: 'flex',
    margin: 'auto auto',
    flexDirection: 'column',
    alignItems: 'center', alignSelf: 'center',
    alignContent: 'center',
    marginTop: '30px'
  },
  kycStatusDiv: {
    display: 'grid',
    alignItems: 'center',
    marginBottom: '10px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '13px',
  },
  kycStatusIconDiv: {
    marginLeft: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tabs: {
    background: 'white',
    borderRadius: '5px 5px 0 0',
  },
  blockpassLogo: {
    width: '200px',
    height: '50%',
    paddingLeft: '2px',
  },
  redErrorMessage: {
    color: 'red'
  },
  profileAccountSection:{
    padding:'40px',
    display:'flex',
    flexDirection:'column',
    gap:'20px',
    '@media(max-width:1149px)':{
      justifyContent:'center',
      alignItems:'center',
      gap:'50px'
    },
    '@media(max-width:786px)':{
      justifyContent:'center',
      alignItems:'center',
      gap:'50px'
    },
    '@media(max-width:542px)':{
      justifyContent:'center',
      alignItems:'center',
      gap:'50px',
      padding:'10px'
    },
    flexWrap:'wrap'
  },
  disconnectWallet:{
    padding:'5px', 
    fontSize:"0.7rem" ,
    borderRadius:'5px',
    color:'#f67622',
    background:'rgb(19,22,35)',
    border:'1px solid #f67622',  
    marginLeft:'10px',
    '&:hover':{
      cursor:'pointer',
      color:'black',
      background:'#f67622'
    }
  },
  walletAddressAndImageDiv:{
    display:'flex',
    gap:'10px',
    '@media(max-width:786px)':{
      justifyContent:'center'
    },
    flexFlow:'wrap'
  },
  addBalanceDiv:{
    display:'flex',
    flexDirection:'column',
    gap:'20px',
    '@media(max-width:1150px)':{
      justifyContent:'center',
      alignItems:'center',
      gap:'50px',
      padding:'10px',
      width:'100%'
    },
  },
  profileSectionSwitchWallet : {
    padding:'40px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  profileSectionSwitchWalletMargin:{
    padding:'40px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    marginTop:'50px'
  },  
  currencyRadioGroup:{
    marginTop:'40px',
    marginBottom:'20px',
    display:'flex',
    justifyContent:'center',
    gap:'20px',
    margin:'auto',
    '@media(max-width:542px)':{
      flexDirection:'column',
      justifyContent:'center',
      alignContent:'center',
      alignItems:'center',
      marginTop:'40px'
    }
  
  },
  vendorSelectionModal:{
      margin:'auto',
      "@media (min-width: 601px) and (max-width: 960px)": {
        height: "30vh",
        width: "50vw",
        marginTop:'20vh',
  
      },
      "@media (min-width: 961px) and (max-width: 1280px)": {
        height: "30vh",
        width: "40vw",
        marginTop:'25vh',
  
      },
      "@media (min-width: 1281px)": {
        height: "40vh",
        width: "30vw",
        marginTop:'30vh',
      },
      "@media (max-width: 600px)": {
        height: "40vh",
        width: "70vw",
        marginTop:'30vh',
      },
      padding:'30px',
      border:'1px solid #f67622',
      boxShadow:'0px 0px 20px 0px #f67622',
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      textAlign:'center',
      alignItems:'center',
      backgroundColor:'rgb(20,22,35)'
  },
  currencyAndBalanceContainer:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    gap:'20px',
    width:'100%',
    "@media (max-width: 991px)": {
      flexWrap:'wrap',
      justifyContent:'center',
      alignItems:'center',
      textAlign:'center',
      gap:'10px'
    },
    "@media (max-width: 767px)": {
      flexDirection:'column',
      flexWrap:'wrap',
      justifyContent:'center',
      alignItems:'center',
      textAlign:'center',
      gap:'10px'
    },
  },
  currencyName:{
    fontFamily:'Saira',
    fontWeight:'900'
  },
  balanceText:{
    color:'#f67622',
    fontFamily:'Archivo'
  },
  flexText:{
    display:'flex',
    justifyContent:'space-between',
    flexWrap:'wrap',
    width:'60%',
    "@media (max-width: 1199px)": {
      width:'70%',
      justifyContent:'space-between',
    },
    "@media (max-width: 992px)": {
      width:'70%',
      justifyContent:'space-between',
    },
    "@media (max-width: 767px)": {
      width:'100%',
      justifyContent:'space-between',
    },
    "@media (max-width: 540px)": {
      width:'100%',
      justifyContent:'center',
      flexDirection:'column'
    },
  },
  subFlexText:{
    display:'flex',
    justifyContent:'space-between',
    width:'60%',
    "@media (max-width: 992px)": {
      width:'80%',
      justifyContent:'space-between',
    },
    "@media (max-width: 767px)": {
      width:'90%',
      justifyContent:'space-between',

    },
    "@media (max-width: 786px)": {
      width:'80%',
      justifyContent:'center',

    },
    "@media (max-width: 540px)": {
      width:'100%',
      justifyContent:'center',
      gap:'10px'
    },
  },
  fetchingTextWithLoader:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column',
    textAlign:'center',
    marginTop:'40px',
    gap:'10px'
  }
});
export { useStyles };
