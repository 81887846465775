import promiseRetry from 'promise-retry'


const DEFAULT_RETRY = 4

export default <T>(func:() => Promise<T>, retries = DEFAULT_RETRY) =>
  promiseRetry(
    (retry) =>
      func().catch((error) => {
        const message = error.message ?? error.response?.data?.error
        if (message?.includes('Invalid JSON RPC response: ""')) retry(error)
        else throw error
      }),
    { factor: 0, ...(Number.isSafeInteger(retries) ? { retries } : { forever: true }) },
  )