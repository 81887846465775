import {EnvVars} from '../constants/Env';

export const NETWORKS = {
  MATIC: {
    MAINNET: {
      id: 137,
      name: "Polygon Mainnet",
      network: "mainnet",
      version: "v1",
      explorer: "https://polygonscan.com/",
      //Use temporary from QCA 
      // infuraProviderApiKey: "a1053bce5abd45b2a7a91467e953f0d1", 
      //rpcUrl: 'https://polygon-mainnet.infura.io/v3/a1053bce5abd45b2a7a91467e953f0d1'
      infuraProviderApiKey: "4ba96753c0c84ca4b57268b7e99703e0", 
      rpcUrl: 'https://polygon-mainnet.infura.io/v3/4ba96753c0c84ca4b57268b7e99703e0',
      TOKEN_CONTRACTS: {
        USDC: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
        USDT: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      }
    },

    MUMBAI: {
      id: 80001,
      name: "Polygon Mumbai",
      network: "testnet",
      version: "v1",
      explorer: "https://mumbai.polygonscan.com/",
      //rpcProvider: "https://polygon-mumbai.infura.io/v3/f6dfe8d5dd0e40418557d079f528a345",
      //Use temporary from QCA 
      // infuraProviderApiKey: "f6dfe8d5dd0e40418557d079f528a345",
      // rpcUrl: 'https://polygon-mumbai.infura.io/v3/f6dfe8d5dd0e40418557d079f528a345',
      infuraProviderApiKey: '4ba96753c0c84ca4b57268b7e99703e0',
      rpcUrl: 'https://polygon-mumbai.infura.io/v3/4ba96753c0c84ca4b57268b7e99703e0',
      TOKEN_CONTRACTS: {
        USDC: "0xbc578632744826aF0179A177F41CaeCa1e907F94", //SOLIS USDC 6 decimals
        USDT: "0x4A2DDa6179482c85bd93C06A63A6Ba5dC7e0C776", //SOLIS USDT 6 decimals
      }
    },
  },
  ETHEREUM:{
    MAINNET: {
      id: 1,
      name: "ETH",
      network: "mainnet",
      version: "v1",
      explorer: "https://etherscan.io",
      rpcUrl: "https://mainnet.infura.io/v3/f6dfe8d5dd0e40418557d079f528a345",
      infuraProviderApiKey: "f6dfe8d5dd0e40418557d079f528a345"
    },

    GOERLI: {
      id: 5,
      name: "goerli",
      network: "testnet",
      version: "v1",
      explorer: "https://goerli.etherscan.io/",
      rpcUrl:"https://goerli.infura.io/v3/f6dfe8d5dd0e40418557d079f528a345",
      infuraProviderApiKey: "f6dfe8d5dd0e40418557d079f528a345",
    },
  }
};

export const CURRENT_NETWORK = EnvVars.ENV === 'prod' ?  NETWORKS["MATIC"]["MAINNET"] : NETWORKS["MATIC"]["MUMBAI"];
//export const CURRENT_NETWORK =NETWORKS["MATIC"]["MAINNET"];

