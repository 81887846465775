import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()({
  formContainer: {
    textAlign: "center",
    //height: '60vh',
    //width: '30vw',
    margin: "auto",
    //marginTop: '10vh',
    borderRadius: "10px",

    color: "white",
  },
  emailContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "80%",
    margin: "auto",
    marginBottom: "3vh",
    marginTop: "3vh",
    color: "white",
  },
  loginButton: {
    fontSize: "1.3em",
    color: "white",
    // background: 'linear-gradient(90deg, rgba(234, 156, 104, 1) 0 %, rgba(233, 84, 67, 1) 100 %)'
    background:
      "linear-gradient(90deg, rgba(234,156,104,1) 0%, rgba(233,84,67,1) 100%)",
    border: "none",
    height: "2.5em",
    cursor: "pointer",
    color: "white",
    boxShadow: '0px 0px 10px 0px #f67622'
  },
  socialIcons: {
    cursor: "pointer",

    "&:hover": {
      color: "orange",
    },
  },
  logo: {
    height: "20%",
    width: "70%",
    marginTop: "10%",
    marginBottom: '10%'
  },
  emailField: {
    height: "50px",
    fontSize: "1.1rem",
    fontWeight: "1.3rem",
    marginTop: "3vh",
    marginBottom: "3vh",
    backgroundColor: "black",
    color: "#f67622",
    fontFamily: 'Aldrich',
    boxShadow: '0px 0px 10px 0px #f67622',
    padding: '20px',
    borderRadius: '10px',
    border: '2px solid #f67622'
  },
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: '3vh'
  },
  termsAndConditions: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline 2px #f67622'
    }
  },
  loginOptions: {
    border: '1px solid #f67622',
    padding: '10px',
    fontSize: '18px',
    paddingLeft: '25px',
    paddingRight: '35px',
    borderRadius: '25px',
    width: '90%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 0px 20px 0px #f67622'
    }
  },
  loginHeadingText:{
    color:'#f67622', 
    textAlign:'center', 
    marginBottom:'40px', 
    fontWeight:'2em !important'
  },


});

export { useStyles };
