import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()({
    navbar: {
        width: '85%',
        height: '40px',
        margin: 'auto',
        color: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        marginTop: '1%'
    },
    navLogo: {
        maxWidth: '100%'
    },
    navLinksContainer: {
        marginLeft: 'auto',
        marginRight: '40px',
        fontFamily: 'Actor',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1.6rem',
        lineHeight: '4rem',
        letterSpacing: '0.1em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    navLinks: {
        textDecoration: 'none',
        margin: '0px 10px'
    },
    connectWalletButton: {
        color: 'white',
        background: 'linear-gradient(277.72deg, #C9009A -32.97%, #FC6401 55.57%, #F4F4F4 144.11%) padding-box,linear-gradient(277.72deg, #C9009A -32.97%, #FC6401 55.57%, #F4F4F4 144.11%) border-box',
        border: '2px solid transparent',
        minHeight: '44px',
        padding: '0px 20px',
        fontSize: '1.6rem',
        letterSpacing: '1px',
        fontStyle: 'normal',
        fontWeight: '900',
        minWidth: '130px',
        borderRadius: '6px',
        img: {
            marginRight: '8px'
        },
        '&:after': {
            display: 'none'
        },
        '&:hover, &:focus, &:active': {
            color: '#FC6401 !important',
            background: 'linear-gradient(#131623, #131623) padding-box,linear-gradient(277.72deg, #C9009A -32.97%, #FC6401 55.57%, #F4F4F4 144.11%) border-box !important',
            border: '2px solid transparent',
            boxShadow: 'none !important'
        },
        '&:disabled': {
            backgroundColor: '#ADBDBA',
            borderColor: '#ADBDBA',
            '&:hover, &:focus, &:active': {
                background: '#ADBDBA !important',
                borderColor: '#ADBDBA !important'
            }
        },
        '@media (max-width:767px)': {
            minWidth: '110px', minHeight: '40px'
        }
    },
    avatar: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        marginLeft: '10px',
        cursor: 'pointer',
        border: '1px solid #f67622',
        '&:hover': {

            boxShadow: '0px 0px 20px 0px #f67622',

        }
    },
    connectedTimeout: {
        color: '#4BB543',
        fontFamily: 'Actor'
    },
    profileLink: {
        '&:after': {
            background: 'none !important'
        }
    }
});

export { useStyles }
