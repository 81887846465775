import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()({
    minterHeading: {
        marginTop: '100px',
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: '2rem',
        lineHeight: '2rem',
        textAlign: 'center',
        textTransform: 'uppercase',
        background: 'linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        marginLeft: '5%'
    },
    orangegradientHeading: {
        fontFamily: 'Archivo',
        fontSize: '1.5rem',
        lineHeight: '3rem',
        background: 'linear-gradient(314.45deg, #C90099 -70.21%, #F67622 58.16%, #D8D8D8 143.73%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        position: 'relative',
        fontWeight: '900'
    },
    parentContainer: {
        width: '100%',
        '@media (min-width:901px)': {

            padding: '30px'
        },
        '@media (max-width:900px)': {
            display: 'flex',
            flexDirection: 'column',
            padding: '30px',

        },
        margin: 'auto',
        maxHeight: '2000px',
        display: 'flex',
        border: '1px solid #F67622',
        boxShadow: '0px 0px 30px 0px rgba(242,131,58,0.5)',
        borderRadius: '10px'
    },
    formContainer: {

    },
    uploadSection: {
        minWidth: '40%',
        maxWidth: '100vh',
        '@media(max-width:900px)': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    uploadSectionButtons: {
        color: 'white',
        border: '2px dashed #F67622',
        width: '90%',
        height: '30%',
        marginTop: '2%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    uploadButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: '60px',
        maxWidth: '200px',
        fontWeight: '600',
        padding: '10px 20px',
        fontSize: '1rem',
        lineHeight: '1rem',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        background: 'linear-gradient(314.45deg, #C90099 -70.21%, #F67622 58.16%, #D8D8D8 143.73%)',
        border: 'none',
        borderRadius: '6px',
        '&:hover': {
            cursor: 'pointer'
        },
        '@media(max-width:900px)': {
            marginTop: '20px'
        }
    },
    imageParentContainer: {
        maxHeight: '40%',
        maxWidth: '100%',
        marginTop: '2%'
    },
    imgPreviewContainer: {
        color: 'white',
        fontFamily: 'Actor',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    nftDetails: {
        minWidth: '55%',
        '@media(max-width:1000px)': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',

        }
    },
    containerDiv: {
        marginBottom: '40px'
    },
    containerDivTwo: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '40px',
        flexWrap: 'wrap'
    },
    inputContainer: {
        fontFamily: 'Aldrich',
        background: 'black',
        color: '#F67622',
        border: '1px solid #F67622',
        height: '50px',
        width: '100%',
        borderRadius: '2px',
        fontSize: '14px',
        backgroundColor: '#131623',
        paddingLeft: '14px',
        paddingRight: '14px',
        paddingTop: '16px',
        paddingBottom: '16px'
    },
    inputContainerHalf: {
        fontFamily: 'Aldrich',
        background: 'black',
        color: '#F67622',
        border: '1px solid #F67622',
        height: '50px',
        minWidth: '240px',
        borderRadius: '2px',
        fontSize: '14px',
        backgroundColor: '#131623',
        paddingTop: '16px',
        paddingLeft: '14px',
        paddingRight: '14px',
        paddingBottom: '16px',
        '&::placeholder': {
            color: '#F67622'
        }
    },
    inputContainerDescription: {
        background: '#131623',
        color: '#F67622',
        border: '1px solid #F67622',
        height: '100px',
        width: '100%',
        borderRadius: '2px',
        fontSize: '14px',
        paddingLeft: '14px',
        paddingRight: '14px',
        paddingTop: '16px',
        paddingBottom: '16px'
    },
    mintButton: {
        margin: 'auto',
        marginTop: '2vh',
        width: '30%',
        background: 'linear-gradient(277.72deg, #C9009A -32.97%, #FC6401 55.57%, #F4F4F4 144.11%)',
        borderRadius: '6px',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    propertiesModal: {
        margin: 'auto',
        minHeight: '50vh',
        width: '50vw',
        border: '1px solid #f67622',
        backgroundColor: 'black',
        opacity: '0.7',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20vh'
    },
    propertyInput: {
        display: 'flex',
        wordBreak: 'break-all',
        color: '#f67622',
        margin: 'auto'
    },
    gifPropertyInputContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: '40%'
    },
    remainingTimeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '40px',

    },
    remainingTimeInput: {
        fontFamily: 'Aldrich',
        background: 'black',
        color: '#F67622',
        border: '1px solid #F67622',
        height: '50px',
        minWidth: '50px',
        borderRadius: '2px',
        fontSize: '14px',
        backgroundColor: '#131623',
        paddingTop: '16px',
        paddingLeft: '14px',
        paddingRight: '14px',
        paddingBottom: '16px',
        '&::placeholder': {
            color: '#F67622'
        }
    },
    minterOptions: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '20px',
        marginBottom: '10px'
    },
    minterOptionsHeading: {
        fontFamily: 'Actor',
        color: '#f67622',
        fontSize: '1.3rem',
        '&:hover': {
            cursor: 'pointer'
        },
        paddingTop: '1px',
    },
    minterOptionsHeadingBorder: {
        textDecoration: 'underline',
        textDecorationColor: 'white',
        textUnderlineOffset: '3px'
    },
    selectButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
    },
    collectionSelect: {
        width: '30%',
        margin: 'auto',
        height: '30px',
        boxShadow: '0px 0px 30px 0px #f67622',
        borderRadius: '10px',
        marginBottom: '20px',
        cursor: 'pointer'
    },
    closePreview: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    bulkUploadParentContainer: {
        width: '100%',
        '@media (min-width:901px)': {
            padding: '30px'
        },
        '@media (max-width:900px)': {
            display: 'flex',
            flexDirection: 'column',
            padding: '30px',

        },
        margin: 'auto',
        maxHeight: '2000px',
        border: '1px solid #F67622',
        boxShadow: '0px 0px 30px 0px rgba(242,131,58,0.5)',
        borderRadius: '10px'
    },
    nftPreviewInputContainer: {
        fontFamily: 'Saira',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '50%',
        flexWrap: 'wrap',
        margin: 'auto',
        marginTop: '40px',
        marginBottom: '10px'
    },
    nftPreviewInput: {
        fontFamily: 'Saira',
        width: '320px',
        backgroundColor: 'rgb(19,22,35)',
        color: 'white',
        border: '1px solid #f67622'
    },
    fetchPreviewButton: {
        width: '10%',
        borderRadius: '10px',
        display: 'block',
        margin: 'auto',
        marginBottom: '20px',
        boxShadow: '0px 0px 30px 0px #f67622',
        '&:hover': {
            boxShadow: '0px 0px 30px 5px #f67622',
            backgroundColor: 'rgb(19,22,35)',
            color: 'white'
        }
    },
    statsDiv: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        fontFamily: 'Saira',
        textAlign: 'center'
    },
    tooltipDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tooltipParent: {
        padding: '10px',
        color: '#f67622'
    },
    plusButton: {
        border: '2px solid #f67622',
        marginTop: '5px',
        marginBottom: '5px',
        backgroundColor: 'black',
        color: '#f67622'
    },
    mintErrorModal: {
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '30vh',
        width: '40vw',
        border: '2px solid #f67622',
        marginTop: '20vh',
        background: 'black',
        color: '#f67622',
        boxShadow: '0px 0px 20px 0px #f67622',
        flexDirection:'column', 
        gap:'20px'
    }

});

export { useStyles }
