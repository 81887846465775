import { useState } from 'react';


import bgFlaresVideo from "../../assets/images/bg-video.mp4"
import img1 from "../../assets/images/Images Buying with Fiat/1.JPG"
import img2 from "../../assets/images/Images Buying with Fiat/2.JPG"
import img3 from "../../assets/images/Images Buying with Fiat/3.JPG"
import img4 from "../../assets/images/Images Buying with Fiat/4.png"
import img5 from "../../assets/images/Images Buying with Fiat/5.JPG"
import img6 from "../../assets/images/Images Buying with Fiat/6.JPG"
import img7 from "../../assets/images/Images Buying with Fiat/7.png"
import img8 from "../../assets/images/Images Buying with Fiat/8.JPG"
import img9 from "../../assets/images/Images Buying with Fiat/9.png"
import img10 from "../../assets/images/Images Buying with Fiat/10.png"
import img11 from "../../assets/images/Images Buying with Fiat/11.JPG"


import solisLogo from "../../assets/images/solis-logo.svg"
import transakImg from "../../assets/images/transakFullLogo.jpg"
const BuyWithFiat = () => {

    return (
        <>
            <div class="bg-video">
                <div class="embed-container ratio ratio-16x9">
                    <video class="embed-video" autoPlay loop muted preload controlsList="nodownload">
                        <source src={bgFlaresVideo} type="video/mp4" />
                    </video>
                </div>
            </div>
            <main className="main-content-wrapper">
                <section className="section-block main-banner">
                    <div className="container">

                        <div className='buy-guide-homepage'>

                            <div className='home-kyc-flex-container'>
                                <div>
                                    <p className='buy-options-heading-white-without-margin'>BUYING NFTs</p>
                                    <p className='buy-options-heading-white-without-margin'>with</p>
                                    <p className='buy-options-heading-white-without-margin'>FIAT $</p>
                                </div>
                                <div>
                                    <ul>
                                        <li>Credit Card</li>
                                        <li>Debit Card</li>
                                        <li>ACH</li>
                                        <li>Wire</li>
                                        <li>Google Pay</li>
                                        <li>Apple Pay</li>
                                        <li>SEPA</li>
                                        <li>&more...</li>
                                    </ul>
                                </div>
                            </div>

                            <div className='kyc-footer-div'>
                                <p className='kyc-footer-heading'>USER GUIDE</p>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {/* <img src={transakImg} className="footer-kyc-logo" style={{ display: 'flex', margin: 'auto' }} />
                                    <div style={{ textAlign: 'center' }}>Powered by</div> */}
                                </div>
                            </div>

                        </div>
                        <div className='flares-bg-container'>
                            <div className="steps-container-div">
                                <div className='buy-options-heading-white'> Buying NFTs using Fiat is easy !</div>
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Step 1</strong> - Go to the NFT you wish to buy in SOLIS.market</p>
                                    <p className='steps-orange'><strong className='orange-strong'>Step 2</strong> – Click on the <strong className='orange-strong'>“BUY NOW”</strong> button</p>
                                    <img src={img1} className='steps-images' />
                                </div>
                            </div>
                            <div className="steps-container-div">
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Step 3</strong> - Select <strong className='orange-strong'>“Connect Wallet”</strong> </p>
                                    <p className='steps-orange'>- The NFT purchase price and any fees are displayed (via the drop down)</p>
                                    <img src={img2} className='steps-images' />
                                </div>
                            </div>

                            <div className="steps-container-div">
                                <div className="steps-text-orange">
                                    <p className="steps-orange"><strong className='orange-strong' >Step 4</strong>  – Select the <strong className='orange-strong'>"Wallet"</strong> you wish to use for the purpose. </p>
                                    <div className='left-text-with-image'>
                                        <div>
                                            <p className="steps-orange">You can choose to connect to the major third-party wallets
                                                providers or use your MAGIC wallet.</p>
                                            <br />
                                            <p className="steps-orange">MAGIC is your free ‘in-built’ cryptocurrency wallet that is
                                                associated with your SOLIS User profile.</p>
                                            <br />
                                            <p className="steps-orange">You can fund and use any wallet you choose from the selection,
                                                in SOLIS.market. You can also switch between your wallets at
                                                any time in your Profile section.</p>
                                            <br />
                                        </div>
                                        <div>
                                            <img src={img3} className="steps-images" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="steps-container-div">
                                <div className="steps-text-orange">
                                    <p className="steps-orange"><strong className='orange-strong' >Step 5</strong>  – Click <strong className='orange-strong'>"Add Balance"</strong> </p>
                                    <div className='left-text-with-image'>

                                        <div>
                                            <p className="steps-orange">If you do not have sufficient funds in your selected wallet
                                                you can select your preferred currency and top up your
                                                wallet balance.</p>
                                            <br />
                                            <p className="steps-orange-without-margin">Please note, that depending on your Country of residence,
                                                not all currency selections may be available from the
                                                On-Ramp provider (if not, please go back and pick an
                                                alternate currency to fund your wallet).</p>
                                        </div>

                                        <div>
                                            <img src={img4} className="steps-images" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="steps-container-div">
                                <div className="steps-text-orange">
                                    <p className="steps-orange"><strong className='orange-strong' >Step 6</strong> - select your preferred on-ramp provider - <strong className='orange-strong'>"Payment Vendor"</strong> </p>
                                    <img src={img5} className='steps-images'></img>
                                    <p className="steps-orange">- SOLIS is integrated with multiple tier 1 secure payment gateways for Fiat-to-Crypto transactions.
                                    </p>
                                    <p className="steps-orange">- Those available (in your territory) will be displayed at this time.
                                    </p>
                                    <p className="steps-orange">- These payment providers allow Users to compliantly (KYC) on-ramp Fiat to Cryptocurrency for use in Solis’ Marketplace.
                                    </p>
                                </div>

                            </div>

                            <div className="steps-container-div">
                                <div className="steps-text-orange">
                                    <p className="steps-orange"><strong className='orange-strong' >Step 7</strong>  – The Payment Gateway (<strong className='orange-strong' style={{ fontStyle: 'italic' }}>secure widget</strong> ) will initiate your payment process.</p>
                                    <p className='steps-orange'>Transak will calculate and display the <strong className='orange-strong'>total cost</strong> of your Purchase, based on:</p>
                                    <div className='text-image-left-container'>
                                        <div className='text-flex-column-container'>
                                            <div>
                                                <p className="steps-orange-without-margin">- Your country of residence (KYC)</p>
                                                <p className="steps-orange-without-margin">- The applicable currency</p>
                                                <p className="steps-orange-without-margin">- Method of payment</p>
                                                <p className="steps-orange-without-margin">- Spot price for the cryptocurrency</p>
                                            </div>
                                            <div>
                                                <p className="steps-orange-italic">If this is your first time using the Payment Gateway, depending on the dollar value of your funding request and/or the policy of the Payment provider, you may need to go through a short KYC process – you only need to complete this once, then you are verified across the Payment provider’s network.
                                                </p>
                                                <br />

                                            </div>
                                        </div>
                                        <div>
                                            <img src={img6} className="steps-images" />
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="steps-container-div">
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Step 8</strong> – Select your preferred Payment Method and input your relevant details. </p>
                                    <div className='text-image-right-container'>
                                        <div>
                                            <img src={img7} className="steps-images" />
                                        </div>
                                        <div className='align-bottom-container'>
                                            <p className="steps-orange"><strong className='orange-strong'>Step 9 -</strong></p>
                                            <p className="steps-orange">Click <strong className='orange-strong'>"Accept Terms of Service"</strong> Box </p>
                                            <p className="steps-orange">Click<strong className='orange-strong'>"Confirm"</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="steps-container-div">
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Step 10</strong> - your funding process will then be initiated by the Payment Gateway. </p>
                                    <img src={img8} className='steps-images' />
                                    <p className='steps-orange'><strong className='orange-strong'>Step 11</strong> - Once your funds are cleared you can click <strong className='orange-strong'>Buy Now</strong> to finalize your purchase.</p>

                                </div>
                            </div>


                            <div className="steps-container-div">
                                <div className='buy-options-heading-white'>SUCCESS ! You purchased your NFT</div>
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Step 8</strong> - Click on <strong className='orange-strong'>"Profile"</strong> or navigate to your Profile page to view the NFT in your Collections. </p>
                                    <img src={img9} className='steps-images' />
                                </div>
                            </div>
                            <div className="steps-container-div">
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Note (Common Error)</strong> – If you selected the incorrect currency or payment method relevant
                                        to your KYC profile, you will get the following error. Please go back and correct your payment
                                        options to complete your purchase </p>
                                    <img src={img10} className='steps-images' />
                                </div>
                            </div>
                            <div className="steps-container-div">
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Note (Common Error)</strong> - If your payment method was declined by your banking institution, you will get the following error.  Please try a different payment method to complete your purchase, or contact your banking institution if the problem persists.  Solis cannot contact your banking institution on your behalf. </p>
                                    <img src={img11} className='steps-images' />
                                </div>
                            </div>
                            <div className="steps-container-div">
                                <div className="steps-text-orange">
                                    <div className='center-text-div-kyc'>
                                        <div>
                                            <p className="steps-orange-without-margin">“The Sun doesn’t discriminate </p>
                                            <p className="steps-orange-without-margin">who it shines its light upon.”</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={solisLogo} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </>
    )
}

export default BuyWithFiat