import { useState } from 'react';
import { useStyles } from '../PaymentOptions/payment-options.style'
import CancelIcon from '@mui/icons-material/Cancel';
import { useErc20TokenContract } from '../../web3/hooks/useErc20TokenContract';
import { useSelector } from 'react-redux';
import { Checkbox } from '@mui/material';
import { styled } from '@mui/system';
import { StateLoader } from '../../Components/Loader/StateLoader';
import { CURRENT_NETWORK } from "../../web3/networks";

const ExternalWalletTransfer = (props) => {
    const { classes } = useStyles()
    const [walletAddress, setWalletAddress] = useState()
    const [amount, setAmount] = useState()
    const authState = useSelector((state) => state.auth)
    const { erc20TokenApproval, erc20TokenTransfer, loadingState } = useErc20TokenContract(props.currency.toUpperCase(), "magic")
    const [isTransactionCompleted, setisTransactionCompleted] = useState(false)
    const [transactionHash, setTransactionHash] = useState()
    const [condition1, setCondition1] = useState()
    const [condition2, setCondition2] = useState()
    const [refetchLatestBalance, setRefetchLatestBalance] = useState(false)

    const handleExternalWalletFundsTransfer = async () => {
        try {
            // await erc20TokenApproval(walletAddress, amount);
            const transactionHash = await erc20TokenTransfer(authState.publicAddress, walletAddress, Number(amount))
            setisTransactionCompleted(true)
            setTransactionHash(transactionHash)
        } catch (error) {
            setisTransactionCompleted(false)
        }

    }
    const handleChekboxes = (event) => {
        if (event.target.name === "condition1") {
            setCondition1(event.target.checked)
        }
        else {
            setCondition2(event.target.checked)
        }
    }
    return (
        <>
            {loadingState && <StateLoader text={loadingState.text} />}

            {
                isTransactionCompleted ?
                    <>
                        {
                            transactionHash ?
                                <div className={classes.exteralWalletTransferResultContainer}>
                                    <CancelIcon sx={{ right: '0px', top: '0px', position: 'absolute', color: '#f67622', cursor: 'pointer' }} onClick={() => { props.setExternalWalletTransferModalOpenFunction(false) }} />
                                    <div>Transaction Successfull !</div>
                                    <div className={classes.explorerUrlDiv} style={{ textAlign: 'center', wordBreak: 'break-all', color: '#f67622' }}>View on Explorer : <a target="_blank" href={`${CURRENT_NETWORK.explorer}tx/${transactionHash}`}>{transactionHash}</a> </div>
                                    <div onClick={() => { props.setExternalWalletTransferModalOpenFunction(false); setRefetchLatestBalance(true); }} style={{ color: '#f67622', fontSize: '1.5rem', cursor: 'pointer', marginTop: '10px' }} >Close</div>
                                </div>
                                :
                                <div className={classes.exteralWalletTransferResultContainer} style={{ justifyContent: 'center' }}>
                                    <CancelIcon sx={{ right: '0px', top: '0px', position: 'absolute', color: '#f67622', cursor: 'pointer' }} onClick={() => { props.setExternalWalletTransferModalOpenFunction(false) }} />
                                    Transaction Failed! Please try again.
                                    <div onClick={() => { props.setExternalWalletTransferModalOpenFunction(false); setRefetchLatestBalance(true); }} style={{ color: '#f67622', fontSize: '1.5rem', cursor: 'pointer', marginTop: '10px' }} >Close</div>
                                </div>
                        }
                    </>
                    :
                    <>
                        <div className={classes.exteralWalletTransferContainer}>
                            <CancelIcon sx={{ right: '0px', top: '0px', position: 'absolute', color: '#f67622', cursor: 'pointer' }} onClick={() => { props.setExternalWalletTransferModalOpenFunction(false) }} />
                            <div>Enter wallet address</div>
                            <input type="text" value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)} className={classes.withdrawInputField} placeholder="0x95E34...." />
                            <div>Enter amount</div>

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                                <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} className={classes.withdrawInputFieldCurrency} placeholder={`0.00 ${props.currency}`} />
                                <span style={{ color: '#f67622' }}>{props.currency}</span>
                            </div>
                            <div >
                                <span style={{ color: '#f67622' }}>Total available balance : </span> <span style={{ fontWeight: '900' }}>{props.availableBalance} {props.currency}</span>
                            </div>
                            <div >
                                <StyledCheckbox checked={condition1} name="condition1" onChange={handleChekboxes} />
                                <span>Your (PoS) USDT/USDC is an ERC20 token on the Polygon blockchain. Please switch your wallet to Polygon mainnet to view your tokens after transfer.
                                </span>
                            </div>
                            <div>
                                <StyledCheckbox checked={condition2} name="condition2" onChange={handleChekboxes} />
                                <span>I acknowledge that SOLIS is not able to retrieve my tokens if I have input the incorrect wallet address and that my tokens maybe lost.
                                </span>
                            </div>
                            {
                                (condition1 && condition2) && (walletAddress && (Number(amount) > 0 && Number(amount) < Number(props.availableBalance))) ?
                                    <button className='btn-primary' onClick={handleExternalWalletFundsTransfer} >Withdraw</button> :
                                    Number(amount) > Number(props.availableBalance) ?
                                        < button className='btn-primary-disabled' onClick={() => null} >Withdraw</button> :
                                        <button className='btn-primary-disabled' onClick={() => null} >Withdraw</button>

                            }

                        </div>
                    </>
            }
        </>
    )
}

export default ExternalWalletTransfer


const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    color: '#f67622',
    '&.Mui-checked': {
        color: '#f67622',
    },
}));