import { useState } from 'react';


import bgFlaresVideo from "../../assets/images/bg-video.mp4"
import img1 from "../../assets/images/Images Buying with Crypto/1.JPG"
import img2 from "../../assets/images/Images Buying with Crypto/2.png"
import img3 from "../../assets/images/Images Buying with Crypto/3.png"
import img4 from "../../assets/images/Images Buying with Crypto/4.png"
import img5 from "../../assets/images/Images Buying with Crypto/5.png"
import img6 from "../../assets/images/Images Buying with Crypto/6.png"
import img7 from "../../assets/images/Images Buying with Crypto/7.png"
import img8 from "../../assets/images/Images Buying with Crypto/8.png"
import img9 from "../../assets/images/Images Buying with Crypto/9.png"
import img10 from "../../assets/images/Images Buying with Crypto/10.png"
import img11 from "../../assets/images/Images Buying with Crypto/11.png"
import img12 from "../../assets/images/Images Buying with Crypto/12.png"
import solisLogo from "../../assets/images/solis-logo.svg"
import transakImg from "../../assets/images/transakFullLogo.jpg"
const BuyWithCrypto = () => {

    return (
        <>
            <div class="bg-video">
                <div class="embed-container ratio ratio-16x9">
                    <video class="embed-video" autoPlay loop muted preload controlsList="nodownload">
                        <source src={bgFlaresVideo} type="video/mp4" />
                    </video>
                </div>
            </div>
            <main className="main-content-wrapper">
                <section className="section-block main-banner">
                    <div className="container">

                        <div className='buy-guide-homepage-relative'>

                            <div className='home-kyc-flex-container'>
                                <div>
                                    <p className='buy-options-heading-white-without-margin'>BUYING NFTs</p>
                                    <p className='buy-options-heading-white-without-margin'>WITH</p>
                                    <p className='buy-options-heading-white-without-margin'>Crypto</p>
                                </div>
                                <div>
                                    <ul>
                                        <li>Metamask</li>
                                        <li>Trust Wallet</li>
                                        <li>Coinbase Wallet</li>
                                        <li>Wallet Connect</li>
                                        <li>Magic</li>
                                    </ul>
                                </div>
                            </div>
                            {/* 
                            <div className='kyc-footer-div'>
                                <p className='kyc-footer-heading'>USER GUIDE</p>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p className='steps-orange'>Gasless Transactions</p>
                                    <img src={solisLogo} className="footer-kyc-logo" style={{ display: 'flex', margin: 'auto' }} />
                                    <div style={{ textAlign: 'center' }}>Powered by</div>
                                </div>
                            </div> */}
                            <div className='kyc-page-powered-by-footer-text-div'>
                                <p className='steps-orange'>Gasless transactions</p>
                                <img src={solisLogo} className="powered-by-image" />
                                <div className='powered-by-text' >Powered by</div>
                            </div>

                        </div>
                        <div className='flares-bg-container'>
                            <div className="steps-container-div">
                                <div className='buy-options-heading-white'> Buying NFTs using Crypto is easy !</div>
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Step 1</strong> - Go to the NFT you wish to buy in SOLIS.market</p>
                                    <p className='steps-orange'><strong className='orange-strong'>Step 2</strong> – Click on the <strong className='orange-strong'>“BUY NOW”</strong> button</p>
                                    <img src={img1} className='steps-images' />
                                </div>
                            </div>
                            <div className="steps-container-div">
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Step 3</strong> - Select the payment option <strong className='orange-strong'>“Crypto”</strong> </p>
                                    <p className='steps-orange'>- The NFT purchase price and fees are displayed</p>
                                    <img src={img2} className='steps-images' />
                                </div>
                            </div>
                            <div className="steps-container-div-relative" >
                                <div className='steps-text-orange' >
                                    <p className='steps-orange'><strong className='orange-strong'>Step 4</strong>  Click <strong className='orange-strong'>“Connect Wallet”</strong> </p>
                                    <div className='image-with-related-text-container'>
                                        <img src={img3} className="steps-images left-align-kyc-image" />
                                        <div className='flex-row-right-column-kyc-container'>
                                            <p className="steps-orange">-Potential slippage amount<br />&nbsp; is also displayed</p>
                                        </div>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                                <div className='fake-div'></div>
                                <div className='kyc-page-powered-by-footer-text-div'>
                                    <p className='steps-orange'>Gasless transactions</p>
                                    <img src={solisLogo} className="powered-by-image" />
                                    <div className='powered-by-text' >Powered by</div>
                                </div>

                            </div>

                            <div className="steps-container-div-relative">
                                <div className="steps-text-orange">
                                    <p className="steps-orange"><strong className='orange-strong'>Step 5</strong> -Click <strong className="orange-strong">"Connect a wallet"</strong> </p>
                                    <p className='steps-orange'>- Select your Wallet from the List below.</p>
                                    <p className='steps-orange'>- You can also choose your default MAGIC wallet, which was created during your Profile creation.</p>
                                    <img src={img4} className="steps-images" />
                                </div>
                                <div className='fake-div'></div>
                                <div className='kyc-page-powered-by-footer-text-div'>
                                    <p className='steps-orange'>Gasless transactions</p>
                                    <img src={solisLogo} className="powered-by-image" />
                                    <div className='powered-by-text' >Powered by</div>
                                </div>
                            </div>
                            <div className="steps-container-div-relative">
                                <div className="steps-text-orange">
                                    <p className="steps-orange-without-margin"><strong className='orange-strong'>Step 6</strong> – your selected wallet will start to connect to Solis.Market </p>
                                    <p className="steps-orange">(Metamask example)</p>
                                    <img src={img5} className="steps-images" />
                                </div>
                                <div className='fake-div'></div>
                                <div className='kyc-page-powered-by-footer-text-div'>
                                    <p className='steps-orange'>Gasless transactions</p>
                                    <img src={solisLogo} className="powered-by-image" />
                                    <div className='powered-by-text' >Powered by</div>
                                </div>
                            </div>
                            <div className="steps-container-div-relative">
                                <div className="steps-text-orange">
                                    <p className="steps-orange"><strong className='orange-strong'>Step 7</strong> – Sign-in to your selected wallet</p>
                                    <p className="steps-orange">(Metamask example)</p>
                                    <img src={img6} className="steps-images" />
                                </div>
                                <div className='fake-div'></div>
                                <div className='kyc-page-powered-by-footer-text-div'>
                                    <p className='steps-orange'>Gasless transactions</p>
                                    <img src={solisLogo} className="powered-by-image" />
                                    <div className='powered-by-text' >Powered by</div>
                                </div>
                            </div>
                            <div className="steps-container-div-relative">
                                <div className="steps-text-orange">
                                    <p className="steps-orange"><strong className='orange-strong'>Step 8</strong> - <strong className='orange-strong'>"Select the Currency"</strong> USDC or USDT</p>

                                    <p className="steps-orange">- Select the Network which holds your USDC or USDT (Ethereum mainnet or Polygon mainnet).</p>
                                    <p className="steps-orange">- The amount of USDC and/or USDT in your wallet, on the selected network, is displayed.</p>
                                    <img src={img7} className="steps-images" />
                                </div>
                                <div className='fake-div'></div>
                                <div className='kyc-page-powered-by-footer-text-div'>
                                    <p className='steps-orange'>Gasless transactions</p>
                                    <img src={solisLogo} className="powered-by-image" />
                                    <div className='powered-by-text' >Powered by</div>
                                </div>
                            </div>
                            <div className="steps-container-div-relative">
                                <div className="steps-text-orange">
                                    <p className="steps-orange-without-margin"><strong className='orange-strong'>Step 9</strong> - <strong className='orange-strong'>"Waiting Token Approval"</strong> Signature Request part - 1 </p>
                                    <p className='steps-orange'>(Metamask Example)</p>
                                    <p className="steps-orange">- Authorize the Signature Request in your wallet.</p>
                                    <p className='steps-orange'>- SOLIS IS GASLESS !  You don’t need to hold any MATIC/ETH in your wallet for gas fees!</p>
                                    <img src={img8} className="steps-images" />
                                </div>
                                <div className='fake-div'></div>
                                <div className='kyc-page-powered-by-footer-text-div'>
                                    <p className='steps-orange'>Gasless transactions</p>
                                    <img src={solisLogo} className="powered-by-image" />
                                    <div className='powered-by-text' >Powered by</div>
                                </div>
                            </div>
                            <div className="steps-container-div-relative">
                                <div className="steps-text-orange">
                                    <p className="steps-orange-without-margin"><strong className='orange-strong'>Step 10</strong> - <strong className='orange-strong'>"Initiating Purchase"</strong>Signature Request part - 2 </p>
                                    <p className='steps-orange'>(Metamask Example)</p>
                                    <p className="steps-orange">- Authorize the Signature Request in your wallet.</p>
                                    <p className='steps-orange'>- SOLIS IS GASLESS !  You don’t need to hold any MATIC/ETH in your wallet for gas fees!</p>
                                    <img src={img9} className="steps-images" />
                                </div>
                                <div className='fake-div'></div>
                                <div className='kyc-page-powered-by-footer-text-div'>
                                    <p className='steps-orange'>Gasless transactions</p>
                                    <img src={solisLogo} className="powered-by-image" />
                                    <div className='powered-by-text' >Powered by</div>
                                </div>
                            </div>
                            <div className="steps-container-div-relative">
                                <div className="steps-text-orange">
                                    <p className="steps-orange-without-margin"><strong className='oranfe-strong'>Step 11</strong> - <strong className='orange-strong'>"Transaction Queued"</strong></p>
                                    <p className='steps-orange'>(Metamask Example)</p>
                                    <p className="steps-orange">- Authorize the Signature Request in your wallet.</p>
                                    <p className='steps-orange'>- Your Transaction is ungoing confirmations on the blockchain (this may take a few minutes).</p>
                                    <img src={img10} className="steps-images" />
                                </div>
                                <div className='fake-div'></div>
                                <div className='kyc-page-powered-by-footer-text-div'>
                                    <p className='steps-orange'>Gasless transactions</p>
                                    <img src={solisLogo} className="powered-by-image" />
                                    <div className='powered-by-text' >Powered by</div>
                                </div>
                            </div>

                            <div className="steps-container-div-relative">
                                <div className="steps-text-orange">
                                    <p className="steps-orange-without-margin"><strong className='oranfe-strong'>Step 12</strong> - <strong className='orange-strong'>"Transaction Successful"</strong></p>
                                    <p className="steps-orange">- Once your ‘confirmations’ are completed you will receive a “Transaction Successful” notice.</p>
                                    <p className='steps-orange'>- Your can click on “View Transaction on Explorer” to see your full transaction details.</p>
                                    <img src={img11} className="steps-images" />
                                </div>
                                <div className='fake-div'></div>
                                <div className='kyc-page-powered-by-footer-text-div'>
                                    <p className='steps-orange'>Gasless transactions</p>
                                    <img src={solisLogo} className="powered-by-image" />
                                    <div className='powered-by-text' >Powered by</div>
                                </div>
                            </div>

                            <div className="steps-container-div-relative">
                                <div className="steps-text-orange">
                                    <p className="steps-orange-without-margin"><strong className='oranfe-strong'>Step 13</strong> - <strong className='orange-strong'>Check your new NFY</strong></p>
                                    <p className="steps-orange">- Navigate to your <strong className='orange-strong'>"Collection"</strong> page in your <strong className='orange-strong'>"Profile"</strong>.</p>
                                    <img src={img12} className="steps-images" />
                                </div>
                                <div className='fake-div'></div>
                                <div className='kyc-page-powered-by-footer-text-div'>
                                    <p className='steps-orange'>Gasless transactions</p>
                                    <img src={solisLogo} className="powered-by-image" />
                                    <div className='powered-by-text' >Powered by</div>
                                </div>
                            </div>

                            <div className="steps-container-div">
                                <div className="steps-text-orange">
                                    <div className='center-text-div-kyc'>
                                        <div>
                                            <p className="steps-orange-without-margin">“The Sun doesn’t discriminate </p>
                                            <p className="steps-orange-without-margin">who it shines its light upon.”</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={solisLogo} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </>
    )
}

export default BuyWithCrypto