import { createContext, useState } from "react";

const EmailContext = createContext(undefined)
const EmailDispatchContext = createContext(undefined)

const EmailContextProvider = ({ children }) => {
    const [emailContextValue, setEmailContextValue] = useState({
        email: null
    })
    return (
        <>
            <EmailContext.Provider value={emailContextValue}>
                <EmailDispatchContext.Provider value={setEmailContextValue}>
                    {children}
                </EmailDispatchContext.Provider>
            </EmailContext.Provider>
        </>
    )
}
export { EmailContext, EmailDispatchContext, EmailContextProvider }