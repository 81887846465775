import axiosInstance, { axiosCustomInstance } from "./api";
import getAPIHostName from "../utils/utils";
import axios from "axios";

const getCollections = async () => {

  try {
    const collections = await axiosCustomInstance.get(
      getAPIHostName() + '/api/collection/all'
    );
    return collections.data.data;
  } catch (error) {
    ////console.log(error)
  }
};

const getCollectionIDs = async (allCollections) => {
  let collectionIDs = {}
  allCollections.map((collection) => {
    collectionIDs[collection.name] = collection.id
  })
  return collectionIDs;
};

const collectionService = {
  getCollections,
  getCollectionIDs
};

export default collectionService;
