import React from "react";
import ComingSoon from "../../Components/ComingSoon/ComingSoon";

const Help = () => {
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default Help;
