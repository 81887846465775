import Card from "../Card/Card";
import CardStatic from "../Toggle/CardStatic";
import ComingSoonGif from "../../assets/images/comingSoonTestv2.gif";
import { useState } from "react";
import {
  collectibleItems,
  collectionDetails,
} from "../../interfaces/collectionTypes";
import { useHistory, generatePath, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMessage } from "../../slices/message.slice";
import Modal from "@mui/material/Modal";

const DefaultCollectionComponent = ({
  array,
  forSale,
  collectionName,
}: collectibleItems) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleClick = (id: any, collectionName: any) => {
    if (array.length === 0) {
      dispatch(
        setMessage({
          type: "error",
          message: "All NFTs have been claimed!",
          timeStamp: Date.now(),
        })
      );
      setOpen(true);
    } else {
      history.push({
        pathname: generatePath(`/:collections/collectible/:id`, {
          id: id,
          collections: collectionName,
        }),
      });
    }
  };

  if (array.length === 0 && forSale === "false")
    return (
      <div className="default-collection-container">
        <Card
          key="1"
          imageURL={ComingSoonGif}
          collectionName={collectionName}
        />
      </div>
    );
  else
    return (
      <>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <div className="no-collectibles-info-modal">
            All NFTs have been claimed! Explore other{" "}
            <Link to="/"> <span style={{color:'#f67622'}}>collections</span> </Link> to buy NFTs.
          </div>
        </Modal>

        <div className="default-collection-container">
          {array &&
            array.map((item: collectionDetails, i) => {
              return (
                <Card
                  key={i}
                  id={item.id}
                  imageURL={item.mediaLocation}
                  cardTitle={item.displayName}
                  cardArtistName={item.name}
                  price={item.price}
                  timeRemaining={item.count}
                  mediaType={item.mediaType}
                  currency={item.currency}
                  array={array}
                  collectionName={collectionName}
                  status={item.status}
                />
              );
            })}
        </div>
        {(collectionName === "AFFECT_CHANGE" ||
          collectionName === "SAINT_JUDE" ||
          collectionName === "ISEKAI_ANIME") && (
          <button
            className="btn-primary"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              marginTop: "20px",
            }}
            onClick={() => handleClick(array[0]?.id, collectionName)}
          >
            Check Availability
          </button>
        )}
      </>
    );
};

export default DefaultCollectionComponent;
