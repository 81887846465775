import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useStyles } from "./profile.style";
import { useHistory } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import BlockpassWidget from './blockpass-widget';
import SocialIcons from "../../Components/Profile/SocialIcons";
import ProfilePictureComponent from "../../Components/Profile/ProfilePictureComponent";
import ProfileNftComponent from "../../Components/Profile/ProfileNftComponent";
import { axiosCustomInstance } from "../../services/api";
import { useQuery } from "react-query";
import getAPIHostName from "../../utils/utils";
import { CircularProgress } from "@mui/material";
import { setMessage } from "../../slices/message.slice";
const Profile = () => {
  const [isDomLoaded, setisDomLoaded] = useState(true);
  const [isLoaderActive, setIsLoaderActive] = useState(true)
  const [nft, setNft] = useState([])
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { classes } = useStyles();

  let email = user.email

  useEffect(() => {
    if (localStorage.getItem("transak") == "TRANSAK_WIDGET_SUCCESS" || localStorage.getItem("transak") === "success") {
      dispatch(setMessage({ type: "success", message: "Payment Successful! Settlement in progress", timeStamp: Date.now() }))
      localStorage.removeItem("transak")
    }
  }, [localStorage])

  const fetchNft = async (userId) => {
    try {
      const result = await axiosCustomInstance.post(getAPIHostName() + `/api/collectible/mynfts`, { email: email })
      setNft(result.data)
    } catch (error) {
      setNft([])
    }
  }
  const { data, isError, isLoading } = useQuery(['profile', email], () => fetchNft(email), { refetchOnMount: true, refetchOnReconnect: true, refetchOnWindowFocus: true })


  if (isLoading || !nft)
    return <Loader />
  else
    return (
      <>
        <main className="main-content-wrapper">
          <section className="section-block main-banner">
            <div className="container">
              <div className={classes.profileContainer}>
                <div className={classes.imageAndAboutContainer}>
                  <ProfilePictureComponent />
                  <div className={clsx(
                    classes.displayName,
                    "font-md",
                    classes.activeClassUnderline
                  )} style={{ wordBreak: 'break-all' }}>
                    {user &&
                      user.displayName !== null && <div style={{ color: '#f67622' }}>
                        {user.displayName}
                      </div>
                    }
                  </div>
                  <BlockpassWidget userId={user.id} />
                  <div className={clsx(classes.aboutContent, "scroll-thumb")} style={{ whiteSpace: 'pre-wrap', color: '#ffffff' }}>{user.bio}</div>
                  <SocialIcons />
                </div>
                <ProfileNftComponent nft={nft} />
              </div>
            </div>
          </section>
        </main>
      </>

    );
};

export default Profile;
