import styled from "@emotion/styled";
import { Modal } from "@mui/material";
import loaderGif from "../../assets/images/solid_loader_v2.gif";

type Props = {
    text?: string
}

const Root = styled.div`
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
`

const Panel = styled.div`
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: white 0 0 8px;
    background: black;
    padding: 1.5em;
    gap: 0.5rem;
    max-width: 20rem;
`

const Text = styled.span`
    text-overflow: ellipsis;
    font-size: 1rem;
`

export const StateLoader = ({
    text
}: Props) => {
    return (
        <Modal
            open={true}
        >
            <Root>
                <Panel>
                    <img src={loaderGif} style={{ width: "100px" }} />

                    {text && <Text>{text}</Text>}
                </Panel>
            </Root>
        </Modal>
    )
}