import { Magic } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";
import { CURRENT_NETWORK } from "../../../web3/networks";

const customNodeOptions = {
    rpcUrl: CURRENT_NETWORK.rpcUrl,
    chainId: CURRENT_NETWORK.id
}

export function createMagicInstance() {
    let magic
    if (process.env.REACT_APP_ENV === "stage.admin" || process.env.REACT_APP_ENV === "dev.admin" || process.env.REACT_APP_ENV === "prod.admin") {
        magic = new Magic(process.env.REACT_APP_ADMIN_MAGIC_APP_ID, {
            network: customNodeOptions,
            extensions: [new OAuthExtension()],
        });
    }
    else {
        magic = new Magic(process.env.REACT_APP_MAGIC_APP_ID, {
            network: customNodeOptions,
            extensions: [new OAuthExtension()],
        });
    }
    return magic
}

export const MAGIC_API_KEY = process.env.REACT_APP_MAGIC_APP_ID;



