import { useState, useEffect, useRef } from "react";
import Loader from "../../Components/Loader/Loader";
import styled from "@emotion/styled";
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import WindowIcon from '@mui/icons-material/Window';
import GridOnIcon from '@mui/icons-material/GridOn';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ListWithoutFilter from "../../Components/MarketFilter/ListWithoutFilter";
import ListWithFilter from "../../Components/MarketFilter/ListWithFilter";
import Pagination from "@mui/material/Pagination";
import axiosInstance from "../../services/api";
import getAPIHostName from "../../utils/utils";
import { useQuery, useQueryClient } from "react-query";
import { Select, MenuItem, InputLabel } from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DefaultCollectionComponent from "../../Components/Collections/DefaultCollectionComponent";
import { useSelector } from "react-redux";
const Collections = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(true)
  const [isStatusOpen, setIsStatusOpen] = useState(true)
  const [isCollectionsOpen, setIsCollectionsOpen] = useState(true)
  const [isPriceOpen, setIsPriceOpen] = useState(true)
  const [isQuantityOpen, setIsQuantityOpen] = useState(true)
  const [isOwnerOpen, setIsOwnerOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [sortByPrice, setSortByPrice] = useState("")
  const [searchSuggestions, setSearchSuggestions] = useState()
  const queryClient = useQueryClient();
  const [minPrice, setMinPrice] = useState()
  const [maxPrice, setMaxPrice] = useState()
  const [queryUrl, setQueryUrl] = useState(getAPIHostName() + "/api/collectible/all")
  const [searchInput, setSearchInput] = useState()
  const [selectedCollections, setSelectedCollections] = useState([]);
  const allCollections = useSelector((state) => state.collections)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [filters, setFilters] = useState({
    status: {
      buyNow: false,
      secondaryMarket: false,
      claimNow: false
    },
    minPrice: 0,
    maxPrice: 0,
    collection: null,
    searchParams: null
  })
  const [requestBody, setRequestBody] = useState({
    size: 30,
    page: 1,
    sortBy: {
      price: ""
    },
    filters: {
      status: {
        buyNow: false,
        secondaryMarket: false,
        claimNow: false
      },
      minPrice: 0,
      maxPrice: 0,
      collection: null,
      searchParams: null
    },
  })
  const [claimNow, setClaimNow] = useState(false)
  const [buyNow, setBuyNow] = useState(false)
  const [secondaryMarket, setSecondaryMarket] = useState(false)



  const getSearchSuggestionsData = async () => {
    const data = await axiosInstance.post(getAPIHostName() + "/api/collectible/search")
    return data.data
  }

  useEffect(() => {
    getSearchSuggestionsData().then((res) => {
      setSearchSuggestions(res.data)
    })
  }, [])


  async function fetchCollectibles(page, requestBody) {
    const { data } = await axiosInstance.post(queryUrl, requestBody);
    return data;
  }

  const { status, data, error, isFetching, isPreviousData, isLoading } = useQuery({
    queryKey: ["projects", page, requestBody],
    queryFn: () => fetchCollectibles(page, requestBody),
    keepPreviousData: true,
    staleTime: 5000,
  });




  useEffect(() => {
    setRequestBody(prevRequestBody => {
      return {
        ...prevRequestBody,
        filters: { ...filters }
      }
    });
  }, [filters]);


  const handleNftStatusFilter = (event) => {
    if (event.target.name === "claimNow") {
      setClaimNow(event.target.checked)
      setFilters({
        ...filters,
        status: {
          ...filters.status,
          claimNow: event.target.checked
        }
      });
    }
    else if (event.target.name === "buyNow") {
      setBuyNow(event.target.checked)
      setFilters({
        ...filters,
        status: {
          ...filters.status,
          buyNow: event.target.checked

        }
      });

    }
    else if (event.target.name === "secondaryMarket") {
      setSecondaryMarket(event.target.checked)
      setFilters({
        ...filters,
        status: {
          ...filters.status,
          secondaryMarket: event.target.checked,
        }
      });

    }
    console.log("filters", filters.status)
  }
  const handlePriceFilter = (event) => {
    console.log("sortByPrice", sortByPrice)
    setSortByPrice(event.target.value)
    setRequestBody(prevState => ({
      ...prevState,
      sortBy: {
        price: event.target.value
      }
    }));
  }
  const handleMinMaxPriceFilter = (event) => {
    if (event.target.name === "minPrice") {
      setMinPrice(event.target.value)
    }
    else if (event.target.name === "maxPrice") {
      setMaxPrice(event.target.value)
    }
  }
  const onBlurMinMaxPrice = () => {
    setRequestBody(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        minPrice: minPrice,
        maxPrice: maxPrice
      }
    }))
  }
  const handleChange = (event, value) => {
    setPage(value);
    setRequestBody(prevRequestBody => ({
      ...prevRequestBody,
      ...filters,
      page: value
    }));
  }
  const handleSearch = (event) => {
    setSearchInput(event.target.value)
    setShowSuggestions(true);

  }

  const handleCollectionNameFilter = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setSelectedCollections(prevCollections => {
        const newCollections = [...prevCollections, name];

        setFilters(prevFilters => ({
          ...prevFilters,
          collection: newCollections
        }));

        return newCollections;
      });
    } else {
      setSelectedCollections(prevCollections => {
        const newCollections = prevCollections.filter(collectionName => collectionName !== name);

        setFilters(prevFilters => ({
          ...prevFilters,
          collection: newCollections
        }));

        return newCollections;
      });
    }
  };
  const handleSuggestionClick = (val) => {
    setSearchInput(val);
    setShowSuggestions(false);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false);
      if (searchInput === "") {
        handleSearchIconClick()
      }
    }, 200);
  };

  const handleSearchIconClick = () => {
    setShowSuggestions(false);
    setFilters((prevState) => ({
      ...prevState,
      searchParams: searchInput

    }))
  }


  return (
    <>
      <main className="main-content-wrapper mt-5">
        <div className="container">
          <div className="searchBarWrap">
            {
              isFilterOpen ?
                <div className="filterButtonDiv" onClick={() => setIsFilterOpen(false)}>
                  <ArrowBackIcon sx={{ color: '#ffffff', marginRight: '10px' }} />
                  <div>Filters</div>
                </div>
                :
                <div className="filterButtonDiv" onClick={() => setIsFilterOpen(true)}>
                  <TuneIcon sx={{ color: '#ffffff', marginRight: '10px' }} />
                  <div>Filters</div>
                </div>
            }
            <div className="searchContainer">
              <FormTextField
                id="standard-basic"
                InputProps={{
                  endAdornment: (
                    <SearchIcon
                      style={{ cursor: 'pointer' }}
                      onClick={handleSearchIconClick}
                    />
                  ),
                }}
                variant="outlined"
                name="searchBar"
                placeholder="Search by name or attribute - Example : Affect change"
                className="searchInputField"
                onChange={handleSearch}
                onBlur={handleBlur}
                value={searchInput}
              />
              {
                showSuggestions && searchInput &&
                <div className="searchSuggestions" >
                  {
                    showSuggestions && searchInput && searchSuggestions && searchSuggestions.filter((item) => {
                      const searchTerm = searchInput.toLowerCase();
                      const suggestions = item.toLowerCase().replace(/<[^>]+>/g, '');
                      return suggestions.startsWith(searchTerm);
                    }).slice(0, 8).map((val, index) => {
                      const cleanVal = val.replace(/<[^>]+>/g, '');
                      return <div key={index} onClick={() => handleSuggestionClick(cleanVal)} className="searchList" >{cleanVal}</div>
                    })
                  }
                </div>
              }

            </div>


            {/* <TextFieldSelect
                id="selectSort"
                select
                label="Sort by price"
                className="searchSelect" 
                menuItems
              /> */}
            <FilterSelect
              labelId="demo-simple-select-label"
              id="selectSort"
              value={sortByPrice}
              onChange={handlePriceFilter}
              className="searchSelect"
              displayEmpty
            >
              <MenuItem disabled value="" style={styles} > <em style={{ color: '#f67622' }}> Sort By Price </em> </MenuItem>
              <MenuItem value="LOW_TO_HIGH" style={styles} >Price low to high</MenuItem>
              <MenuItem value="HIGH_TO_LOW" style={styles} >Price high to low</MenuItem>
            </FilterSelect>

            {/* <ToggleButtonGroup
              color="primary"
              className="viewToggle"
              //value={alignment}
              exclusive
            //onChange={handleChange}
            >
              <ToggleButton value="viewStandard"><WindowIcon /></ToggleButton>
              <ToggleButton value="viewCompant"><GridOnIcon /></ToggleButton>
            </ToggleButtonGroup> */}
          </div>
          <div className="filter-container" >
            {
              isFilterOpen ?
                <>
                  <div className="collection-with-filter">
                    <div className="sticky-parent">
                      <div class="gradient-box">
                        <div className="filterCollapsesWrapper">
                          <div className="filterCollapses" onClick={() => setIsStatusOpen(!isStatusOpen)}>
                            <p className="marketText" >Status</p>
                            {isStatusOpen ? <KeyboardArrowUpIcon sx={{ color: '#f67622' }} /> : <KeyboardArrowDownIcon sx={{ color: '#f67622' }} />}
                          </div>
                          {isStatusOpen ?
                            <div className="filterContentWrapper">
                              <div className="filterContent">
                                Buy Now <Checkbox name="buyNow" checked={buyNow} onChange={handleNftStatusFilter} sx={{ color: '#ffffff', '&.Mui-checked': { color: '#ffffff' }, }} />
                              </div>
                              <div className="filterContent">
                                Secondary Market <Checkbox name="secondaryMarket" checked={secondaryMarket} onChange={handleNftStatusFilter} sx={{ color: '#ffffff', '&.Mui-checked': { color: '#ffffff' }, }} />
                              </div>
                              <div className="filterContent">
                                Claim Now <Checkbox name="claimNow" checked={claimNow} onChange={handleNftStatusFilter} sx={{ color: '#ffffff', '&.Mui-checked': { color: '#ffffff' }, }} />
                              </div>
                              {/* <div className="filterContent" style={{ padding: '10px 15px' }}>
                                      <FormTextField
                                        id="standard-basic"
                                        InputProps={{
                                          endAdornment: (
                                            <CalendarTodayIcon />
                                          ),
                                        }}
                                        variant="outlined"
                                        name="website"
                                        placeholder="Start Date"
                                        className="filterInputField"
                                      />
                                      <Box mr={1} />
                                      <FormTextField
                                        id="standard-basic"
                                        InputProps={{
                                          endAdornment: (
                                            <CalendarTodayIcon />
                                          ),
                                        }}
                                        variant="outlined"
                                        name="website"
                                        placeholder="End Date"
                                        className="filterInputField"
                                      />
                                    </div> */}
                              {/* <div className="filterContent" style={{ padding: '10px 15px 20px 15px' }}>
                                      <FormTextField
                                        id="standard-basic"
                                        variant="outlined"
                                        name="website"
                                        placeholder="Start Time"
                                        className="filterInputField"
                                      />
                                      <Box ml={1} mr={1}>to</Box>
                                      <FormTextField
                                        id="standard-basic"
                                        variant="outlined"
                                        name="website"
                                        placeholder="End Time"
                                        className="filterInputField"
                                      />
                                    </div> */}

                            </div>
                            :
                            <p style={{ display: 'none' }}></p>}
                        </div>
                        <div className="filterCollapsesWrapper">
                          <div className="filterCollapses" onClick={() => setIsPriceOpen(!isPriceOpen)} >
                            <p className="marketText">Price</p>
                            {isPriceOpen ? <KeyboardArrowUpIcon sx={{ color: '#f67622' }} /> : <KeyboardArrowDownIcon sx={{ color: '#f67622' }} />}
                          </div>
                          {isPriceOpen ?
                            <div className="filterContentWrapper">
                              <div className="filterContent" style={{ padding: '20px 15px 20px 15px' }}>
                                <FormTextField
                                  id="standard-basic"
                                  variant="outlined"
                                  name="minPrice"
                                  placeholder="Min"
                                  className="filterInputField"
                                  value={minPrice}
                                  onChange={handleMinMaxPriceFilter}
                                  onBlur={onBlurMinMaxPrice}
                                />
                                <Box ml={1} mr={1}>to</Box>
                                <FormTextField
                                  id="standard-basic"
                                  variant="outlined"
                                  name="maxPrice"
                                  placeholder="Max"
                                  className="filterInputField"
                                  value={maxPrice}
                                  onChange={handleMinMaxPriceFilter}
                                  onBlur={onBlurMinMaxPrice}

                                />
                                <Box mr={1} />
                                {/* <TextFieldSelect
                                        id="selectSort"
                                        select
                                        label="ETH"
                                        className="searchSelect"
                                      /> */}
                              </div>

                            </div>
                            :
                            <p style={{ display: 'none' }}></p>
                          }
                        </div>


                        <div className="filterCollapsesWrapper">
                          <div className="filterCollapses" onClick={() => setIsCollectionsOpen(!isCollectionsOpen)}>
                            <p className="marketText" >Collections</p>
                            {isCollectionsOpen ? <KeyboardArrowUpIcon sx={{ color: '#f67622' }} /> : <KeyboardArrowDownIcon sx={{ color: '#f67622' }} />}
                          </div>
                          {isCollectionsOpen ?
                            <div className="filterContentWrapper">
                              {
                                allCollections && allCollections.collections.map((item, key) => {
                                  if (item.forSale === true)
                                    return (
                                      <div className="filterContent">
                                        {item.displayName}
                                        <Checkbox
                                          name={item.displayName}
                                          checked={selectedCollections.includes(item.displayName)}
                                          onChange={handleCollectionNameFilter}
                                          sx={{ color: '#ffffff', '&.Mui-checked': { color: '#ffffff' }, }}
                                        />

                                      </div>
                                    )
                                })
                              }
                            </div>
                            :
                            <p style={{ display: 'none' }}></p>}
                        </div>



                        {/* <div className="filterCollapsesWrapper">
                                <div className="filterCollapses">
                                  <p className="marketText">Quantity</p>
                                  {isQuantityOpen ? <KeyboardArrowUpIcon sx={{ color: '#f67622' }} onClick={() => setIsQuantityOpen(false)} /> : <KeyboardArrowDownIcon sx={{ color: '#f67622' }} onClick={() => setIsQuantityOpen(true)} />}
                                </div>
                                {isQuantityOpen ?
                                  <div className="filterContentWrapper">
                                    <div className="filterContent" style={{ padding: '20px 15px 20px 15px' }}>
                                      <FormControl className="filterRadioGroup">
                                        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                                          <FormControlLabel value="allItems" labelPlacement="start" control={<Radio sx={{ color: '#ffffff', '&.Mui-checked': { color: '#ffffff' }, }} />} label="All Items" />
                                          <FormControlLabel value="singleItem" labelPlacement="start" control={<Radio sx={{ color: '#ffffff', '&.Mui-checked': { color: '#ffffff' }, }} />} label="Single Items" />
                                          <FormControlLabel value="bundles" labelPlacement="start" control={<Radio sx={{ color: '#ffffff', '&.Mui-checked': { color: '#ffffff' }, }} />} label="Bundles" />
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  </div>
                                  :
                                  <p style={{ display: 'none' }}></p>
                                }
                              </div>
                              <div className="filterCollapsesWrapper">
                                <div className="filterCollapses">
                                  <p className="marketText">Artist/Owner</p>
                                  {isOwnerOpen ? <KeyboardArrowUpIcon sx={{ color: '#f67622' }} onClick={() => setIsOwnerOpen(false)} /> : <KeyboardArrowDownIcon sx={{ color: '#f67622' }} onClick={() => setIsOwnerOpen(true)} />}
                                </div>
                                {isOwnerOpen ?
                                  <div className="filterContentWrapper">
                                    <div className="filterContent" style={{ padding: '20px 15px 20px 15px' }}>
                                      <p>Filter by owner here...</p>
                                    </div>
                                  </div>
                                  :
                                  <p style={{ display: 'none' }}></p>
                                }
                              </div> */}
                      </div>
                    </div>
                    {
                      (isLoading || isFetching) ? <ListWithFilter isLoading={true} data={[]} />
                        : <ListWithFilter data={data?.data.collectibleList} isLoading={false} />
                    }
                  </div>
                  <CustomPagination
                    count={data?.data.totalPages}
                    page={page}
                    onChange={handleChange}
                    showFirstButton
                    showLastButton
                  />
                </>
                :
                <>
                  {
                    (isLoading || isFetching) ? <ListWithFilter isLoading={true} data={[]} />
                      : <ListWithoutFilter data={data?.data.collectibleList} />
                  }
                  <CustomPagination
                    count={data?.data.totalPages}
                    page={page}
                    onChange={handleChange}
                    showFirstButton
                    showLastButton
                  />
                </>
            }
          </div>
        </div>
      </main>
    </>
  );
};

export default Collections;

const FormTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#F67622",
    opacity: "0.8",
  },
  "& label.Mui-completed": {
    color: "#F67622",
    opacity: "0.8",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#F67622",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#F67622",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#F67622",
    },
    "&:hover fieldset": {
      borderColor: "#f67622",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#f67622",
    },
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    "& fieldset": {
      borderColor: "#F67622",
    },
    "&:hover fieldset": {
      borderColor: "#F67622",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F67622",
    },
  },
  "& .MuiInputBase-root.Mui-disabled": {
    color: "#F67622",
  },
  "& .MuiInputBase-root": {
    color: "#F67622",
  },
  "& .MuiFormLabel-root": {
    color: "#F67622",
  },
  width: '100%',
  maxWidth: 300,
  '&.searchInputField': {
    maxWidth: 'unset',
    '& input': {
      paddingLeft: 10,
    },
    '& .MuiInputBase-root': {
      color: '#ffffff'
    },
    '& fieldset': {
      border: '1px solid #ffffff!important',
      borderRadius: 10,
      background: '#ffffff22',
    }
  },
  '&.filterInputField': {
    maxWidth: 'unset',
    '& input': {
      paddingLeft: 10,
      fontFamily: "Archivo",
      fontSize: 14,
    },
    '& .MuiInputBase-root': {
      color: '#ffffff'
    },
    '& fieldset': {
      border: '1px solid #ffffff!important',
      borderRadius: 10,
      background: '#ffffff22',
    },
    '& svg': {
      fontSize: '1rem',
    }
  },
  "& .MuiInputAdornment-root:hover": {
    cursor: "pointer",
    color: "#f67622"
  }
});


const TextFieldSelect = styled(TextField)({

});


const FilterSelect = styled(Select)({
  backgroundColor: 'white',
  padding: '0.5rem',
  border: '1px solid #f67622',
  borderRadius: '10px',
  '& .MuiSelect-select': {
    paddingRight: '0',
    paddingLeft: '0',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    '&:focus': {
      backgroundColor: 'white',
    },
    '& option': {
      color: 'black',
    },
    '&:checked': {
      backgroundImage: 'linear-gradient(90deg, rgba(234, 156, 104, 1) 0%, rgba(233, 84, 67, 1) 50%, rgba(201, 0, 154, 1) 100%)',
      color: 'transparent',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
  '& .MuiSelect-icon': {
    color: 'rgba(233, 84, 67, 1)',
  },
});



const FilterSelectLabel = styled(InputLabel)({
  color: 'black',
  paddingLeft: '0.5rem',
});

const CustomPagination = styled(Pagination)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80px', // Increase the height to make it bigger
  marginTop: '20px', // Add 20px top margin

  '& .MuiPaginationItem-root': {
    color: 'white',
    fontSize: '16px',
    margin: '0 5px',
    '@media (max-width: 600px)': {
      fontSize: '14px',
    },
  },

  '& .Mui-selected': {
    backgroundColor: 'white',
    color: '#f67622',
    '&:hover': {
      backgroundColor: '#f67622',
    },
  }
})

const styles = {
  marginBottom: '0',
  background: 'rgb(234, 156, 104)',
  background: '-moz-linear-gradient(90deg, rgba(234, 156, 104, 1) 0%, rgba(233, 84, 67, 1) 50%, rgba(201, 0, 154, 1) 100%)',
  background: '-webkit-linear-gradient(90deg, rgba(234, 156, 104, 1) 0%, rgba(233, 84, 67, 1) 50%, rgba(201, 0, 154, 1) 100%)',
  background: 'linear-gradient(90deg, rgba(234, 156, 104, 1) 0%, rgba(233, 84, 67, 1) 50%, rgba(201, 0, 154, 1) 100%)',
  filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea9c68", endColorstr="#e95443", GradientType=1)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontFamily: 'Archivo',
  fontWeight: '500',
  fontSize: '16px',
};
