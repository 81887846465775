//import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Modal } from "@mui/material";
import loaderGif from "../../assets/images/solid_loader_v2.gif";

interface LoadParams {
  keepLoading?: boolean;
  redirect?: string;
  renderingtext?:string;
}
export const Loader = ({ keepLoading=true, redirect,renderingtext}:LoadParams) => {

  //const {keepLoading, redirect} = props;
  //const [keepLoading, setKeepLoading] = useState(true);
  //const [redirect, setRedirection] = useState();

  
  return (
    <>
    {!keepLoading && redirect ? (
        <>
          <Redirect to={redirect} />
        </>
      ) : (
      <Modal
        open={keepLoading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
  {
    renderingtext ? 
      <>
          <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "40vh", 
            flexDirection:'column'
          }}
        >
          <img src={loaderGif} style={{ width: "150px" }} />
          <div>{renderingtext}</div>

        </div>
      </>
    : <>
         <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "40vh",
          }}
        >
          <img src={loaderGif} style={{ width: "150px" }} />
        </div>
      </>
  }
      </Modal>
      )
    }
    </>
  );
};

export default Loader;
