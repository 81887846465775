import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "./profile.style";
import FormLabel from "@mui/material/FormLabel";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from "@emotion/styled";
import { getUser, getUserDetail } from "../../slices/user.slice";
import { axiosCustomInstance } from "../../services/api";
import getAPIHostName from "../../utils/utils";
import { useQuery } from "react-query";
import Loader from "../../Components/Loader/Loader";
import blockpassLogo from "../../assets/images/blockpass.png"
import RefreshIcon from '@mui/icons-material/Refresh';
const BlockpassWidget = ({ userId }) => {
    const user = useSelector((state) => state.user);
    const auth = useSelector((state) => state.auth);

    const blockPassClientId = process.env.REACT_APP_BLOCKPASS_CLIENTID;
    const [kycColor, setKycColor] = useState("")
    const [kycStatusText, setKycStatusText] = useState("")
    const [kycTooltipText, setKycTooltiptext] = useState("")
    const [remount, setRemount] = useState(false)
    const { classes } = useStyles()
    const dispatch = useDispatch()
    // useEffect(() => {
    //     ////console.log("Loading blockpass widget");
    //     props.id && loadBlockPassWidget();
    // }, [props.id])


    const handleRefresh = async () => {
        dispatch(getUserDetail({ email: user.email }))
    }

    const handleClick = () => {
        userId && loadBlockPassWidget()
    }
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    const onClickUrl = (url) => {
        return () => openInNewTab(url)
    }

    const loadBlockPassWidget = () => {
        ////console.log("load blockpass widget")
        const blockpass = new window.BlockpassKYCConnect(
            blockPassClientId, // service client_id from the admin console
            {
                refId: userId, // assign the local user_id of the connected user
            }
        )

        blockpass.startKYCConnect()

        //TODO: Need to close the popup and refresh the profile page
        //to reflect latest status i.e call dispatch(getUser()); 

        blockpass.on('KYCConnectSuccess', () => {
            dispatch(getUserDetail({ email: user.email }))
            setRemount(true)
        })
        blockpass.on('KYCConnectCancel', () => {
            dispatch(getUserDetail({ email: user.email }))
            setRemount(true)
        })
        blockpass.on('KYCConnectClose', () => {
            dispatch(getUserDetail({ email: user.email }))
            setRemount(true)
        })
    };
    let email = user && user.email
    const fetchUserDetails = async (email) => {
        await axiosCustomInstance.get(
            getAPIHostName() + '/api/users/email=' + email
        );
    }
    // const { data, isError, isLoading } = useQuery(['user', email], () => fetchUserDetails(email), { refetchOnMount: true, refetchOnReconnect: true, refetchOnWindowFocus: true })
    useEffect(() => {
        dispatch(getUserDetail({ email: user.email }))
        if (user.kycStatus === null) { setKycColor("red"); setKycStatusText("Start KYC"); }
        else if (user.kycStatus === "approved") { setKycColor("green"); setKycStatusText("Approved");; setKycTooltiptext("Verified By Blockpass") }
        else if (user.kycStatus === "waiting") { setKycColor("yellow"); setKycStatusText("Review Pending"); setKycTooltiptext("Your profile is yet to be reviewed") }
        else if (user.kycStatus === "inreview") { setKycColor("yellow"); setKycStatusText("In review"); setKycTooltiptext("Your profile is currently under review") }
        else if (user.kycStatus === "rejected") {
            setKycColor("red"); setKycStatusText("Rejected"); setKycTooltiptext("Your profile is rejected. Please check your email from blockpass for more details")
        }
    }, [user.kycStatus])
    // if (isLoading) return <Loader />
    return (
        <>
            <div className={classes.kycStatusDiv}>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', fontSize: '18px' }}>
                    <div >KYC STATUS  :</div>
                    <div style={{ color: kycColor, marginLeft: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px' }}>{kycStatusText} {user.kycStatus !== "approved" ? <RefreshIcon sx={{ color: '#f67622!important', cursor: 'pointer' }} onClick={handleRefresh} /> : null} </div>
                </div>
                <div className={classes.kycStatusIconDiv}>
                    <div style={{ color: kycColor }}>
                        {
                            user.kycStatus === null ?
                                <>
                                    <StyledTooltip placement="top" title={
                                        <>
                                            <div >
                                                Verify With Blockpass
                                            </div>
                                        </>
                                    }
                                    >
                                        <button id="blockpass-kyc-connect" className="btn-primary" onClick={onClickUrl('https://verify-with.blockpass.org/?clientId=solis_market&refId=' + user.id + '&email=' + user.email)}>
                                            Verify with Blockpass
                                        </button>
                                    </StyledTooltip>
                                </> :
                                user.kycStatus === "inreview" ?
                                    <>
                                        <StyledTooltip placement="bottom" title={
                                            <>
                                                <div className={classes.tooltipParent}>
                                                    Your profile is currently under review                                                </div>
                                            </>
                                        }
                                        >
                                            <button id="blockpass-kyc-connect" className="btn-primary" onClick={onClickUrl('https://verify-with.blockpass.org/?clientId=solis_market&refId=' + user.id + '&email=' + user.email)}>
                                                View on Blockpass
                                            </button>
                                        </StyledTooltip>
                                    </>
                                    :
                                    user.kycStatus === "waiting" ?
                                        <>
                                            <StyledTooltip placement="bottom" title={
                                                <>
                                                    <div className={classes.tooltipParent}>
                                                        Your profile is yet to be reviewed
                                                    </div>
                                                </>
                                            }
                                            >
                                                <button id="blockpass-kyc-connect" className="btn-primary" onClick={onClickUrl('https://verify-with.blockpass.org/?clientId=solis_market&refId=' + user.id + '&email=' + user.email)}>
                                                    View on Blockpass
                                                </button>
                                            </StyledTooltip>
                                        </> :
                                        user.kycStatus === "rejected" ?
                                            <>
                                                <StyledTooltip placement="bottom" title={
                                                    <>
                                                        <div className={classes.tooltipParent}>
                                                            Your profile is rejected. Please check your email from blockpass for more details                                                        </div>
                                                    </>
                                                }
                                                >
                                                    <button id="blockpass-kyc-connect" className="btn-primary" onClick={onClickUrl('https://verify-with.blockpass.org/?clientId=solis_market&refId=' + user.id + '&email=' + user.email)}>
                                                        View on Blockpass
                                                    </button>
                                                </StyledTooltip>
                                            </> :
                                            user.kycStatus === "approved" ?

                                                <>
                                                    <StyledTooltip placement="bottom" title={
                                                        <>
                                                            <div className={classes.tooltipParent}>
                                                                Verified by BlockPass                                                            </div>
                                                        </>
                                                    }
                                                    >
                                                        <button id="blockpass-kyc-connect" className="btn-primary" onClick={onClickUrl('https://verify-with.blockpass.org/?clientId=solis_market&refId=' + user.id + '&email=' + user.email + '&auto=1')}>
                                                            View on Blockpass
                                                        </button>
                                                    </StyledTooltip>
                                                </>


                                                : null
                        }

                    </div>
                </div>
            </div>
        </>
    );


}
export default BlockpassWidget;



const StyledTooltip = styled(props => (
    <Tooltip classes={{ popper: props.className }} {...props} />
))`
              & .MuiTooltip-tooltip {
                background-color: black;
              color: #f67622;
        
              font-size:14px;
              text-align:center; 
              display:flex; 
              justify-content:center; 
              align-items:center;   
              
              }
              `;