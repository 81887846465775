import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useStyles } from "./faq.style";
import { useHistory } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { styled } from '@mui/system';
import { Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Faq = () => {
  const [isDomLoaded, setisDomLoaded] = useState(true);
  const [isLoaderActive, setIsLoaderActive] = useState(true)
  const [nft, setNft] = useState([])
  const history = useHistory();
  const [copyOpen, setCopyOpen] = useState(null)
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { classes } = useStyles();


  const handleCopyTooltip = () => {
    setCopyOpen(true)
    navigator.clipboard.writeText("info@solis.io")
    setTimeout(() => {
      setCopyOpen(false)
    }, 1000)
  }




  return (
    <>
      <main className="main-content-wrapper">
        <section className="section-block main-banner">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 col-md-3 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
                <div className={classes.sideMenuNav}>
                  <ul className={classes.sideMenuList}>
                    <li><a href="#">Getting Started</a></li>
                    <li><a href="#generalInformation">General Information</a></li>
                    <li><a href="#magic">Magic</a></li>
                    <li><a href="#kyc">KYC/AML</a></li>
                    <li><a href="#support">Support</a></li>


                  </ul>
                </div>
              </div>
              <div className="col-sm-8 col-md-9 col-lg-9 wow fadeInUp" data-wow-delay="0.2s">
                <div className={classes.mainContentArea}>
                  <h1 id="gettingStarted">Faqs</h1>
                  <br />

                  <div className="row">
                    <h4>Getting Started</h4>
                  </div>

                  <div className={classes.question}>
                    <strong>What is SOLIS?</strong><br />
                    <p>SOLIS is a curated ecosystem designed to empower creatives to interact directly with their communities in order to finance, collaborate, create, and own their creations.</p>
                  </div>

                  <div className={classes.question}>
                    <strong>What does curated mean?</strong><br />
                    <p>At SOLIS, we believe in transparency and authentic experiences between our creative partners and their communities. Maintaining that integrity is key to everything we do, so we work intimately with partners that share those same values.</p>
                  </div>

                  <div className={classes.question}>
                    <strong>How do I get started?</strong><br />
                    <p>The first thing you need to do is create an account. Upon entering your email address, you will be redirected to your email account, where you will be prompted with a button to enter the SOLIS Market to begin building your profile. </p>
                  </div>

                  <div className={classes.question}>
                    <strong>How do I create a SOLIS profile?</strong><br />
                    <p>In order to interact with our content, you must first create a SOLIS profile that is just for you. From your profile, you can create your name, add an image, attach your socials, add a bio, and customize how our community learns more about you! </p>
                  </div>

                  <div className={classes.question}>
                    <strong>What is a digital Wallet?</strong><br />
                    <p>A 'wallet' is software that you can use to manage your collectibles and other tokens.</p>
                  </div>

                  <div className={classes.question}>
                    <strong>Why do I need my own wallet?</strong><br />
                    <p>A custom wallet allows you to maintain exclusive ownership of your purchases. </p>
                  </div>

                  <div className={classes.question}>
                    <strong>Why is a wallet required to sign up?</strong><br />
                    <p>SOLIS is a non-custodial marketplace, which means you have total control over your funds, content, art, or any other tokens in your wallet at all times. A wallet is required to manage your profile, hold your collectibles, and send and receive transactions on SOLIS.</p>
                  </div>

                  <div className={classes.question}>
                    <strong>What if I don't have a wallet?</strong><br />
                    <p>SOLIS is built to be used by those who already possess a wallet, as well as those who may be newer to the world of digital collectibles. If you login and do not already have a wallet, SOLIS can provide one for you through our partners at Magic. This wallet is automatically assigned to you at sign in, and can only be accessed through your profile. </p>
                  </div>

                  <div className={classes.lastQuestion} id="generalInformation">
                    <strong>What Wallets does SOLIS accept?</strong><br />
                    <p>Currently SOLIS has integrated Magic, Metamask, Coinbase Wallet, Trust Wallet and Wallet Connect.</p>
                  </div>


                  <div className="row">
                    <h4>General Information</h4>
                  </div>

                  <div className={classes.question}>
                    <strong>What blockchain technology does the SOLIS Market use?</strong><br />
                    <p>The SOLIS Market is built on the Polygon blockchain.</p>
                  </div>

                  <div className={classes.question}>
                    <strong>What payment methods do you accept?</strong><br />
                    <p>SOLIS Market transacts with the following cryptocurrencies and stable coins - USDC & USDT.  You can also purchase using Fiat via our partnerships.</p>
                  </div>

                  <div className={classes.lastQuestion} id="magic">
                    <strong>What are SOLIS' fees?</strong><br />
                    <p>On all sales, there's a 5% marketplace fee added on top of the sale price that's paid for by the buyer.</p>
                  </div>


                  <div className="row">
                    <h4>Magic</h4>
                  </div>

                  <div className={classes.question}>
                    <strong>What is Magic?</strong><br />
                    <p>Magic is the number #1 User authentication and private key management solution for web3 and web2.  Magic is built by Fortmatic Inc, a cybersecurity company headquartered in San Francisco, specializing in blockchain private key management.</p>
                  </div>

                  <div className={classes.question}>
                    <strong>Who uses Magic?</strong><br />
                    <p>Magic is used by some of the leading DeFi, NFT and Web3 companies and is compatible with over 20 of the top blockchains, including Polygon.</p>
                  </div>

                  <div className={classes.question}>
                    <strong>How does Magic work with my SOLIS Market account?</strong><br />
                    <p>Users can create a passwordless Solis.Market account by simply using their email address to create a their Solis User Profile and receive Magic Connect Web3 wallet. No passwords or seed phrases are required. </p>
                  </div>

                  <div className={classes.question}>
                    <strong>What happens once I create my SOLIS Market account? </strong><br />
                    <p>Every time you sign in you will receive a one-time email authentication (similar to Slack and Medium) to access your SOLIS account.</p>
                  </div>

                  <div className={classes.question}>
                    <strong>I have my own wallet - do I need to use my Magic Wallet?</strong><br />
                    <p>No. You can switch between your preferred wallet and Magic wallet within your User Profile. </p>
                  </div>

                  <div className={classes.lastQuestion} id="kyc">
                    <strong>I would like to learn more about Magic. </strong><br />
                    <p>For more information, please refer to <a href="https://magic.link/docs/home/welcome" target="_blank">https://magic.link/docs/home/welcome</a> </p>
                  </div>



                  <div className="row">
                    <h4>SOLIS Market KYC/AML</h4>
                  </div>

                  <div className={classes.question}>
                    <strong>What does KYC and AML mean?</strong><br />
                    <p>KYC means Know Your Customer; AML means Anti-money Laundering.</p>
                  </div>

                  <div className={classes.question}>
                    <strong>Do I need to KYC my SOLIS profile?</strong><br />
                    <p>KYC is not compulsory to utilize SOLIS Market.</p>
                  </div>

                  <div className={classes.question}>
                    <strong>What are the benefits of KYC?</strong><br />
                    <p>KYC Users have the advantage of accessing a greater range of digital assets and opportunities within SOLIS Market.</p>
                  </div>

                  <div className={classes.question}>
                    <strong>Learn More</strong><br />
                    <a href="https://www.solis.market/kyc#s5" style={{ cursor: 'pointer !important' }}><p>Click Here</p>
                    </a>
                  </div>

                  <div className={classes.lastQuestion} id="support">
                    <strong>What does Blockpass do with my data?</strong><br />
                    <p>For more information, please refer to <a href="https://www.blockpass.org/2022/03/22/what-does-blockpass-do-with-your-data" target="_blank">https://www.blockpass.org/2022/03/22/what-does-blockpass-do-with-your-data</a></p>
                  </div>



                  <div className="row">
                    <h4>Support</h4>
                  </div>

                  <div className={classes.lastQuestion}>
                    <strong>How do I get support?</strong><br />
                    <div style={{ display: 'flex', gap: '20px', marginTop: '10px' }}>
                      <a href="mailto:info@solis.io">
                        <p>info@solis.io</p>
                      </a>

                      <StyledTooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        open={copyOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        placement="top"
                        title={
                          <>
                            Email copied to clipboard!
                          </>
                        }
                      >
                        <ContentCopyIcon style={{ cursor: 'pointer', color: '#f67622' }} onClick={handleCopyTooltip} />

                      </StyledTooltip>

                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>

  );
};

export default Faq;


const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: black;
    color: #f67622;
    box-shadow: 0px 0px 5px 0px #f67622;
    border: 0.5px solid #f67622;
    font-size: 14px;
  }
`;
