import { useHistory, generatePath } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { identify, init } from 'logrocket';
import { getAllCollections } from '../../slices/collection.slice';
import { axiosCustomInstance } from '../../services/api';
import getAPIHostName from '../../utils/utils';
import HomePageCollectionLeft from '../Home/HomePageCollectionLeft';
import HomePageCollectionRight from '../Home/HomePageCollectionRight';
const HomeToggle = () => {

    const { isLoggedIn, isProfileComplete } = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const allCollections = useSelector((state) => state.collections)

    const [collectionIDs, setCollectionIDs] = useState()
    const [collections, setCollections] = useState()

    useEffect(() => {
        ////console.log("coll fetched ", allCollections.isCollectionFetched)
        if (isLoggedIn && allCollections.isCollectionFetched) {
            setCollectionIDs(allCollections.collectionIDs);
            let collections = allCollections.collections;
            let sortedCollections = [...collections].sort((a, b) => {
                return a.order - b.order
            })
            setCollections(sortedCollections)
            ////console.log("allcollec coll", allCollections);
        }
    }, [allCollections, isLoggedIn])


    function setSource(path) {
        try {
            return require(path).default
        }
        catch (err) {
            return require("../../assets/images/comingSoonTestv2.gif").default
        }
    }
    return (
        <>
            <section className="featured-section">
                <div className="container">
                    <div className="main-title">
                        <h2 className="font-lg">
                            <span className="gradient1">
                                FEATURED RELEASES
                            </span>
                        </h2>
                    </div>
                    <div className="inner-content homeCollectionList">
                        {
                            collections?.map((item, index) => {
                                if (collectionIDs && collectionIDs[item.name] !== undefined) {
                                    const id = collectionIDs[item.name]
                                    const path = generatePath("/collections/:id", { id })
                                    const imageSource = setSource(`../../assets/collection/${item.name}/${item.name}_hp_image.png`)
                                    if (item.order % 2 === 0) {
                                        return <HomePageCollectionLeft displayName={item.displayName} path={path} imageUrl={imageSource} collectionName={item.name} collectionDescription={item.description} forSale={item.forSale} launchDate={item.launchDate} key={index} />
                                    }
                                    else {
                                        return <HomePageCollectionLeft displayName={item.displayName} path={path} imageUrl={imageSource} collectionName={item.name} collectionDescription={item.description} forSale={item.forSale} launchDate={item.launchDate} key={index} />
                                    }
                                }
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    )

}

export default HomeToggle