import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import getAPIHostName from "../../utils/utils";
import { axiosCustomInstance } from "../../services/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import { useHistory, Redirect, generatePath } from "react-router-dom";

import Modal from "@mui/material/Modal";
import Signin from "../SignIn/sign-in";
import "../../assets/css/main.scss";
import BannerCarousel from "../../Components/Carousel/BannerCarousel";

import HomeToggle from "../../Components/Toggle/HomeToggle";
import { getAllCollections } from "../../slices/collection.slice";
import HeaderTextModal from "../../Components/Header/HeaderTextModal";
const Home = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoggedIn, email, isProfileComplete, isLoading } = useSelector((state) => state.auth);
  const [isHeaderTextModalOpen, setIsHeaderTextModalOpen] = useState(false)
  const collections = useSelector((state) => state.collections)
  const affectChangeCollectionId = collections.collectionIDs && collections.collectionIDs['AFFECT_CHANGE']
  const saintJudeCollectionId = collections.collectionIDs && collections.collectionIDs['SAINT_JUDE']
  const setHeaderTextModalOpenFunction = (val) => {
    setIsHeaderTextModalOpen(val)
  }


  useEffect(() => {
    if (isProfileComplete) {
      history.push("/");
      initialCall();
    }
  }, [isProfileComplete]);

  async function fetchAffectChangeCollection() {
    const res = await axiosCustomInstance.get(getAPIHostName() + `/api/collection/${affectChangeCollectionId}`)
    return res.data;
  }
  async function fetchSaintJudeCollection() {
    const res = await axiosCustomInstance.get(getAPIHostName() + `/api/collection/${saintJudeCollectionId}`)
    return res.data;
  }
  useEffect(() => {
    const promotionCodes = JSON.parse(process.env.REACT_APP_PROMOTION_CODES);

    if (promotionCodes.includes(localStorage.getItem("promo")) && isProfileComplete) {
      let collectibleArray = [];
      if (localStorage.getItem("promo") === "STJUDECONSENSUS2023") {
        fetchSaintJudeCollection().then((collectibleList) => {
          console.log("collectible list", collectibleList)
          collectibleArray = collectibleList.data.categories[0].collectibleList.map((collectible) => {
            // if (collectible.status === "CLAIM")
            return collectible
          })
          console.log("collectible array", collectibleArray)
          const randomCollectible = collectibleArray.sort(() => 0.5 - Math.random())[0];
          console.log("randomCollectible", randomCollectible)
          localStorage.clear();
          history.push(`/SAINT_JUDE/collectible/${randomCollectible.id}`)
        })
      }
      else {
        fetchAffectChangeCollection().then((collectibleList) => {
          collectibleArray = collectibleList.data.categories[0].collectibleList.map((collectible) => {
            return collectible
          })
          const randomCollectible = collectibleArray.sort(() => 0.5 - Math.random())[0];
          localStorage.clear();
          history.push(`/AFFECT_CHANGE/collectible/${randomCollectible.id}`)
        })
      }

    }
  }, [isProfileComplete])


  const initialCall = async () => {
    ////console.log("route call")
    await dispatch(getAllCollections({}));
  }

  return (
    <>
      {isLoggedIn && (isProfileComplete == false) ? (
        <>

          {/* 1) TODO: During redirection there should be an intermediary generic screen 
        with three arguments finalPath: "/edit", message: "Setting user for first time", displayTime: "5"
         and then redirect the user to final path.

         2) If the backend api is down populate "System maintenance error" and keep rechecking every 10 seconds till api succeeds
         and navigate the user where he came from.
        
         3) Redirection in the same screen is not working.
        */}
          <Redirect to={"/edit"} />
        </>
      ) : (
        <div>
          <Modal
            open={!isLoggedIn}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <>
              {!isLoggedIn &&
                <>
                  {
                    isHeaderTextModalOpen && !isLoggedIn && <HeaderTextModal />
                  }
                  <Signin setHeaderTextModalOpenFunction={setHeaderTextModalOpenFunction} />
                </>}
            </>
          </Modal>
          <main className="main-content-wrapper">

            <section className="section-block main-banner">
              <div className="container">

                <div className="before-shape before-shape-home">
                  <div className="banner-slider">
                    <BannerCarousel />
                  </div>
                </div>
              </div>
            </section>
            <HomeToggle />
          </main>
        </div>
      )}
    </>
  );
};
export default Home;



