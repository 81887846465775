import React from "react";
import { useState, useEffect } from "react";
import { useStyles } from "../../Components/NftCollection/IndividualNftCard.style";
import { Link, useLocation, generatePath, useHistory } from "react-router-dom";
import IndividualNftCard from "../../Components/NftCollection/IndividualNftCard";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

//import { useWeb3React } from "@web3-react/core";
import axiosInstance, { axiosCustomInstance } from "../../services/api";

import Web3 from "web3";
import { collectionID } from "../../global/collection.id";

import NftInfoComponent from "../../Components/NftCollection/NftInfoComponent";
import OwnedIndividualNftCard from "../../Components/NftCollection/OwnedIndividualNftCard";
import getAPIHostName from "../../utils/utils";
import Loader from "../../Components/Loader/Loader";
import { useSelector } from "react-redux";
const OwnedNftPage = () => {
    const [open, setOpen] = React.useState(false);
    const [nftData, setNFtData] = React.useState()
    const [currentCollectionName, setCurrentCollectionName] = useState()
    const [currentCollectionID, setCurrentCollectionID] = useState()
    const [collectionArray, setCollectionArray] = useState([])
    const location = useLocation();
    const collections = useSelector((state) => state.collections)
    //const { active, account, library, connector, activate, deactivate, error } = useWeb3React();
    const web3 = new Web3()
    const route = useParams();




    let id = route.id;
    const fetchCollectible = async (id) => {
        try {
            const res = await axiosCustomInstance.get(getAPIHostName() + `/api/collectible/${id}`)
            return res
        } catch (error) {
            ////console.log(error)
        }
    }
    const { data, isError, isLoading, isFetched } = useQuery(['collectible', id], () => fetchCollectible(id), { refetchOnMount: true, refetchOnReconnect: true, refetchOnWindowFocus: true })
    useEffect(() => {
        if (isFetched) {
            data && setCurrentCollectionID(data.data.data.collectionId)
            const findKey = (key) => {
                return Object.keys(collections.collectionIDs).filter((k) => collections.collectionIDs[k].includes(key))
            }
            const collectionName = collections && findKey(data.data.data.collectionId)
            setCurrentCollectionName(collectionName)
        }
    }, [isFetched])
    const history = useHistory();
    const handleClick = () => {
        setOpen(true);
        navigator.clipboard.writeText(`${window.location.href}`);
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const revertBack = () => {
        history.goBack(-1)
    }
    if (!isFetched || isLoading) return <Loader />
    else
        return (
            <>
                <main className="main-content-wrapper">
                    <div className="container" style={{ marginTop: '25px', marginBottom: '20px', cursor: 'pointer' }}>
                        <div className="link-div" onClick={() => history.push("/profile")}>
                            <KeyboardBackspaceIcon sx={{ cursor: 'pointer' }} />
                            Back to Profile
                        </div>
                    </div>
                    <section className="section-block individual-block main-banner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    {
                                        (data && currentCollectionName) && <OwnedIndividualNftCard nftDetail={data.data.data} currentCollectionName={currentCollectionName} />
                                    }
                                </div>
                                {
                                    (data && currentCollectionName) && <NftInfoComponent nftData={data.data.data} currentCollectionID={data.data.data.collectionId} currentCollectionName={currentCollectionName[0]} />
                                }
                            </div>
                        </div>
                    </section>

                </main>
            </>
        )
}

export default OwnedNftPage