import { Biconomy } from "@biconomy/mexa"
import axios from 'axios'
//import promiseRetry from 'promise-retry'
//import axiosRetry from 'axios-retry'
import retryRPC from './retryRPC'
import {ethers} from "ethers"

export enum BiconomyTxnStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED"
}

export function waitForEvent(biconomy: Biconomy, name: string) {
    return new Promise((res, rej) => {
        console.log('before execute', name, biconomy)
        try {
            // biconomy.on(name, (data: any) => {
            //     console.log('in resolve', name, data, biconomy)
            //     return res(data)
            // }).on('onError', err => {
            //     console.error('got error', err)
            //     return rej(err)
            // })

              
        } catch (e) {
            rej(e)
        }
    })
}

export const sendRequestBiconomy = async (body: any) => {
    const apiUrl = 'https://api.biconomy.io/api/v2/meta-tx/native';
    const apiKey = process.env.REACT_APP_BICONOMY_API_KEY
    //const { apiUrl, apiKey } = biconomySettings
    const headerSettings = {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    }

    return new Promise((resolve, reject) => {
      retryRPC(() => axios.post(apiUrl, JSON.stringify(body), headerSettings))
        .then((res) => resolve(res))
        .catch((error) => {
          try {
            const [, , errorJSON] = error.response.data.message.match(
              /(error=(\{.*?\}))(?=,\s*(method|transaction|code|version))/,
            )
            reject(JSON.parse(JSON.parse(errorJSON).body).error)
          } catch {
            reject(error)
          }
        })
    })
  }

  export const getSignatureParametersEthers = (signature: any) => {
    if (!ethers.utils.isHexString(signature)) {
      throw new Error(
        'Given value "'.concat(signature, '" is not a valid hex string.')
      );
    }
    const r = signature.slice(0, 66);
    const s = "0x".concat(signature.slice(66, 130));
    let v = "0x".concat(signature.slice(130, 132));
    v = ethers.BigNumber.from(v).toString();
    if (![27, 28].includes(Number(v))) v += 27;
    return {
      r: r,
      s: s,
      v: Number(v),
    };
  };


  export const parseTransactionError = (error: any) =>{
    let parsedError:any
    if(error.reason && error.reason.match(/execution reverted/)) {
      if(error.reason.match(/Item is not Active/)) {
        parsedError =  {
          errorCode: "ITEM_SOLD",
          errorMessage: "The following item is sold. Sorry for the inconvenience"
        }
      }
      else if(error.reason.match(/Owner cannot buy his own NFT/)) {
        parsedError =  {
          errorCode: "INVALID_PURCHASE",
          errorMessage: "Owner cannot buy his NFT"
        }
      }
      else if(error.reason.match(/Not the Owner/)) {
        parsedError =  {
          errorCode: "INVALID_PURCHASE",
          errorMessage: "Invalid owner"
        }
      }
    
    }else {
      parsedError = {
        errorCode: "ERROR",
        errorMessage: error.message.toString()
      }
    }
    return parsedError
  }


  