import React, { useState } from "react";
import { useSigner, useContract, useProvider } from "wagmi";
import { getBiconomyInstance, getChainId } from "../web3";
import {ExternalProvider} from "../web3"
import { Biconomy } from "@biconomy/mexa";

const MarketplaceAbi = require("../contracts/Marketplace.sol/Marketplace.json")

// export const useWeb3Contract = async (contractArtifacts: any) => {
//   const [data, setData] = useState();

//   const { data: signer } = useSigner();
//   const chainId = getChainId();
//   const provider = useProvider({ chainId });
  
//   //Biconomy transactions
//   const biconomy = getBiconomyInstance(provider);
//   await biconomy.init();

//   const contract = useContract({
//     address: contractArtifacts.address,
//     abi: MarketplaceAbi.abi,
//     signerOrProvider: biconomy.signer,
//   });

//   return [contract];
// };

// export default useWeb3Contract;
