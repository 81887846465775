import axios from "axios";
import { getMagicBearerToken } from './auth.service';
import updateApiStatusCode, { logoutCleanup, logoutUser, setIsLoading } from "../slices/auth.slice"
import { setMessage } from "../slices/message.slice";
import { AxiosError } from 'axios';

let store
export const injectStore = _store => {
    store = _store
}

const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BASEURL,
});

export const axiosCustomInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BASEURL,
});

axiosCustomInstance.interceptors.response.use(
    response => {
        if (response.status === 201 || response.status === 200) {
            // store.dispatch(setMessage({ type: "success", message: "Profile saved successfully", timeStamp: Date.now() }))
        }
        return response
    },
    error => {
        ////console.log("error api.js", error.toJSON().message)
        if (error.toJSON().message === "Network Error") {
            store.dispatch(logoutUser({}));
            store.dispatch(logoutCleanup())
            window.location.href = "/"
            store.dispatch(setMessage({ type: "error", message: "Network Error", timeStamp: Date.now() }))
        }
        else if (!error?.response) {
            store.dispatch(setMessage({ type: "error", message: "No server response", timeStamp: Date.now() }))
        }
        else if (error.response.data.error.message.includes("JWT")) {
            store.dispatch(logoutCleanup());
        }

        else if (error.response.status === 403) {
            store.dispatch(updateApiStatusCode("inactive"))
        }
        else return error
    });

// axiosInstance.interceptors.request.use(async config => {

//     config.headers = {
//        // "authorization": await getMagicBearerToken()
//     }
//     return config;
// },
//     error => {
//         Promise.reject(error)
//     });

export default axiosInstance