import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()({
  sideMenuNav: {
    background: "#0e0e16",
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 10,
    position: 'sticky',
    top: 135,
    "@media(max-width:992px)": {
      top: 90,
    },
    "@media(max-width:575px)": {
      marginBottom: 30,
    },
  },
  sideMenuList: {
    li: {
      padding: "10px 20px",
      a: {
        "&:hover" : {
          color: 'rgb(246, 118, 34)!important',
          cursor: "pointer",
        }
      }
    }
  },
  mainContentArea:{
    h4: {
      fontStyle: "normal",
      fontWeight: "600",
      textAlign: "left",
      //textTransform: "uppercase",
      background: "linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      display: 'block',
      float: 'left',
      width: 'auto',
      "&:hover": {
        cursor: "pointer",
      },
    },
    strong:{
      background: "linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)",
      color: 'white',
      borderRadius: 4,
      padding: "0px 5px",
    },
    p: {
      fontSize: 16,
    },
  },
  question: {
    borderBottom: '1px solid #ffffff66',
    paddingTop: 20,
    paddingBottom: 20,
  },
  lastQuestion: {
    paddingTop: 20,
    paddingBottom: 80,
  },
  noStyleList:{
    li:{
      listStyle: 'disc',
      marginLeft: 17,
      marginTop: 10,
      li:{
        listStyle: 'circle',
        marginTop: "-5px",
      }
    }
  }
  
});
export { useStyles };
