import { Magic } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";
import userService from "./user.service";
import axiosInstance from "./api";
import getAPIHostName from "../utils/utils";
import { createMagicInstance } from "./Vendor/Magic/magic";
export const magic = createMagicInstance()

export const SOLIS_USER = "solis-user";


//Login with Magic
export const loginUser = async (email, tokenParam, postData, isSocialLogin) => {
  try {

    //if token param, call api/register/token 
    if (tokenParam) {
      try {
        await axiosInstance.post(
          getAPIHostName() + "/api/register/token", postData
        );

      } catch (error) {
        ////console.log("error register toiken", error)
      }
    }
    else {
      const redirectURI = process.env.REACT_APP_MAGIC_REDIRECT_URI;
      await magic.auth.loginWithMagicLink({ email, redirectURI: redirectURI + "/magic/callback" });
      return await getMagicUserMetaData('loginUser');
    }

  } catch (error) {
    ////console.log("magic error", error);
  }
};
// `getRedirectResult()` returns an object with user data from Magic and the social provider
export const finishSocialLogin = async () => {
  try {
    ////console.log("finish social login")
    return await getMagicUserMetaData('loginWithSocials');
  } catch (error) {
    ////console.log("magic error", error);
  }
};

export const refreshUser = async (emailParam) => {
  try {
    let userResponse;
    // if (emailParam) {
    //   userResponse = await userService.refreshUser(emailParam);
    // }
    userResponse = await userService.refreshUser(emailParam);
    return userResponse;
  } catch (error) {
    throw error;
  }
}
export const loginWithRedirectCredentials = async (credentialToken) => {
  try {
    await magic.auth.loginWithCredential(credentialToken);
    return await getMagicUserMetaData('loginWithRedirectCredentials');
  } catch (error) {
    ////console.log("magic error", error);
  }
};


const getMagicUserMetaData = async (source) => {
  ////console.log("getMagicUserMetaData-source: " + source);
  const isLoggedIn = await magic.user.isLoggedIn();

  //If loggedIn persist the magic metadata to local storage
  if (isLoggedIn) {
    //Load metadata
    const magicMetadata = await magic.user.getMetadata();

    //## Moved this to call directly magic because of expired token
    //Set the token to local storage also update the state
    const magicToken = await magic.user.getIdToken()

    //Check user profile complete
    try {
      const loginResponse = await userService.loginUser(magicToken);
      if (loginResponse.data.error && (loginResponse.data.error === 'USER_NOT_FOUND' || loginResponse.data.error === "USER_PROFILE_NOT_COMPLETE")) {
        return { magicMetadata: { ...magicMetadata, isLoggedIn: true, isProfileComplete: false, isLoading: false, initializeRequired: false }, userDetail: {} };
      }

      let isProfileComplete = (loginResponse != null && loginResponse.data.data && loginResponse.data.data.email === magicMetadata.email) ? true : false;
      return { magicMetadata: { ...magicMetadata, isLoggedIn: true, isProfileComplete, isLoading: false, initializeRequired: false }, userDetail: loginResponse.data.data };
    } catch (error) {
      if (error.response.status === 404 && error.response.data.data === 'User with the given ID not found.') {
        return { magicMetadata: { ...magicMetadata, isLoggedIn: true, isProfileComplete: false, isLoading: false, initializeRequired: false }, userDetail: {} };
      }
    }
  } else {
    return { magicMetadata: { isLoggedIn: false, isProfileComplete: false, isLoading: false } };
  }
};

export const getMagicBearerToken = async () => {
  const magicToken = await magic.user.getIdToken()
  return magicToken;
};

//Login with Google OAUTH
export const googleOauthLogin = async () => {
  ////console.log("Iam here ", window.location.origin);

  const didToken = await magic.oauth.loginWithRedirect({
    provider: 'google',
    redirectURI: new URL('https://auth.magic.link/v1/oauth2/DHACmwDlIUR9bab7R-AfF9bPZ_cZqGMHR6Ql1IOyHac=/callback'),
  });
  ////console.log("Token  ", didToken);
};

//Login with Facebook OAUTH
export const facebookOauthLogin = async () => {
  await magic.oauth.loginWithRedirect({
    provider: "facebook",
    redirectURI: `${window.location.origin}/market`,
  });
};

//Login with Apple OAUTH
export const appleOauthLogin = async () => {
  await magic.oauth.loginWithRedirect({
    provider: "apple",
    redirectURI: `${window.location.origin}/market`,
  });
};

//Logout
export const logoutUser = async () => {
  try {
    await magic.user.logout();
    await userService.logoutUser();
    return;
  } catch (error) {
    ////console.log("magic error", error);
  }
};

export const isUserLoggedIn = async () => {
  const isLoggedIn = await magic.user.isLoggedIn();
};

const authService = {
  loginUser,
  refreshUser,
  loginWithRedirectCredentials,
  logoutUser,
  getMagicUserMetaData,
  finishSocialLogin
};

export default authService;
