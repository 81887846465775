import { useState, useEffect, useRef } from "react";
//import { useWeb3React } from "@web3-react/core";

import { setMessage } from "./slices/message.slice";
import { useDispatch } from "react-redux";
import SnackBar from "./Components/Error/snackbar";
import { refresh, updateApiStatusCode } from "./slices/auth.slice";

export function useEagerConnect() {
  //const { activate, active } = useWeb3React();

  // const [tried, setTried] = useState(false);

  // useEffect(() => {
  //   if (!tried && active) {
  //     setTried(true);
  //   }
  // }, [tried, active]);

  // return tried;
}

export function useInactiveListener(suppress: boolean = false) {
  // const { active, error, activate } = useWeb3React();

  // useEffect((): any => {
  //   const { ethereum } = window as any;
  //   if (ethereum && ethereum.on && !active && !error && !suppress) {
  //     const handleConnect = () => {
  //       ////console.log("Handling 'connect' event");
  //     };
  //     const handleChainChanged = (chainId: string | number) => {
  //       ////console.log("Handling 'chainChanged' event with payload", chainId);
  //     };
  //     const handleAccountsChanged = (accounts: string[]) => {
  //       ////console.log("Handling 'accountsChanged' event with payload", accounts);
  //       if (accounts.length > 0) {
  //       }
  //     };
  //     const handleNetworkChanged = (networkId: string | number) => {
  //       ////console.log("Handling 'networkChanged' event with payload", networkId);
  //     };

  //     ethereum.on("connect", handleConnect);
  //     ethereum.on("chainChanged", handleChainChanged);
  //     ethereum.on("accountsChanged", handleAccountsChanged);
  //     ethereum.on("networkChanged", handleNetworkChanged);

  //     return () => {
  //       if (ethereum.removeListener) {
  //         ethereum.removeListener("connect", handleConnect);
  //         ethereum.removeListener("chainChanged", handleChainChanged);
  //         ethereum.removeListener("accountsChanged", handleAccountsChanged);
  //         ethereum.removeListener("networkChanged", handleNetworkChanged);
  //       }
  //     };
  //   }
  // }, [active, error, suppress, activate]);
}

// export function useTransitionModal(
//   message: string,
//   displayTime: number,
//   finalPath: string
// ) {
//   return { message, displayTime, finalPath };
// }

export const useApiStatusDispatcher = () => {
  const dispatch = useDispatch();

  return (payload: any) => {
    dispatch(updateApiStatusCode(payload));
  };
};

// export const useRefresh = () => {
//   const dispatch = useDispatch();
//   const [fetch, setFetch] = useState(false);
//   const delay = 1;
//   useEffect((): any => {
//     let timer = setInterval(() => {
//       ////console.log("run");
//       dispatch(refresh());
//     }, delay * 10000);
//     return () => {
//       clearInterval(timer);
//     };
//   }, []);
// };

const useInfiniteLoading = (props: any) => {
  const { getItems } = props;
  const [items, setItems] = useState([]);
  const pageToLoad = useRef(
    new URLSearchParams(window.location.search).get("page") || 1
  );
  const initialPageLoaded = useRef(false);
  const [hasMore, setHasMore] = useState(false);
  const loadItems = async () => {
    const data = await getItems({
      page: pageToLoad.current,
    });
    setHasMore(data.totalPages > pageToLoad.current);
    setItems((prevItems) => [...prevItems, ...data]);
  };
  useEffect(() => {
    if (initialPageLoaded.current) {
      return;
    }
    loadItems();
    initialPageLoaded.current = true;
  }, [loadItems]);
  return {
    items,
    hasMore,
    loadItems,
  };
};

export function useInterval(callback: any, delay: number) {
  const savedCallback = useRef(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
