
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  formContainer: {
    textAlign: "center",
    //height: '60vh',
    //width: '30vw',
    margin: "auto",
    marginTop: '10vh',
    borderRadius: "10px",
    color: "white",
  },
  moonpayFrame:{
    height:'100%',
    width:'100%',
  },
  moonpayContainer:{
    margin:'auto',
    position:'relative',
    padding:'20px',
    border:'2px solid #f67622',
    height:'auto',
    backgroundColor:'rgb(20,22,35)',
    boxShadow:'0px 0px 30px 0px #f67622',
    '@media(min-width:1199px)' :{
      marginTop:'20vh',
      height:'70vh',
      width:'35vw'
    },
    '@media(max-width:1198px)' :{
      marginTop:'20vh',
      height:'70vh',
      width:'35vw'
    },
    '@media(max-width:890px)' :{
      marginTop:'20vh',
      height:'60vh',
      width:'50vw'
    },
    '@media(max-width:786px)' :{
      marginTop:'20vh',
      height:'60vh',
      width:'60vw'
    },
    '@media(max-width:600px)' :{
      marginTop:'10vh',
      height:'70vh',
      width:'60vw'
    },
    '@media(max-width:600px)' :{
      marginTop:'10vh',
      height:'70vh',
      width:'90vw'
    },
    
  },
  logo: {
    height: "40%",
    width: "90%",
    marginTop: "10%",
  },
  paymentModal: {
    width: '30vw',
    minheight: '50vh',
    maxHeight: '150vh',
    margin: 'auto',
    marginTop: '12vh',
    border: '1px solid #f67622',
    boxShadow: '0px 0px 30px 0px #f67622',
    background: '#131623',
    fontFamily: 'Saira',
    position:'relative',
    padding: '20px', 
    '@media(max-width:542px)':{
      width:'90%',
      marginTop:'20vh!important',
      fontSize:'0.8rem'
    },
    '@media(min-width:543px)':{
      width:'85%',
      marginTop:'20vh!important'
    },
    '@media(min-width:787px)':{
      width:'60%',
      marginTop:'20vh!important'
    },
    '@media(min-width:1199px)':{
      width:'35%',
      marginTop:'20vh!important'
    },
    
  },
  paymentModalSuccess:{
    width: '30vw',
    minheight: '50vh',
    maxHeight: '150vh',
    margin: 'auto',
    marginTop: '12vh',
    border: '1px solid #f67622',
    boxShadow: '0px 0px 30px 0px #f67622',
    background: '#131623',
    fontFamily: 'Saira',
    position:'relative',
    padding: '20px', 
    '@media(max-width:542px)':{
      width:'90%',
      marginTop:'20vh!important',
      fontSize:'0.8rem'
    },
    '@media(min-width:543px)':{
      width:'70%',
      marginTop:'20vh!important'
    },
    '@media(min-width:787px)':{
      width:'60%',
      marginTop:'20vh!important'
    },
    '@media(min-width:1199px)':{
      width:'35%',
      marginTop:'20vh!important'
    },
   
  },
  paymentModalHeading: {
    fontSize: '1.3rem',
    fontWeight: '600',
    fontFamily: 'Aldrich',
    textAlign: 'center',
    marginBottom: '20px',
    color:'#f67622'
  },
  paymentOptionsDiv: {
    border: '1px solid #f67622',
    padding: '10px',
    fontSize: '18px',
    paddingLeft: '30px',
    paddingRight: '45px',
    borderRadius: '25px',
    width: '90%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 0px 20px 0px #f67622'
    }
  },
  paymentOptionsLogo: {
    height: '30px',
    width: '30px'
  },
  cancelPaymentButton: {
    textAlign: 'center',
    color: '#f67622',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  paymentWindowHeading: {
    textAlign: "left",
    fontSize: '1.3rem',
    color: '#f67622',
    fontWeight:'900'    
  },
  paymentWindowHeadingSuccess:{
    textAlign: "left",
    fontSize: '1.3rem',
    color: '#f67622',
    fontWeight:'900',
  },
  currencyDropdown:{
    display:'flex',
    justifyContent:'center',
    marginTop:'30px',
    marginBottom:'20px'
  },
  transakButtonDiv:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    marginBottom:'20px'
  },
  thumbnailDiv:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    width:'80%',
    margin:'auto',
    '@media(max-width:1199px)':{
      flexDirection:'column',
      alignItems:'center'
    }
  },
  thumbnailImageDiv:{
    height:'100px',
    width:'100px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    borderRadius:'5px'
  },
  thumbnailDetailsDiv:{
    '@media(max-width:1199px)':{
      textAlign:'center',
      marginBottom:'20px'
    }
  },
  accordionMainText:{
    display:'flex',
    justifyContent:'space-between',
    width:'90%',
    flexGrow:'wrap',
    fontWeight:'500',
  },

  paymentOptionButtons:{
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    width:'50%',
    margin:'auto'
  },
  accordionContainer:{
    width:'80%',
    margin:'auto',
    '@media(max-width:542px)':{
      width:'95%',
    }
  },
  accordionSummaryDivContainer:{
    display:'grid',
    gridTemplateRows:'auto auto', 
    gridGap:'10px', 
  },
  accordionSummaryDiv:{
    display:'flex',
    justifyContent:'space-between',
    width:'84%',
    flexGrow:'wrap'
  },
  walletConnectAndDisconnect:{
    marginTop:'10px',
    textAlign:'center'
  },
  connectedTextDiv:{
    textAlign:'center',
    cursor:'pointer'
  },
  disconnectWallet:{
    padding:'5px', 
    fontSize:"0.7rem" ,
    borderRadius:'5px',
    color:'#f67622',
    background:'rgb(19,22,35)',
    border:'1px solid #f67622',  
    marginLeft:'10px',
    '&:hover':{
      cursor:'pointer',
      color:'black',
      background:'#f67622'
    },
    marginBottom:'20px'
  },
  connectedTextDiv:{
    display:'flex',
  },
  dropdownDiv:{
    display:'flex !important',
    justifyContent:'space-between !important'
  },
  currencyRadioGroup:{
    marginTop:'40px',
    marginBottom:'20px',
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    gap:'20px',
    margin:'auto',
    '@media(max-width:542px)':{
      flexDirection:'column',
      justifyContent:'center',
      alignContent:'center',
      alignItems:'center',
      marginTop:'40px'
    }
  
  },
  selectedCurrencyBalanceDiv:{
    textAlign:'center',
    marginTop:'10px',
    marginBottom:'20px',
    fontWeight:'600',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  addBalanceDiv:{
    display:'flex', 
    justifyContent:'center',
    alignItems:'center',
    gap:'30px',
    color:'#f67622',
    fontWeight:'600',
    '@media(max-width:786px)':{
      display:'flex',
      flexDirection:'column' 
    }
  },
  footerCancelButton:{
    display:'flex',
    justifyContent:'center',
    '&:hover':{
      color:'#f67622',
      fontWeight:'600', 
      cursor:'pointer'
    },
    marginTop:'30px'
  },
  explorerUrlDiv:{
    display:'flex',
    textAlign:'center',
    flexDirection:'column',
    marginTop:'20px', 
    justifyContent:'center',
    alignItems:'center',
    flexGrow:'wrap', 
    gap:'10px',
    fontWeight:'600',
    '&hover':{
      cursor:'pointer',
    } 
  },
  explorerUrlSpan:{
    color:'#f67622',
    fontWeight:'600',
    cursor:'pointer',
    '&hover':{
      cursor:'pointer',
      fontWeight:'600',
      color:'#f67622'
    } 
  },
  moonpayWidgetParent:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    position:'relative', 
    margin:'auto',
    padding:'10px',
    "@media (min-width: 601px) and (max-width: 960px)": {
      height: "60vh",
      width: "80vw",
      marginTop:'20vh',

    },
    "@media (min-width: 961px) and (max-width: 1280px)": {
      height: "60vh",
      width: "40vw",
      marginTop:'20vh',

    },
    "@media (min-width: 1281px)": {
      height: "70vh",
      width: "35vw",
      marginTop:'20vh',
    },
    "@media (max-width: 600px)": {
      height: "90vh",
      width: "90vw",
      marginTop:'10vh',

    },
    border:'1px solid #f67622',
    backgroundColor:'rgb(20,22,35)'

  },
  textWithImageDiv:{
    display:'flex',
    alignItems:'center',
    gap:'5px',
    margin:'auto',
    marginLeft:'10px'
  } ,
  paymentLogo:{
    width:'25px',
    height:'25px'
  },
  fiatPaymentRadioGroup:{
    display:'flex',
    justifyContent:'center',
    margin:'auto',
    marginTop:'40px',
    marginBottom:'20px',
    '@media(max-width:542px)':{
      flexDirection:'column',
      justifyContent:'center',
      alignContent:'center',
      alignItems:'center',
      marginTop:'40px'
    }
  },
  radiogroupWrapper:{
    display:'flex',
    justifyContent:'center',
    alignItems:"center",
  },
  buyNowWithFiatDiv:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  fiatPendingDiv:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column',
    gap:'20px'
  },
  afterFiatCheckoutDiv:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column',
    gap:'20px'
  },
  userDecisionButtonContainer:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexGrow:'wrap',
    maxWidth:'40vw',
    height:'auto',
    margin:'auto',
    flexDirection:'column',
    '@media(min-width:1199px)' :{
      marginTop:'35vh',
   
    },
    '@media(max-width:1198px)' :{
      marginTop:'35vh',
 
    },
    '@media(max-width:890px)' :{
      marginTop:'35vh',

    },
    '@media(max-width:786px)' :{
      marginTop:'35vh',

    },
    '@media(max-width:600px)' :{
      marginTop:'25vh',

    },
    '@media(max-width:600px)' :{
      marginTop:'25vh',

    },
  },
  userDecisionButtonDiv:{
    marginTop:'30px',
    display:'flex',
    gap:'20%',
    alignItems:'center',
  },
  userDecisionButton:{
    color:'#f67622',
    border:'1px solid #f67622',
    background:'black',
    borderRadius:'10px',
    '&:hover':{
      color:'white',
      background:'#f67622',
      border:'1px solid black',
      boxShadow:'0px 0px 10px 0px #f67622'
    }
  },
  userDecisionText:{
    fontFamily:'saira',
    fontWeight:'900'
  },
  addressWithCopyIcon:{
    display:'flex',
    justifyContent:'center',
    gap:'10px',
    marginTop:'10px',
    marginBottom:'10px'
  },
  refreshBalanceIcon:{
    '&:hover':{
      cursor:'pointer',
      color:'#f67622'
    }
  },
  exteralWalletTransferContainer:{
    margin:'auto',
    position:'relative',
    padding:'20px',
    border:'2px solid #f67622',
    height:'auto',
    backgroundColor:'rgb(20,22,35)',
    boxShadow:'0px 0px 30px 0px #f67622',
    '@media(min-width:1199px)' :{
      marginTop:'20vh',
      height:'65vh',
      width:'35vw'
    },
    '@media(max-width:1198px)' :{
      marginTop:'20vh',
      height:'65vh',
      width:'45vw'
    },
    '@media(max-width:890px)' :{
      marginTop:'20vh',
      height:'70vh',
      width:'50vw'
    },
    '@media(max-width:786px)' :{
      marginTop:'20vh',
      height:'70vh',
      width:'60vw'
    },
    '@media(max-width:600px)' :{
      marginTop:'10vh',
      height:'70vh',
      width:'60vw'
    },
    '@media(max-width:600px)' :{
      marginTop:'10vh',
      height:'70vh',
      width:'90vw'
    },

    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    flexDirection:'column',
    fontFamily:'Saira'
  },
  withdrawInputField:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    color: "#f67622",
    fontFamily: "Saira",
    backgroundColor: "transparent",
    border: "1px solid #f67622",
    padding: "8px 16px",
    maxHeight: "50px",
    width:'100%',
    maxWidth:'450px',
    boxShadow:'0px 0px 10px 0px #f67622'
  },
  withdrawInputFieldCurrency:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    color: "#f67622",
    fontFamily: "Saira",
    backgroundColor: "transparent",
    border: "1px solid #f67622",
    padding: "8px 16px",
    maxHeight: "50px",
    maxWidth:'200px',
    boxShadow:'0px 0px 10px 0px #f67622'
  },
  exteralWalletTransferResultContainer:{
    margin:'auto',
    position:'relative',
    padding:'20px',
    border:'2px solid #f67622',
    height:'auto',
    backgroundColor:'rgb(20,22,35)',
    boxShadow:'0px 0px 30px 0px #f67622',
    '@media(min-width:1199px)' :{
      marginTop:'20vh',
      height:'25vh',
      width:'35vw'
    },
    '@media(max-width:1198px)' :{
      marginTop:'20vh',
      height:'25vh',
      width:'45vw'
    },
    '@media(max-width:890px)' :{
      marginTop:'20vh',
      height:'35vh',
      width:'50vw'
    },
    '@media(max-width:786px)' :{
      marginTop:'20vh',
      height:'35vh',
      width:'60vw'
    },
    '@media(max-width:600px)' :{
      marginTop:'10vh',
      height:'40vh',
      width:'60vw'
    },
    '@media(max-width:600px)' :{
      marginTop:'10vh',
      height:'40vh',
      width:'90vw'
    },

    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    flexDirection:'column',
    fontFamily:'Saira'
  },


});

export { useStyles };