import { fetchBalance } from "@wagmi/core";
import {ethers} from "ethers";
const { getErc20TokenCurrencyAddressByChainId,ChainId, getChainId, getErc20TokenContractArtifacts } = require("../../../web3/web3")
export async function getWalletBalanceByToken(walletAddress,tokenName){
const chainId = getChainId()

let roundedBalance
try {
    
    const tokenArtifacts = getErc20TokenContractArtifacts(tokenName)
    const result = await fetchBalance({
        address : walletAddress,
        chainId,
        token: tokenArtifacts.address,
        formatUnits: 'wei'
    })
    const ethersBalance = result && Number(result.formatted) / 10 ** Number(result.decimals)
        roundedBalance = parseFloat(ethersBalance).toFixed(2)
} catch (error) {
    return "0.00"
}
    return roundedBalance      


}