import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Loader from '../../Components/Loader/Loader'
import { axiosCustomInstance } from '../../services/api'
import { logoutCleanup } from '../../slices/auth.slice'
import getAPIHostName from '../../utils/utils'

const InitializeComponent = () => {
    const dispatch = useDispatch()
    const authState = useSelector((state) => state.auth)
    const history = useHistory()
    useEffect(() => {
        if (authState.isLoggedIn) {
            axiosCustomInstance.get(getAPIHostName() + '/api/login/register')
        }
        else {
            dispatch(logoutCleanup())
            history.push("/")
        }
    }, [])
    return (
        <>
            <Loader />
        </>
    )
}

export default InitializeComponent