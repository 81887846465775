import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()({


    videoContainer: {
        maxHeight: '900px'
    },
    longDescription: {
        marginBottom: '30px',
        fontFamily: 'sans-serif'
    }
});
export { useStyles }
