import { createSlice } from "@reduxjs/toolkit";


const initialState = { message: '', type: '', timeStamp: '' };
const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      const { type, message, timeStamp } = action.payload
      state = action.payload
      return state
    },
  },
});

const { reducer, actions } = messageSlice;
export const { setMessage } = actions;
export default reducer;