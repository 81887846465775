import React from "react";
import ComingSoon from "../../Components/ComingSoon/ComingSoon";
import Loader from "../../Components/Loader/Loader";
import { useState } from "react";
const Ecosystem = () => {
  const [testLoading, setTestLoading] = useState(false)
  if (testLoading)
    return <Loader />
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default Ecosystem;
