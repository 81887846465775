import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()({
  parentContainer: {
    width: "75.5%",
    margin: "auto",
    marginTop: "100px",
    display: "grid",
    gridTemplateColumns: "66.43% 32.52%",
    gridColumnGap: "1.3%",
    maxHeight: "1373px",
  },
  nftCollectionCards: {
    maxHeight: "528px",
    marginTop: "80px",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  orangeGradientHeading: {
    fontFamily: "Saira",
    textTransform: "uppercase",
    fontSize: "3.8rem",
    lineHeight: "4rem",
    background:
      "-moz-linear-gradient(314.45deg, #C90099 -70.21%, #F67622 58.16%, #D8D8D8 143.73%)",
    background:
      "-webkit-linear-gradient(314.45deg, #C90099 -70.21%, #F67622 58.16%, #D8D8D8 143.73%)",
    background:
      "linear-gradient(314.45deg, #C90099 -70.21%, #F67622 58.16%, #D8D8D8 143.73%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    position: "relative",
    fontWeight: "900",
  },
  scrollBlock: {
    padding: "0px !important",

    "@media(min-width:990px)": {
      maxHeight: "100px",
      padding: "0 px !important",
    },
    "@media(min-width:1200px)": {
      maxHeight: "200px",
      padding: "0 px !important",
    },
  },
  scrollBlockContainer: {
    "@media(min-width:990px)": {
      maxHeight: "500px",
    },
  },
  propertyNameContainerDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  detailsField: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  detailsFieldData: {
    wordBreak: 'break-all'
  },
  detailsFieldName: {
    wordBreak: 'keep-all'
  },
  propertyContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: 15,
    flexWrap: 'wrap',
    zIndex: '10',
    position: 'relative',
  },
  propertySubContainers: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  historyTab:{
    display:'grid',
    gridTemplateRows:'30px auto',
    maxHeight:'200px',
    overflowY:'scroll',
    '&::-webkit-scrollbar': {
      width: '3px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
      borderRadius: '100px',
    },
  },
  historyTabListContainer:{
    display:'grid',
    gridTemplateColumns:'auto auto auto',

  },
  historyTabListContainerOrange:{
    display:'grid',
    gridTemplateColumns:'auto auto auto',
    color:'#f67622'
  }
});
export { useStyles };
