import { useEffect, useRef, useState } from "react";
import Card from "../Card/Card"
import { useStyles } from "../../Pages/Profile/profile.style"
import clsx from "clsx";
import Loader from "../Loader/Loader";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { openTransak, } from "../../services/Vendor/Transak/transak";
import { useAccount, useDisconnect } from "wagmi";
import { getWalletBalanceByToken } from "../../services/Vendor/Wagmi/wagmiHelper";
import { setIsLoading } from "../../slices/auth.slice";
import { CURRENT_NETWORK } from "../../web3/networks";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { toArray } from "../../helpers/toArray";
import { Select, MenuItem, InputLabel } from "@mui/material";
import styled from "@emotion/styled";
import Modal from '@mui/material/Modal';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Moonpay } from "../../Pages/PaymentOptions/moon-pay";
import { useDispatch } from "react-redux";
import { setMessage } from "../../slices/message.slice";
import axiosInstance from "../../services/api";
import getAPIHostName from "../../utils/utils";
import MoonpayOffRamp, { initiateMoonpaySdk } from "../../Pages/PaymentOptions/MoonpayOffRamp";
import ExternalWalletTransfer from "../../Pages/PaymentOptions/ExternalWalletTransfer";
const ProfileNftComponent = ({ nft }) => {

    const [isCollectionsActive, setIsCollectionsActive] = useState(true);
    const [isFavoritesActive, setIsFavoritesActive] = useState(false);
    const [isAccountsActive, setIsAccountsActive] = useState(false);
    const [isMyListingsActive, setIsMyListingsActive] = useState(false)
    const [isLoaderActive, setIsLoaderActive] = useState(false)
    const [walletBalance, setWalletBalance] = useState()
    const [myListings, setMyListings] = useState([]);
    const [isFetchingListings, setIsFetchingListings] = useState(false);
    const [vendorSelectionModalOpen, setVendorSelectionModalOpen] = useState(false)
    const [withdrawVendorSelectionModalOpen, setWithdrawVendorSelectionModalOpen] = useState(false)
    const [selectedVendor, setSelectedVendor] = useState("")
    const [selectedWithdrawVendor, setSelectedWithdrawVendor] = useState(false)
    const [moonpayModalOpen, setMoonpayModalOpen] = useState(false)
    // const [moonpayOffRampModalOpen, setMoonpayOffRampModalOpen] = useState(false)
    const [externalWalletTransferModalOpen, setExternalWalletTransferModalOpen] = useState(false)
    const [connectedWalletName, setConnectedWalletName] = useState()
    const [connectedWalletLogo, setConnectedWalletLogo] = useState()
    const [favoriteNftList, setFavoriteNftList] = useState()
    const [isFetchingFavorites, setIsFetchingFavorites] = useState(false)
    const [selectedWithdrawCurrency, setSelectedWithdrawCurrency] = useState()
    const [currencyAndBalanceArray, setCurrencyAndBalanceArray] = useState()
    const [availableBalanceForWithdraw, setAvailableBalanceForWithdraw] = useState()
    const userState = useSelector((state) => state.user)
    const authState = useSelector((state) => state.auth)
    const { isLoading } = useSelector((state) => state.auth)
    const { address, isConnected, connector } = useAccount()
    const { openConnectModal } = useConnectModal()
    const { disconnect } = useDisconnect()
    const { classes } = useStyles();
    const [currency, setCurrency] = useState("USDC")
    const supportedCurrenciesCrypto = toArray(process.env.REACT_APP_SUPPORTED_CURRENCIES_CRYPTO)
    const dispatch = useDispatch()
    const handleClick = (event, provider) => {
        event.preventDefault()

        if (provider === "collections") {
            setIsAccountsActive(false)
            setIsCollectionsActive(true);
            setIsFavoritesActive(false);
            setIsMyListingsActive(false);
        }
        if (provider === "favorites") {
            setIsAccountsActive(false)
            setIsCollectionsActive(false);
            setIsFavoritesActive(true);
            setIsMyListingsActive(false);
        }
        if (provider === "accounts") {
            setIsAccountsActive(true)
            setIsCollectionsActive(false);
            setIsFavoritesActive(false);
            setIsMyListingsActive(false);
        }
        if (provider === "myListings") {
            setIsAccountsActive(false)
            setIsCollectionsActive(false);
            setIsFavoritesActive(false);
            setIsMyListingsActive(true);
        }
    };

    async function getBalance(address, currency) {
        const balance = await getWalletBalanceByToken(address, currency)
        return balance
    }
    async function getAllBalances(address, supportedCurrenciesCrypto) {
        const promises = supportedCurrenciesCrypto.map(async (currency) => {
            const balance = await getBalance(address, currency);
            return { name: currency, balance };
        });

        const updatedCurrencies = await Promise.all(promises);
        return updatedCurrencies;
    }


    const setLogoSrc = () => {
        const walletName = connector.name.toLowerCase()
        console.log(walletName)
        const path = require(`../../assets/images/${walletName}.svg`)
        console.log("required path", path)
        setConnectedWalletLogo(path)
    }
    const getFavoriteNftList = async () => {
        const res = await axiosInstance.get(getAPIHostName() + `/api/users/email=${userState.email}`);
        const favoriteIds = res.data.data.favorites;

        const collectiblePromises = favoriteIds.map(id =>
            axiosInstance.get(getAPIHostName() + `/api/collectible/${id}`)
        );

        const collectibles = await Promise.all(collectiblePromises);

        // Extract the nested data from each response
        const collectibleData = collectibles.map(res => res.data.data);

        return collectibleData;
    }

    const getMyListings = () => {
        return nft.data.filter(item => item.ownerId === userState.id && item.status === "IN_MARKET");
    };


    useEffect(() => {
        setIsLoading(true)
        getAllBalances(address, supportedCurrenciesCrypto).then((res) => {
            setCurrencyAndBalanceArray(res)
            setIsLoading(false)

        })
    }, [, address, localStorage.getItem("transak"), externalWalletTransferModalOpen])

    useEffect(() => {
        if (isFavoritesActive) {
            setIsFetchingFavorites(true)
            getFavoriteNftList().then((list) => {
                setFavoriteNftList(list)
                setIsFetchingFavorites(false)
            })
        }

    }, [isFavoritesActive])



    useEffect(() => {
        if (isMyListingsActive) {
            setIsFetchingListings(true)
            const list = getMyListings();
            setMyListings(list)
            setIsFetchingListings(false)
        }
    }, [isMyListingsActive, nft])



    const handleSwitchWallet = async () => {
        if (address && isConnected) {
            disconnect()
        }
        else openConnectModal()
    }

    const handleDropdown = (event) => {
        setCurrency(event.target.value)
    }
    const handleVendorRadioGroupValues = (event) => {
        if (event.target.value === "moonpay") {
            setSelectedVendor("moonpay")
            setVendorSelectionModalOpen(false)
            setMoonpayModalOpen(true)
        }
        else {
            setSelectedVendor("transak")
            setVendorSelectionModalOpen(false)
            openTransak(address, "USDT,USDC", 0, userState.email, "userWallet")
        }
        setSelectedVendor("")
    }

    const handleWithdrawRadioGroupValues = (event) => {
        if (event.target.value === "moonpay") {
            setSelectedWithdrawVendor("moonpay")
            setWithdrawVendorSelectionModalOpen(false)
            // setMoonpayOffRampModalOpen(true)


            //USDC hardcoded moonpay off ramp
            initiateMoonpaySdk(address, "usdc")
        }
        else {
            setSelectedWithdrawVendor("transak")
            setWithdrawVendorSelectionModalOpen(false)
            setExternalWalletTransferModalOpen(true)
        }
        setSelectedWithdrawVendor("")
    }


    const setMoonpayModalOpenFunction = (val) => {
        setMoonpayModalOpen(val)
    }
    const setExternalWalletTransferModalOpenFunction = (val) => {
        setExternalWalletTransferModalOpen(val)
    }
    return (
        <>
            <Modal
                open={moonpayModalOpen}
                onClose={() => { setVendorSelectionModalOpen(false); setSelectedVendor(""); }}
                aria-labelledby="vendor-selection-modal"
                aria-describedby="venodr-selection-modal"
            >
                <>
                    <Moonpay setMoonpayModalOpenFunction={setMoonpayModalOpenFunction} walletAddress={address} amount="300" currencyCode="usdc" />
                </>
            </Modal>


            <Modal
                open={externalWalletTransferModalOpen}
                onClose={() => { setVendorSelectionModalOpen(false); setSelectedVendor(""); }}
                aria-labelledby="vendor-selection-modal"
                aria-describedby="venodr-selection-modal"
            >
                <>
                    <ExternalWalletTransfer setExternalWalletTransferModalOpenFunction={setExternalWalletTransferModalOpenFunction} currency={selectedWithdrawCurrency} availableBalance={availableBalanceForWithdraw} />
                </>
            </Modal>

            <Modal
                open={vendorSelectionModalOpen}
                onClose={() => { setVendorSelectionModalOpen(false); setSelectedVendor("") }}
                aria-labelledby="vendor-selection-modal"
                aria-describedby="venodr-selection-modal"
            >
                <>
                    <div className={classes.vendorSelectionModal}>
                        <h3>Select payment gateway</h3>
                        <RadioGroup
                            aria-labelledby="vendor-selection-option-radio-buttons-group-label"
                            name="vendoSelection"
                            style={{ color: "#f67622", fontFamily: "Saira" }}
                            onChange={(event) => handleVendorRadioGroupValues(event)}
                            className={classes.currencyRadioGroup}
                            value={selectedVendor}
                        >
                            <FormControlLabel
                                value="moonpay"
                                control={
                                    <SolisColorRadioButton />
                                }
                                label="MoonPay"
                            />

                            <FormControlLabel
                                value="transak"
                                control={
                                    <SolisColorRadioButton />
                                }
                                label="Transak"
                            />
                        </RadioGroup>
                    </div>
                </>
            </Modal>



            <Modal
                open={withdrawVendorSelectionModalOpen}
                onClose={() => { setWithdrawVendorSelectionModalOpen(false); setSelectedWithdrawVendor("") }}
                aria-labelledby="vendor-selection-modal"
                aria-describedby="venodr-selection-modal"
            >
                <>
                    <div className={classes.vendorSelectionModal}>
                        <h3>Select mode of withdrawal</h3>
                        <RadioGroup
                            aria-labelledby="vendor-selection-option-radio-buttons-group-label"
                            name="vendoSelection"
                            style={{ color: "#f67622", fontFamily: "Saira" }}
                            onChange={(event) => handleWithdrawRadioGroupValues(event)}
                            className={classes.currencyRadioGroup}
                            value={selectedWithdrawVendor}
                        >
                            <FormControlLabel
                                value="moonpay"
                                control={
                                    <SolisColorRadioButton />
                                }
                                label={<span style={{ color: '#f67622', opacity: '0.6' }}>Moonpay (Coming Soon) </span>}
                                disabled
                            />

                            <FormControlLabel
                                value="external"
                                control={
                                    <SolisColorRadioButton />
                                }
                                label="External wallet"
                            />
                        </RadioGroup>
                    </div>
                </>
            </Modal>



            <div className={classes.displayContainer}>
                <div
                    className="overflow-auto"
                    style={{ display: "grid", gridTemplateColumns: "200px 200px 200px 200px", borderBottom: "2px solid #ffffff", }}
                >
                    {isCollectionsActive ? (
                        <div className={classes.tabs}>
                            <div
                                style={{ fontSize: "20px" }}
                                className={clsx(
                                    classes.gradientHeading,
                                    classes.activeClassUnderline
                                )}
                            >
                                Collection
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{ fontSize: "20px" }}
                            className={clsx(classes.gradientHeading)}
                            onClick={(e) => handleClick(e, "collections")}
                            name="collections"
                        >
                            Collection
                        </div>
                    )}
                    {isFavoritesActive ? (
                        <div className={classes.tabs}>
                            <div
                                style={{ fontSize: "20px" }}
                                className={clsx(
                                    classes.gradientHeading,
                                    classes.activeClassUnderline
                                )}
                            >
                                Favorites
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{ fontSize: "20px" }}
                            className={clsx(classes.gradientHeading)}
                            onClick={(e) => handleClick(e, "favorites")}

                            name="favorites"
                        >
                            Favorites
                        </div>
                    )}
                    {isAccountsActive ? (
                        <div className={classes.tabs}>
                            <div
                                style={{ fontSize: "20px" }}
                                className={clsx(
                                    classes.gradientHeading,
                                    classes.activeClassUnderline
                                )}
                            >
                                Accounts
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{ fontSize: "20px" }}
                            className={clsx(classes.gradientHeading)}
                            onClick={(e) => handleClick(e, "accounts")}
                            name="accounts"
                        >
                            Accounts
                        </div>
                    )}
                    {
                        isMyListingsActive ? (
                            <div className={classes.tabs}>
                                <div
                                    style={{ fontSize: "20px" }}
                                    className={clsx(
                                        classes.gradientHeading,
                                        classes.activeClassUnderline
                                    )}
                                >
                                    My Listings
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{ fontSize: "20px" }}
                                className={clsx(classes.gradientHeading)}
                                onClick={(e) => handleClick(e, "myListings")}
                                name="myListings"
                            >
                                My Listings
                            </div>
                        )
                    }

                </div>
                {isCollectionsActive ? (
                    <div className="category-items">
                        <div className={classes.cardsContainer}>
                            {
                                isLoaderActive ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div> :
                                    (nft.data && nft.data.map((item) => {
                                        ////console.log("item", item)
                                        return (
                                            <>
                                                <Card
                                                    id={item.id}
                                                    imageURL={item.mediaLocation}
                                                    cardTitle={item.displayName}
                                                    cardArtistName={item.name}
                                                    price={item.price}
                                                    timeRemaining={item.count}
                                                    mediaType={item.mediaType}
                                                    currency={item.currency}
                                                    nftType="owned"
                                                    nftStatus={item.status}
                                                    collectionName={item.collectionName}
                                                />
                                            </>
                                        )
                                    })
                                    )

                            }
                        </div>
                    </div>
                ) : null}
                {isFavoritesActive ? (

                    isFetchingFavorites ?
                        <div className={classes.fetchingTextWithLoader}>
                            <CircularProgress sx={{ color: '#f67622' }} />
                            <div>Fetching favorite NFTs</div>
                        </div>
                        :
                        !isFetchingFavorites && favoriteNftList && favoriteNftList.length === 0 ?
                            <div className={classes.fetchingTextWithLoader}>
                                No favorite NFTs found
                            </div>
                            :
                            <div className="category-items">
                                <div className={classes.cardsContainer}>
                                    {
                                        favoriteNftList && favoriteNftList.map((item, index) => {
                                            return (
                                                <Card
                                                    id={item.id}
                                                    imageURL={item.mediaLocation}
                                                    cardTitle={item.displayName}
                                                    cardArtistName={item.name}
                                                    price={item.price}
                                                    timeRemaining={item.count}
                                                    mediaType={item.mediaType}
                                                    currency={item.currency}
                                                    nftType={userState.id === item.ownerId ? "owned" : null}
                                                    nftStatus={item.status}
                                                    collectionName={item.collectionName}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>

                ) : null}

                {
                    isAccountsActive ? (
                        <>
                            {
                                isConnected && !isLoading &&
                                <div className={classes.profileAccountSection}>
                                    <div className={classes.walletAddressDiv}>
                                        <p>Connected {connector && connector.name.toString()} wallet address : </p>
                                        <div className={classes.walletAddressAndImageDiv}>
                                            <span style={{ margin: '0px', padding: '0px', fontFamily: 'archivo', color: '#f67622', wordBreak: 'break-all' }}>{address}</span>
                                            <button onClick={handleSwitchWallet} className={classes.disconnectWallet} > Disconnect </button>

                                        </div>
                                    </div>
                                    <div className={classes.addBalanceDiv}>

                                        {
                                            currencyAndBalanceArray && currencyAndBalanceArray.map((item, key) => {
                                                return (
                                                    <div className={classes.currencyAndBalanceContainer}>
                                                        <div className={classes.flexText}>
                                                            <div className={classes.subFlexText}>
                                                                <span className={classes.currencyName}>{item.name} </span>
                                                                <span style={{ fontFamily: 'Saira' }}>Balance on Polygon Network : </span>
                                                            </div>
                                                            <div>
                                                                <span className={classes.balanceText}>{item.balance}</span>
                                                            </div>
                                                        </div>
                                                        <button className="btn-primary" onClick={() => setVendorSelectionModalOpen(true)} >Add Balance</button>
                                                        {
                                                            // address.toString() === authState.publicAddress.toString() ?
                                                            <button className="btn-primary" onClick={() => { setWithdrawVendorSelectionModalOpen(true); setSelectedWithdrawCurrency(item.name); setAvailableBalanceForWithdraw(item.balance) }} >Withdraw</button>
                                                            // : null
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                !address && <button className="btn-primary" onClick={handleSwitchWallet} style={{ margin: '40px' }} >Connect Wallet</button>
                            }
                        </>
                    ) : null
                }
                {
                    isMyListingsActive ? (
                        isFetchingListings ?
                            <div className={classes.fetchingTextWithLoader}>
                                <CircularProgress sx={{ color: '#f67622' }} />
                                <div>Fetching your listings</div>
                            </div>
                            :
                            !isFetchingListings && myListings && myListings.length === 0 ?
                                <div className={classes.fetchingTextWithLoader}>
                                    No listings found
                                </div>
                                :
                                <div className="category-items">
                                    <div className={classes.cardsContainer}>
                                        {
                                            myListings && myListings.map((item, index) => {
                                                return (
                                                    <Card
                                                        id={item.id}
                                                        imageURL={item.mediaLocation}
                                                        cardTitle={item.displayName}
                                                        cardArtistName={item.name}
                                                        price={item.price}
                                                        timeRemaining={item.count}
                                                        mediaType={item.mediaType}
                                                        currency={item.currency}
                                                        nftType="owned"
                                                        nftStatus={item.status}
                                                        collectionName={item.collectionName}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                    ) : null
                }



            </div >
        </>
    )

}
export default ProfileNftComponent



const CurrencyDropdown = styled(Select)({
    backgroundColor: 'white',
    width: '100px',
    color: 'black',
    padding: '0.5rem',
    border: '1px solid #f67622',
    borderRadius: '10px',
    '& .MuiSelect-select': {
        paddingRight: '0',
        paddingLeft: '0',
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem',
        '&:focus': {
            backgroundColor: 'white',
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        display: 'none',
    },
    '& .MuiSelect-icon': {
        color: 'rgba(233, 84, 67, 1)',
    },

});


const SolisColorRadioButton = styled(Radio)(({ theme }) => ({
    color: '#f67622',
    '&.Mui-checked': {
        color: '#f67622'
    },
    color: "#f67522",
    "&.Mui-checked": {
        color: "#f67622",
    },
    "&.Mui-disabled": {
        color: "#f67622",
        opacity: '0.6'
    }
}));