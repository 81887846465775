import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "./Components/Header/header";
import RouterSwitch from "./Components/Router/routes";
import Footer from "./Components/Footer/footer";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { EmailContextProvider } from "./global/context/emailContext";

import { TransitionScreenProvider } from "./global/context/transitionScreenContext";
import TransitionScreenModal from "./Components/Loader/TransitionScreenModal";
import SnackBar from "./Components/Error/snackbar";
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
  metaMaskWallet,
  trustWallet,
  coinbaseWallet
  
} from '@rainbow-me/rainbowkit/wallets';

// Rainbow Kit Integration Starts Here
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
  Wallet
} from "@rainbow-me/rainbowkit";
import { configureChains, createClient, goerli, mainnet, WagmiConfig } from "wagmi";
import { polygon, polygonMumbai } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { infuraProvider } from "wagmi/providers/infura";
import { rainbowMagicWallet } from "./web3/RainbowMagicWallet";
import { CURRENT_NETWORK } from "./web3/networks";

const networkChain = CURRENT_NETWORK.id===137 ? [polygon,mainnet] : [polygonMumbai,goerli]
//const networkChain = [polygonMumbai];

const { chains, provider } = configureChains(
  networkChain,
  [
    infuraProvider({ apiKey: CURRENT_NETWORK.infuraProviderApiKey }),
    // publicProvider(),
  ]
);
                                                               
// const { connectors } = getDefaultWallets({
//   appName: "My RainbowKit App",
//   chains,
// });

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet({chains}), 
      trustWallet({chains}),
      coinbaseWallet({appName:"Solis",chains}),
      // injectedWallet({ chains }),
      // rainbowWallet({ chains }),
      walletConnectWallet({ chains }),
      rainbowMagicWallet({chains }) as unknown as Wallet
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

// Rainbow Kit Integration Ends Here
const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          chains={chains}
          modalSize="compact"
          theme={darkTheme({
            accentColor: "#7b3fe4",
            accentColorForeground: "#f67622",
            borderRadius: "medium",
          })}
        >
          <QueryClientProvider client={queryClient}>
            <EmailContextProvider>
              <TransitionScreenProvider>
                <BrowserRouter>
                  <React.StrictMode>
                    <Header />
                    <TransitionScreenModal />
                    <SnackBar />
                    <RouterSwitch />
                    <Footer />
                  </React.StrictMode>
                </BrowserRouter>
              </TransitionScreenProvider>
            </EmailContextProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
};

export default App;
