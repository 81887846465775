import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../services/user.service";
import { setIsProfileComplete, setIsLoading } from "./auth.slice";
import { setMessage } from "./message.slice";

const initialState = {};

//Login initiated during sign up
export const getUserDetail = createAsyncThunk(
  "user/detail",
  async ({ email }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const userResponse = await userService.getUserDetail(email);
      thunkAPI.dispatch(setIsLoading(false));
      return { ...userResponse };
    } catch (error) {
      console.error("getUserDetail:", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const setUserDetail = createAsyncThunk(
  "user/add",
  async ({ postUrl, userData }, thunkAPI) => {
    try {
      await thunkAPI.dispatch(setIsLoading(true));
      const userResponse = await userService.setUserDetail({ postUrl, userData });
      // const userResponse = await userService.getUserDetail(userData.email);
      //Once the user details are set update the isProfileComplete flag
      //in auth state.However this action should be done only once for all
      //for the first time user signs up. will create a new function
      //when time permits
      if (userResponse) {
        thunkAPI.dispatch(setIsProfileComplete(true))
        await thunkAPI.dispatch(setIsLoading(false));
        await thunkAPI.dispatch(setMessage({ message: 'Profile saved successfully', type: 'success', timeStamp: Date.now() }))
      }
      // await thunkAPI.dispatch(setIsLoading(false));
      // await thunkAPI.dispatch(setMessage({ message: 'Profile saved successfully', type: 'success', timeStamp: Date.now() }))
      return { ...userResponse };
    } catch (error) {
      await thunkAPI.dispatch(setIsLoading(false));
      await thunkAPI.dispatch(setMessage({ message: `${error.toString()}`, type: 'error', timeStamp: Date.now() }))
      console.error("setUserDetail", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue();
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUser: (state, action) => {
      return state;
    },
    setUser: (state, action) => {
      const user = action.payload;
      return { ...user };
    },
    clearUserState: (state, action) => {
      return initialState;
    },
  },
  extraReducers: {
    //Login actions
    [getUserDetail.fulfilled]: (state, action) => {
      const user = action.payload;
      return { ...user };
    },
    [getUserDetail.rejected]: (state, action) => {
      state = null;
    },
    // setUser detail to return the user details post creating record
    [setUserDetail.fulfilled]: (state, action) => {
      const user = action.payload;
      return { ...user };
    },
    [setUserDetail.rejected]: (state, action) => {
      state = null;
    },
  },
});

const { reducer, actions } = userSlice;
export const { setUser, getUser, clearUserState } = actions;
export default reducer;
