import React, { useEffect } from "react";
import { useStyles } from "../../Pages/NftCollection/individualNftPage.style"
import { useState } from "react";
import { useHistory, generatePath, useParams } from "react-router-dom";
import share from "../../assets/images/share.svg";
import heart from "../../assets/images/white.png";
import person from "../../assets/images/person.svg";
import category from "../../assets/images/category.svg";
import clsx from "clsx";
import Loader from "../Loader/Loader";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import marketIcon1 from "../../assets/images/iconMarket_-02.svg";
import marketIcon2 from "../../assets/images/iconMarket_-03.svg";
import marketIcon3 from "../../assets/images/iconMarket_-04.svg";
import marketIcon4 from "../../assets/images/iconMarket_-05.svg";
import marketIcon5 from "../../assets/images/iconMarket_-06.svg";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import parse from 'html-react-parser';
import { CURRENT_NETWORK } from "../../web3/networks";
import getAPIHostName from "../../utils/utils";
import { useSelector } from "react-redux";
import axiosInstance from "../../services/api";
import { CircularProgress } from "@mui/material";

const NftInfoComponent = ({ nftData, currentCollectionID, currentCollectionName, description, about }) => {
    const [isDecriptionOpen, setIsDescriptionOpen] = useState(false)
    const [isHistoryOpen, setIsHistoryOpen] = useState(false)
    const [isPropertiesOpen, setIsPropertiesOpen] = useState(false)
    const [isAboutOpen, setIsAboutOpen] = useState(false)
    const [isDetailsOpen, setIsDetailsOpen] = useState(false)
    const [isHeartActive, setIsHeartActive] = useState(false);
    const [open, setOpen] = useState(false)
    const [initialCheck, setInitialCheck] = useState(false)
    const [isFetchingHistory, setIsFetchingHistory] = useState(false)
    const [historyList, setHistoryList] = useState()
    const { classes } = useStyles()
    const history = useHistory()
    const route = useParams();
    const userState = useSelector((state) => state.user)

    const addCollectibleToFavorites = async () => {
        await axiosInstance.put(getAPIHostName() + "/api/collectible/favorites", { collectibleId: nftData.id, email: userState.email })
    }

    const handleClick = () => {
        setOpen(true);
        navigator.clipboard.writeText(`${window.location.href}`);
    };
    useEffect(() => {
        if (isHeartActive) {
            addCollectibleToFavorites().then((res) => {
                console.log("add")
            })
        }
    }, [isHeartActive])
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    // useEffect(()=>{
    //     if(isHeartActive) {

    //     }
    // },[isHeartActive])
    ////console.log(description, about)
    const propertiesData = {
        // "rarity": [
        //   {
        //     "name": "property 1",
        //     "value": "10%"
        //   },
        //   {
        //     "name": "property 2",
        //     "value": "8%"
        //   },
        //   {
        //     "name": "property 3",
        //     "value": "27%"
        //   }
        // ]
    }
    const isNftFavorite = async () => {
        const res = await axiosInstance.get(getAPIHostName() + `/api/users/email=${userState.email}`)
        const favoriteList = res.data.data.favorites;
        const currentCollectibleId = nftData.id;
        setInitialCheck(true)
        if (favoriteList.includes(currentCollectibleId)) {
            return true
        }
        else return false
    }
    const setNftAsFavorite = async (val) => {
        const action = val === true ? "ADD" : "REMOVE"
        await axiosInstance.post(getAPIHostName() + "/api/collectible/favorites", { collectibleId: nftData.id, email: userState.email, action: action })
    }
    const getHistoryList = async () => {
        const data = await axiosInstance.post(getAPIHostName() + "/api/collectible/history", { collectibleId: nftData.id })
        return data.data.data
    }

    useEffect(() => {

        isNftFavorite().then((res) => {
            console.log(res)
            if (res) {
                setIsHeartActive(true)
            }
        })
    }, [])
    useEffect(() => {
        if (isHeartActive && initialCheck) {
            setNftAsFavorite(true).then((res) => {
                console.log("NFT set as favorites")
            })
        }
        else if (initialCheck) {
            setNftAsFavorite(false).then((res) => {
                console.log("NFT set as favorites")
            })
        }
    }, [isHeartActive])

    useEffect(() => {
        if (isHistoryOpen) {
            setIsFetchingHistory(true)
            getHistoryList().then((res) => {
                setHistoryList(res)
                setIsFetchingHistory(false)
            }).catch((err) => {
                setHistoryList([])
                setIsFetchingHistory(false)
            })

        }
    }, [isHistoryOpen])


    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: '25%' }}
                >
                    Copied successfully
                </Alert>
            </Snackbar>
            <div className="col-lg-4">
                <div className="banner-content-block overflow-hidden" style={{ overflow: "unset!important" }}>
                    <div className="top-bar-block">
                        <h2>
                            {/* {currentCollectionName.toString().replace(/_/g, " ")} */}
                            {/* {nftData.name}  */}
                            {
                                parse(`${nftData.name}`)
                            }
                            <br />
                        </h2>
                        <div className="setting-user m-0 p-0">
                            <div className="user-names">
                                <span>
                                    <img src={person} alt="" />
                                    {nftData.displayName}
                                </span>
                                {
                                    currentCollectionName && currentCollectionName === "AFFECT_CHANGE" ?
                                        <span>
                                            <img src={category} alt="" />
                                            Sergey "Do What You Love" Gordienko
                                        </span>
                                        : currentCollectionName && currentCollectionName === "HER_VOICE" ?
                                            <span>
                                                <img src={category} alt="" />
                                                Melissa Jun Rowley, Ty Spectives, Nneka Irobunda, Creative Aya, Visionnaire
                                            </span>
                                            : null
                                }
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                {isHeartActive ? (
                                    <div className="share-btn-active">
                                        <img
                                            src={heart}
                                            onClick={() => setIsHeartActive(false)}
                                        ></img>
                                    </div>
                                ) : (
                                    <div className="share-btn">
                                        <img
                                            src={heart}
                                            onClick={() => setIsHeartActive(true)}
                                        ></img>
                                    </div>
                                )}
                                {/* <div className="share-btn">
                                    <img src={share} alt="" onClick={handleClick} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div
                        className="scroll-block"
                        style={{ maxHeight: "100%" }}
                    >
                        <div>
                            <div className="marketItemContainer">
                                <div className={classes.propertyNameContainerDiv}>
                                    <p className="marketText"><img src={marketIcon1} alt="" className="marketIcon" />Description:</p>
                                    {
                                        isDecriptionOpen ? <KeyboardArrowUpIcon sx={{ color: '#f67622' }} onClick={() => setIsDescriptionOpen(false)} /> : <KeyboardArrowDownIcon sx={{ color: '#f67622' }} onClick={() => setIsDescriptionOpen(true)} />
                                    }
                                </div>
                                {
                                    isDecriptionOpen ? <p className="marketParaBottom">{parse(`${nftData.description}`)}</p> : <p style={{ display: 'none' }}>{parse(`${nftData.description}`)}</p>
                                }
                            </div>
                            {nftData.properties !== null ? <div className="marketItemContainer">
                                <div className={classes.propertyNameContainerDiv}>
                                    <p className="marketText"><img src={marketIcon2} alt="" className="marketIcon" />Properties:</p>
                                    {
                                        isPropertiesOpen ? <KeyboardArrowUpIcon sx={{ color: '#f67622' }} onClick={() => setIsPropertiesOpen(false)} /> : <KeyboardArrowDownIcon sx={{ color: '#f67622' }} onClick={() => setIsPropertiesOpen(true)} />
                                    }
                                </div>
                                {
                                    isPropertiesOpen ? <div className={classes.propertyContainer} >
                                        {
                                            nftData.properties ? nftData.properties.map((item) => {
                                                return (
                                                    <>
                                                        <div className={clsx(classes.propertySubContainers, "propertySubtainers")}>
                                                            <p className="marketPropertyContent">{item.trait_type}<br />
                                                                <span>{item.value}</span>
                                                            </p>
                                                        </div>
                                                    </>
                                                )
                                            })
                                                : null
                                        }
                                    </div> : <div style={{ display: 'none' }}></div>
                                }
                            </div> : null}
                            <div className="marketItemContainer">
                                <div className={classes.propertyNameContainerDiv}>
                                    <p className="marketText"><img src={marketIcon3} alt="" className="marketIcon" />About:</p>
                                    {
                                        isAboutOpen ? <KeyboardArrowUpIcon sx={{ color: '#f67622' }} onClick={() => setIsAboutOpen(false)} /> : <KeyboardArrowDownIcon sx={{ color: '#f67622' }} onClick={() => setIsAboutOpen(true)} />
                                    }
                                </div>
                                {
                                    isAboutOpen ?
                                        <div>
                                            <div className="link-div" onClick={() => history.push({
                                                pathname: generatePath("/collections/:id", { id: currentCollectionID }),
                                            })}>
                                            </div>
                                            {
                                                currentCollectionName && currentCollectionName === "AFFECT_CHANGE" ?
                                                    <p>In collaboration with Sergey “Do What You Love” Gordienko this exclusive group of conscious leaders, celebrities, social media influencers, and experts this collection will be the only one rewarded with perks from the entire Affect Change television series</p>
                                                    : currentCollectionName && currentCollectionName === "HER_VOICE" ?
                                                        <p> Blending the "To Have Faith in Her" poem written by Warrior Love Productions founder and CEO, Melissa Jun Rowley, choreography by renowned dancers Delacyn and Ty Spectives, and AI generative art by Creative Aya, the collectibles are the vision of WLP creative director and recording artist, Visionnaire, who also designed and arranged the multilingual performance of the poem. In addition to these collaborators, 26 women from around the world, who are active leaders in the worlds of Web3, art and social innovation, contributed to the collectibles as narrators of the poem. A total of 21 countries are represented, and a total of 10 different languages are spoken in the art pieces. </p>
                                                        :
                                                        currentCollectionName && currentCollectionName === "SAINT_JUDE" ?
                                                            <p>
                                                                St. Jude Children’s Research Hospital® is leading the way the world understands, treats, and defeats childhood cancer and other life-threatening diseases. Our purpose is clear: Finding cures. Saving children.®
                                                                <br /> <br />
                                                                Because of your support, we can provide children cutting-edge treatments not covered by insurance, at no cost to families. This NFT is inspired by St. Jude patient artwork in recognition of St. Jude at Consensus 2023. With your help, we won't stop until no child dies of cancer.
                                                                <br />
                                                                <br />
                                                                DISCLAIMER
                                                                <br /> <br />
                                                                THE NFT AND ARTWORK ARE PROVIDED "AS IS" AND ON AN "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTY OF ANY KIND AND, TO THE MAXIMUM EXTENT PERMITTED BY LAW.  ALSAC/ST. JUDE HEREBY EXPRESSLY DISCLAIMS ANY ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND, WHETHER ARISING OUT OF TRADE USAGE, COURSE OF DEALING, STATUTE OR COMMON LAW, INCLUDING ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, TITLE, AND NONINFRINGEMENT. WITHOUT LIMITING THE ABOVE, ALSAC/ST. JUDE MAKES NO REPRESENTATION OR WARRANTY CONCERNING THE QUALITY, ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS, OR RELIABILITY OF THE ARTWORK OR ANY INFORMATION OR CONTENT DISPLAYED IN CONNECTION WITH THE NFT OR THAT THE NFT AND ARTWORK WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS.
                                                                ALSAC/ST. JUDE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY CLAIMS OR LOSSES THAT ARISE OUT OF OR ARE RELATED TO ANY FAILURE OR ABNORMAL BEHAVIOR OF ANY SOFTWARE OR SYSTEMS THAT SUPPORT OR ARE OTHERWISE ASSOCIATED WITH THE NFT OR ARTWORK, INCLUDING ANY MEDIA SERVERS, CRYPTO WALLETS, SMART CONTRACTS, BLOCKCHAINS, NODE COMMUNICATIONS, THIRD-PARTY MARKETPLACES, OR OTHER DISTRIBUTED LEDGER TECHNOLOGIES. FURTHER, ALSAC/ST. JUDE ACCEPTS NO RESPONSIBILITY OR LIABILITY FOR ANY CLAIMS OR LOSSES THAT ARISE OUT OF OR ARE OTHERWISE RELATED TO: (I) ERRORS SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED ADDRESSES; (II) SERVER FAILURE OR DATA LOSS; (III) CORRUPTED CRYPTOGRAPHIC FILES, INCLUDING THOSE RELATING TO YOUR CRYPTO WALLET; (IV) THE BEHAVIOR OR OUTPUT OF ANY SOFTWARE OR HARDWARE, INCLUDING ANY FEATURES, DEVELOPMENT ERRORS, OR OTHER ISSUES OF ANY MEDIA SERVER, BLOCKCHAIN OR CRYPTO WALLET; (V) ANY OTHER UNAUTHORIZED THIRD-PARTY ACTIVITIES; OR (VI) THE USE OF ANY SOFTWARE, HARDWARE OR OTHER MEANS, INCLUDING ANY VIRUS, WORM, MALWARE, MALICIOUS OR OTHER HARMFU L COMPUTER CODE, THE PURPOSE OR EFFECT OF WHICH IS TO DESTROY, DISRUPT, DISABLE, DISTORT, OR OTHERWISE HARM OR IMPEDE IN ANY MANNER THE SECURITY OR INTEGRITY OF THE SOFTWARE AND SYSTEMS THAT SUPPORT OR ARE OTHERWISE ASSOCIATED WITH THE NFT OR ARTWORK INCLUDING ANY MEDIA SERVERS, CRYPTO WALLETS, SMART CONTRACTS, BLOCKCHAINS, NODE COMMUNICATIONS, THIRD-PARTY MARKETPLACES, OR OTHER DISTRIBUTED LEDGER TECHNOLOGIES.
                                                                THE NFT IS A DIGITAL ASSET. IT EXISTS ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE APPLICABLE BLOCKCHAIN NETWORK AND ITS ASSOCIATED DISTRIBUTED LEDGER. ANY TRANSFER OF TITLE TO ANY DIGITAL ASSET, SUCH AS THE NFT, OCCURS ON A PUBLIC BLOCKCHAIN'S DISTRIBUTED LEDGER WITHIN SUCH BLOCKCHAIN'S NETWORK, WHICH ALSAC/ST. JUDE DOES NOT OWN OR CONTROL. ACCORDINGLY, ALSAC/ST. JUDE MAKES NO REPRESENTATIONS, WARRANTIES, OR GUARANTEES THAT ALSAC/ST. JUDE OR ANY THIRDPARTY MARKETPLACE CAN AFFECT TRANSFER OF ANY RIGHT, TITLE, OR INTEREST IN OR TO THE NFT. YOU BEAR FULL RESPONSIBILITY FOR VERIFYING THE IDENTITY, LEGITIMACY, AND AUTHENTICITY OF ASSETS YOU PURCHASE THROUGH THIRD-PARTY MARKETPLACES, INCLUDING THE NFT. NOTWITHSTANDING ANY INDICATORS AND MESSAGES THAT MAY SUGGEST THE IDENTITY, LEGITIMACY, OR AUTHENTICITY OF THE NFT, ALSAC/ST. JUDE MAKES NO CLAIMS CONCERNING, NOR ANY REPRESENTATION, WARRANTY, OR GUARANTEE PERTAINING TO, THE IDENTITY, LEGITIMACY, OR AUTHENTICITY OF ASSETS AVAILABLE ON OR THROUGH ANY THIRD-PARTY MARKETPLACES, INCLUDING WITH RESPECT TO THE NFT OR ANY TRANSACTION THAT SELLER OR YOU MAY CONDUCT OR OTHERWISE ENGAGE IN ON OR THROUGH SUCH THIRD-PARTY MARKETPLACES.
                                                                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONSUMER CONTRACTS. AS A RESULT, THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.



                                                            </p>
                                                            :
                                                            currentCollectionName && currentCollectionName === "ISEKAI_ANIME" ?
                                                            <p>
                                                                Isekaiverse, a web-3 era entertainment company, that revolutionizes interactions between fans, creators, and professionals by providing resources and tools to help creators bring their unique brands and intellectual properties to life.
                                                            </p>
                                                            : null
                                            }
                                        </div> : null
                                }
                            </div>
                            <div className="marketItemContainer">
                                <div className={classes.propertyNameContainerDiv}>
                                    <p className="marketText"><img src={marketIcon4} alt="" className="marketIcon" />History:</p>
                                    {
                                        isHistoryOpen ? <KeyboardArrowUpIcon sx={{ color: '#f67622' }} onClick={() => setIsHistoryOpen(false)} /> : <KeyboardArrowDownIcon sx={{ color: '#f67622' }} onClick={() => setIsHistoryOpen(true)} />
                                    }
                                </div>
                                {
                                    isHistoryOpen ?
                                        <>
                                            {
                                                isFetchingHistory ?
                                                    <CircularProgress style={{ color: '#f67622', margin: 'auto', display: 'flex', marginTop: '10px', marginBottom: '10px' }} />
                                                    :
                                                    historyList?.length > 0 ?
                                                        <>
                                                            <div className={classes.historyTab}>
                                                                <div className={classes.historyTabListContainerOrange}>
                                                                    <div className={classes.historyTabList}>From</div>
                                                                    <div className={classes.historyTabList}>To</div>
                                                                    <div className={classes.historyTabList}>Date</div>
                                                                </div>
                                                                {
                                                                    historyList?.map((item, key) => {
                                                                        return (
                                                                            <div className={classes.historyTabListContainer}>
                                                                                <div className={classes.historyTabList}>{item.usernameFrom.length > 10 ? `${item.usernameFrom.slice(0, 10)}...` : item.usernameFrom}</div>
                                                                                <div className={classes.historyTabList}>{item.usernameTo.length > 10 ? `${item.usernameTo.slice(0, 10)}...` : item.usernameTo}</div>
                                                                                <div className={classes.historyTabList}> <a href={`${CURRENT_NETWORK.explorer}tx/${item.transactionHash}`} target="_blank" > {item.timeDifference} </a> </div>
                                                                            </div>

                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                        :
                                                        <div style={{ textAlign: 'center' }}>
                                                            No history available
                                                        </div>
                                            }
                                        </>
                                        :
                                        <p style={{ display: 'none' }}>{nftData.history}</p>
                                }
                            </div>
                            <div className="marketItemContainer">
                                <div className={classes.propertyNameContainerDiv}>
                                    <p className="marketText"><img src={marketIcon5} alt="" className="marketIcon" />Details:</p>
                                    {
                                        isDetailsOpen ? <KeyboardArrowUpIcon sx={{ color: '#f67622' }} onClick={() => setIsDetailsOpen(false)} /> : <KeyboardArrowDownIcon sx={{ color: '#f67622' }} onClick={() => setIsDetailsOpen(true)} />
                                    }
                                </div>
                                {
                                    isDetailsOpen ?
                                        <div>
                                            <div className={classes.detailsField} style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                                <p className={classes.detailsFieldName}>Contract Address: </p>
                                                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                                    {
                                                        nftData.contractAddress ?
                                                            <p className={classes.detailsFieldData}>

                                                                <a href={`${CURRENT_NETWORK.explorer}/address/${nftData.contractAddress}`} target="_blank" style={{ color: '#f67622' }}><p className={classes.detailsFieldData} >{nftData.contractAddress.slice(0, 4)}...{nftData.contractAddress.slice(-4)}</p>
                                                                </a>


                                                            </p>
                                                            :
                                                            <p className={classes.detailsFieldData}>

                                                            </p>
                                                    }
                                                    {
                                                        nftData.contractAddress && <ContentCopyIcon onClick={() => { navigator.clipboard.writeText(`${nftData.contractAddress}`); setOpen(true) }} sx={{
                                                            "&:hover": {
                                                                cursor: 'pointer', color: '#f67622'
                                                            }
                                                        }} />
                                                    }
                                                </div>
                                            </div>
                                            <div className={classes.detailsField}>
                                                <p className={classes.detailsFieldName} >Token ID: </p>
                                                {nftData.tokenId &&
                                                    <a href={`${CURRENT_NETWORK.explorer}/token/${nftData.contractAddress}?a=${nftData.tokenId}`} target="_blank" style={{ color: '#f67622' }}><p className={classes.detailsFieldData} >{nftData.tokenId}</p>
                                                    </a>
                                                }
                                            </div>
                                            <div className={classes.detailsField}>
                                                <p>Token Standard: </p> <p>ERC-721</p>
                                            </div>
                                            <div className={classes.detailsField}>
                                                <p>Blockchain: </p> <p>Polygon</p>
                                            </div>
                                        </div> : <div style={{ display: 'none' }}>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default NftInfoComponent

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
