import React from "react";
import Slider from "react-slick";
import { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CheckImage, } from "../../helpers/checkFile"
import comingsoonImage from "../../assets/images/comingSoonTestv2.gif"
import HomePageBanner from "../Home/HomePageBanner";
const AFFECT_CHANGE = require("../../assets/collection/AFFECT_CHANGE/AFFECT_CHANGE_mp_image.mp4")
const BUNNY = require("../../assets/collection/BUNNY/BUNNY_mp_image.jpg")
const CAUSE_PLAY_IRL = require("../../assets/collection/CAUSE_PLAY_IRL/CAUSE_PLAY_IRL_mp_image.png")
const FANTASY_FOOTBALL_CAUSE = require("../../assets/collection/FANTASY_FOOTBALL_CAUSE/FANTASY_FOOTBALL_CAUSE_mp_image.png")
const HER_VOICE = require("../../assets/collection/HER_VOICE/HER_VOICE_mp_image.png")
const SOLIS_CORE = require("../../assets/collection/SOLIS_CORE/SOLIS_CORE_mp_image.gif")
const SPOKEN_WORLD = require("../../assets/collection/SPOKEN_WORLD/SPOKEN_WORLD_mp_image.png")
const AFV_COLLECTION = require("../../assets/collection/AFV_COLLECTION/AFV_COLLECTION_mp_image.jpg")
const comingSoon = require("../../assets/images/comingSoonTestv2.gif")
const SOLIS_FLARES = "https://solis-video-files.s3.amazonaws.com/Flares_012.gif"
const SAINT_JUDE = require("../../assets/collection/SAINT_JUDE/SAINT_JUDE_mp_image.png")
const ISEKAI_ANIME = require("../../assets/collection/ISEKAI_ANIME/ISEKAI_ANIME_mp_image.png")

const BannerButtonCarousel = (props) => {
  const imageFiles = [AFV_COLLECTION, SOLIS_FLARES, AFFECT_CHANGE, CAUSE_PLAY_IRL, FANTASY_FOOTBALL_CAUSE, HER_VOICE, SAINT_JUDE, SOLIS_CORE, SPOKEN_WORLD, BUNNY, ISEKAI_ANIME]
  let tempImageUrl;
  for (var i = 0; i < imageFiles.length; ++i) {
    const image = imageFiles[i]
    if (typeof image === "string" && image.includes("https") && props.displayName === "SOLIS_FLARES") { tempImageUrl = image; break; }
    else if (typeof image !== "string" && image.default.toString().includes(props.displayName)) {
      tempImageUrl = image.default
      break;
    } else if (typeof image === 'string' && image.includes(props.displayName)) {
      tempImageUrl = image
      break
    }
    else tempImageUrl = comingSoon.default
  }
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  function setSource(path) {
    try {
      // return require("../../assets/collection/FANTASY_FOOTBALL_CAUSE/FANTASY_FOOTBALL_cAUSE_mp_image.png")
      return require(path).default
    }
    catch (err) {
      return require("../../assets/images/comingSoonTestv2.gif").default
    }
  }

  const imageSrc = setSource("../../assets/collection/" + props.collectionName + "/" + props.collectionName + "_mp_image.png")

  return (
    <>
      <div className="content">
        <Slider ref={setSliderRef} {...sliderSettings}>
          {/* <div className="banner-item">
            <div
              className="thumb-img"
              style={{
                backgroundImage: `url(${tempImageUrl})`,
                cursor: "pointer",
                backgroundSize: "cover",
              }}
            >
            </div>
          </div> */}
          <HomePageBanner imageSource={tempImageUrl} />
        </Slider>


      </div>
    </>
  );
};

export default BannerButtonCarousel;
