import React from 'react'
import { useStyles } from '../../Pages/nft-minter/nft-minter.style'

const CreatorWallet = (props) => {
  return (
    <>
      <div>

        <div>{props.name} {props.publicAddress} {props.emailid} {props.percentage}</div>

      </div>
    </>
  )
}

export default CreatorWallet