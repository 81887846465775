import { useHistory, generatePath, useLocation } from "react-router-dom";
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledWhite from "@mui/icons-material/PlayCircleFilledWhite";
import { useRef } from "react";
import { useState } from "react";
import videoIcon from '../../assets/images/video-icon.png'
import parse from 'html-react-parser';
import loaderGif from "../../assets/images/blackBgLoader.gif";

const Card = (props) => {
  const history = useHistory();
  const id = props.id;
  const [isPlaying, setIsPlaying] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const videoRef = useRef(null)
  const location = useLocation()
  const playVideo = () => {
    setIsPlaying(true)
    videoRef.current.play()
  }
  const pauseVideo = () => {
    setIsPlaying(false)
    videoRef.current.pause()
  }
  const handleClick = (e) => {
    //id && history.push(generatePath("/collections/collectible/:id", { id }));

    if (props.nftType === "owned") {
      id &&
        history.push({
          pathname: generatePath("/myNfts/:id", { id: id, collectionName: props.collectionName })
        });
    }
    else {
      id &&
        history.push({
          pathname: generatePath(`/:collections/collectible/:id`, { id: id, collections: props.collectionName }),

        });
    }
  };
  return (
    <>
      <div className="category-item" onClick={handleClick} style={{ width: `${props.width}`, cursor: 'pointer' }}>

        <div className="card h-100 comingSoonBackground" style={{ minHeight: "220px", background: `url('${props.imageURL}')`, backgroundSize: "cover", backgroundPosition: 'center center' }}>
          {
            (props.nftStatus !== "PREASSIGNED") ?
              <>
                {
                  props.mediaType.includes("video") ?
                    <div className="video-player video-playerContainer">
                      <video playsInline
                        autoPlay
                        loop
                        muted
                        controls=""
                        ref={videoRef}
                        poster={props.thumbnailLocation}
                        className="videoContainer"

                      >
                        <source src={props.imageURL} type="video/mp4" />
                      </video>
                      <img src={videoIcon} style={{ height: '25px', width: '25px', marginLeft: '10px', position: 'absolute', bottom: 5, right: 7 }} alt="videoIcon" onClick={handleClick} />
                    </div> :
                    <img
                      src={props.imageURL}
                      className="card-img-top"
                      alt="..."
                      style={{ maxHeight: "200px" }}
                    />
                }
              </>
              :
              <>
                {
                  props.mediaType.includes("video") ?
                    <div className="video-player video-playerContainer">
                      <div style={{ position: 'relative' }} className="preAssignedImage">
                        <video playsInline
                          autoPlay
                          loop
                          muted
                          controls=""
                          ref={videoRef}
                          poster={props.thumbnailLocation}
                          className="videoContainer"
                          style={{ opacity: '0.6' }}
                        >
                          <source src={props.imageURL} type="video/mp4" />
                        </video>
                        <img src={loaderGif} className="card-img-top" alt="" style={{ position: 'absolute', transform: 'translate(-50%,-50%)', top: '50%', left: '50%', maxHeight: '100%', }} />
                        <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', }} className="settlementText" >Awaiting Settlement</span>
                      </div>
                      <img src={videoIcon} style={{ height: '25px', width: '25px', marginLeft: '10px', position: 'absolute', bottom: 5, right: 7 }} alt="videoIcon" onClick={handleClick} />
                    </div> :
                    <div style={{ position: 'relative' }} className="preAssignedImage">
                      <img
                        src={props.imageURL}
                        className="card-img-top"
                        alt="..."
                        style={{ maxHeight: "200px", opacity: '0.6' }}
                      />
                      <img src={loaderGif} className="card-img-top" alt="" style={{ position: 'absolute', transform: 'translate(-50%,-50%)', top: '50%', left: '50%', maxHeight: '100%', }} />
                      <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', }} className="settlementText" >Awaiting Settlement</span>

                    </div>
                }
              </>
          }
          <div className="card-body">

            <div className="title-sub-title">
              <h3 className="card-title">
                {props.cardTitle}
              </h3>
              <span>
                {
                  parse(`${props.cardArtistName}`)
                }
              </span>
            </div>
            <div className="price-block">
              {/* {props.type == "CRYPTO" ? (
                <span>{`${props.price} ${props.currency}`}</span>
              ) : props.type == "FIAT" ? (
                <span>{`$ ${props.price} ${props.currency}`}</span>
              ) : (
                <span>""</span>
              )} */}
              {
                props.collectionName === "SAINT_JUDE" ?
                  <span>&nbsp;</span> : <span style={{ fontFamily: 'Archivo' }} >{props.price === '0' ? "COMING SOON" : props.price}
                    {` ${props.currency}`}</span>
              }
              <span>{props.count}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
