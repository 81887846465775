import React from 'react'
import { Magic } from 'magic-sdk'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginRedirect, setIsLoggedIn, setIsLoading, login } from '../../slices/auth.slice'
import { useHistory, useLocation } from 'react-router-dom'
import solisLoader from "../../assets/images/loaderGif.gif"
import { getUserDetail, setUser } from "../../slices/user.slice";
import { OAuthExtension } from "@magic-ext/oauth";
import { loginUser } from '../../services/auth.service'
import { useInterval } from '../../hooks'
import { setMessage } from '../../slices/message.slice'
import { createMagicInstance } from '../../services/Vendor/Magic/magic'


const SignInRedirect = (props) => {
    const dispatch = useDispatch()
    const authState = useSelector((state) => state.auth);
    const { isLoggedIn, isLoading } = useSelector((state) => state.auth)
    const history = useHistory()
    const location = useLocation();
    const [isSocialLogin, setIsSocialLogin] = useState(false)
    const redirectFunction = async () => {
        try {
            dispatch(loginRedirect({ 'credentialToken': props.magicCredential, 'isSocialLogin': isSocialLogin }));
        } catch (error) {
            ////console.log(error)
        }
    }
    useEffect(() => {
        const provider = new URLSearchParams(location.search).get('provider');
        if (window.location.search.includes("error")) {
            dispatch(setMessage({ type: "error", timeStamp: Date.now(), message: "Login Failed! Please try again" }))
            history.push("/")
        }
        else if (window.location.search && !authState.isLoggedIn) {
            ////console.log("inside use effect", provider)
            setIsSocialLogin(true)
            redirectFunction();
        }
        if (provider === "google" || provider === "apple" || provider === "facebook" || provider === "twitter") {
            magic = createMagicInstance()
            finishSocialLogin()
        }

        if (authState.isLoggedIn) {
            history.push('/')
        }
    }, [, authState.isLoggedIn])

    let magic;

    // const createMagicInstance = () => {
    //     magic = new Magic(process.env.REACT_APP_MAGIC_APP_ID, {
    //         extensions: [new OAuthExtension()],
    //     });
    // }
    const finishSocialLogin = async () => {
        const result = await magic.oauth.getRedirectResult();
        await authenticateWithServer(result);
    }
    const authenticateWithServer = async result => {
        ////console.log("result", result)
        dispatch(login({ email: result.oauth.userInfo.email, isSocialLogin: true }))
    };





    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: 'column',
                    alignItems: "center",
                    margin: "auto",
                    marginTop: "30vh",
                    fontFamily: 'Saira',
                }}
            >
                <img src={solisLoader} style={{ width: "150px" }} />
                <h4>Redirecting to marketplace</h4>
            </div>
        </>
    )
}

export default SignInRedirect