import React, { useContext } from 'react'
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import solisLoader from "../../assets/images/loaderGif.gif"
import { useTransitionModal } from '../../hooks';
import { TransitionScreenContext } from '../../global/context/transitionScreenContext';
import { TransitionScreenDispatchContext } from '../../global/context/transitionScreenContext';
import { useSelector } from 'react-redux';
const TransitionScreenModal = () => {
    const [open, setOpen] = React.useState(false);
    const [display, setDisplay] = useState('none')
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const history = useHistory();
    const transitionModalDetails = useContext(TransitionScreenContext);
    const setTransitionScreenDetails = useContext(TransitionScreenDispatchContext);
    const message = transitionModalDetails.message
    const finalPath = transitionModalDetails.finalPath
    const displayTime = transitionModalDetails.displayTime
    useEffect(() => {
        setDisplay('flex')
        if (displayTime > 0) {
            setOpen(true)
            setTimeout(() => {
                setOpen(false)
                setDisplay('none')
                if (finalPath !== undefined) {
                    history.push(finalPath)
                }
            }, 1000 * transitionModalDetails.displayTime);
        }
    }, [displayTime])
    // const messageState = useSelector((state) => state.message)
    // useEffect(() => {
    //     if (messageState.message === "Server Error") {
    //         setOpen(true)
    //     }
    //     else setOpen(false)
    // }, [messageState])

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div
                    style={{
                        display: display,
                        margin: 'auto',
                        justifyContent: "center",
                        flexDirection: 'column',
                        alignItems: "center",
                        margin: "auto",
                        marginTop: "40vh",
                        fontFamily: 'Saira'
                    }}
                >
                    <img src={solisLoader} style={{ width: "150px" }} />
                    <h4>{transitionModalDetails.message}</h4>
                </div>
            </Modal>
        </>
    )
}

export default TransitionScreenModal



