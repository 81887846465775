import React, { useEffect, useState } from 'react'
import IndividualNftCard from '../../Components/NftCollection/IndividualNftCard'
import NftInfoComponent from '../../Components/NftCollection/NftInfoComponent'
import getAPIHostName from '../../utils/utils'
import { CircularProgress } from '@mui/material'
import { axiosCustomInstance } from '../../services/api'
import { useStyles } from './nft-minter.style'
import IndividualNftPreviewCard from '../../Components/MintNFT/IndividualNftPreviewCard'
import Loader from '../../Components/Loader/Loader'

const NftPreview = () => {
    const [isRequestComplete, setIsRequestComplete] = useState(false)
    const [progress, setProgress] = useState(false)
    const [response, setResponse] = useState()
    const [collectibleId, setCollectibleId] = useState()
    const [previewNftData, setPreviewNftData] = useState()
    const [mediaType, setMediaType] = useState("video/mp4")
    const [isFetchClicked, setIsFetchClicked] = useState(false)
    const [isPreviewLoading, setIsPreviewLoading] = useState(false)

    const fetchPreview = async () => {
        setIsFetchClicked(true)
        setIsPreviewLoading(true)
        try {
            const data = await axiosCustomInstance.get(getAPIHostName() + `/api/collectible/${collectibleId}`)
            setPreviewNftData(data.data.data)
            setIsPreviewLoading(false)
        } catch (error) {
            setPreviewNftData("error")
            setIsPreviewLoading(false)
        }
        //setMediaType()
        setIsPreviewLoading(false)
    }
    const { classes } = useStyles()
    return (
        <>

            <div className={classes.nftPreviewInputContainer}>
                <h5>Enter Collectible ID to preview </h5>
                <input type="text" className={classes.nftPreviewInput} value={collectibleId} placeholder="Collectible ID" onChange={(e) => setCollectibleId(e.target.value)} />
            </div>
            {
                collectibleId !== undefined && collectibleId !== "" ? <button onClick={fetchPreview} className={classes.fetchPreviewButton}>Fetch</button>
                    : null
            }
            {
                !isFetchClicked ? null
                    : isPreviewLoading ? <Loader /> :
                        previewNftData !== "error" ?
                            <>
                                <section className="section-block individual-block main-banner">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <IndividualNftPreviewCard nftDetail={previewNftData} />
                                            </div>
                                            <NftInfoComponent nftData={previewNftData} currentCollectionID="1234" currentCollectionName={previewNftData.name} />
                                        </div>
                                    </div>
                                </section>
                            </> :
                            <>
                                <section className="section-block individual-block main-banner">
                                    <div className="container">
                                        <div className="row">
                                            Error fetching preview. Please try again
                                        </div>
                                    </div>
                                </section>
                            </>
            }

        </>
    )
}

export default NftPreview