
import React, { useState, useEffect } from "react";
import { useStyles } from "./payment-options.style";
import axiosInstance from "../../services/api";
import getAPIHostName from "../../utils/utils";
import Loader from "../../Components/Loader/Loader";
import CancelIcon from '@mui/icons-material/Cancel';


export const Moonpay = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [moonpayURL, setMoonpayURL] = useState();
    const getSignedMoonPayUrl = async () => {
        const moonpayRequestBody = {
            walletAddress: props.walletAddress,
            currencyCode: props.currencyCode ? props.currencyCode : "usdt",
            baseCurrencyAmount: props.amount,
            baseCurrencyCode: "usd",

        }
        const response = await axiosInstance.post(getAPIHostName() + "/api/moonpay/signature", moonpayRequestBody)
        return response.data.signedUrl
    }
    useEffect(() => {
        setIsLoading(true)
        getSignedMoonPayUrl().then((res) => {
            setMoonpayURL(res)
            console.log("moonpayUrl", moonpayURL)
            setIsLoading(false)
        })
    }, [])
    const { classes } = useStyles();

    const displayMoonpayFrame =

        <iframe
            className={classes.moonpayFrame}
            src={moonpayURL}
        >
            <p>Your browser does not support iframes.</p>
        </iframe>



    if (isLoading) return <Loader />
    else
        return (
            <>
                <div className={classes.moonpayContainer}>
                    {/* <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Add money with card</h3> */}
                    <CancelIcon sx={{ right: '0px', top: '0px', position: 'absolute', color: '#f67622', cursor: 'pointer' }} onClick={() => { props.setMoonpayModalOpenFunction(false) }} />
                    {displayMoonpayFrame}
                </div>
            </>
        );

}



