import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import LogRocket from 'logrocket';
import PrivateRoutes from "./PrivateRoutes";
import Home from "../../Pages/Home/home";
import Market from "../../Pages/Market/market";
import Community from "../../Pages/Community/community";
import SignIn from "../../Pages/SignIn/sign-in";
import NftMinter from "../../Pages/nft-minter/nft-minter";
import Profile from "../../Pages/Profile/profile";
import Faq from "../../Pages/Faq/faq";
import Kyc from "../../Pages/Kyc/kyc";
import Ecosystem from "../../Pages/Ecosystem/ecosystem";
import Help from "../../Pages/Help/help";
import IndividualNftPage from "../../Pages/NftCollection/individualNftPage";
import EditProfileForm from "../../Pages/Profile/edit-profile-form";
import { initialise, refresh, refreshApi, setIsLoading } from "../../slices/auth.slice";
import Collections from "../../Pages/Collections/collections";
import ComingSoonModal from "../../Pages/ComingSoon/ComingSoonModal";
import SignInRedirect from "../RedirectComponent/signin-redirect";
import MarketFilter from "../../Pages/MarketFilter/marketFilter";
import DefaultCollectionComponent from "../Collections/DefaultCollectionComponent";
import DefaultCollectionPage from "../Collections/DefaultCollectionPage";
import { EmailContext } from "../../global/context/emailContext";
import InitializeComponent from "../../global/Initialize/InitializeComponent";
import { getAllCollections } from "../../slices/collection.slice";
import OwnedNftPage from "../../Pages/Profile/owned-nft-page";
import { useRefresh } from "../../hooks";
import BulkUpload from "../../Pages/nft-minter/BulkUpload";
import { useInterval } from "../../hooks";
import { setMessage } from "../../slices/message.slice";
import LicenseAgreement from "../Footer/LicenseAgreement";
import { useBeforeunload } from 'react-beforeunload';
import BuyWithFiat from "../../Pages/Help/BuyWithFiat";
import BuyWithCrypto from "../../Pages/Help/BuyWithCrypto";
import AddingFunds from "../../Pages/Help/AddingFunds";

const Router = (props) => {
  const [count, setCount] = useState(0)
  const location = useLocation();
  const dispatch = useDispatch();
  const emailContextValue = useContext(EmailContext);
  const { isLoggedIn, initializeRequired, email, statusCode, isLoading, isInLogin, isProfileComplete } = useSelector((state) => state.auth);
  const collections = useSelector((state) => state.collections);


  const history = useHistory()
  // const useRefreshApieQuery = refreshApi.endpoints.getRefreshData.initiate()
  // ////console.log("query", useRefreshApieQuery)


  const queryParam = new URLSearchParams(location.search).get("promo");
  const promotionCodes = JSON.parse(process.env.REACT_APP_PROMOTION_CODES);

  if (promotionCodes && promotionCodes.includes(queryParam)) {
    localStorage.setItem("promo", queryParam);
  }
  ////console.log("promo", localStorage.getItem("promo"))
  useEffect(() => {
    async function performAsync() {
      if (statusCode === "inactive") {
        history.push("/initialize");
      }
      ////console.log("collections", collections)
      if (!collections.isCollectionFetched) {
        dispatch(getAllCollections({}));
      }
    }

    performAsync()
  }, [, isLoggedIn, statusCode]);

  useInterval(() => {
    if (isLoading && !isInLogin) {
      dispatch(setIsLoading(false))
      if (!isLoggedIn) {
        history.push("/")
        dispatch(setMessage({ type: "error", message: 'Failed login! Please try again.', timeStamp: Date.now() }))
      }
    }
    else if (isLoading && isInLogin) {
      setCount(count + 1);
      if (count > 2) {
        dispatch(setIsLoading(false))
        if (!isLoggedIn) {
          history.push("/")
          dispatch(setMessage({ type: "error", message: 'Failed login! Please try again.', timeStamp: Date.now() }))
        }
      }
    }
  }, 15000);


  if (isLoggedIn) {
    return (
      <Switch>
        <Route path="/magic/callback" component={SignInRedirect} />
        <Route path="/initialize" component={InitializeComponent} />
        <PrivateRoutes path="/market" component={MarketFilter} exact />
        <PrivateRoutes path="/profile" component={Profile} exact />
        <PrivateRoutes path="/faq" component={Faq} exact />
        <PrivateRoutes path="/kyc" component={Kyc} exact />
        <PrivateRoutes path="/buyWithFiat" component={BuyWithFiat} exact />
        <PrivateRoutes path="/buyWithCrypto" component={BuyWithCrypto} exact />
        <PrivateRoutes path="/addingFunds" component={AddingFunds} exact />
        <PrivateRoutes path="/community" component={Community} exact />
        <PrivateRoutes path="/ecosystem" component={Ecosystem} />
        <PrivateRoutes path="/collections/:id" component={Collections} exact />
        <PrivateRoutes path="/help" component={Help} />
        <PrivateRoutes path="/signin" component={SignIn} />
        {/* {
          process.env.REACT_APP_ENV === "dev.admin" || process.env.REACT_APP_ENV === "stage.admin" || process.env.REACT_APP_ENV === "prod.admin" ?
            <PrivateRoutes path="/mintnft" component={NftMinter} /> : null
        } */}
        <PrivateRoutes path="/mintnft" component={NftMinter} />
        {
          process.env.REACT_APP_ENV === "dev.admin" || process.env.REACT_APP_ENV === "stage.admin" || process.env.REACT_APP_ENV === "prod.admin" ?
            <PrivateRoutes path="/bulkupload" component={BulkUpload} exact />
            : null
        }
        <PrivateRoutes path="/:collections/collectible/:id" component={IndividualNftPage} exact />
        <PrivateRoutes path="/collection/:id/:category" component={DefaultCollectionPage} exact />
        <PrivateRoutes path="/comingsoon" component={ComingSoonModal} />
        <PrivateRoutes path="/marketFilter" component={MarketFilter} exact />
        <PrivateRoutes path="/myNfts/:id" component={OwnedNftPage} exact />

        <Route
          path="/edit"
          exact
          component={EditProfileForm}
        ></Route>
        <Route path="/" component={Home} exact></Route>
        <Route path="/licenseagreement" exact component={LicenseAgreement}></Route>
      </Switch>
    );
  } else {
    return (
      <Switch>
        {
          location.search &&
          <Route path="/magic/callback" render={(props) => <SignInRedirect magicCredential={location.search} />}></Route>
        }
        <Route to="/" component={Home} exact></Route>
      </Switch>
    );
  }
};
export default Router;
