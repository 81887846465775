import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()({
    footerContainer: {
        fontFamily: 'Actor',
        marginTop: '100px',
        width: '75.5%',
        height: '147px',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'space-between'
    },
    supportContainer: {

    },
    connectContainer: {


    },
    connectBlankContainer: {

    },
    whiteText: {
        fontSize: '18px',
        lineHeight: '22px',
        marginTop: '0px',
        color: '#FFFFFF',
        textTransform: 'uppercase'
    },
    whiteTextSmall: {
        marginTop: '0px',

        fontSize: '16px',
        lineHeight: '26px',
        color: '#FFFFFF'
    },
    orangeText: {
        marginTop: '0px',
        fontSize: '16px',
        lineHeight: '30px',
        background: 'linear-gradient(90deg, rgba(234,156,104,1) 0%, rgba(233,84,67,1) 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        position: 'relative', zIndex: '1',
        fontWeight: '300'
    },
    orangeTextSmall: {
        marginTop: '0px',
        fontSize: '16px',
        lineHeight: '20px',
        background: 'linear-gradient(90deg, rgba(234,156,104,1) 0%, rgba(233,84,67,1) 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        position: 'relative', zIndex: '1',
        fontWeight: '300'
    }
});

export { useStyles }
