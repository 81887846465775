import { useState } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "./collections.style";

import { useLocation } from "react-router-dom";
import HeadingComponent from "../../Components/Collections/HeadingComponent";
import BannerButtonCarousel from "../../Components/Carousel/BannerButtonsCarousel";
import NftCarousels from "../../Components/Carousel/NftCarousels";
import parse from 'html-react-parser';

import Loader from "../../Components/Loader/Loader";
import { pusher } from "../../services/Vendor/Pusher/pusherService"
import { Channels } from "../../services/Vendor/Pusher/pusherChannels"
import { Events } from "../../services/Vendor/Pusher/pusherChannels"
import { useQuery } from "react-query";
import { axiosCustomInstance } from "../../services/api";
import DefaultCollectionComponent from "../../Components/Collections/DefaultCollectionComponent";
import { collectionItems } from '../../interfaces/collectionTypes';
import { setMessage } from "../../slices/message.slice";

interface mainHeading_prop {
  text: String
}

const Collections = () => {
  const [array, setArray] = useState([]);
  const [isDomLoaded, setIsDomLoaded] = useState(true);
  const [collectibleList, setCollectibleList] = useState();
  const [poetryLoungeList, setPoetryLoungeList] = useState();

  const dispatch = useDispatch();
  const location = useLocation();
  const route = window.location.pathname.split("/")
  const id: String = route[2]


  var channel = pusher.subscribe(Channels.COLLECTION);
  channel.bind(Events.COLLECTION.REFRESH, (data:any) => {
    console.log("data from pusher",data)
    // Method to be dispatched on trigger.  
    if (data.message === id) {
      console.log("Refreshing collectibles")
      // setRefreshNft(true) 
      // dispatch(setMessage({ type: "success", message: "Refreshing collectibles!",timeStamp:Date.now() }))
      // window.location.reload()
    }
  });




  const fetchCollections = async (id: String) => {

    const { data }: collectionItems = await axiosCustomInstance.get(process.env.REACT_APP_BASEURL + `/api/collection/${id}`);
    return data
  }

  const { data, isError, isLoading } = useQuery(['collections', id], () => fetchCollections(id), { refetchOnMount: true, refetchOnReconnect: true, refetchOnWindowFocus: true })

  let youtubeVideoId, driveVideoId, vimeoVideoId;
  if (data && data.data.promotionVideo && data.data.promotionVideo.includes("youtube")) {
    youtubeVideoId = data.data.promotionVideo.match(/([a-z0-9_-]{11})/gim)[0]
  }
  else if (data && data.data.promotionVideo && data.data.promotionVideo.includes("drive")) {
    driveVideoId = data.data.promotionVideo.match(/[-\w]{25,}/)
  }
  else if (data && data.data.promotionVideo && data.data.promotionVideo.includes("vimeo")) {
    var url = data.data.promotionVideo;
    var match = url.match(/https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/);
    ////console.log("match", match)
    vimeoVideoId = match[3]
  }
  const { classes } = useStyles();
  if (isLoading && !isError) {
    return <Loader />
  }
  else if (isError) {
    return <Loader />
  }
  else
    return (
      <>
        <main className="main-content-wrapper">
          <section className="section-block main-banner">
            <div className="container">
              <div className="before-shape">
                <div className="banner-slider">
                  <BannerButtonCarousel displayName={data.data.name} />
                </div>
              </div>
            </div >
          </section >
          <section className="section-block arrow-left-right">
            <div className="container">
              <section className="section-block bottom-content text-center">
                <div className="container">
                  <div className="main-title mb-0">
                    <h2 className="font-lg">
                      <span>{data.data.displayName}</span>
                    </h2>
                
                  </div>
        
                </div >
              </section >
              {
                data.data && data.data.categories.length === 1 && data.data.forSale ?
                  <>
                    {data.data.categories[0].name === "DEFAULT" ? null : <HeadingComponent heading={data.data.categories[0].name} />}
                    <div className="category-items memorabilia-slider">
                      <DefaultCollectionComponent array={data.data.categories[0].collectibleList} collectionName={data.data.name} forSale={data.data.forSale} />
                    </div>
                  </>
                  :
                  data.data.categories.map((item, index) => {
                    if (data.data.forSale)
                      return (
                        <>
                          <HeadingComponent heading={item.name} />
                          <div className="overflow-hidden overflow-hidden-padding">
                            <div className="category-items memorabilia-slider">
                              <NftCarousels key={index} array={item.collectibleList} collectionName={data.data.name} categoryName={item.name} forSale={data.data.forSale} />
                            </div>
                          </div>
                        </>
                      )
                  })
              }


          <section className="section-block bottom-content text-center">
                <div className="container">
                  <div className="main-title mb-0">
                      <div className="collectionHtmlDiv">
                       { 
                         parse(`${data.data.longDescription}`)
                      }  
                     </div>
                 </div>
               </div>    
              
              <div className="container">
              {
                    data.data.promotionVideo && data.data.promotionVideo !== undefined && data.data.promotionVideo !== "" ?
                      <>
                        <div className={classes.videoContainer}>
                          {
                            data.data.promotionVideo.includes("youtube") ?
                              <iframe className="videoContainer" style={{ objectFit: 'cover' }}
                                src={"https://www.youtube.com/embed/" + youtubeVideoId} title={data.data.displayName} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                              :
                              data.data.promotionVideo.includes("drive") ?
                                <iframe src={"https://drive.google.com/file/d/" + driveVideoId[0] + "/preview"} className="videoContainer" style={{ objectFit: 'cover', height: '100%', width: '100%', }} allow="autoplay" allowFullScreen frameBorder="0"></iframe>
                                :
                                data.data.promotionVideo.includes("vimeo") ?
                                  <iframe className="videoContainer" title="vimeo-player" style={{ objectFit: 'cover' }} src={data.data.promotionVideo} frameBorder="0" allowFullScreen></iframe> :

                                  <video playsInline
                                    autoPlay
                                    loop
                                    muted
                                    controls
                                    controlsList="nodownload"
                                    className="videoContainer"
                                    style={{ objectFit: 'cover', height: '100%', width: '100%', }}
                                  >
                                    <source src={data.data.promotionVideo} type="video/mp4" />
                                  </video>
                          }
                        </div>
                      </>
                      : null
                  } 

              </div>


         </section>





            </div >

          </section >

        </main >

      </>
    );
};

export default Collections;

const MainHeading = (props: mainHeading_prop) => {
  return (
    <>
      <div className="main-title text-center d-block">
        <h2 className="font-lg" style={{ cursor: "pointer" }}>
          <span className="gradient1">{props.text}</span>
        </h2>
      </div>
    </>
  );
};