import {useHistory} from "react-router-dom"

interface HomePageBannerProps {
    imageSource : string, 
    backgroundText: string, 
    path:string
}
const HomePageBanner = (props:HomePageBannerProps) => { 
  const history = useHistory();

  let content = null
  if (props.imageSource&&props.imageSource.endsWith('.mp4')||props.imageSource&&props.imageSource.endsWith('.mov')) {
    content = (
      <video
        src={props.imageSource}
        muted
        autoPlay
        loop
        className='banner-video'
      />
    )
  } else {
    content = (
      <div className="thumb-img" style={{ background: `url(${props.imageSource})` }}>
        <a href="#">
          <div className="banner-text">
            { 
            <span className="coming-soon">{props.backgroundText}</span>}
          </div>
        </a>
      </div>
    )
  }

  return (
    <div className="banner-item" onClick={()=>history.push(props.path)} style={{cursor:'pointer'}}>
      {content}
    </div>
  )
}

export default HomePageBanner