import React from "react";
import { useState, useEffect } from "react";
import Card from "../Card/Card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import solisLogo from "../../assets/images/solis-logo.svg";
import AFVmystery from "../../assets/images/AFVmystery.jpg";
import AFVcommunity from "../../assets/images/AFVcommunity.png";
import { collectionDetails } from '../../interfaces/collectionTypes';
import CardStatic from '../Toggle/CardStatic';
import { slideSettings } from "../../interfaces/collectionTypes";
import { Settings } from "http2";
import Loader from "../Loader/Loader";

interface NftCarouselsVar {
  array: collectionDetails[],
  collectionName: String,
  categoryName: String,
  forSale: string
}

const NftCarousels = ({ array, collectionName, categoryName, forSale }: NftCarouselsVar) => {
  const [sliderRef, setSliderRef] = useState(null);
  const [isValidImage, setIsValidImage] = useState(true);
  const [isDomLoaded, setIsDomLoaded] = useState(false)

  const sliderSettings: slideSettings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    lazyLoad: "progressive",
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  ////console.log("rendered collection carousel with array", array)
  if (array.length < 5) {
    while (array.length < 5) {
      array.push(fakeCardData)
    }
  }
  if (array !== undefined)
    return (
      <>
        <div className="content">
          <Slider ref={setSliderRef} {...sliderSettings}>
            {array &&
              array.map((item, i) => {
                return (
                  <>

                    <Card
                      key={i}
                      id={item.id}
                      imageURL={item.mediaLocation}
                      cardTitle={item.displayName}
                      cardArtistName={item.name}
                      price={item.price}
                      timeRemaining={item.count}
                      mediaType={item.mediaType}
                      currency={item.currency}
                      array={array}
                      collectionName={collectionName}
                      status={item.status}
                    />

                  </>
                );
              })}
          </Slider>
        </div>
      </>
    );

};

export default NftCarousels;

const fakeCardData: collectionDetails = {
  id: "",
  name: "",
  displayName: "",
  count: null,
  mediaType: '',
  price: null,
  currency: "",
  placeholder: "https://newwebsitesonfolio.s3.ap-southeast-2.amazonaws.com/img/portfolio/comingSoonTestv3.gif",
  mediaLocation: "https://newwebsitesonfolio.s3.ap-southeast-2.amazonaws.com/img/portfoliof",
  status:""

};
const afvCommunityCard: collectionDetails = {
  id: "",
  name: "Name",
  displayName: "CardName",
  count: 1,
  mediaType: 'image',
  price: 0.0231,
  currency: "ETH",
  placeholder: AFVcommunity,
  mediaLocation: "jhvchjqew",
  status:""

}
const afvMysteryCard: collectionDetails = {
  id: "",
  name: "Name",
  displayName: "CardName",
  count: 1,
  mediaType: 'image',
  price: 0.0231,
  currency: "ETH",
  placeholder: AFVmystery,
  mediaLocation: "",
  status:""

}