import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./payment-options.style";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';
import RadioGroup from "@mui/material/RadioGroup";
import parse from 'html-react-parser';
import { useAccount, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import transakLogo from "../../assets/images/transakLogo.png"
import moonpayLogo from "../../assets/images/moonpayLogo.png"
// Wagmi Connection Starts Here
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { openTransak } from "../../services/Vendor/Transak/transak";
import { fetchBalance } from "@wagmi/core";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useBuyMarketItem } from "../../web3/hooks/useBuyMarketItem";
import axiosInstance from "../../services/api";
import getAPIHostName from "../../utils/utils";
import { StateLoader } from "../../Components/Loader/StateLoader";
import KeyboardBackspace from "@mui/icons-material/KeyboardBackspace";
import { checkAndAddWallet } from "../../services/web3.service";
import { useDispatch } from "react-redux";
import { setMessage } from "../../slices/message.slice";
import { Link, useHistory } from "react-router-dom";
import { CURRENT_NETWORK } from "../../web3/networks";
import { openInNewTab } from "../../helpers/openLink";
import Modal from '@mui/material/Modal';
import { Moonpay } from "./moon-pay";

// Wagmi Connection Ends Here
const { getErc20TokenContractArtifacts, getChainId } = require("../../web3/web3")

export const PaymentOptions = (props) => {
    const { classes } = useStyles();
    const [selectedCryptoCurrency, setSelectedCryptoCurrency] = useState("USDC");
    const [acceptedCurrencyList, setAcceptedCurrencyList] = useState([]);
    const [explorerTransactionUrl, setExplorerTransactionUrl] = React.useState(null);
    const [selectedCurrencyBalance, setSelectedCurrencyBalance] = useState();
    const [isAsyncRunning, setIsAsyncRunning] = useState(false)
    const [paymentOption, setPaymentOption] = useState(process.env.REACT_APP_PAYMENT_OPTIONS_FIAT === "false" ? "crypto" : null)
    const [isTransakPaymentFinished, setsIsTransakPaymentFinished] = useState(false);
    const [cryptoPaymentNetwork, setCryptoPaymentNetwork] = useState(process.env.REACT_APP_CRYPTO_PAYMENT_NETWORK_OPTIONS_ENABLE === "true" ? null : "polygon");
    const connectedWalletImagePath = useRef(null)
    const [balanceNeeded, setBalanceNeeded] = useState()
    const slippagePrice = useRef(null)
    const transakSuccessful = useRef(null)
    const connectedWalletName = useRef(null)
    const [moonpayModalOpen, setMoonpayModalOpen] = useState(false)
    const [addBalanceProcess, setAddBalanceProcess] = useState(null)
    const [copyOpen, setCopyOpen] = useState(false)

    const [isFiatModalClosedByUser, setIsFiatModalClosedByUser] = useState(null)
    const [sellingPriceNft, setSellingPriceNft] = useState()


    const userState = useSelector((state) => state.user)
    const authState = useSelector((state) => state.auth)
    const dispatch = useDispatch();
    const messageState = useSelector((state) => state.message.buyNowMessage)
    const findKey = (key) => {
        return Object.keys(collections.collectionIDs).filter((k) => collections.collectionIDs[k].includes(key))
    }

    const collections = useSelector((state) => state.collections)
    const collectionName = collections && findKey(props.nftDetail.collectionId)
    const displayName = collectionName && collectionName.toString().replace(/_/g, " ")

    //Wagmi
    const { openConnectModal } = useConnectModal();

    const { chain } = useNetwork()
    const { address, isConnected, connector } = useAccount()
    const { chains, error, isLoading, pendingChainId, switchNetwork } =
        useSwitchNetwork()
    const { disconnect } = useDisconnect()
    const [buyMarketItem, loadingBuyState] = useBuyMarketItem(selectedCryptoCurrency, connector?.name.toString().toLowerCase());
    //const [bridgeEthToMatic, loadingBridgeState] = useEthToMaticBridge(props.nftDetail.totalPrice)
    const chainId = getChainId();


    //Wagmi
    async function fetchSelectedCryptoBalance() {
        try {
            setIsAsyncRunning(true)
            let balance;
            if (cryptoPaymentNetwork === "ethereum") {
                const tokenArtifacts = getErc20TokenContractArtifacts(selectedCryptoCurrency)
                balance = await fetchBalance({
                    address,
                    chainId,
                    token: tokenArtifacts.address,
                    formatUnits: 'wei'
                })
            }
            else if (cryptoPaymentNetwork === "polygon") {
                const tokenArtifacts = getErc20TokenContractArtifacts(selectedCryptoCurrency)
                balance = await fetchBalance({
                    address,
                    chainId,
                    token: tokenArtifacts.address,
                    formatUnits: 'wei'
                })
            }
            setIsAsyncRunning(false)
            return balance
        } catch (error) {
            setIsAsyncRunning(false)

        }
    }

    useEffect(() => {
        setSellingPriceNft(Number(props.nftDetail.totalPrice))
        slippagePrice.current = parseFloat(0.01 * Number(props.nftDetail.totalPrice)).toFixed(2)
        if (isTransakPaymentFinished) {
            props.paymentOptionModalFunction(false)
        }
        if (acceptedCurrencyList.length <= 0) {
            const acceptableCurrencyArray = props && props.nftDetail.acceptableCurrencies.split(",")
            setAcceptedCurrencyList(acceptableCurrencyArray)
        }

        selectedCryptoCurrency && fetchSelectedCryptoBalance().then((result) => {

            const ethersBalance = result && Number(result.formatted) / 10 ** Number(result.decimals)
            const roundedBalance = parseFloat(ethersBalance).toFixed(2)
            setSelectedCurrencyBalance(roundedBalance)
            const balanceNeeded = parseFloat(Number(sellingPriceNft) - Number(roundedBalance)).toFixed(2)
            setBalanceNeeded(balanceNeeded)
        })

        if (address && connector) {
            let walletData = {};
            walletData.address = address
            walletData.walletProvider = connector?.name.toString().toLowerCase()
            walletData.email = userState.email
            // console.log("email", userState.email)
            // console.log("wallet", walletData)
            checkAndAddWallet(walletData)
        }

    }, [, props, isTransakPaymentFinished, address, isConnected, selectedCryptoCurrency])


    useEffect(() => {
        disconnect()
    }, [])



    const history = useHistory()
    const tenderlyBuyMarketItem = async () => {
        const tenderlyEventData = {
            "eventData": {
                "buyerAddress": address,
                "itemOwner": props.nftDetail.ownderAddress,
                "nftAddress": props.nftDetail.contractAddress,
                "price": sellingPriceNft,
                "tokenId": props.nftDetail.tokenId
            },
            "eventName": "SellMarketItem",
            "collectibleId": props.nftDetail.id,
            "buyerId": userState.id
        }
        await axiosInstance.post(getAPIHostName() + "/api/webhook/tenderly/contract/event", tenderlyEventData)
    }

    useEffect(() => {
        if (messageState && messageState.type === "biconomy") {
            tenderlyBuyMarketItem().then((result) => {
                ////console.log("result", result)
            }).catch((error) => {
                ////console.log("error", error)
            })
            // setExplorerTransactionUrl(messageState.message)
        }
    }, [messageState])

    useEffect(() => {
        if (address) {
            // if (cryptoPaymentNetwork === "ethereum") {
            //     if (chain && chain.id !== 5) switchNetwork(5)
            // }
            // else if (cryptoPaymentNetwork === "polygon") {
            //     if (chain && chain.id !== 137) switchNetwork(137)
            // }

            //assigning connected wallet name 
            connectedWalletName.current = connector.name.toLowerCase()

        }
    }, [selectedCryptoCurrency, address])

    useEffect(() => {

        if (localStorage.getItem("transak") == "TRANSAK_WIDGET_SUCCESS" || localStorage.getItem("transak") === "success") {
            dispatch(setMessage({ type: "success", message: "Payment Successful! Settlement in progress", timeStamp: Date.now() }))
            localStorage.removeItem("transak")
            setAddBalanceProcess(false)
        }
    }, [localStorage.getItem("transak")])

    useEffect(() => {
        let timeoutId;
        if (loadingBuyState) {
            timeoutId = setTimeout(() => {
                window.location.reload();
            }, 40000);
        }
        return () => clearTimeout(timeoutId);
    }, [loadingBuyState]);



    const handleBuy = async (nftDetail) => {
        const transactionHash = await buyMarketItem(nftDetail.marketItemId, String(nftDetail.totalPrice));
        transactionHash && setExplorerTransactionUrl(transactionHash)
        dispatch(setMessage({ type: "success", message: "Transaction Successful!", timeStamp: Date.now() }))
    };
    const functionIsTransakPaymentFinished = (value) => {
        setsIsTransakPaymentFinished(true)
    }
    const handleRadiogroupValues = (event) => {
        if (event.target.value === "USDC") {
            setSelectedCryptoCurrency("USDC")
        }
        else if (event.target.value === "USDT") {
            setSelectedCryptoCurrency("USDT")
        }
    }

    const handleWalletConnect = async () => {
        // setIsCryptoPaymentSelected(true)
        if (isConnected) {
            if (chain.id !== CURRENT_NETWORK.id && paymentOption === "crypto") {
                switchNetwork(CURRENT_NETWORK.id)
            }
        }
        else if (!address) {
            try {
                openConnectModal()
            } catch (error) {
                ////console.log("error", error)
            }
        }
    }

    const handlePaymentOptionRadioGroup = async (event) => {
        if (event.target.value === "crypto") {
            setPaymentOption("crypto")
            setCryptoPaymentNetwork("polygon")
        }
        else if (event.target.value === "fiat") {
            setPaymentOption("fiat")
            setIsAsyncRunning(true)
            setIsAsyncRunning(false)
        }
    }


    const handleCopyTooltip = () => {
        setCopyOpen(true)
        navigator.clipboard.writeText(address)
        setTimeout(() => {
            setCopyOpen(false)
        }, 1000)
    }
    const redirectToProfile = () => {
        if (explorerTransactionUrl) {
            history.push("/profile")
        }
    }


    const setMoonpayModalOpenFunction = (val) => {
        if (val === false) {
            // setIsFiatModalClosedByUser(true)
            // setIsFiatTransactionPerformed(true)
            // setIsFiatTransactionHappened(true);
            setMoonpayModalOpen(false)
            setAddBalanceProcess(false)
        }
        else {
            setMoonpayModalOpen(val)
        }
    }

    const handleVendorSelectionRadio = async (event) => {
        if (event.target.value === "moonpay") {
            setMoonpayModalOpenFunction(true)
        }
        else {
            openTransak(address, "USDT,USDC", 0, userState.email, "userWallet")
        }
    }
    const handleAddBalance = () => {
        setAddBalanceProcess(true)
    }
    return (
        <>

            <Modal
                open={moonpayModalOpen}
                aria-labelledby="vendor-selection-modal"
                aria-describedby="venodr-selection-modal"
            >
                <>
                    <Moonpay setMoonpayModalOpenFunction={setMoonpayModalOpenFunction} walletAddress={address} currencyCode={selectedCryptoCurrency ? selectedCryptoCurrency.toString().toLowerCase() : "usdc"} />
                </>
            </Modal>

            <Modal
                open={isFiatModalClosedByUser}
            >
                <div className={classes.userDecisionButtonContainer}>
                    <span className={classes.userDecisionText}>Did you complete the transaction successfully</span>
                    <div className={classes.userDecisionButtonDiv}>
                        <button className={classes.userDecisionButton} onClick={() => { setIsFiatModalClosedByUser(null); setMoonpayModalOpen(false); }} >Yes</button>
                        <button className={classes.userDecisionButton} onClick={() => { setIsFiatModalClosedByUser(null); setMoonpayModalOpen(false); props.paymentOptionModalFunction(false); }}  >No</button>
                    </div>
                </div>
            </Modal>



            {loadingBuyState && <StateLoader text={loadingBuyState.text} />}
            <div className={explorerTransactionUrl ? classes.paymentModalSuccess : classes.paymentModal}>
                <CancelIcon sx={{ right: '0px', top: '0px', position: 'absolute', color: '#f67622', cursor: 'pointer' }} onClick={() => { localStorage.removeItem("transak"); props.paymentOptionModalFunction(false); transakSuccessful.current = false; redirectToProfile() }} />
                {
                    (explorerTransactionUrl || transakSuccessful.current === true) ?
                        <div className={classes.paymentWindowHeadingSuccess}>You have purchased</div>
                        :
                        addBalanceProcess ?
                            <div className={classes.paymentWindowHeading} style={{ textAlign: 'center' }} >Select payment vendor to buy crypto</div>
                            :
                            <div className={classes.paymentWindowHeading} >You are buying</div>
                }
                {
                    !addBalanceProcess &&
                    <div className={classes.thumbnailDiv}>
                        <div className={classes.thumbnailDetailsDiv}>
                            {
                                parse(`${props.nftDetail.name}`)
                            }
                            <br></br>
                            <span style={{ color: '#f67622' }}>   {props.nftDetail.displayName}
                            </span>
                        </div>
                        <div className={classes.thumbnailImageDiv}>
                            {
                                props.nftDetail.mediaType.includes("video") ?
                                    <video

                                        controlsList="nodownload"
                                        autoPlay
                                        muted
                                        loop
                                    >
                                        <source src={props.nftDetail.mediaLocation} />
                                    </video>
                                    :
                                    <img src={props.nftDetail.mediaLocation} ></img>
                            }
                        </div>

                    </div>
                }
                {
                    explorerTransactionUrl ?
                        <>
                            <div className={classes.accordionContainer}>
                                <Accordion sx={{ color: 'white', background: 'rgb(20,22,35)', border: '1px solid #f67622', boxShadow: '0px 0px 10px 0px #f67622', marginBottom: '20px' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div className={classes.accordionMainText}>
                                            <span >Purchased NFT for</span> <span>{props.nftDetail.totalPrice} {selectedCryptoCurrency ? selectedCryptoCurrency : props.nftDetail.currency} </span>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className={classes.accordionSummaryDivContainer}>
                                            <div className={classes.accordionSummaryDiv} style={{
                                                borderTop: '2px solid black',
                                                paddingTop: '20px'
                                            }} >
                                                <span>NFT listing price : </span> <span> {parseFloat(props.nftDetail.price).toFixed(2)}</span>
                                            </div>
                                            <div className={classes.accordionSummaryDiv}>
                                                <span>Processing fees : </span> <span>  {parseFloat(props.nftDetail.transactionFeePrice).toFixed(2)}</span>
                                            </div>
                                            {/* <div className={classes.accordionSummaryDiv}>
                                                <span>Precision rounding : </span> <span>  {parseFloat((props.nftDetail.price) / 100).toFixed(2)}</span>
                                            </div> */}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className={classes.explorerUrlDiv}>
                                <span>Transaction Successful !</span>
                                <span className={classes.explorerUrlSpan} onClick={() => openInNewTab(`${CURRENT_NETWORK.explorer}/tx/${explorerTransactionUrl}`)} >View transaction on explorer</span>
                            </div>

                        </>
                        :

                        addBalanceProcess ?
                            <>

                                <div className={classes.radiogroupWrapper}>
                                    <RadioGroup
                                        aria-labelledby="list-for-sale-radio-buttons-group-label"
                                        name="vendorOption"
                                        style={{ color: "#f67622", fontFamily: "Saira" }}
                                        onChange={(event) => handleVendorSelectionRadio(event)}
                                        className={classes.fiatPaymentRadioGroup}
                                    >

                                        <FormControlLabel
                                            value="moonpay"
                                            control={
                                                <SolisColorRadioButton />
                                            }
                                            label={
                                                <div className={classes.textWithImageDiv}>
                                                    <img src={moonpayLogo} className={classes.paymentLogo} /> <span>MoonPay</span>
                                                </div>
                                            }
                                        />
                                        <FormControlLabel
                                            value="transak"
                                            control={
                                                <SolisColorRadioButton />
                                            }
                                            label={
                                                <div className={classes.textWithImageDiv}>
                                                    <img src={transakLogo} className={classes.paymentLogo} /> <span>Transak</span>
                                                </div>
                                            }
                                        />
                                    </RadioGroup>
                                </div>
                                < div onClick={() => { setAddBalanceProcess(false) }} className={classes.footerCancelButton}>
                                    <KeyboardBackspace /> Go back
                                </div>
                            </>
                            :
                            <>
                                <div className={classes.accordionContainer}>
                                    <Accordion sx={{ color: 'white', background: 'rgb(20,22,35)', border: '1px solid #f67622', boxShadow: '0px 0px 10px 0px #f67622', marginBottom: '20px' }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <div className={classes.accordionMainText}>
                                                <span >{paymentOption === "fiat" ? "Deposit your crypto for NFT purchase" : "Purchasing NFT for"}</span> <span>{parseFloat(props.nftDetail.totalPrice).toFixed(2)} {selectedCryptoCurrency ? selectedCryptoCurrency : props.nftDetail.currency} </span>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className={classes.accordionSummaryDivContainer}>
                                                <div className={classes.accordionSummaryDiv} style={{
                                                    borderTop: '2px solid black',
                                                    paddingTop: '20px'
                                                }} >
                                                    <span>NFT listing price : </span> <span> {parseFloat(props.nftDetail.price).toFixed(2)}</span>
                                                </div>
                                                <div className={classes.accordionSummaryDiv}>
                                                    <span>Processing fees : </span> <span>  {parseFloat(props.nftDetail.transactionFeePrice).toFixed(2)}</span>
                                                </div>
                                                {/* {
                                                    paymentOption === "crypto" && <div className={classes.accordionSummaryDiv}>
                                                        <span>Slippage : </span> <span>  {parseFloat((props.nftDetail.price) / 100).toFixed(2)}</span>
                                                    </div>
                                                } */}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>

                                <div>

                                    {

                                        (cryptoPaymentNetwork === "polygon" && paymentOption === "crypto") ?
                                            <>
                                                {
                                                    !address && <button className="btn-primary" onClick={handleWalletConnect} style={{ display: 'flex', margin: 'auto', marginTop: '30px' }}>Connect Wallet</button>
                                                }
                                                {
                                                    address &&
                                                    <div className={classes.walletConnectAndDisconnect}>
                                                        <div>
                                                            <div>Connected {connectedWalletName.current} wallet address : </div>
                                                            <div className={classes.addressWithCopyIcon}>
                                                                {address}
                                                                <StyledTooltip
                                                                    PopperProps={{
                                                                        disablePortal: true,
                                                                    }}

                                                                    open={copyOpen}
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    placement="top"
                                                                    title={
                                                                        <>
                                                                            Address Copied !
                                                                        </>
                                                                    }
                                                                >
                                                                    <ContentCopyIcon style={{ cursor: 'pointer', color: '#f67622' }} onClick={handleCopyTooltip} />

                                                                </StyledTooltip>
                                                            </div>
                                                        </div>
                                                        {/* <img style={{ height: '25px', width: '25x', marginLeft: '10px', marginRight: '20px' }} src={""} /> */}

                                                        <button className={classes.disconnectWallet} onClick={disconnect}>Disconnect Wallet</button>
                                                    </div>
                                                }
                                                {
                                                    address &&
                                                    <RadioGroup
                                                        aria-labelledby="list-for-sale-radio-buttons-group-label"
                                                        name="currencyName"
                                                        style={{ color: "#f67622", fontFamily: "Saira" }}
                                                        onChange={(event) => handleRadiogroupValues(event)}
                                                        className={classes.currencyRadioGroup}
                                                        value={selectedCryptoCurrency}
                                                    >
                                                        <FormControlLabel
                                                            value="USDC"
                                                            control={
                                                                <SolisColorRadioButton />
                                                            }
                                                            label="USDC"
                                                        />

                                                        <FormControlLabel
                                                            value="USDT"
                                                            control={
                                                                <SolisColorRadioButton />
                                                            }
                                                            label="USDT"
                                                        />
                                                    </RadioGroup>
                                                }
                                                {
                                                    (address && selectedCurrencyBalance) ?
                                                        <div className={classes.selectedCurrencyBalanceDiv}>
                                                            Connected wallet balance : {selectedCurrencyBalance} {selectedCryptoCurrency ? selectedCryptoCurrency : null}
                                                            <StyledTooltip
                                                                PopperProps={{
                                                                    disablePortal: true,
                                                                }}

                                                                // open={refetchOpen}
                                                                // disableFocusListener
                                                                // disableHoverListener
                                                                // disableTouchListener
                                                                placement="top"
                                                                title={
                                                                    <>
                                                                        Refetch Balance
                                                                    </>
                                                                }
                                                            >
                                                                <RefreshIcon className={classes.refreshBalanceIcon} onClick={() => fetchSelectedCryptoBalance()} />
                                                            </StyledTooltip>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    isAsyncRunning && <CircularProgress style={{ color: '#f67622', margin: 'auto', display: 'flex', justifyContent: 'center' }} />
                                                }
                                                {
                                                    (address && selectedCurrencyBalance && !explorerTransactionUrl) && !isAsyncRunning &&
                                                    <>
                                                        {
                                                            Number(sellingPriceNft > Number(selectedCurrencyBalance))
                                                                ?
                                                                <div className={classes.addBalanceDiv}>
                                                                    <span className={classes.addBalanceText}>Balance needed : {balanceNeeded} {selectedCryptoCurrency ? selectedCryptoCurrency : "USDC"}  </span>
                                                                    <button onClick={handleAddBalance} className="btn-primary" style={{ fontSize: '0.8rem' }} >Add Balance</button>

                                                                </div>
                                                                : <button onClick={() => handleBuy(props.nftDetail)} className="btn-primary" style={{ display: 'flex', margin: 'auto' }} >Buy now</button>
                                                        }
                                                    </>
                                                }


                                            </> : null
                                    }

                                </div>




                            </>


                }


            </div>

        </>

    );
};



const Dropdown = styled(Select)(({ theme }) => ({
    color: '#f67622',
    background: 'rgb(19,22,35)',
    border: '1px solid #f67622',
    height: '50px',
    fontFamily: 'Saira',
    letterSpacing: '1px',
    width: '200px'
}));

const DropdownItems = styled(MenuItem)(({ theme }) => ({
    color: '#f67622',
    background: 'rgb(19,22,35)'
}));


const SolisColorRadioButton = styled(Radio)(({ theme }) => ({
    color: '#f67622',
    '&.Mui-checked': {
        color: '#f67622'
    },
    color: "#f67522",
    "&.Mui-checked": {
        color: "#f67622",
    },
    "&.Mui-disabled": {
        color: "#f67622",
        opacity: '0.6'
    }
}));

const StyledTooltip = styled((props) => (
    <Tooltip classes={{ popper: props.className }} {...props} />
))`
            & .MuiTooltip-tooltip {
                background - color: black;
            color: #f67622;
            box-shadow: 0px 0px 5px 0px #f67622;
            border: 0.5px solid #f67622;
            font-size: 14px;
    }
            `;
