import { useSelector } from "react-redux";
import { Route, Redirect } from 'react-router-dom';

const PrivateRoutes = ({ component: Component, ...rest }) => {

    const { isLoggedIn, email, isProfileComplete } = useSelector((state) => state.auth);

    return (
        <Route
            {...rest}
            render={(props) => {

                return !isLoggedIn ? <Redirect to="/" /> :
                    (isLoggedIn && isProfileComplete) ? <Component {...props} /> : <Redirect to={"/edit"} />

            }
            }
        />
    );
};
export default PrivateRoutes;
