import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()({
  carouselContainer: {
    position: "relative",
    textAlign: "center",
    marginTop: "100px",
    width: "75.52%",
    maxHeight: "470px",
    margin: "auto",
    border: "1px solid rgba(242 ,131 ,58, 0.5)",
    borderRadius: "10px",
    border:
      "1px solid linear-gradient(314.45deg, #C90099 -70.21%, #F67622 58.16%, #D8D8D8 143.73%)",
    "&:after": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      right: "-8px",
      bottom: "-8px",
      background: "rgb(216,216,216)",
      background:
        "linear-gradient(132deg, rgba(216,216,216,1) 0%, rgba(246,118,34,1) 100%)",
      borderRadius: "10px",
      zIndex: "-1",
    },
  },
  comingSoon: {
    fontFamily: "Saira",
    position: "absolute",
    color: "#F4F4F4",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "4.8rem",
    lineHeight: "3rem",
    textTransform: "uppercase",
    textShadow: "0px 10px 10px rgba(19, 22, 35, 0.5)",
    bottom: "12.76%",
    right: "14.18%",
  },
  Heading: {
    fontFamily: "Saira",
    marginTop: "100px",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "6.4rem",
    lineHeight: "4.8rem",
    textTransform: "uppercase",
    // background: 'linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)',
    // background: '-moz-linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)',
    // background: '-webkit-linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)',
    background: "linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    width: "75%",
    margin: "auto",
  },
  featuredReleasesContainer: {
    marginTop: "100px",
    width: "75.5%",
    maxHeight: "470px",
    display: "grid",
    gridTemplateColumns: "67% 33%",
    margin: "auto",
  },
  featuredReleasesTextContainer: {
    fontFamily: "Saira",
    padding: "20px",
    display: "grid",
    gridTemplateRows: "auto auto auto",
  },
  subHeading: {
    marginTop: "0",
    marginBottom: "0",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "2.6rem",
    lineHeight: "3rem",
    textTransform: "uppercase",
    color: "#F4F4F4",
  },
  containerText: {
    marginTop: "0",
    marginBottom: "0",
    fontFamily: "Archivo",

    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "1.6rem",
    lineHeight: "2rem",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: "#F4F4F4",
  },
  countdown: {
    marginTop: "0",
    marginBottom: "0",
    fontFamily: "Saira",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "2.5rem",

    textTransform: "uppercase",
    color: "#F4F4F4",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    background:
      "linear-gradient(314.45deg, #C90099 -70.21%, #F67622 58.16%, #D8D8D8 143.73%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  sundanceFestivalContainer: {
    marginTop: "100px",
    width: "75.5%",
    maxHeight: "470px",
    display: "grid",
    gridTemplateColumns: "33% 67%",
    margin: "auto",
  },
  searchOurMarketHeading: {
    fontFamily: "Saira",
    marginTop: "100px",
    fontStyle: "normal",
    fontWeight: "900 ",
    fontSize: "6.4rem",
    lineHeight: "6.4rem",
    textAlign: "center",
    textTransform: "uppercase",
    // background: 'linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)',
    // background: '-moz-linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)',
    // background: '-webkit-linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)',
    background: "linear-gradient(90deg, #C9009A 0.01%, #FC6401 100.01%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  searchOurMarketSubheading: {
    fontStyle: "normal",
    fontSize: "3.6rem",
    lineHeight: "4rem",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#F4F4F4",
  },
  visitMarketButton: {
    fontFamily: "Saira",
    width: "15.9%",
    background:
      "linear-gradient(277.72deg, #C9009A -32.97%, #FC6401 55.57%, #F4F4F4 144.11%)",
    color: "white",
    border: "none",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    padding: "20px 60px",
    fontSize: "2.4rem",
    lineHeight: "2.4rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardContainer: {
    marginTop: "100px",
    display: "grid",
    width: "75.5%",
    margin: "auto",
    gridTemplateColumns: "auto auto auto",
    gridColumnGap: "1.38%",
  },
  featuredReleases: {
    // height: '100%',
    "&:hover": {
      cursor: "pointer",
    },
  },
  hoverClass: {
    "&:hover": {
      cursor: "pointer",
      color: "rgba(242 ,131 ,58, 1) !important",
    },
  },
});
export { useStyles };
