import transakSDK from "@transak/transak-sdk";
import { setMessage } from "../../../slices/message.slice";
import store from "../../../store";

const createSettingsForUserWallet = (walletAddress,selectedCryptoCurrency,balanceNeeded,emailId)=>{

    // if(!emailId) {
    //     throw new Error("Email id is mandatory to perform add balance operation")
    // }

    const settings = {
        apiKey:process.env.REACT_APP_TRANSAK_API_KEY,  // Your API Key
        environment: process.env.REACT_APP_TRANSAK_ENVIRONMENT,
        // defaultCryptoCurrency: selectedCryptoCurrency,
        cryptoCurrencyList: selectedCryptoCurrency,
        defaultCryptoAmount: balanceNeeded,
        themeColor: '#f67622', // App theme color
        widgetHeight: '600px',
        walletAddress: walletAddress,
        networks: "polygon",
        cryptoCurrencyCode:selectedCryptoCurrency,
        // email: emailId,
        exchangeScreenTitle: "SOLIS",
    }
    return settings
}
const createSettingsForTreasuryWallet = (walletAddress,selectedCryptoCurrency,balanceNeeded,emailId, partnerOrderId, partnerCustomerId)=>{

    if(!partnerOrderId || !partnerCustomerId){
        throw new Error("PartnerOrderId, PartnerCustomerId cannot be null for Treasury wallet funding")
    }
    const settings = {
        apiKey:process.env.REACT_APP_TRANSAK_API_KEY,  // Your API Key
        environment: process.env.REACT_APP_TRANSAK_ENVIRONMENT,
        defaultCryptoCurrency: "USDT",
        cryptoCurrencyList: "USDT",
        defaultCryptoAmount: balanceNeeded,
        cryptoAmount:balanceNeeded,
        themeColor: '#f67622', // App theme color
        widgetHeight:'600px',
        walletAddress: walletAddress,
        isDisableCrypto: true,
        networks: "polygon",
        // email: emailId,
        cryptoCurrencyCode:selectedCryptoCurrency,
        exchangeScreenTitle: "SOLIS",
        disableWalletAddressForm:true,
        partnerOrderId,
        partnerCustomerId
    }
    console.log("createSettingsForTreasuryWallet", settings)
    return settings
}




export function openTransak(address,selectedCryptoCurrency,balanceNeeded,emailId,walletType, partnerOrderId, partnerCustomerId) { 
    
    let settings;
    let windowAlert;
    if(walletType==="treasuryWallet"){
        windowAlert = "Your transaction is successful. NFT should be shortly available clearing settlement";
        settings = createSettingsForTreasuryWallet(address, selectedCryptoCurrency, balanceNeeded, emailId, partnerOrderId, partnerCustomerId)
    }
    else {
        windowAlert = "Funds have been added to the wallet. It will be reflected shortly in your conneced wallet address.";
       settings = createSettingsForUserWallet(address, selectedCryptoCurrency, balanceNeeded, emailId)
    }

    const transak = new transakSDK(settings);

    transak.init();

    // To get all the events
    transak.on(transak.ALL_EVENTS, (data) => {
        localStorage.setItem("transak",data.eventName) 
    });

    // This will trigger when the user closed the widget
    // transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (eventData) => {
    //     localStorage.setItem("transak","close")
    //     transak.close();
    // });

    // This will trigger when the user marks payment is made.
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
        ////console.log(orderData);
        // window.alert("Funds have been added to the wallet. It will be reflected shortly in your conneced wallet address.") 
        localStorage.setItem("transak","success")
        if(walletType==="treasuryWallet") {
            store.dispatch(setMessage({message:windowAlert,type:"success",timeStamp:Date.now()}))
        }
        else {
            store.dispatch(setMessage({message:windowAlert,type:"Funds have been added to the wallet. It will be reflected shortly in your conneced wallet address.",timeStamp:Date.now()}))
        }
        transak.close();
    });
}