import { useEffect, useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { CircularProgress, duration, Select } from "@mui/material";
import Box from "@mui/material/Box";
import { useStyles } from "./nft-minter.style";
import { getCurrentWalletConnected, mintNFT } from "../../web3/web3.utils";
import { pinFileToIPFS } from "./pinata";
import SelectLabels from "../../Components/MintNFT/SelectLabels";
import axiosInstance, { axiosCustomInstance } from '../../services/api'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import clsx from "clsx";
import BulkUpload from "./BulkUpload";
import NftPreview from "./NftPreview";
import tooltipBlack from '../../assets/images/tooltipBlack.png'
import metamaskImg from '../../assets/images/metamask.svg'
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import styled from "@emotion/styled";
import CreatorWallet from "../../Components/MintNFT/CreatorWallet";
import getAPIHostName from "../../utils/utils";
const NftMinter = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [remainingHours, setRemainingHours] = useState(0);
  const [remainingMinutes, setRemainingMinutes] = useState(0);
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const [file, setFile] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [mediaSize, setMediaSize] = useState(null);
  const [mediaDuration, setMediaDuration] = useState("");
  const [imageSource, setImageSource] = useState(null);
  const [imgLoading, setImgLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [creatorEmail, setCreatorEmail] = useState("");
  const [price, setPrice] = useState();
  const [currencyType, setCurrencyType] = useState("");
  const [currency, setCurrency] = useState("");
  const [isSecondary, setIsSecondary] = useState("");
  const [nftStandard, setNftStandard] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [format, setFormat] = useState("");
  const [collectionName, setCollectionName] = useState()
  const [emailArray, setEmailArray] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [listingType, setListingType] = useState("");
  const [gifStartTime, setGifStartTime] = useState("");
  const [gifDuration, setGifDuration] = useState(5);
  const [mintingInprogress, setMintingInprogress] = useState(false);
  const [collectionList, setCollectionList] = useState([]);
  const [collectionNamesList, setCollectionNamesList] = useState([]);
  const [collectionId, setCollectionId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [checked, setChecked] = useState(false)
  const [propertiesModal, setPropertiesModal] = useState(false)
  const [mintError, setMintError] = useState(false)
  const [minute, setMinute] = useState(0)
  const [second, setSeconds] = useState(0)
  const [gifHeight, setGifHeight] = useState(300)
  const [gifWidth, setGifWidth] = useState(300)
  const [gifFps, setGifFps] = useState(60)
  const [count, setCount] = useState(0)
  const [networkId, setNetworkId] = useState()
  const [singleUpload, setSingleUpload] = useState(true)
  const [bulkUpload, setBulkUpload] = useState(false)
  const [nftPreview, setNftPreview] = useState(false)
  const [mediaLocation, setMediaLocation] = useState(null);
  const [preAssignInput, setPreAssignInput] = useState();
  const [errorMessage, setErrorMessage] = useState("")
  const [licenseAgreement, setLicenseAgreement] = useState();
  const [preAssignUserEmail, setPreAssignUserEmail] = useState()
  const [linkToMintedCollectible, setLinkToMintedCollectible] = useState()
  const [creatorWallets, setCreatorWallets] = useState({
    emailid: "",
    publicAddress: "",
    percentage: "",
    name: ""
  });
  const [creatorWalletList, setCreatorWalletList] = useState([])
  async function fetchUsers() {
    return await axiosInstance.get(
      process.env.REACT_APP_BASEURL + "/api/users/all"
    );
  }
  async function fetchCollectionList() {
    return await axiosInstance.get(
      process.env.REACT_APP_BASEURL + "/api/collection/all"
    );
  }
  useEffect(() => {
    //Load users
    let userObject;
    let tempArray = [""];
    let users = [];

    fetchUsers().then((userObject) => {
      users = userObject.data.data;
      users.map((item) => {
        tempArray.push({ name: item.email, id: item.email });
      });
      setEmailArray(tempArray);
    })
    fetchCollectionList().then((collectionList) => {
      setCollectionList(collectionList)

      const collectionNamesList = collectionList && collectionList.data.data.map((item) => {
        return { name: item.name, id: item.id };
      });
      setCollectionNamesList(collectionNamesList);

      collectionList && setCollectionList(collectionList);
    });


  }, []);

  useEffect(() => {
    const categoryList = [];
    if (
      collectionList.data &&
      collectionList.data.data &&
      collectionList.data.data.length > 0
    ) {
      collectionList.data.data.map((collection) => {
        if (collection.id == collectionId) {
          collection.categories.map((category) => {
            categoryList.push({ name: category.name, id: category.id });
          });
        }
      });
      setCategoryList(categoryList);
    }
  }, [collectionId]);



  const history = useHistory();
  var extensionLists = {};
  extensionLists.video = ["m4v", "avi", "mpg", "mp4", "webm", "mov"];
  extensionLists.image = ["jpg", "gif", "bmp", "png"];

  const callbackNftStandard = useCallback((nftStandard) => {
    setNftStandard(nftStandard);
  });
  const callbackCreatorEmail = useCallback((creatorEmail) => {
    setCreatorEmail(creatorEmail);
  });
  const callbackCurrencyType = useCallback((currencyType) => {
    setCurrencyType(currencyType);
  });
  const callbackCurrency = useCallback((currency) => {
    setCurrency(currency);
  });
  const callbackIsSecondary = useCallback((isSecondary) => {
    setIsSecondary(isSecondary);
  });
  const callbackListingType = useCallback((listingType) => {
    setListingType(listingType);
  });

  const callbackCollectionId = useCallback((collectionId) => {
    setCollectionId(collectionId);
  });

  const callbackCategoryId = useCallback((categoryId) => {
    setCategoryId(categoryId);
  });

  const callbackNetworkId = useCallback((networkID) => {
    setNetworkId(networkID)
  })



  const handleChange = (event) => {
    ////console.log("checked", event.target.checked)
    setChecked(event.target.checked)
    if (event.target.checked) {
      setPreAssignInput(true)
    }
  }
  const handleCreatorWalletChange = (event) => {
    const value = event.target.value
    ////console.log("value", value)
    setCreatorWallets({ ...creatorWallets, [event.target.name]: value })
    // setCreatorWallets({})
  }
  const handleCreatorWalletList = (event) => {
    const id = creatorWalletList.length + 1;
    const isNullUndefEmptyStr = Object.values(creatorWallets).every(value => {
      if (value === null || value === undefined || value === '') {
        return true;
      }
      return false;
    });
    if (!isNullUndefEmptyStr) {
      setCreatorWalletList((prev) => [
        ...prev,
        {
          id: id,
          creatorWallets: creatorWallets
        }
      ]);
    }
  }
  const handleClose = () => {
    setPropertiesModal(false)
  }
  const handleErrorClose = () => {
    setMintError(false)
  }
  const submitNftMetaData = async (mintData) => {
    ////console.log(mintData);

    try {
      const result = await axiosInstance.post(getAPIHostName() + "/api/collectible/add", mintData);
      return result;
    } catch (error) {
      return error
    }
  };
  const onMintPressed = async () => {
    ////console.log("On Mint pressed");
    const min = minute.toString().length === 1 ? ("0" + minute.toString()) : minute.toString();
    const sec = second.toString().length === 1 ? ("0" + second.toString()) : second.toString();
    const startTime = "00" + ":" + min + ":" + sec;
    const duration = gifDuration.toString()
    const size = gifHeight.toString() + "X" + gifWidth.toString()
    const fps = gifFps.toString()
    const remainingTime = (remainingHours.toString().length === 1 ? ("0" + remainingHours.toString()) : remainingHours.toString()) + (remainingMinutes.toString().length === 1 ? ("0" + remainingMinutes.toString()) : remainingMinutes.toString()) + (remainingSeconds.toString().length === 1 ? ("0" + remainingSeconds.toString()) : remainingSeconds.toString())


    var mintData = {
      title,
      description,
      displayName,
      file,
      mediaType,
      mediaSize,
      mediaDuration,
      mediaLocation,
      creatorEmail,
      collectionId,
      categoryId,
      count,
      price,
      currencyType,
      currency,
      isSecondary,
      quantity,
      listingType,
      order: 1,
      networkId,
      startDate: "01-Mar-2022",
      gifProperties: {
        startTime,
        duration,
        size,
        fps
      }
    };
    setMintingInprogress(true);

    setMintingInprogress(false);
    const creatorWalletPayloadArray = creatorWalletList.map((item) => {
      return item
    })
    mintData.creatorWalletPayloadArray = creatorWalletPayloadArray
    if (preAssignUserEmail) { mintData.preAssignUserEmail = preAssignUserEmail; mintData.status = "PREASSIGNED" }
    try {
      const res = await submitNftMetaData(mintData);
      setLinkToMintedCollectible(res.data.data.id)
    } catch (error) {
      setErrorMessage("Error creating NFT")
      setMintError(true)
    }
    setMintingInprogress(false);
    setErrorMessage("Success")
    setMintError(true)
    setStatus(status);
  };



  // handle file upload
  const handleFile = async (e) => {
    setImgLoading(true);

    if (e.target.files[0]) {
      try {
        setFile(e.target.files[0]);
        ////console.log("filelog", e.target.files[0]);
        setMediaType(e.target.files[0].type);
        setMediaSize(e.target.files[0].size);
        setFormat(`typeString.split('/)[0]`);
        // const ipfsMediaLocation = await pinFileToIPFS(e.target.files[0]);
        // setMediaLocation(ipfsMediaLocation);
        const buildFormData = (formData, data, parentKey) => {
          if (Array.isArray(data)) {
            data.forEach((el) => {
              buildFormData(formData, el, parentKey)
            })

          } else if (typeof data === "object" && !(data instanceof File)) {
            Object.keys(data).forEach((key) => {
              buildFormData(formData, (data)[key], parentKey ? `${parentKey}.${key}` : key)
            })

          } else {
            if (data === null || data === undefined) {
              return
            }

            let value = typeof data === "boolean" || typeof data === "number" ? data.toString() : data
            formData.append(parentKey, value)
          }
        }
        const getFormData = (data) => {
          const formData = new FormData()
          buildFormData(formData, data)
          return formData
        }
        const collectionName = collectionNamesList.filter((val) => { return val.id === collectionId })
        const extension = "." + e.target.files[0].name.split(".").pop();
        setCollectionName(collectionName[0].name)
        const data = { file: e.target.files[0], collectionName: collectionName[0].name, collectibleFormat: extension }
        const multipartFormData = getFormData(data)
        const res = await axiosCustomInstance({
          method: "post",
          url: "/api/admin/collectible/upload",
          data: multipartFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        setMediaLocation(res.data.data)
        if (e.target.files.length !== 0) {
          const reader = new FileReader();
          reader.onload = (e) => {
            var media = new Audio(reader.result);
            media.onloadeddata = () => {
              setMediaDuration(media.duration);
            };
            setImageSource(e.target.result);
            setImgLoading(false);
          };
          reader.readAsDataURL(e.target.files[0]);
        }
      } catch (e) {
        console.error(e);
        setImgLoading(false);
      }
    } else {
      console.error("File should be less than 10MB");
      setImgLoading(false);
    }
  };

  const handleRemainingTime = (e) => {
    if (e.target.name === "RemainingHours") {
      setRemainingHours(e.target.value)
    }
    else if (e.target.name === "RemainingMinutes") {
      setRemainingMinutes(e.target.value)
    }
    else setRemainingSeconds(e.target.value)
  };
  const handleLicenseAgreementCheckbox = (event) => {
    setLicenseAgreement(event.target.checked)
    if (licenseAgreement === true) {
      ////console.log("true license agreement")
    }
    else {
      ////console.log("false license agreement")
    }
  }

  const { classes } = useStyles();
  return (
    <>
      <Modal
        open={propertiesModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.propertiesModal}>
          <div className={classes.propertyInput}>
            <input name="property1" placeholder="property name" />
            <input name="value1" placeholder="value" />
          </div>
          <div className={classes.propertyInput}>
            <input name="property2" placeholder="property name" />
            <input name="value2" placeholder="value" />
          </div>
          <div className={classes.propertyInput}>
            <input name="property3" placeholder="property name" />
            <input name="value3" placeholder="value" />
          </div>
          <div className={classes.propertyInput}>
            <input name="property4" placeholder="property name" />
            <input name="value4" placeholder="value" />
          </div>
        </div>
      </Modal>
      <Modal
        open={mintError}
        onClose={handleErrorClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className={classes.mintErrorModal}>
          <div> {errorMessage} </div>
          {
            !linkToMintedCollectible ?
              <>
                <CircularProgress sx={{ color: '#f67622' }} />
                <span>Loading collectible Link</span>
              </>
              :
              <>
                <Link to={`/${collectionName}/collectible/${linkToMintedCollectible}`}>Collectible Link</Link>
              </>
          }
        </div>
      </Modal>
      <div className={classes.minterHeading}>Solis NFT Minter</div>
      <div className="container">
        <div className={classes.minterOptions}>
          <div className={singleUpload ? clsx(classes.minterOptionsHeading, classes.minterOptionsHeadingBorder) : classes.minterOptionsHeading} onClick={() => { setBulkUpload(false); setSingleUpload(true); setNftPreview(false); }}>Single Upload</div>
          <div className={bulkUpload ? clsx(classes.minterOptionsHeading, classes.minterOptionsHeadingBorder) : classes.minterOptionsHeading} onClick={() => { setBulkUpload(true); setSingleUpload(false); setNftPreview(false) }}>Bulk Upload</div>
          <div className={nftPreview ? clsx(classes.minterOptionsHeading, classes.minterOptionsHeadingBorder) : classes.minterOptionsHeading} onClick={() => { setBulkUpload(false); setSingleUpload(false); setNftPreview(true) }} >NFT Preview</div>
        </div>

        {
          singleUpload ?
            <div className={classes.parentContainer}>
              <div className={classes.uploadSection}>
                <div className={classes.containerDivTwo}>
                  <SelectLabels
                    selectorName="NFT collection"
                    label="Nft Collection"
                    menuItems={collectionNamesList}
                    parentCallback={callbackCollectionId}
                  />
                  <SelectLabels
                    selectorName="Category List"
                    label="Category List"
                    menuItems={categoryList}
                    parentCallback={callbackCategoryId}
                  />
                </div>
                {
                  categoryId && collectionId ?
                    <>
                      <div className={classes.orangegradientHeading}>Upload</div>
                      <div className={classes.uploadSectionButtons}>
                        <input
                          accept="audio/*, video/*, image/*, .html, .pdf"
                          type="file"
                          className={classes.uploadButton}
                          onChange={handleFile}
                        />
                        <div
                          className={classes.orangegradientHeading}
                          style={{
                            marginTop: "20px",
                            fontSize: "1.2rem",
                            textAlign: "center",
                            lineHeight: "1.3rem",
                            padding: "20px",
                          }}
                        >
                          Supports JPG, PNG and MP4 videos. Max file size : 10MB.
                        </div>
                      </div>
                      <div className={classes.imageParentContainer}>
                        {imgLoading && <CircularProgress className={classes.imgProgress} />}
                        {imageSource && (
                          <div className={classes.imgPreviewContainer}>
                            {
                              mediaType === "video/mp4" ? <p>Video uploaded</p> : <img
                                src={imageSource}
                                alt="preview-img"
                                style={{ maxHeight: "200px", maxWidth: "200px" }}
                              />
                            }
                            <div>
                              {file.size > 100000
                                ? `${file.size / 100000} MB`
                                : file.size > 1000
                                  ? `${file.size / 1000} KB`
                                  : `${file.size} MB`}
                            </div>
                          </div>
                        )}
                      </div>
                    </> : null
                }

                {
                  (mediaType && mediaType.includes("video")) ? <div className={classes.gifPropertyInputContainer}>
                    <h4>Gif duration</h4>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <button onClick={() => {
                        if (gifDuration > 1) setGifDuration(gifDuration - 1)
                      }}>-</button>
                      <input className={classes.inputContainer} value={gifDuration} placeholder="gif duration" label="gif duration"
                        onChange={(e) => {
                          setGifDuration(e.target.value)
                        }}
                      /><button onClick={() => {
                        if (gifDuration < mediaDuration)
                          setGifDuration(gifDuration + 1)
                      }}>+</button> <p>Seconds</p>
                    </div>
                  </div> : null
                }

                {
                  mediaType && mediaType.includes("video") ? <div>
                    <h4>Start time (hh:mm:ss)</h4>
                    <div className={classes.gifPropertyInputContainer}>
                      <input placeholder="minutes" label="minutes" className={classes.inputContainerHalf} value={minute} /> <div><button onClick={() => {
                        if (minute < (mediaDuration / 60) - 1) setMinute(minute + 1)
                      }}>+</button><button onClick={() => {
                        if (minute > 0) setMinute(minute - 1)
                      }} >-</button></div> <input placeholder="seconds" label="seconds" className={classes.inputContainerHalf} value={second} /> <div><button onClick={() => {
                        if (second < 58) setSeconds(second + 1)
                        if (second === 59) { setSeconds(0); setMinute(minute + 1); }
                      }}>+</button><button onClick={() => {
                        if (second > 0) setSeconds(second - 1)
                      }} >-</button></div>
                    </div>
                  </div> : null
                }

                {
                  mediaType && mediaType.includes("video") ?
                    <div className={classes.gifPropertyInputContainer}>
                      <h4>Size(Resolution)</h4>
                      <input placeholder="height" value={gifHeight} onChange={(event) => setGifHeight(event.target.value)} />X<input placeholder="width" value={gifWidth} onChange={(event) => setGifWidth(event.target.value)} />
                    </div> : null
                }
                {
                  mediaType && mediaType.includes("video") ?
                    <div className={classes.gifPropertyInputContainer}>
                      <h4>Gif FPS</h4>
                      <input placeholder="FPS" label="FPS" value={gifFps} onChange={(event) => { if (event.target.value >= 0 && event.target.value < 60) setGifFps(event.target.value) }} />
                    </div>
                    : null
                }

              </div>
              <div className={classes.nftDetails}>
                <div className={classes.containerDiv}>
                  {/* <div className={classes.orangegradientHeading}>Title</div> */}
                  <input
                    type="text"
                    className={clsx(classes.inputContainer, "placeholder-class")}
                    id="title"
                    key="title"
                    name="title"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                    placeholder="Title"
                  />
                </div>

                <div className={classes.containerDiv}>
                  {/* <div className={classes.orangegradientHeading}>Description</div> */}
                  <textarea
                    type="text"
                    className={clsx(
                      classes.inputContainerDescription,
                      "placeholder-class"
                    )}
                    id="description"
                    key="description"
                    name="description"
                    placeholder="Description"
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                  />
                </div>
                <div className={classes.containerDiv}>
                  <input
                    type="text"
                    className={clsx(classes.inputContainer, "placeholder-class")}
                    id="display name"
                    key="display name"
                    name="display name"
                    value={displayName}
                    onChange={(event) => setDisplayName(event.target.value)}
                    placeholder="Display Name"
                  />
                </div>
                <div className={classes.containerDivTwo}>
                  <SelectLabels
                    selectorName="Creator Name"
                    menuItems={emailArray}
                    label="Creator Name"
                    parentCallback={callbackCreatorEmail}
                  />

                </div>





                {/* 
                <div className={classes.remainingTimeContainer}>
                  <input placeholder="hours" value={remainingHours} onChange={handleRemainingTime} name="RemainingHours" className={classes.remainingTimeInput} />
                  <input placeholder="minutes" value={remainingMinutes} onChange={handleRemainingTime} name="RemainingMinutes" className={classes.remainingTimeInput} />
                  <input placeholder="seconds" value={remainingSeconds} onChange={handleRemainingTime} name="RemainingSeconds" className={classes.remainingTimeInput} />
                </div> */}



                <div className={classes.containerDivTwo}>
                  <input
                    type="text"
                    className={classes.inputContainerHalf}
                    id="price"
                    key="price"
                    name="price"
                    value={price}
                    onChange={(event) => {
                      ////console.log(event.target.value);
                      setPrice(event.target.value);
                    }}
                    placeholder="Price"
                  />
                </div>
                <div className={classes.containerDivTwo}>
                  <SelectLabels
                    selectorName="Network"
                    menuItems={[
                      { name: "Polygon", id: "137" },
                      { name: "Mantle", id: "50001" },
                    ]}
                    label="Network"
                    parentCallback={callbackNetworkId}
                  />
                </div>

                <div className={classes.containerDivTwo}>
                  <SelectLabels
                    selectorName="Currency Type"
                    menuItems={[
                      { name: "CRYPTO", id: "CRYPTO" },
                      // { name: "FIAT", id: "FIAT" },
                    ]}
                    label="Currency Type"
                    parentCallback={callbackCurrencyType}
                  />
                  {currencyType == "CRYPTO" ? (
                    <SelectLabels
                      selectorName="Currency"
                      label="Currency"
                      menuItems={[{ name: "ETH", id: "ETH" }, { name: "USDT", id: "USDT" }, { name: 'USDC', id: 'USDC' }]}
                      parentCallback={callbackCurrency}
                    />
                  ) : currencyType == "FIAT" ? (
                    <SelectLabels
                      selectorName="Currency"
                      label="Currency"
                      menuItems={[{ name: "USD", id: "USD" }]}
                      parentCallback={callbackCurrency}
                    />
                  ) : (
                    <SelectLabels
                      selectorName="Currency"
                      label="Currency"
                      menuItems={[""]}
                      parentCallback={callbackCurrency}
                    />
                  )}
                </div>

                <div className={classes.containerDivTwo}>
                  <SelectLabels
                    selectorName="Secondary Sale"
                    menuItems={[
                      { name: "TRUE", id: "TRUE" },
                      { name: "FALSE", id: "FALSE" },
                    ]}
                    label="Secondary Sale"
                    parentCallback={callbackIsSecondary}
                  />
                  <SelectLabels
                    selectorName="Listing type"
                    menuItems={[
                      { name: "BUYNOW", id: "BUYNOW" },
                      { name: "BIDNOW", id: "BIDNOW" },
                      { name: "CLAIM", id: "CLAIM" }
                    ]}
                    label="Listing type"
                    parentCallback={callbackListingType}
                  />
                </div>
                <div className={classes.containerDivTwo}>
                  {/* <SelectLabels
                    selectorName="NFT Standard"
                    menuItems={[
                      { name: "ERC-721", id: "ERC-721" },
                      // { name: "ERC-1155", id: "ERC-1155" },
                    ]}
                    label="NFT Standard"
                    parentCallback={callbackNftStandard}
                  /> */}

                  {nftStandard == "ERC-1155" ? (
                    <input
                      type="text"
                      className={classes.inputContainerHalf}
                      id="quantity"
                      key="quantity"
                      name="quantity"
                      value={quantity}
                      onChange={(event) => {
                        ////console.log(event.target.value);
                        setQuantity(event.target.value);
                      }}
                      placeholder="Quantity"
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
                <h5 style={{ color: '#f67622' }}>Add creator Wallets</h5>
                {
                  creatorWalletList.map((item, index) => {
                    return (
                      <CreatorWallet number={index} emailid={item.creatorWallets.emailid} publicAddress={item.creatorWallets.publicAddress} percentage={item.creatorWallets.percentage} name={item.creatorWallets.name} />
                    )
                  })
                }

                <input type="text" className={classes.inputContainerHalf} name="emailid" placeholder="emailid" value={creatorWallets.emailid} onChange={handleCreatorWalletChange} />
                <input type="text" className={classes.inputContainerHalf} name="percentage" placeholder="percentage" value={creatorWallets.percentage} onChange={handleCreatorWalletChange} />
                <input type="text" className={classes.inputContainerHalf} name="publicAddress" placeholder="publicAddress" value={creatorWallets.publicAddress} onChange={handleCreatorWalletChange} />
                <input type="text" className={classes.inputContainerHalf} name="name" placeholder="name" value={creatorWallets.name} onChange={handleCreatorWalletChange} />

                <div>
                  <button onClick={handleCreatorWalletList} className={classes.plusButton}>+</button>
                </div>

                <div className={classes.containerDivTwo}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Checkbox
                      checked={preAssignInput}
                      onClick={() => setPreAssignInput(!preAssignInput)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{
                        color: '#f67622',
                        '&.Mui-checked': {
                          color: '#f67622'
                        }
                      }}
                    />
                    <p>Pre Assign NFT</p>
                  </div>
                </div>

                {
                  preAssignInput ? <input type="text" className={classes.inputContainerHalf} name="preAssignInput" placeholder="Pre assigned Email" value={preAssignUserEmail} onChange={(e) => { setPreAssignUserEmail(e.target.value) }} ></input> : null
                }

                <button
                  className="btn-primary"
                  onClick={onMintPressed}
                  style={{
                    maxWidth: "200px",
                    "@media(max-width:900px)": {
                      display: "block",
                      margin: "auto",
                    },
                  }}
                >
                  Create NFT
                </button>


                {mintingInprogress && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </div>
            </div>
            : bulkUpload ? <BulkUpload /> : <NftPreview />
        }

      </div>

    </>
  );
};
export default NftMinter;

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: black;
    color: #f67622; 
    box-shadow:0px 0px 10px 0px #f67622; 
    border:2px solid #f67622; 
    width:900px;  
    height:200px;
    font-size:14px;
  }
`;