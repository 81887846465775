import React from 'react'
import Card from "../Card/Card";
import Skeleton from '@mui/material/Skeleton';

const ListWithFilter = ({data,isLoading}:any) => {
  return (
    <>
      <div className='category-items memoribilia-slider scrollable'>
        {isLoading ? 
          <div className="default-collection-container">
            {
                   Array.from(Array(30)).map((_, i) => (
                    <Skeleton key={i} variant="rectangular" style={skeletonStyles} animation="pulse" />
                  ))
            }
          </div>
        :
          <div className="default-collection-container">
            {data && data.map((item: any, i:any) => {
              return (
                <>
             
             <Card
                      key={i}
                      id={item.id}
                      imageURL={item.mediaLocation}
                      cardTitle={item.displayName}
                      cardArtistName={item.name}
                      price={item.price}
                      timeRemaining={item.count}
                      mediaType={item.mediaType}
                      currency={item.currency}
                      array={[]}
                      collectionName={item.collectionName}
                      status={item.status}
                    />
                </>
              );
            })}
          </div>
        }
      </div>
    </>
  )
}

export default ListWithFilter


const skeletonStyles = {
  flex: '0 0 calc(25% - 20px)',
  maxWidth: 'calc(25% - 20px)',
  minWidth: '100px',
  maxHeight: 'calc(100vw / 4 - 20px)',
  minHeight: '100px',
  backgroundColor: '#f67622',
  margin: '10px',
}
