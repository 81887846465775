import React from "react";
import Slider from "react-slick";
import { useState } from "react";
import { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import comingSoonGif from "../../assets/images/comingSoonTestv2.gif"
import { useSelector } from "react-redux";
import HomePageBanner from "../Home/HomePageBanner";
import { generatePath } from "react-router-dom";
const AFFECT_CHANGE = require("../../assets/collection/AFFECT_CHANGE/AFFECT_CHANGE_hp_banner_image.mp4")
const CAUSE_PLAY_IRL = require("../../assets/collection/CAUSE_PLAY_IRL/CAUSE_PLAY_IRL_hp_banner_image.png")
const FANTASY_FOOTBALL_CAUSE = require("../../assets/collection/FANTASY_FOOTBALL_CAUSE/FANTASY_FOOTBALL_CAUSE_hp_banner_image.png")
const HER_VOICE = require("../../assets/collection/HER_VOICE/HER_VOICE_hp_banner_image.png")
const SOLIS_CORE = require("../../assets/collection/SOLIS_CORE/SOLIS_CORE_hp_banner_image.gif")
const SPOKEN_WORLD = require("../../assets/collection/SPOKEN_WORLD/SPOKEN_WORLD_hp_banner_image.png")
const AFV_COLLECTION = require("../../assets/collection/AFV_COLLECTION/AFV_COLLECTION_hp_banner_image.jpg")
const BUNNY = require("../../assets/collection/BUNNY/BUNNY_hp_banner_image.jpg")
const SAINT_JUDE = require("../../assets/collection/SAINT_JUDE/SAINT_JUDE_hp_banner_image.png")
const ISEKAI_ANIME = require("../../assets/collection/ISEKAI_ANIME/ISEKAI_ANIME_hp_banner_image.png")

const comingSoon = require("../../assets/images/comingSoonTestv2.gif")
const BG_VIDEO = require("../../assets/images/bg-video.mp4")
// const SOLIS_FLARES = require("../../assets/collection/SOLIS_FLARES/SOLIS_FLARES_hp_banner_image.png")
const SOLIS_FLARES = "https://solis-video-files.s3.amazonaws.com/Flares_806_976x606.gif"
const BannerCarousel = () => {
  const [sliderRef, setSliderRef] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false)
  const videoRef = useRef(null)
  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  const playVideo = () => {
    setIsPlaying(true)
    videoRef.current.play()
  }
  const pauseVideo = () => {
    setIsPlaying(false)
    videoRef.current.pause()
  }
  const allCollections = useSelector((state) => state.collections)
  function setSource(path) {
    try {
      return require(path).default
      // return require("../../assets/collection/FANTASY_FOOTBALL_CAUSE/FANTASY_FOOTBALL_CAUSE_hp_banner_image.png").default
    }
    catch (err) {
      return require("../../assets/images/comingSoonTestv2.gif").default
    }
  }
  const imageFiles = [AFV_COLLECTION, SOLIS_FLARES, AFFECT_CHANGE, CAUSE_PLAY_IRL, BUNNY, FANTASY_FOOTBALL_CAUSE, HER_VOICE, SOLIS_CORE, SPOKEN_WORLD, SAINT_JUDE, ISEKAI_ANIME, BG_VIDEO]
  let tempImageUrl
  const findImage = (collectionName) => {
    for (var i = 0; i < imageFiles.length; ++i) {
      // if (collectionName === 'SPOKEN_WORLD') {
      //   return BG_VIDEO
      // }

      const image = imageFiles[i]
      if (typeof image === "string" && image.includes("https") && collectionName === "SOLIS_FLARES") {
        tempImageUrl = image; return tempImageUrl;
      }
      else if (typeof image !== "string" && image.default.toString().includes(collectionName)) {
        tempImageUrl = image.default
        return tempImageUrl
      } else if (typeof image === 'string' && image.includes(collectionName)) {
        tempImageUrl = image
        return tempImageUrl
      }
      else tempImageUrl = comingSoon.default
    }
  }
  return (
    <>
      <div className="content">
        <Slider ref={setSliderRef} {...sliderSettings}>
          {
            allCollections.collections !== undefined && allCollections.collections.map((item) => {
              const imageSrc = setSource(`../../assets/collection/${item.name}/${item.name}_hp_banner_image.png`)
              const tempImageSrc = findImage(item.name)
              const id = item.id
              const path = generatePath("/collections/:id", { id })
              return <HomePageBanner imageSource={tempImageSrc} backgroundText={item.displayName} path={path} />
            })
          }

          {/* <div className="banner-item">
            <div className="thumb-img">
              <a href="#">
                <div className="video-player">
                  <video playsInline
                    loop
                    muted
                    controls=""
                    ref={videoRef}
                    poster=""
                  >
                    <source src="" type="video/mp4" />
                  </video>
                  {
                    isPlaying ? <PauseCircleFilledIcon sx={{ marginLeft: '10px', cursor: 'pointer' }} onMouseHover={(e) => {
                      e.stopPropagation();
                      if (isPlaying) {
                        pauseVideo()
                      }
                      else {
                        playVideo()
                      }
                    }} /> :

                      <PlayCircleFilledWhite sx={{ marginLeft: '10px', cursor: 'pointer' }} onMouseLeave={(e) => {
                        e.stopPropagation();
                        if (isPlaying) {
                          pauseVideo()
                        }
                        else {
                          playVideo()
                        }
                      }} />
                  }
                </div>
                <div className="banner-text">
                  <span className="coming-soon">Dante Basco's RUFIO Collection</span>
                </div>
              </a>
            </div>
          </div> */}

        </Slider>
      </div>
    </>
  );
};

export default BannerCarousel;
