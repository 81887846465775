import { Hyphen, SIGNATURE_TYPES, RESPONSE_CODES } from "@biconomy/hyphen";

export const initHyphen = async (provider) => {
    let hyphen = new Hyphen(provider , {
      debug: true,
      environment: "prod",
      onFundsTransfered: (data) => {
        ////console.log("data", data);
      }
    });
    await hyphen.init();
    return hyphen;
}


