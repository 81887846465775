import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux';
import { injectStore } from './services/api';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from "./slices/auth.slice";
import userReducer from "./slices/user.slice";
import collectionReducer from './slices/collection.slice'
import messageReducer from './slices/message.slice'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const reducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  collections: collectionReducer,
  message: messageReducer
});

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
injectStore(store);

export default store;

