import React from "react";
import ComingSoon from "../../Components/ComingSoon/ComingSoon";

const community = () => {
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default community;
