import React, { useEffect, useRef, useState } from "react";

import { useStyles } from "./IndividualNftCard.style";
import { useSelector } from "react-redux"
import ListForSale from "./ListForSale"
import { Checkbox, Modal } from "@mui/material";

const OwnedIndividualNftCard = ({ nftDetail, currentCollectionName }) => {

    const [paymentOptions, setShowPaymentOptions] = useState(false);
    const [nftType, setNftType] = useState("")
    const [currencyType, setCurrencyType] = useState("")
    const [listForSale, setListForSale] = useState()
    const [currency, setCurrency] = useState("")
    const [currencyValue, setCurrencyValue] = useState("")
    const user = useSelector((state) => state.user)
    const [isListedForSale, setIsListedForSale] = useState(false)
    const openListForSaleFunction = (value) => {
        setListForSale(value)
    }
    const handleChange = (event) => {
        event.preventDefault()
        if (event.target.name === "nftType") setNftType(event.target.value)
        else if (event.target.name === "currencyType") setCurrencyType(event.target.value)
        else if (event.target.name === "currency") setCurrency(event.target.value)
        else if (event.target.name === "currencyValue") setCurrencyValue(event.target.value)
    }
    useEffect(() => {
        if (user.id === nftDetail.ownerId && nftDetail.status === "IN_MARKET") {
            setIsListedForSale(true)
        }
    }, [])
    const { classes } = useStyles()
    return (
        <>
            <div className="individualNftPage" style={{ display: 'relative', }}>
                <div style={{ position: 'relative' }} className="individualNFTPage">
                    <div className="before-shape before-shape-noHover">
                        <div className="banner-slider" style={{ cursor: "pointer" }}>
                            <div className="banner-item">
                                {
                                    (nftDetail.mediaDuration !== undefined && nftDetail.mediaDuration !== "") ?
                                        <div className="thumb-img-individual-nft for-video">
                                            <div className="video-player individual-video-playerContainer">
                                                <video
                                                    controls
                                                    style={{ widht: "100%" }}
                                                    controlsList="nodownload"
                                                    autoPlay
                                                    muted
                                                    loop
                                                >
                                                    <source src={nftDetail.mediaLocation} type="video/mp4" />
                                                </video>
                                            </div>
                                        </div> :
                                        <div className="thumb-img-individual-nft for-image">
                                            <img
                                                src={nftDetail.mediaLocation}
                                                alt=""
                                            />
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="price-buynow-btn">
                        <div className="current-price flex-fill">
                            {
                                currentCollectionName[0] === "SAINT_JUDE" ?
                                    <span className="nftPriceLabel">
                                        St Jude at Consensus 2023
                                    </span>
                                    :
                                    <span className="nftPriceLabel">
                                        {nftDetail.price} {nftDetail.currency}
                                    </span>
                            }
                            {
                                currentCollectionName[0] !== "SAINT_JUDE" &&
                                <>
                                    <span className="priceBreakLine"></span>

                                    <h3 className="currentPriceLabel">
                                        CURRENT
                                        <br />
                                        PRICE
                                    </h3>
                                </>
                            }
                        </div>
                        {
                            currentCollectionName[0] !== "SAINT_JUDE" && < div className="right-block">

                                <button className="btn-primary nftBuyNow" value={listForSale} onClick={() => openListForSaleFunction(!listForSale)} > {isListedForSale ? "Remove from Market" : "List for Sale"} </button>

                            </div>
                        }

                    </div>
                </div>
            </div>
            <Modal
                open={listForSale}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ overflowY: 'scroll' }}
            >
                <>
                    <div className="col-lg-12 mb-5">
                        <ListForSale
                            openListForSaleFunction={openListForSaleFunction}
                            nftDetail={nftDetail}
                            collectionName={currentCollectionName}
                        />
                    </div>

                </>
            </Modal>

        </>
    );
};

export default OwnedIndividualNftCard;




