import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { axiosCustomInstance } from '../../services/api'
import { getAllCollections } from '../../slices/collection.slice';
import getAPIHostName from '../../utils/utils'
import { useStyles } from './nft-minter.style';
import Loader from '../../Components/Loader/Loader';
import axiosInstance from '../../services/api';

const BulkUpload = () => {
    const [collectionName, setCollectionName] = useState(null)
    const [progress, setProgress] = useState(false)
    const [isRequestComplete, setIsRequestComplete] = useState(false)
    const [response, setResponse] = useState()
    const [responseType, setResponseType] = useState()
    const [collectibleId, setCollectibleId] = useState()
    const [previewMediaLocation, setPreviewMediaLocation] = useState()
    const [mediaType, setMediaType] = useState("video/mp4")
    const [isFetchClicked, setIsFetchClicked] = useState(false)
    const [isPreviewLoading, setIsPreviewLoading] = useState(false)
    const dispatch = useDispatch();
    const collectionState = useSelector((state) => state.collections)
    useEffect(() => {
        let allCollectionNames = [];
        collectionState.collections.map((item) => {
            allCollectionNames.push(item.name)
        })
        setCollectionName(allCollectionNames)
    }, [])

    const handleSubmit = async () => {
        setProgress(true)
        try {
            const resp = await axiosInstance.post(getAPIHostName() + "/api/bulkupload", { "collectionName": "SOLIS FLARES" })
            ////console.log("resp", resp)
        } catch (error) {
            ////console.log("error", error)
            setResponseType("error")
            setResponse(error)
            setProgress(false)
            setIsRequestComplete(true)
        }
        setProgress(false)
        setIsRequestComplete(true)
    }

    const { classes } = useStyles()
    return (
        <>
            <div className={classes.bulkUploadParentContainer}>
                <div className='container'>
                    <div className={classes.orangegradientHeading} style={{ textAlign: 'center' }}>Select Collections</div>
                    <div className={classes.selectButtonContainer}>
                        <select className={classes.collectionSelect} >
                            {
                                collectionName && collectionName.map((item) => {
                                    return (
                                        <>
                                            <option value={item}>{item}</option>
                                        </>
                                    )
                                })
                            }
                        </select>
                        {
                            progress ? <Loader /> : <button onClick={handleSubmit} className="btn-primary" style={{ width: '15%', margin: 'auto', marginBottom: '50px' }}>Submit</button>
                        }
                    </div>

                    {/* <div className={classes.statsDiv}>
                        {
                            isRequestComplete && responseType !== "error" ?
                                <>
                                    <div>Process Completed</div>
                                    <div>New Records : {response.newRecords}</div>
                                    <div>Updated Records : {response.updatedRecords}</div>
                                    <div>Deleted Records  : {response.deletedRecords}</div>
                                </> : isRequestComplete && responseType === "error" ?
                                    <>
                                        <div>
                                            {response.type}  {response.value.map((val) => { return val })}
                                        </div>
                                    </> : isRequestComplete ? <div>Error processing record</div> : null
                        }
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default BulkUpload