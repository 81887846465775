import React from "react";
const ComingSoon = () => {
  return (
    <>
      <main className="main-content-wrapper">
        <section className="section-block">
          <div className="container" style={{ textAlign: "center" }}>
            <div className="main-title">
              <h2>
                <span className="gradient1">Coming Soon</span>
              </h2>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ComingSoon;
