import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { ChainId, getMarketPlaceContractArtifacts } from "../web3"
import { useSigner, useProvider, useAccount } from "wagmi";
import { getBiconomyInstance, getChainId,getErc20TokenCurrencyAddress } from "../web3";
import { useErc20TokenContract } from "./useErc20TokenContract";
import { setMessage } from "../../slices/message.slice";
import store from "../../store";
import { setIsLoading } from "../../slices/auth.slice";
import { initHyphen } from "../Vendor/Biconomy/hyphenService";
import { ContractDetail } from "../ContractDetail";
const MarketplaceAbi = require("../contracts/Marketplace.sol/Marketplace.json")

type LoadingState = {
  text?: string
}
export const useEthToMaticBridge = (address:string, tokenTicker:string) => {
//   const [hyphen,setHyphen] = useState(null)
//   const [loadingState, setLoadingState] = useState<LoadingState>(null)

//   useEffect(() => {
//     const initializeHyphen = async () => {
//      try {
//        setLoadingState({})
//        const hyphen = await initHyphen()
//        setHyphen(hyphen)
//      } catch (e) {
//        ////console.log('hyphen error', e)
//        console.error(e)
//      } finally {
//        setLoadingState(null)
//      }
//    };
//    initializeHyphen();
//  }, []); 

//   const infiniteApproval = false;
//   const useBiconomy = false;
//   const tokenAddress = getErc20TokenCurrencyAddress(tokenTicker);
//   const amount = "1";
  
//   const bridgeEthToMatic = async(amount:string) => {
//     try {
//       setLoadingState({
//         text: 'Get token Approval'
//       })
//         //Get the approval
//         const approveTx = await hyphen.tokens.approveERC20(
//                                   tokenAddress,amount.toString(),address,infiniteApproval,useBiconomy)
  
//         await approveTx.wait(5);
        
//         setLoadingState({
//           text: 'Processing transaction'
//         });
  
//         //Initiate deposit
//         const transferTx = await hyphen.depositManager.deposit({
//           sender: address,
//           receiver:address, 
//           tokenAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
//           depositContractAddress: "",
//           amount: amount,
//           fromChainId: "1",
//           toChainId: "137",
//           useBiconomy: false,
//           tag:"solis"
//         });
  
//         await transferTx.wait(5);
  
//         setLoadingState({
//           text: 'Bridiging funds from mainnet to polygon...'
//         });
//     } catch (error) {
//       setLoadingState(null)
//     }finally{
//       setLoadingState(null)
//     }
//   }

//   return [bridgeEthToMatic, loadingState];

};





