import { useState } from 'react';


import bgFlaresVideo from "../../assets/images/bg-video.mp4"
import img1 from "../../assets/images/Images Adding funds/1.jpg"
import img2 from "../../assets/images/Images Adding funds/2.JPG"
import img3 from "../../assets/images/Images Adding funds/3.JPG"
import img4 from "../../assets/images/Images Adding funds/4.JPG"
import img5 from "../../assets/images/Images Adding funds/5.png"
import img6 from "../../assets/images/Images Adding funds/6.JPG"
import img7 from "../../assets/images/Images Adding funds/7.png"
import img8 from "../../assets/images/Images Adding funds/8.JPG"
import img9 from "../../assets/images/Images Adding funds/9.JPG"
import img10 from "../../assets/images/Images Adding funds/10.png"

import solisLogo from "../../assets/images/solis-logo.svg"
import transakImg from "../../assets/images/transakFullLogo.jpg"
const AddingFunds = () => {

    return (
        <>
            <div class="bg-video">
                <div class="embed-container ratio ratio-16x9">
                    <video class="embed-video" autoPlay loop muted preload controlsList="nodownload">
                        <source src={bgFlaresVideo} type="video/mp4" />
                    </video>
                </div>
            </div>
            <main className="main-content-wrapper">
                <section className="section-block main-banner">
                    <div className="container">

                        <div className='buy-guide-homepage'>

                            <div className='home-kyc-flex-container'>
                                <div>
                                    <p className='buy-options-heading-white-without-margin'>Adding Funds</p>
                                    <p className='buy-options-heading-white-without-margin'>to your Wallets</p>
                                    <p className='buy-options-heading-white-without-margin'>WITH</p>
                                    <p className='buy-options-heading-white-without-margin'>FIAT $</p>
                                </div>
                                <div>
                                    <ul>
                                        <li>Credit Card</li>
                                        <li>Debit Card</li>
                                        <li>ACH</li>
                                        <li>Wire</li>
                                        <li>Google Pay</li>
                                        <li>Apple Pay</li>
                                        <li>SEPA</li>
                                        <li>&more...</li>
                                    </ul>
                                </div>
                            </div>

                            <div className='kyc-footer-div'>
                                <p className='kyc-footer-heading'>USER GUIDE</p>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <img src={transakImg} className="footer-kyc-logo" style={{ display: 'flex', margin: 'auto' }} />
                                    <div style={{ textAlign: 'center' }}>Powered by</div>
                                </div>
                            </div>

                        </div>
                        <div className='flares-bg-container'>
                            <div className="steps-container-div">
                                <div className='buy-options-heading-white'> Adding Funds to your Wallets using Fiat is easy !</div>
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'>You can add cryptocurrency funds (via on-ramping Fiat $ currency) directly to your connected wallets within your <strong className='orange-strong' >Profile</strong> Section (<strong className='orange-strong'>Accounts</strong> tab) </p>
                                    <p className='steps-orange'>This allows you to have the correct cryptocurrency ready to use, so you don’t miss out on any opportunities in Solis.Market</p>
                                    <p className='steps-orange'>You have two main options:</p>
                                    <p className='steps-orange-without-margin'>Option 1:  Add funds to your <strong className='orange-strong'>SOLIS magic wallet</strong> (internal wallet)</p>
                                    <p className='steps-orange-without-margin' style={{ marginLeft: '20px' }}>	[Note: the funds in your Magic wallet can only be used within Solis.Market]</p>
                                    <br />
                                    <p className='steps-orange-without-margin'>Option 2:  funds to your <strong className='orange-strong'>external connected wallets</strong> (i.e Metamask, etc]</p>
                                    <p className='steps-orange-without-margin' style={{ marginLeft: '20px' }}>	[Note: funds in your external wallets can also be used outside of Solis.Market]
                                    </p>
                                </div>
                            </div>

                            <div className="steps-container-div">
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Step 1</strong> - Go to your <strong className='orange-strong'>Profile</strong> Section and select the <strong className='orange-strong'>Accounts</strong> tab and click <strong className='orange-strong'>“Connect Wallet”</strong> </p>
                                    <img src={img1} className='steps-images' />
                                </div>
                            </div>

                            <div className="steps-container-div">
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Step 2 </strong>– Select the Wallet you would like to add cryptocurrency funds to</p>
                                    <img src={img2} className='steps-images' />
                                </div>
                            </div>

                            <div className="steps-container-div">
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Step 3 </strong> – Select the <strong className='orange-strong'>type of cryptocurrency</strong> you want to add to your wallet
                                        from the drop-down menu</p>
                                    <br />
                                    <p className='steps-orange'>- Your selected (connected) wallet is displayed on the left</p>
                                    <p className='steps-orange'>- Click <strong className='steps-orange'>“Add balance”</strong> to fund your wallet</p>
                                    <img src={img3} className='steps-images' />
                                </div>
                            </div>

                            <div className="steps-container-div">
                                <div className="steps-text-orange">
                                    <p className="steps-orange"><strong className='orange-strong' >Step 4</strong> – populate your<strong className='orange-strong'>funding widget</strong>and click <strong className='orange-strong'>"BUY NOW"</strong></p>
                                    <div className='text-image-left-container'>
                                        <div className='text-flex-column-container'>
                                            <div>
                                                <p className="steps-orange-without-margin">- Input the dollar $ amount you want to fund </p>
                                                <p className="steps-orange-without-margin">- Select your appropriate payment method</p>
                                            </div>

                                        </div>
                                        <div>
                                            <img src={img4} className="steps-images" />
                                        </div>
                                    </div>
                                    <p className="steps-orange-without-margin">- Transak is SOLIS’ secure payment gateway for Fiat-to-Crypto transactions. </p>
                                    <p className="steps-orange-without-margin">- Transak allows Users to compliantly (KYC) on-ramp Fiat to Cryptocurrency for use in Solis’ Marketplace.</p>
                                    <p className="steps-orange-without-margin">- Transak is available across 160 cryptocurrencies on 75+ blockchains via cards, bank transfers and other payment methods in 150 countries.
                                    </p>
                                </div>

                            </div>

                            <div className="steps-container-div">
                                <div className="steps-text-orange">
                                    <p className="steps-orange"><strong className='orange-strong' >Step 5</strong> – Transak (secure) will initiate your payment process.</p>
                                    <p className='steps-orange'>Transak will calculate and display the total cost of your Purchase, based on:</p>
                                    <div className='text-image-left-container'>
                                        <div className='text-flex-column-container'>
                                            <div>
                                                <p className="steps-orange-without-margin">- Your country of residence (KYC)</p>
                                                <p className="steps-orange-without-margin">- The applicable currency</p>
                                                <p className="steps-orange-without-margin">- Method of payment</p>
                                                <p className="steps-orange-without-margin">- Spot price for the cryptocurrency</p>
                                            </div>
                                            <div>
                                                <p className="steps-orange-italic">(If this is your first time using Transak, you will
                                                    need to go through a short KYC process – you
                                                    only need to complete this once, then you are
                                                    verified across the Transak network).</p>
                                                <br />
                                                <p className="steps-orange-without-margin">Details on the Transak secure KYC process can
                                                    been found here:</p>
                                                <a href='https://support.transak.com/hc/en-us/articles/360020474317-KYC-on-Transak' className='steps-orange-italic' >https://support.transak.com/hc/en-us/articles/360020474317-KYC-on-Transak</a>
                                            </div>
                                        </div>
                                        <div>
                                            <img src={img5} className="steps-images" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="steps-container-div">
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Step 6</strong> – Select your preferred <strong className='orange-strong'>Payment Method</strong> and input your relevant details. </p>
                                    <div className='text-image-right-container'>
                                        <div>
                                            <img src={img6} className="steps-images" />
                                        </div>
                                        <div className='align-bottom-container'>
                                            <p className="steps-orange"><strong className='orange-strong'>Step 7 -</strong></p>
                                            <p className="steps-orange">Click <strong className='orange-strong'>"Accept Terms of Service"</strong> Box </p>
                                            <p className="steps-orange">Click<strong className='orange-strong'>"Confirm"</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="steps-container-div">
                                <div className='buy-options-heading-white'>SUCCESS !  You have added crypto funds to your wallet</div>
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Step 8</strong> - Click on <strong className='orange-strong'>"Profile"</strong> or navigate to your Profile page to view the NFT in your Collections. </p>
                                    <br />
                                    <p className="steps-orange-without-margin">- Please allow up to 2 minutes for your funds to appear in your balance</p>
                                    <p className="steps-orange-without-margin">- Please refresh your screen (if required) after this time, to see your updated balance</p>
                                    <p className="steps-orange-without-margin">- You can toggle between your cyptocurrencies (drop-down) menu to see your balances</p>
                                    <img src={img7} className='steps-images' />
                                </div>
                            </div>

                            <div className="steps-container-div">
                                <div className='buy-options-heading-white'>Who else uses Transak?</div>
                                <div className="steps-text-orange">
                                    <p className="steps-orange">Just like SOLIS, the following companies trust Transak: </p>
                                    <img src={img8} className="steps-images" />
                                </div>
                            </div>

                            <div className="steps-container-div">
                                <div className='buy-options-heading-white'>What payment options do I have?</div>
                                <div className="steps-text-orange">
                                    <p className="steps-orange">SOLIS offers the following Fiat payment options to Transak <strong className='orange-strong' >verified</strong> clients: </p>
                                    <img src={img9} className="steps-images" />
                                </div>
                            </div>

                            <div className="steps-container-div">
                                <div className='steps-text-orange'>
                                    <p className='steps-orange'><strong className='orange-strong'>Note (Common Error)</strong> – If you selected the incorrect currency or payment method relevant
                                        to your KYC profile, you will get the following error. Please go back and correct your payment
                                        options to complete your purchase </p>
                                    <img src={img10} className='steps-images' />
                                </div>
                            </div>

                            <div className="steps-container-div">
                                <div className='buy-options-heading-white'>Who is Transak?</div>
                                <div className='steps-text-orange'>
                                    <p className="steps-orange">Transak is authorised, regulated and secure.</p> <br />
                                    <p className="steps-orange-without-margin">Transak is the trading name of Light Technology Limited (a company registered in England
                                        & Wales, Company Number: 11539646) and is a registered crypto asset firm with the UK
                                        Financial Conduct Authority (FRN: 928910) under the Money Laundering, Terrorist
                                        Financing and Transfer of Funds (Information on the Payer) Regulations 2017 (as amended)
                                        in respect of its activities in crypto assets.</p> <br />
                                    <p className="steps-orange-without-margin">
                                        Transak USA LLC is a Delaware limited liability Company with file number 6482877, trading
                                        under the name “Transak”. Transak is a registered Money Services Business (“MSB”) with
                                        the US Treasury’s Financial Crimes Enforcement Network (“FinCEN”) under MSB
                                        Registration number: 31000215880221.
                                    </p>
                                    <br />
                                    <p className="steps-orange-without-margin">For more information on Transak, please visit –</p>
                                    <br />
                                    <a className="steps-orange-without-margin" href="https://transak.com/privacy-policy" >Privacy policy: https://transak.com/privacy-policy</a>
                                    <br />
                                    <a className="steps-orange-without-margin" href="https://support.transak.com/hc/en-us">Support: https://support.transak.com/hc/en-us</a>
                                </div>
                            </div>

                            <div className="steps-container-div">
                                <div className="steps-text-orange">
                                    <div className='center-text-div-kyc'>
                                        <div>
                                            <p className="steps-orange-without-margin">“The Sun doesn’t discriminate </p>
                                            <p className="steps-orange-without-margin">who it shines its light upon.”</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={solisLogo} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default AddingFunds