import React from 'react'
import { useStyles } from "./payment-options.style";
import CancelIcon from '@mui/icons-material/Cancel';
import axiosInstance from '../../services/api';
import getAPIHostName from '../../utils/utils';

export const initiateMoonpaySdk = async (walletAddress, currency) => {
    const moonpaySdk = window.MoonPayWebSdk.init({
        debugging: true,
        flow: 'sell',
        environment: 'sandbox',
        variant: 'overlay',
        params: {
            apiKey: process.env.REACT_APP_MOONPAY_API_KEY,
            walletAddress: walletAddress,
            currencyCode: currency
        },

    });
    const urlForSignature = moonpaySdk.generateUrlForSigning();

    const response = await axiosInstance.post(getAPIHostName() + "/api/moonpay/signature/offramp", JSON.stringify(urlForSignature))
    let url = new URL(response.data.signedUrl)
    let signature = url.searchParams.get('signature');

    moonpaySdk.updateSignature(signature);

    moonpaySdk.show();
}

