import { useStyles } from "../../Pages/Profile/profile.style"
import userImage from "../../assets/images/defaultProfile.png";
import pencil from '../../assets/images/pencil.png'
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
const ProfilePictureComponent = () => {
    const history = useHistory()
    const user = useSelector((state) => state.user);
    const [isImageLoading, setIsImageLoading] = useState(true)
    const { classes } = useStyles()
    return (
        <div className={classes.profilePictureContainer}>
            <div style={{ position: 'relative', maxWidth: '200px', maxHeight: '200px' }} className={classes.profilePictureDiv} >
                {user.profilePicture && user.profilePicture !== "#" ? (
                    <>
                        <img
                            src={user.preSignedProfileUrl}
                            alt="user img"
                            className={classes.profilePicture}
                            style={{ display: isImageLoading ? "none" : "block" }}
                            onLoad={(e) => { setIsImageLoading(false) }}
                        />
                        <div style={{ margin: 'auto', height: '200px', width: '200px', display: isImageLoading ? "block" : "none" }}>
                            <CircularProgress style={{ color: '#f67622', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', position: 'relative', display: isImageLoading ? "block" : "none" }} />
                        </div>
                    </>

                ) : (
                    <>
                        <img
                            src={userImage}
                            alt="user image"
                            className={classes.profilePicture}
                        >

                        </img>
                    </>
                )}
                <img src={pencil} alt="edit button" className={classes.editButton} onClick={() => history.push("/edit")} />
            </div>

        </div>
    )
}

export default ProfilePictureComponent