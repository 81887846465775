import React from 'react'
import bgVideo from "../../assets/images/bg-video.mp4"
import '../../LicenseAgreement.css'
const LicenseAgreement = () => {

    return (
        <>
            <div className='main-content-wrapper'>
                <div className="bg-video">
                    <div className="embed-container ratio ratio-16x9">
                        <video className="embed-video" loop autoPlay muted>
                            <source src={bgVideo} type="video/mp4" />
                        </video>
                    </div>
                </div>
                <main className="main-content-wrapper inner-line-bg">
                    <div className="container">
                        <div className="card-content wow fadeInUp" data-wow-delay="0.4s">
                            <div className="row align-items-center">
                                <div className="col-md-8 order-2 order-md-1 wow fadeInUp" data-wow-delay="0.4s">
                                    <div className="content-dec">
                                        <h2><span>SOLIS.Market License Agreement</span></h2>
                                        <p>
                                            This SOLIS.Market License Agreement (this “Agreement”) is a legally binding agreement
                                            between
                                            you and SOLIS LABS INC (“SOLIS”) that describes the rights in Intellectual Property (as
                                            such term is
                                            defined below) you may obtain when you either purchase or receive any SOLIS.Market NFT
                                            (as such
                                            term is defined below). For clarity, this Agreement does not otherwise govern the
                                            transaction that is
                                            effectuated on the Ethereum or Polygon blockchain (or any other blockchains) when you
                                            buy or
                                            offer to buy any SOLIS.Market NFT, including through any related decentralized
                                            technologies,
                                            websites, services, tools, applications, smart contracts, and APIs, which are provided
                                            by third party
                                            vendors (including but not limited to ConsenSys Software Inc. d/b/a Metamask and Matic
                                            Network
                                            BVI LTD d/b/a Polygon Technology or other) and governed by and subject to the terms of
                                            use
                                            provided by those third party vendors, unless such third party terms of use conflict or
                                            are
                                            inconsistent with the terms of this Agreement, in which case the terms of this Agreement
                                            shall
                                            prevail. Subject to the foregoing, this Agreement supplements the Terms and Conditions
                                            and privacy
                                            policy that otherwise govern your use of SOLIS.Market’s website and application, but for
                                            clarity,
                                            these terms do not apply to any other product or service purchased from a SOLIS.Market
                                            website or
                                            application besides SOLIS.Market NFTs.
                                        </p>
                                        <span className="underlined_heading__disclaimer"><b>1. Definitions.</b></span>
                                        <br></br>

                                        <p>“Intellectual Property” means the original works that was minted as a SOLIS.Market NFT.
                                        </p>

                                        <p>
                                            &quot; SOLIS.Market NFT&quot; means a unique Ethereum (“ETH”) blockchain-tracked,
                                            non-fungible token that
                                            serves as a digital collectible and was originally offered for sale by SOLIS.Market.
                                        </p>


                                        <p>
                                            “Own” or “Owned” means, with respect to a SOLIS.Market NFT, one that (a) you originally
                                            purchased
                                            from SOLIS.Market; or (b) purchased on n Approved Marketplace from a legitimate owner of
                                            that
                                            SOLIS.Market NFT.
                                        </p>


                                        <p>
                                            “Third Party IP” means any third-party patent rights (including, without limitation,
                                            patent
                                            applications and disclosures), copyrights, trade secrets, trademarks, know-how or any
                                            other
                                            intellectual property rights recognized in any country or jurisdiction in the world.
                                        </p>

                                        <p>
                                            Information we collect automatically: When you interact with our Services, we (and our
                                            partners,
                                            advertisers, advertising networks and other third party service providers) automatically
                                            collect
                                            certain information. For example, we collect information about the webpages you view and
                                            how you
                                            move through our Services, how you reached our Services, how you interact with our
                                            social media
                                            pages, and how you interact with our email communications.
                                        </p>

                                        <p>“Approved Marketplace” means a marketplace that is officially endorsed and approved as a
                                            partner
                                            of SOLIS.Market by SOLIS.
                                        </p>






                                        <u className="underlined_heading__disclaimer">2. License</u>


                                        <p>

                                            Subject to your compliance with the terms of this Agreement, SOLIS hereby grants you a
                                            worldwide,
                                            non-exclusive, personal, limited license, solely with respect to any SOLIS.Market NFT(s)
                                            that you
                                            Own, to display the Intellectual Property associated with such SOLIS.Market NFT(s),
                                            privately or
                                            publicly, solely for personal, non-commercial purposes, including on social media
                                            platforms, digital
                                            galleries, or otherwise on the Internet or in association with your offer to sell or
                                            trade your
                                            SOLIS.Market NFT(s). This license does not grant you any rights in or to the
                                            Intellectual Property
                                            separate from the associated SOLIS.Market NFT(s), including any of the copyrights
                                            described in
                                            Section 4 below. </p>


                                        <u className="underlined_heading__disclaimer"> <b>3. Restrictions.</b></u>


                                        <p>
                                            You agree that you may not, and will not permit any third party to, do or attempt to do
                                            any of the
                                            following without SOLIS’s express prior written consent in each case: (i) modify the
                                            Intellectual
                                            Property associated with your SOLIS.Market NFT(s) in any way; (ii) use the Intellectual
                                            Property to
                                            advertise, market, or sell any product or service; (iii) use the Intellectual Property
                                            in connection with
                                            malicious, harmful, offensive or obscene images, videos, or other materials or forms of
                                            media,
                                            including any that depict hatred, intolerance, violence, cruelty, or anything else that
                                            could
                                            reasonably be found to constitute hate speech or otherwise violate applicable laws or
                                            regulations or
                                            infringe upon the rights of others; (iv) use or incorporate the Intellectual Property in
                                            movies, videos,
                                            video games, or any other forms of media for a commercial purpose; (v) sell, distribute
                                            for
                                            commercial gain, or otherwise commercialize merchandise that includes, contains, or
                                            consists of the
                                            Intellectual Property; (vi) trademark, copyright, or seek to trademark, copyright, or
                                            otherwise
                                            acquire additional intellectual property rights in or to the Intellectual Property,
                                            including any SOLIS
                                            name, trademark, logo, trade dress (including the red border design), or other source
                                            indicators
                                            contained or depicted therein; (vii) attempt to mint, tokenize, or create an additional
                                            cryptographic
                                            token representing the Intellectual Property on any platform; (viii) falsify,
                                            misrepresent, or conceal
                                            the authorship of the Intellectual Property or the SOLIS.Market NFT; or (ix) otherwise
                                            commercially
                                            use or exploit any Intellectual Property for your or any third party’s benefit,
                                            including by selling
                                            copies of any Intellectual Property or selling derivative works embodying any
                                            Intellectual Property.
                                        </p>

                                        <u className="underlined_heading__disclaimer"><b>4. Ownership.</b></u>
                                        <p>
                                            You acknowledge and agree that SOLIS (or, as applicable, its licensors) owns all legal
                                            right, title and
                                            interest in and to the Intellectual Property, and all intellectual property rights
                                            therein. The rights that
                                            you have in and to the Intellectual Property are limited to those expressly described in
                                            Section 2 of
                                            this Agreement. SOLIS (on behalf of itself and, as applicable, its licensors) reserves
                                            all other rights in
                                            and to the Intellectual Property, including all copyrights in and to the Intellectual
                                            Property (e.g., the
                                            right to reproduce and make copies, to prepare derivate works, to distribute, sell, or
                                            transfer, to
                                            display, to perform, and to publicly display and publicly perform).

                                        </p>

                                        <u className="underlined_heading__disclaimer"><b>5. License Term.</b></u>
                                        <p>The license granted in Section 2 applies only to the extent that you continue to Own the
                                            applicable
                                            SOLIS.Market NFT. If at any time you sell, trade, donate, give away, or transfer your
                                            SOLIS.Market
                                            NFT to a new Owner on SOLIS.Market or through an Approved Marketplace, the license
                                            granted in
                                            Section 2 shall be transferred to that new Owner, and you will have no further rights in
                                            or to the
                                            SOLIS.Market NFT or Intellectual Property associated with that SOLIS.Market NFT. If at
                                            any time you
                                            burn or otherwise dispose of your SOLIS.Market NFT for any reason, or sell, trade,
                                            donate, give
                                            away, or transfer your SOLIS.Market NFT other than on SOLIS.Market or through an
                                            Approved
                                            Marketplace, the license granted in Section 2 will immediately expire with respect to
                                            that
                                            SOLIS.Market NFT without the requirement of notice or any further action, and you will
                                            have no
                                            further rights in or to the SOLIS.Market NFT or Intellectual Property associated with
                                            that
                                            SOLIS.Market NFT.
                                        </p>


                                        <u className="underlined_heading__disclaimer"><b>6. Indemnification.
                                        </b></u>
                                        <p>
                                            You shall indemnify, defend (at SOLIS’s request) and hold harmless SOLIS, its affiliates
                                            and licensors,
                                            and its and their respective officers, agents, directors, representatives, contractors,
                                            and employees,
                                            from and against any and all claims, suits, demands, actions, losses, liabilities,
                                            damages, judgements,
                                            penalties, fines, expenses and other costs (including reasonable attorneys’ fees)
                                            arising from your
                                            breach or alleged breach of this Agreement. SOLIS will also have the right to provide
                                            our own
                                            defense additionally or alternatively at our own expense.
                                        </p>

                                        <u className="underlined_heading__disclaimer"><b>7. Limitation of Liability.
                                        </b></u>
                                        <p>
                                            In no event will SOLIS be liable to you for any special, incidental, exemplary,
                                            indirect, punitive, or
                                            consequential damages (including loss of profits) with respect to the subject matter of
                                            this
                                            Agreement, whether such liability arises from any claim based upon contract, warranty,
                                            tort
                                            (including negligence), strict liability or otherwise, and whether or not you have been
                                            advised of the
                                            possibility of such loss or damage. SOLIS’s aggregate liability under this Agreement
                                            shall not exceed
                                            the net revenues actually received by SOLIS in connection with any transaction in which
                                            You
                                            purchased or sold your SOLIS.Market NFT. The foregoing limitation of liability shall
                                            only apply to the
                                            extent permitted by applicable law. In no event will SOLIS be liable for any inability
                                            for you to access
                                            the Intellectual Property for any reason, including as a result of any downtime,
                                            failure, obsolescence,
                                            removal, termination or other disruption relating to (a) the servers upon which the
                                            Intellectual
                                            Property is stored; (b) the Solis.Market platform; or (c) any other NFT platform.
                                        </p>

                                        <u className="underlined_heading__disclaimer"><b>8. Assignment.
                                        </b></u>
                                        <p>SOLIS will have the unrestricted right to assign this Agreement and to assign,
                                            subcontract, license
                                            and sublicense any or all of its rights and obligations hereunder. This Agreement
                                            (including, without
                                            limitation, the license granted hereunder) is personal to you and shall not be assigned
                                            or transferred
                                            by you, except to a new Owner of the SOLIS.Market NFT as set forth in Section 5 above.
                                            Any other
                                            attempt by you to assign, sub-license, or transfer your rights under this Agreement
                                            shall be null and
                                            void.
                                        </p>
                                        <u className="underlined_heading__disclaimer"><b>9. Dispute Resolution.</b>
                                        </u>
                                        <p>
                                            This Agreement will be governed by, and construed and interpreted in accordance with,
                                            the laws of
                                            the British Virgin Islands, without regard to its conflicts-of-law principles. Any
                                            dispute arising out of
                                            or relating to this Agreement may be brought and adjudicated only in the courts of the
                                            British Virgin
                                            Islands, and SOLIS and you submit to the exclusive jurisdiction of such courts and waive
                                            any
                                            objections based upon improper venue or inconvenient forum. Neither SOLIS nor you will
                                            seek to
                                            litigate any claims against the other on a className action or representative party basis
                                            and shall pursue
                                            any claims solely on an individual basis.</p>



                                        <u className="underlined_heading__disclaimer"><b>10. Remedies.
                                        </b></u>
                                        <p>
                                            Your rights and remedies in the event of any breach of this Agreement are strictly
                                            limited to the
                                            right, if any, to recover damages in an action at law, and you acknowledge that your
                                            remedy of
                                            money damages is adequate. You will not be entitled by reason of any such breach, and
                                            you will not
                                            seek, any equitable relief, whether injunctive or otherwise.
                                        </p>
                                        <u className="underlined_heading__disclaimer"><b>11. Miscellaneous Terms.
                                        </b></u>

                                        <p>This Agreement constitutes the complete understanding and agreement of you and TIME with
                                            respect to the Intellectual Property and supersedes any and all prior or contemporaneous
                                            written or
                                            oral agreements between you and SOLIS with respect to all Intellectual Property. Prior
                                            agreements
                                            between SOLIS and you relating to any Intellectual Property will continue to govern the
                                            prior
                                            Intellectual Property. The language of any clause or term of this Agreement will not be
                                            construed for
                                            or against the drafter. No right or term of this Agreement will be deemed waived, and no
                                            breach of
                                            this Agreement excused, unless the waiver or consent is in writing and signed by you and
                                            SOLIS. Any
                                            modification or amendment to this Agreement must be made in writing and signed by you
                                            and
                                            SOLIS.
                                        </p>
                                        <br /> <br />
                                    </div>
                                </div >
                            </div >
                        </div >
                    </div >
                </main >
            </div>

        </>
    )
}

export default LicenseAgreement