import React from 'react'

interface heading_prop {
    heading: String
}

const HeadingComponent = (props: heading_prop) => {
    return (
        <>
            <div className="main-title" style={{ marginBottom: '0px' }}>
                <h3 className="gradient-heading"><span>{props.heading}</span></h3>
            </div>
        </>
    )
}

export default HeadingComponent
