import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { getErc20TokenContractArtifacts, GasTxnType } from "../web3"
import { useSigner, useAccount, useNetwork} from "wagmi";
import { getBiconomyInstance, isTransactionGasless } from "../web3";
import { waitForEvent, sendRequestBiconomy, getSignatureParametersEthers} from "../../utils/biconomy";
//import { sleep } from "react-query/types/core/utils";

let abi = require("ethereumjs-abi");
type LoadingState = {
  text?: string
}
export const useErc20TokenContract = (tokenTicker:string, walletName: string) => {
  const [contract, setContract] = useState<any>();
  const [biconomy, setBiconomy] = useState<any>();
  const { data: signer } = useSigner();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const erc20TokenArtifacts = getErc20TokenContractArtifacts(tokenTicker);
  const [loadingState, setLoadingState] = useState<LoadingState>(null)

  
  const domainType = [{
    name: "name",
    type: "string"
  }, {
    name: "version",
    type: "string"
  }, {
    name: "verifyingContract",
    type: "address"
  }, {
    name: "salt",
    type: "bytes32"
  }];
  const metaTransactionType = [{ name: "nonce", type: "uint256" },{ name: "from", type: "address" },{ name: "functionSignature", type: "bytes" }];
  
  useEffect(() => {
    const initBiconomy = async () => {
      setLoadingState({})
try {
  const biconomy = getBiconomyInstance(signer);
  await biconomy.init();
  const contractObj = new ethers.Contract(
    erc20TokenArtifacts.address,
    erc20TokenArtifacts.abi,
    biconomy.ethersProvider
  );
  setContract(contractObj);
  setBiconomy(biconomy);
} catch (error) {
  setLoadingState(null)
}
      setLoadingState(null)
    };
    if (signer?.provider) initBiconomy();
  }, [signer?.provider, tokenTicker]);

  const erc20TokenApproval = async (approvalForAddress:any, tokenPrice: any) => {
    try{
      //Gasless is only supported for polygon network with stable coins on 6 decimals
      setLoadingState({
        text: 'Waiting for approval'
      })
      const priceIn6Decimals = tokenPrice * Math.pow(10, 6);
      
      let transactionHash;
      if(isTransactionGasless(GasTxnType.ERC20)) {
        transactionHash = await performGaslessTransactionApproval(priceIn6Decimals, approvalForAddress)
      } else {
        transactionHash = await performNormalTransactionApproval(priceIn6Decimals, approvalForAddress);
      }
      
      console.log("transacitonHash", transactionHash);
      return transactionHash;

    } catch(error:any) {
      console.log("Error in erc20TokenApproval:"+ error.message);
      throw error;
    }
    finally{
      setLoadingState(null)
    }
  };

  const performNormalTransactionApproval = async(priceIn6Decimals: number, approvalForAddress: string) => {

    let { data } = await contract.populateTransaction.approve(approvalForAddress, priceIn6Decimals);  
    
    let txParams = {
      data: data,
      to: erc20TokenArtifacts.address,
      from: address,
      signatureType: "EIP712_SIGN",
    };

    try {
      const transaction = await biconomy.provider.send("eth_sendTransaction", [txParams]);
      const finalTransaction = walletName === 'magic' ? transaction : transaction.result;
      const receipt = await contract.provider.waitForTransaction(finalTransaction, 10);
      console.log("transaction", receipt); 
  
      return receipt;
    } catch (error) {
      throw error
    }
  };

  const performGaslessTransactionApproval = async(priceIn6Decimals: number, approvalForAddress: string) => {
   
   const nonce  = tokenTicker === "USDC" ? await contract.nonces(address) : await contract.getNonce(address);
  const apiId = tokenTicker === "USDC" ? "7e3b1150-1e74-4c4d-a96f-bfe0a6912732" : "2fd0cf4a-9014-4f24-b696-83df517505d3"
    const contractInterface = new ethers.utils.Interface(erc20TokenArtifacts.abi);
    let functionSignature = contractInterface.encodeFunctionData("approve", [approvalForAddress, priceIn6Decimals]);

    let messageToSign = constructMetaTransactionMessage(
      nonce,
      Number(chain?.network!), // salt
      functionSignature,
      erc20TokenArtifacts.address
    );

    const signature = await biconomy.provider.send("eth_signTypedData_v4", [address, messageToSign]);
    
    const finalSignature = walletName === 'magic' ? signature : signature.result;
    let { r, s, v } = getSignatureParametersEthers(finalSignature);
    let { data } = await contract.populateTransaction.executeMetaTransaction(
          address, functionSignature, r, s, v );

    let txParams = {
      data: data,
      to: erc20TokenArtifacts.address,
      from: address,
      signatureType: "eth_signTypedData_v4",
    };

    const metaTxBody = {
      to: erc20TokenArtifacts.address,
      userAddress: address,
      apiId,
      params: [address , functionSignature, r, s, v],
    }

    //const tx = await biconomy.provider.send("eth_sendTransaction", [txParams]);
    const tx:any = await sendRequestBiconomy(metaTxBody);
    if(tx.data.code!==200) {
      throw Error(tx.data.message)
    }

    //Wait for confirmations
    const receipt = await contract.provider.waitForTransaction(tx.data.txHash) 
    return receipt.transactionHash;
  }

  const erc20TokenTransfer = async (fromAddress:string, toAddress:string, price: number) => {
    try{
      //Gasless is only supported for polygon network with stable coins on 6 decimals
      const priceIn6Decimals = price * Math.pow(10, 6);

      setLoadingState({
        text: 'Waiting for fund transfer'
      })
      let transactionHash;
      if(isTransactionGasless(GasTxnType.ERC20)) {
        transactionHash = await performGaslessTransactionTransfer(fromAddress, toAddress, priceIn6Decimals)
      } else {
        transactionHash = await performNormalTransactionTransfer(fromAddress, toAddress, priceIn6Decimals);
      }
      
      console.log("transacitonHash", transactionHash);
      return transactionHash;

    } catch(error:any) {
      console.log("Error in erc20TokenTransfer:"+ error.message);
      throw error;
    }
    finally{
      setLoadingState(null)
    }
  };

  const performNormalTransactionTransfer = async(fromAddress: string, toAddress: string, priceIn6Decimals: number) => {

    let { data } = await contract.populateTransaction.transfer( toAddress, priceIn6Decimals);  
    
    let txParams = {
      data: data,
      to: erc20TokenArtifacts.address,
      from: address,
      signatureType: "EIP712_SIGN",
    };

    try {
      const transaction = await biconomy.provider.send("eth_sendTransaction", [txParams]);
      const finalTransaction = walletName === 'magic' ? transaction : transaction.result;
      const receipt = await contract.provider.waitForTransaction(finalTransaction, 10);
      console.log("transaction", receipt); 
  
      return receipt.transactionHash;
    } catch (error) {
      throw error
    }
  };

  const performGaslessTransactionTransfer = async(fromAddress: string, toAddress: string, priceIn6Decimals: number) => {
   
    const nonce  = tokenTicker === "USDC" ? await contract.nonces(address) : await contract.getNonce(address);
    const apiId = tokenTicker === "USDC" ? "7e3b1150-1e74-4c4d-a96f-bfe0a6912732" : "2fd0cf4a-9014-4f24-b696-83df517505d3"
    const contractInterface = new ethers.utils.Interface(erc20TokenArtifacts.abi);
    let functionSignature = contractInterface.encodeFunctionData("transfer", [ toAddress, priceIn6Decimals]);

    let messageToSign = constructMetaTransactionMessage(
      nonce,
      Number(chain?.network!), // salt
      functionSignature,
      erc20TokenArtifacts.address
    );

    const signature = await biconomy.provider.send("eth_signTypedData_v4", [address, messageToSign]);
    
    const finalSignature = walletName === 'magic' ? signature : signature.result;
    let { r, s, v } = getSignatureParametersEthers(finalSignature);
    let { data } = await contract.populateTransaction.executeMetaTransaction(
          address, functionSignature, r, s, v );

    let txParams = {
      data: data,
      to: erc20TokenArtifacts.address,
      from: address,
      signatureType: "eth_signTypedData_v4",
    };

    const metaTxBody = {
      to: erc20TokenArtifacts.address,
      userAddress: address,
      apiId,
      params: [address , functionSignature, r, s, v],
    }

    //const tx = await biconomy.provider.send("eth_sendTransaction", [txParams]);
    const tx:any = await sendRequestBiconomy(metaTxBody);
    if(tx.data.code!==200) {
      throw Error(tx.data.message)
    }

    //Wait for confirmations
    const receipt = await contract.provider.waitForTransaction(tx.data.txHash) 
    return receipt.transactionHash;
  }

  const constructMetaTransactionMessage = (
    nonce: any,
    chainId: any,
    functionSignature: any,
    contractAddress: any
  ) => {
    const usdcDomainData = {
      name: "USD Coin (PoS)",
      version: "1",
      verifyingContract: contractAddress,
      salt: '0x' + (137).toString(16).padStart(64, '0')
    };

    const usdtDomainData = {
      name: "(PoS) Tether USD",
      version: "1",
      verifyingContract: contractAddress,
      salt: '0x' + (137).toString(16).padStart(64, '0')
    };
    
    const domainData = tokenTicker === "USDC" ? usdcDomainData : usdtDomainData;
    let message = {
        nonce : parseInt(nonce),
        from : address,
        functionSignature
    }

    const dataToSign = JSON.stringify({
      types: {
        EIP712Domain: domainType,
        MetaTransaction: metaTransactionType
      },
      domain: domainData,
      primaryType: "MetaTransaction",
      message: message
    });

    return dataToSign;
  };

  return {erc20TokenApproval, erc20TokenTransfer,loadingState};
};



