import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useStyles } from "./kyc.style";
import { useHistory } from "react-router-dom";
import blockpassBtnImg from "../../assets/images/blockpassBTN.png";
import kyc1Img from "../../assets/images/kyc1.png";
import kyc2Img from "../../assets/images/kyc2.png";
import kyc3Img from "../../assets/images/kyc3.png";
import kyc4Img from "../../assets/images/kyc4.png";
import kyc5Img from "../../assets/images/kyc5.png";
import kyc6Img from "../../assets/images/kyc6.png";
import kyc7Img from "../../assets/images/kyc7.png";
import kyc8Img from "../../assets/images/kyc8.png";
import Loader from "../../Components/Loader/Loader";
const Kyc = () => {
const [isDomLoaded, setisDomLoaded] = useState(true);
const [isLoaderActive, setIsLoaderActive] = useState(true)
const dispatch = useDispatch()
const { classes } = useStyles();







return (
<>
  <main className="main-content-wrapper">
    <section className="section-block main-banner">
      <div className="container">
        <div className="row">
          <div className="col-sm-4 col-md-3 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <div className={classes.sideMenuNav}>
              <ul className={classes.sideMenuList}>
                <li><a href="#s1">Who uses KYC?</a></li>
                <li><a href="#s2">Let's get you KYC'd!</a></li>
                <li><a href="#s3">Do you want to Invest? Input your Address!</a></li>
                <li><a href="#s4">Was my KYC successful?</a></li>
                <li><a href="#s5">Who is Blockpass</a></li>


              </ul>
            </div>
          </div>
          <div className="col-sm-8 col-md-9 col-lg-9 wow fadeInUp" data-wow-delay="0.2s">
            <div className={classes.mainContentArea}>
              <h1 id="gettingStarted">KYC Guide</h1>
              <br />

              <span id="s1" style={{width:1, height:1, display: 'block', position: "relative", bottom: 100}}></span>
              <div className="row">
                <h4>WHO USES KYC?</h4>
                <p>SOLIS’ digital asset marketplace joins the growing list of tier 1 blockchain and
                  cryptocurrency companies providing a safe and regulatory compliant environment for
                  its clients to participate and its users to enjoy. </p>

                <li>Among those that provide the benefits of KYC include:</li>
                <ul className={classes.noStyleList}>

                  <li>Cryptocurrency Exchanges
                    <ul>
                      <li>Binance, Coinbase, Crypto.com..</li>
                    </ul>
                  </li>

                  <li>Tier 1 blockchain companies -
                    <ul>
                      <li>EMURGO (the commercial arm of Cardano), StrongNode..</li>
                    </ul>
                  </li>

                  <li>Companies launching ICO’s (Initial Coin Offerings)
                  </li>

                  <li>Launchpads
                    <ul>
                      <li>Seedify, Trustswap, FireStarter..</li>
                    </ul>
                  </li>

                  <li>Tier 1 gaming, entertainment companies -
                    <ul>
                      <li>Animoca Brands, Yuga Labs, H3RO3S..</li>
                    </ul>
                  </li>

                  <li>NFT projects -
                    <ul>
                      <li>Bored Ape Yatch Club (BAYC)..</li>
                    </ul>
                  </li>

                  <li id="s2">Decentralized finance -
                    <ul>
                      <li>OpenDeFi, Stratosphere DeFi..</li>
                    </ul>
                  </li>

                  <li>Metaverses
                    <ul>
                      <li>Sandbox, OVR..</li>
                    </ul>
                  </li>

                </ul>
              </div>
              <br />

              <div className="row">
                <h4>LET'S GET YOU KYC'D!</h4>
                <p>SOLIS’ digital asset marketplace joins the growing list of tier 1 blockchain and
                  cryptocurrency companies providing a safe and regulatory compliant environment for
                  its clients to participate and its users to enjoy. </p>

                <ul className={classes.noStyleList}>

                  <li>Step 1<br />
                    Go to your Profile in SOLIS.market
                  </li>

                  <li>Step 2<br />
                    Click on the “Verify with Blockpass” button<br />
                    <img src={blockpassBtnImg} alt="block pass image example"
                      style={{height: "30px", width: "auto", marginLeft: "0px",}} />
                  </li>

                  <li>Step 3<br />
                    Click “Start”<br />
                    <img src={kyc1Img} alt="KYC example" style={{height: "350px", width: "auto", marginLeft: "0px",}} />
                  </li>

                  <li>Step 4<br />
                    After submitting your email address - Click “Continue”<br />
                    <span style={{fontSize: 12}}>(did you know if you already have a Blockpass Identity you can use it
                      in SOLIS?)</span><br />
                    <img src={kyc2Img} alt="KYC example" style={{height: "auto", width: "340px", marginLeft: "0px",}} />
                  </li>

                  <li>Step 5<br />
                    Blockpass will send you an email to your inbox<br />
                    <ul>
                      <li>Open the email and Click “Continue Registration”</li>
                      <li>If you did not receive your email (please check Junk), you can click “Resend Magic Link”
                        and you will receive a new email. </li>
                    </ul>
                    <span style={{fontSize: 12}}>(did you know that SOLIS uses Magic also?)</span><br />
                    <img src={kyc3Img} alt="KYC example" style={{height: "auto", width: "340px", marginLeft: "0px",}} />
                  </li>

                  <li>Step 6<br />
                    Click your preferred Primary Identification from the selection<br />
                    (make sure you have your physical ID ready to go)<br />
                    <img src={kyc4Img} alt="KYC example" style={{height: "auto", width: "340px", marginLeft: "0px",}} />
                  </li>

                  <li>Step 7<br />
                    Click the “Launch Camera” button to take a photo of your ID on both the front and
                    the back (if required). <br />
                    Make sure you fit the ID into the square box displayed, and then confirm.<br />
                    <span style={{fontSize: 12}}>(make sure you give your web browser access to use your laptop/desktop
                      camera)<br />
                      (if you prefer to use your mobile device camera, you can “email a link” to your mobile device
                      email browser
                      instead)</span><br />
                    <img src={kyc5Img} alt="KYC example" style={{height: "auto", width: "340px", marginLeft: "0px",}} />
                  </li>

                  <li>Step 8<br />
                    The interface will automatically populate your ID information (where available).<br />
                    Make sure you fit the ID into the square box displayed, and then confirm.<br />
                  </li>

                  <li>Step 9<br />
                    Go to the “Selfie” tab. Click on “Take a Picture” and take a picture of yourself<br />
                    <span style={{fontSize: 12}}>(make sure to position your head within the frame)</span><br />
                    <img src={kyc6Img} alt="KYC example" style={{height: "auto", width: "340px", marginLeft: "0px",}} />
                    <br /><br />
                  </li>

                </ul>
              </div>
              <span id="s3" style={{width:1, height:1, display: 'block', position: "relative", bottom: 100}}></span>
              <div className="row">
                <h4>DO YOU WANT TO INVEST? INPUT YOUR ADDRESS!</h4>

                <ul className={classes.noStyleList}>

                  <li>Step 10<br />
                    Go to the “Proof of Address” tab. (For SOLIS.Exchange Users)<br /><br />
                    Take a picture of a document that can prove your address, such as a utility bill, certificate of
                    residence or a bank statement (that has both your name and address clearly displayed).<br /><br />
                    Click “Launch Camera” to take an image of the document,<br />
                    or/<br />
                    Click “Upload a Photo” to upload the document from
                    your computer.<br />
                    <span style={{fontSize: 12}}>(make sure to the document is less than 3 months old)</span><br />
                    <img src={kyc7Img} alt="KYC example" style={{height: "auto", width: "340px", marginLeft: "0px",}} />
                  </li>

                  <li>Step 11<br />
                    Finally, Click on the “Register” button to submit all of your information<br /><br />
                    Your KYC process will be completed shortly and you can have full access to the
                    SOLIS ecosystem !<br />
                  </li>


                </ul>
              </div>
              <br />
              <span id="s4" style={{width:1, height:1, display: 'block', position: "relative", bottom: 100}}></span>
              <div className="row">
                <h4>WAS MY KYC SUCCESSFUL?</h4>
                <p>You will receive emails notifying you that your Profile (KYC) is in review, and another email</p>
                <p>
                  notifying you when your Application (KYC) has been Approved !</p>
                <p>
                  At anytime, you can also check your KYC status in your SOLIS Profile –
                  Your KYC Status will be displayed as either “Pending” or “Approved”</p>

                <img src={kyc8Img} alt="KYC example"
                  style={{height: "auto", width: "200px", marginLeft: "12px",marginBottom: "25px",border: '1px solid white'}} />
                <br />
              </div>

              <span id="s5" style={{width:1, height:1, display: 'block', position: "relative", bottom: 100}}></span>
              <div className="row">
                <h4>WHO IS BLOCKPASS</h4>
                <p>Blockpass, the pioneer of On-chain KYC(TM), is a fast, fully comprehensive KYC & AML
screening software-as-a-service for blockchains, Crypto, Defi and other regulated industries.
Through Blockpass, end-users easily create a verified portable identity that they can control
and re-use to onboard with any service instantly. </p>
                <p>
                By integrating with Chainlink Network - a decentralized oracle solution - Blockpass
introduced the first On-chain KYC(TM) solution that will service many blockchains in the
years to come. Additionally, Blockpass' partnership with Animoca Brands has
demonstrated how verification and adherence to standards can be proved on a blockchain
without revealing any underpinning data - a significant boon for verifying Animoca Brands'
NFT prize winners and a huge step towards securing the ecosystem of the Metaverse. The
Blockpass App is available from the App Store and Google Play</p>

<p>For more information on Blockpass, please visit – <br/>
Privacy policy <a href="https://www.blockpass.org/privacy/" style={{textDecoration: 'underline'}} target="_blank">https://www.blockpass.org/privacy/</a><br/>
Terms of service <a href="https://www.blockpass.org/terms/" style={{textDecoration: 'underline'}} target="_blank">https://www.blockpass.org/terms/</a>
</p>
                

          
              </div>





            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</>

);
};

export default Kyc;
