import { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useSelector } from 'react-redux';
import { useMessage } from '../../slices/message.slice'
import React from 'react';
import MuiAlert from "@mui/material/Alert";
import { useLocation } from 'react-router-dom';

const SnackBar = () => {
    const messageState = useSelector(state => state.message)
    const [open, setOpen] = useState(false);
    const [type, setType] = useState();
    const [message, setMessage] = useState();
    const [timestamp, setTimestamp] = useState();
    const location = useLocation();
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    useEffect(() => {
        if (messageState.type === "success" && location.pathname === "/profile") {
            setOpen(true)
            setType("success")
            setMessage(messageState.message)
            setTimestamp(messageState.timeStamp)
        }

        else if (messageState.type === "error" && location.pathname.includes("collection")) {
            setOpen(true)
            setType("error")
            setMessage(messageState.message)
            setTimestamp(messageState.timeStamp)
        }
        else if (messageState.type === "error" && location.pathname.includes("edit")) {
            setOpen(true)
            setType("error")
            setMessage(messageState.message)
            setTimestamp(messageState.timeStamp)
        }
        else if (messageState.type === "success" && location.pathname.includes("edit")) {
            setOpen(true)
            setType("success")
            setMessage(messageState.message)
            setTimestamp(messageState.timeStamp)
        }
        else if (messageState.type === "error" && location.pathname.includes("magic")) {
            setOpen(true)
            setType("error")
            setMessage(messageState.message)
            setTimestamp(messageState.timeStamp)
        }
        else if (location.pathname.includes("collectible") && messageState.type === "error") {
            setOpen(true)
            setType("error")
            setMessage(messageState.message)
            setTimestamp(messageState.timeStamp)
        }
        else if (location.pathname.includes("collectible") && messageState.type === "success") {
            setOpen(true)
            setType("success")
            setMessage(messageState.message)
            setTimestamp(messageState.timeStamp)
        }
        else if (location.pathname.includes("myNfts") && messageState.type === "error") {
            setOpen(true)
            setType("error")
            setMessage(messageState.message)
            setTimestamp(messageState.timeStamp)
        }
        else if (location.pathname.includes("myNfts") && messageState.type === "success") {
            setOpen(true)
            setType("success")
            setMessage(messageState.message)
            setTimestamp(messageState.timeStamp)
        }
        else if (messageState.type === "biconomy") {
            setOpen(true)
            setType("success")
            setMessage(messageState.message)
            setTimestamp(messageState.timeStamp)
        }
    }, [messageState, localStorage])

    // if ( messageState && (messageState.type === "success" || messageState.type === "error" || message.type === "biconomy") && (Date.now() - messageState.timeStamp) <= 1000) {
    //     return (
    //         <>
    //             <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}
    //                 sx={{
    //                     marginTop: '80px'
    //                 }}
    //                 anchorOrigin={{
    //                     vertical: "top",
    //                     horizontal: "right"
    //                 }}
    //             >
    //                 <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
    //                     {message}
    //                 </Alert>

    if (messageState && ((messageState.type === "success" || messageState.type === "error") && (Date.now() - messageState.timeStamp) <= 1000)) {
        return (
            <>
                <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}
                    sx={{
                        marginTop: '80px'
                    }}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                >
                    <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                        {message}
                    </Alert>

                </Snackbar>
            </>
        )
    }
    else return (
        <>

        </>
    )
}

export default SnackBar