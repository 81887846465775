import { createContext, useState } from "react";

const TransitionScreenContext = createContext(undefined)
const TransitionScreenDispatchContext = createContext(undefined)


function TransitionScreenProvider({ children }) {
    const [transitionScreenDetails, setTransitionScreenDetails] = useState({
        message: null,
        displayTime: null,
        finalPath: null
    });
    return (
        <TransitionScreenContext.Provider value={transitionScreenDetails}>
            <TransitionScreenDispatchContext.Provider value={setTransitionScreenDetails}>
                {children}
            </TransitionScreenDispatchContext.Provider>
        </TransitionScreenContext.Provider>
    );
}

export { TransitionScreenContext, TransitionScreenDispatchContext, TransitionScreenProvider }