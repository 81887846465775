
import axiosInstance from "../services/api"
import store from "../store"
const authState = store.getState().auth
export const collectionID = {
    "SOLIS MARKET": "074cadc4-6656-43b0-8a19-3ce0b0477a50",
    "SPOKEN WORLD": "b4b1a6a2-4d0d-436b-82d9-3cffa8a58ea7",
    "AFV COLLECTION": "8b0e1f90-3f5c-437b-b6c8-bc03101b7bc2",
    "SOLIS FLARES": "c5f60c30-3e05-46eb-8e5e-583d557bb970",
}

export const collectionIDs = async () => {
    if (authState.isLoggedIn) {
        const collections = {}
        const allCollections = await axiosInstance(process.env.REACT_APP_BASEURL + "/api/collections/all");
        allCollections.map((collection) => {
            collections.push({ name: collection.name, id: collection.id })
        })
        return collections
    }
}
