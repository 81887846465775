const key = process.env.REACT_APP_PINATA_KEY;
const secret = process.env.REACT_APP_PINATA_SECRET;
const axios = require('axios');

export const pinJSONToIPFS = async (JSONBody) => {
    const url = process.env.REACT_APP_PINATA_API_URL + "/pinning/pinJSONToIPFS";
    return axios
        .post(url, JSONBody, {
            headers: {
                pinata_api_key: key,
                pinata_secret_api_key: secret,
            }
        })
        .then(function (response) {
            return {
                success: true,
                pinataUrl: process.env.REACT_APP_PINATA_GATEWAY_URL + "/" + response.data.IpfsHash
            };
        })
        .catch(function (error) {
            ////console.log(error)
            return {
                success: false,
                message: error.message,
            }

        });
};

export const pinFileToIPFS = async (file) => {
    const url = process.env.REACT_APP_PINATA_API_URL + "/pinning/pinFileToIPFS";
    let data = new FormData();
    data.append('file', file)

    const res = await axios.post(url, data, {
        maxContentLength: 'Infinity',
        headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            pinata_api_key: key,
            pinata_secret_api_key: secret,
        }
    });

    return process.env.REACT_APP_PINATA_GATEWAY_URL + "/" + res.data.IpfsHash;
}
