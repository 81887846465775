import { useQuery } from "react-query";
import axiosInstance from "../../services/api";
import Loader from '../Loader/Loader';
import Card from "../Card/Card";
import CardStatic from "../Toggle/CardStatic"
import { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useHistory } from "react-router-dom";
const DefaultCollectionPage = () => {
    const [collectionName, setCollectionName] = useState()
    const history = useHistory()
    const route = window.location.pathname.split("/")
    const id = route[1]
    const categoryName = route[2].toUpperCase()
    const fetchCollections = async (id) => {
        const { data } = await axiosInstance.get(process.env.REACT_APP_BASEURL + `/api/collection/${id}`);
        setCollectionName(data.data && data.data.collectionName)
        // data = data.data && data.data.data.map((item) => {
        //     if (item.categoryName === categoryName) {
        //         return item.collectibleList
        //     }
        // })
        return data;
    }
    const { data, isError, isLoading } = useQuery(['collectionCategory', id], () => fetchCollections(id), { refetchOnMount: true, refetchOnReconnect: false, refetchOnWindowFocus: false, refetchInterval: 'infinity', cacheTime: 'infinity', staleTime: 'infinity' })

    if (isLoading) return <Loader />
    else
        return (
            <main className="main-content-wrapper">

                <section className="section-block arrow-left-right">
                    <div className="container">
                        <div className="link-div" onClick={() => history.goBack()}>
                            <KeyboardBackspaceIcon />
                            Back to {collectionName} collection
                        </div>

                        <div className="category-items memorabilia-slider">
                            <div className="default-collection-container">
                                {data.data &&
                                    data.data.data.map((item) => {
                                        if (item.categoryName === categoryName) {
                                            return item.collectibleList.map((collectible) => {
                                                return (
                                                    <>
                                                        <Card
                                                            id={collectible.id}
                                                            imageURL={collectible.media_location}
                                                            cardTitle={collectible.name}
                                                            cardArtistName={collectible.creater_id.first_name}
                                                            price={collectible.price}
                                                            timeRemaining={collectible.count}
                                                            description={collectible.description}
                                                            listingType={collectible.listing_type}
                                                            filesize={collectible.filesize}
                                                            duration={collectible.media_duration}
                                                            mediaType={collectible.media_type}
                                                            owner={`${collectible.owner_id.first_name}+" "+${collectible.owner_id.middle_name}+" "+${collectible.owner_id.last_name}`}
                                                            contractAddress={collectible.contract_address}
                                                            contractAbi={collectible.contract_abi}
                                                            tokenId={collectible.token_id}
                                                            currencyType={collectible.currency_type}
                                                            currency={collectible.currency}
                                                            poster={collectible.poster}
                                                            data={data}
                                                            thumbnailLocation={collectible.thumbnail_location}
                                                            collectionName={collectionName}
                                                        />

                                                    </>
                                                );
                                            })
                                        }

                                    })}

                            </div>
                        </div>
                        <div className="link-div" onClick={() => history.goBack()} style={{ textAlign: 'center', marginTop: '20px' }}>

                            Go back
                        </div>
                    </div>
                </section>
            </main>
        )
}

export default DefaultCollectionPage