import { TextField } from "@mui/material";
import { MenuItem } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
function SelectLabel({ selectorName, menuItems, label, parentCallback }) {

    const { classes } = useStyles();
    return (
        <div  >
            <TextField
                className={classes.root}
                variant="outlined"
                label={label}
                // defaultValue={selectorName}
                select
                InputProps={{ style: { fontSize: 14, fontFamily: 'Aldrich' } }}
                InputLabelProps={{ style: { fontSize: 14, fontFamily: 'Aldrich', padding: '0px' } }}
                onChange={(event) => parentCallback(event.target.value)}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {
                    menuItems.map((val) => {
                        return (
                            <MenuItem value={val.id}>{val.name}</MenuItem>
                        )
                    })
                }
            </TextField>
        </div >
    );
}

const useStyles = makeStyles()({
    root: {
        width: 240,
        "& .MuiOutlinedInput-input": {
            color: "#F67622"
        },
        "& .MuiInputLabel-root": {
            color: "#F67622",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F67622"
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#F67622"
        },
        "&:hover .MuiInputLabel-root": {
            color: "#F67622"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F67622"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#F67622"
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "#F67622"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F67622"
        }
    }
});
export default SelectLabel
