import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useStyles } from '../../Pages/Home/home.style';
const AFFECT_CHANGE = require("../../assets/collection/AFFECT_CHANGE/AFFECT_CHANGE_hp_image.mp4")
const CAUSE_PLAY_IRL = require("../../assets/collection/CAUSE_PLAY_IRL/CAUSE_PLAY_IRL_hp_image.png")
const FANTASY_FOOTBALL_CAUSE = require("../../assets/collection/FANTASY_FOOTBALL_CAUSE/FANTASY_FOOTBALL_CAUSE_hp_image.png")
const HER_VOICE = require("../../assets/collection/HER_VOICE/HER_VOICE_hp_image.png")
const SOLIS_CORE = require("../../assets/collection/SOLIS_CORE/SOLIS_CORE_hp_image.gif")
const SPOKEN_WORLD = require("../../assets/collection/SPOKEN_WORLD/SPOKEN_WORLD_hp_image.png")
const BUNNY = require("../../assets/collection/BUNNY/BUNNY_hp_image.mov")
const AFV_COLLECTION = require("../../assets/collection/AFV_COLLECTION/AFV_COLLECTION_hp_image.jpg")
const SAINT_JUDE = require("../../assets/collection/SAINT_JUDE/SAINT_JUDE_hp_image.png")
const ISEKAI_ANIME = require("../../assets/collection/ISEKAI_ANIME/ISEKAI_ANIME_hp_image.png")

const comingSoon = require("../../assets/images/comingSoonTestv2.gif")
// const SOLIS_FLARES = require("../../assets/collection/SOLIS_FLARES/SOLIS_FLARES_hp_image.png") 
const SOLIS_FLARES = "https://solis-video-files.s3.amazonaws.com/Flares_034.gif"
const VIDEO = require('../../assets/images/bg-video.mp4')

const imageFiles = [AFV_COLLECTION, SOLIS_FLARES, AFFECT_CHANGE, CAUSE_PLAY_IRL, BUNNY,FANTASY_FOOTBALL_CAUSE, HER_VOICE, SAINT_JUDE,SOLIS_CORE, SPOKEN_WORLD, ISEKAI_ANIME]

interface collectionProps {
    displayName: string;
    path: string;
    imageUrl: string;
    collectionName: string;
    collectionDescription: string;
    forSale: boolean;
    launchDate: string;
}

const HomePageCollectionLeft = (props: collectionProps) => {
    const history = useHistory();
    const { classes } = useStyles();
    let tempImageUrl = VIDEO;

    for (var i = 0; i < imageFiles.length; ++i) {
        const image = imageFiles[i]
        if (typeof image === "string" && image.includes("https") && props.collectionName === "SOLIS_FLARES") { tempImageUrl = image; break; }
        else if (typeof (image) !== "string" && image.default.toString().includes(props.collectionName)) {
            tempImageUrl = image.default
            break;
        } else if (typeof image === "string" && image.includes(props.collectionName)) {
            tempImageUrl = image
            break;
        }
        else tempImageUrl = comingSoon.default
    }

    let picture = null
    if (tempImageUrl&&tempImageUrl.endsWith('.mp4')||tempImageUrl&&tempImageUrl.endsWith(".mov")) {
        picture = (
            <video
                src={tempImageUrl}
                muted
                autoPlay
                loop
                className='featureBannerImgWrap'
            />
        )
    } else {
        picture = (
            <div className="featureBannerImgWrap" style={{ background: `url(${tempImageUrl})` }}>
            </div>
        )
    }

    return (
        <>
            <div className={clsx("row", "g-xl-5", "mt-3", "gridAreaCollectionWrap")}>
                <div className="col-lg-5 col-xl-4 gridAreaCollection1" onClick={() => history.push(props.path)}
                >
                    <div className="card-content">
                        <h3 className={classes.hoverClass}>
                            {props.displayName}
                        </h3>
                        <p>
                            {props.collectionDescription}
                        </p>
                        <div className="timer-content">

                            {
                                props.launchDate ? <span>{props.launchDate}
                                </span> : null
                            }
                        </div>
                    </div>
                </div>
                <div className={clsx("col-lg-7", "col-xl-8", "gridAreaCollection2")} style={{ alignItems: "center" }}>
                    <div className={clsx("card-image before-shape featuredReleases", classes.featuredReleases)}
                        onClick={() => history.push(props.path)}
                    >
                        {picture}
                    </div>
                </div>

            </div>

        </>
    )
}

export default HomePageCollectionLeft