import { useStyles } from "./footer.style";
import FooterLogo from "../../assets/images/footer-logo.svg";
import twitterLogo from "../../assets/images/twitter.svg";
import mediumLogo from "../../assets/images/medium.svg";
import discordLogo from '../../assets/images/Discord.svg'
import instagramLogo from '../../assets/images/instgram.svg'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutUser, logoutCleanup } from "../../slices/auth.slice";
import { useLocation } from "react-router-dom";
const Footer = () => {
  const location = useLocation();
  const { isLoggedIn, email } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const logout = async () => {
    await dispatch(logoutUser({}));
    await dispatch(logoutCleanup());
    history.push("/");
  };
  return (
    <>
      <footer className="main-footer">
        <div className="container">
          <div className="footer-link-section">
            <div className="row">
              <div
                className="col-sm-6 col-md-3 col-lg-3 wow fadeInUp"
                data-wow-delay="0.2s"
              >
                {
                  location.pathname === "/profile" ? null : <div className="footer-logo">
                    <img src={FooterLogo} alt="SOLIS Inc" width="160px" />
                  </div>
                }
              </div>
              <div
                className="col-sm-6 col-md-3 col-lg-2 wow fadeInUp"
                data-wow-delay="0.6s"
              >
                <h2>SUPPORT</h2>
                <ul className="footer-links">
                  <li>
                    <a href="https://www.iubenda.com/terms-and-conditions/28371850">Terms and conditions</a>
                  </li>
                  <li>
                    <a href="https://www.iubenda.com/privacy-policy/28371850/full-legal">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="https://www.iubenda.com/privacy-policy/28371850/cookie-policy">Cookie Policy</a>
                  </li>
                  <li>
                    <a href="https://www.solislabs.io/disclaimer.html">Disclaimer</a>
                  </li>
                  <li>
                    <a href="https://www.solislabs.io/whitelist.html">Contact us</a>
                  </li>
                  <li>
                    <a href="https://www.solislabs.io/license-agreement.html">License Agreement</a>
                  </li>
                  <li>
                    <a href="#!" onClick={() => logout()}>Logout</a>
                  </li>
                </ul>
              </div>
              <div
                className="col-sm-6 col-md-6 col-lg-5 wow fadeInUp"
                data-wow-delay="0.8s"
              >
                <h2>CONNECT</h2>
                <div className="social-icon">
                  <a href="https://twitter.com/solis_io" className="social-item">
                    <img src={twitterLogo} alt="twitter" />
                    <div className="inner-text">
                      Follow us on Twitter
                      <br /> <span>@solis_io</span>
                    </div>
                  </a>
                  <a href="https://medium.com/@SolisLabs" className="social-item">
                    <img src={mediumLogo} alt="medium" />
                    <div className="inner-text">
                      Join the conversation
                      <br /> <span>@SolisLabs</span>
                    </div>
                  </a>
                  <a href="https://discord.gg/NMmbN7Yv8F" target="_blank" className="social-item">
                    <img src={discordLogo} alt="discord" style={{ height: '25px', width: '25px' }} />
                    <div className="inner-text">
                      Join our discord
                      <br /> <span>@Solis_io</span>
                    </div>
                  </a>
                  <a href="https://www.instagram.com/solis.io" class="social-item">
                    <img src={instagramLogo} alt="instagram" width="25px" />
                    <div class="inner-text">
                      Instagram<br /> <span>@solis.io</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
