import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../services/auth.service";
import { clearUserState, getUserDetail, setUser } from "./user.slice";

const initialState = { 'isLoggedIn': false, 'isProfileComplete': false, 'isLoading': false, 'isInLogin': false, initializeRequired: false };

//Login initiated during sign up
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, tokenParam, postData, isSocialLogin }, thunkAPI) => {
    ////console.log("params", email, isSocialLogin)
    try {
      await thunkAPI.dispatch(setIsLoading(true));
      await thunkAPI.dispatch(setIsInLogin(true));
      //Load user metadata from magic   
      let userData;
      if (tokenParam) {
        userData = await authService.loginUser(email, tokenParam, postData);
      }
      else if (isSocialLogin) {
        userData = await authService.finishSocialLogin()
      }
      else {
        userData = await authService.loginUser(email);
      }
      if (userData.magicMetadata.isProfileComplete) {
        await thunkAPI.dispatch(setUser(userData.userDetail));
      }
      const magicMetadata = userData.magicMetadata;
      await thunkAPI.dispatch(setIsLoading(false));
      await thunkAPI.dispatch(setIsInLogin(false));
      return { ...magicMetadata };
    } catch (error) {
      await thunkAPI.dispatch(setIsLoading(false));
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//Login initiated during sign up
export const loginRedirect = createAsyncThunk(
  "auth/loginredirect",
  async ({ credentialToken, isSocialLogin }, thunkAPI) => {
    if (isSocialLogin) {
      try {
        await thunkAPI.dispatch(setIsLoading(true));
        await thunkAPI.dispatch(setIsInLogin(true));
        const userData = await authService.finishSocialLogin();
        if (userData.magicMetadata.isProfileComplete) {
          await thunkAPI.dispatch(setUser(userData.userDetail));
        }
        await thunkAPI.dispatch(setIsLoading(false));
        await thunkAPI.dispatch(setIsInLogin(false));
        const magicMetadata = userData.magicMetadata;
        return { ...magicMetadata };
      } catch (error) {
        await thunkAPI.dispatch(setIsLoading(false));

        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        //thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
      finally {
        await thunkAPI.dispatch(setIsLoading(false));
        await thunkAPI.dispatch(setIsInLogin(false));

      }
    }
    else {
      try {
        await thunkAPI.dispatch(setIsLoading(true));
        await thunkAPI.dispatch(setIsInLogin(true));
        //Load user metadata from magic
        const userData = await authService.loginWithRedirectCredentials(credentialToken);
        if (userData.magicMetadata.isProfileComplete) {
          await thunkAPI.dispatch(setUser(userData.userDetail));
        }
        await thunkAPI.dispatch(setIsLoading(false));
        await thunkAPI.dispatch(setIsInLogin(false));
        const magicMetadata = userData.magicMetadata;
        return { ...magicMetadata };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        //thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      } finally {
        await thunkAPI.dispatch(setIsLoading(false));
        await thunkAPI.dispatch(setIsInLogin(false));
      }
    }
  }
);

// Initiate authState if the user is logged in through magic
// but redux state is not created
export const initialise = createAsyncThunk(
  "auth/initialise",
  async ({ }, thunkAPI) => {
    try {
      const userData = await authService.getMagicUserMetaData('auth/initialise');
      if (userData.magicMetadata.isProfileComplete) {
        await thunkAPI.dispatch(setUser(userData.userDetail));
      }
      const magicMetadata = userData.magicMetadata;
      // await thunkAPI.dispatch(setIsLoading(false));
      return { ...magicMetadata, initializeRequired: false };
    } catch (error) {
      await thunkAPI.dispatch(setIsLoading(false));
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue();
    } finally {
      await thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const refresh = createAsyncThunk(
  "auth/refresh",
  async ({ emailParam }, thunkAPI) => {
    try {
      await thunkAPI.dispatch(setIsLoading(true));
      let userResponse;
      // if (emailParam) {
      //   userResponse = await authService.refreshUser(emailParam);
      // }
      userResponse = await authService.refreshUser(emailParam);
      // const { data, status, error, refetch } = refreshApi.endpoints.getRefreshData.useQuery({ pollingInterval: 1000, })
      // const { data, status, error, refetch } = store.dispatch(
      //   refreshApi.endpoints.getRefreshData.initiate({
      //     subscriptionOptions: { pollingInterval: 3000 },
      //   })
      // )
      // userResponse = data;
      if (userResponse.data.error && userResponse.data.error === 'USER_NOT_FOUND') {
        thunkAPI.dispatch(initializeRequired(true));
      } else {
        thunkAPI.dispatch(setUser(userResponse.data));
      }
      await thunkAPI.dispatch(setIsLoading(false));
    } catch (error) {
      await thunkAPI.dispatch(setIsLoading(false));

      if (error.response.status === 403) {
        thunkAPI.dispatch(initializeRequired(true));
      }
    } finally {
      await thunkAPI.dispatch(setIsLoading(false));
    }
  }
);


//Logout from magic and update the auth state and
//also update user state
// Initiate authState if the user is logged in through magic
// but redux state is not created
export const logoutUser = createAsyncThunk(
  "auth/logout",
  async ({ }, thunkAPI) => {
    try {
      await thunkAPI.dispatch(setIsLoading(true));
      await thunkAPI.dispatch(clearUserState());
      await authService.logoutUser();
      await thunkAPI.dispatch(setIsLoading(false));
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);




const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuthState: (state, action) => {
      state = { ...state };
    },
    logoutCleanup: (state, action) => {
      return initialState;
    },
    setIsProfileComplete: (state, action) => {
      const isProfileComplete = action.payload;
      return { ...state, isProfileComplete }
    },
    setIsLoggedIn: (state, action) => {
      const isLoggedIn = action.payload;
      return { ...state, isLoggedIn }
    },
    setIsLoading: (state, action) => {
      const isLoading = action.payload;
      return { ...state, isLoading }
    },
    setIsInLogin: (state, action) => {
      const isInLogin = action.payload;
      return { ...state, isInLogin }
    },
    initializeRequired: (state, action) => {
      const initializeRequired = action.payload;
      return { ...state, initializeRequired }
    },
    updateApiStatusCode: (state, action) => {
      const statusCode = action.payload
      return { ...state, statusCode }
    }
  },

  extraReducers: {
    //Login actions
    [login.fulfilled]: (state, action) => {
      const userMetadata = action.payload;
      if (Object.keys(userMetadata).length == 0) {
        return { isLoggedIn: false };
      }
      return { ...userMetadata };
    },
    [login.rejected]: (state, action) => {
      return initialState
    },


    [initialise.fulfilled]: (state, action) => {
      const userMetadata = action.payload;
      return { ...userMetadata };
    },

    [initialise.rejected]: (state, action) => {
      return initialState;
    },

    [refresh.fulfilled]: (state, action) => {

    },
    [refresh.rejected]: (state, action) => {
      return initialState;
    },

    //Login redirect actions
    //This function is initialised when magic redirects the user
    //with a call back url when email link is clicked.
    [loginRedirect.fulfilled]: (state, action) => {
      return { ...action.payload };
    },
    [loginRedirect.rejected]: (state, action) => {
      return initialState;
    },

    //Logout actions
    [logoutUser.fulfilled]: (state, action) => {
      return initialState;
    },
    [logoutUser.rejected]: (state, action) => {
      return initialState;
    },
  },
});

const { reducer, actions } = authSlice;
export const { updateAuthState, logoutCleanup, setIsProfileComplete, setIsInLogin, setIsLoggedIn, setIsLoading, initializeRequired, updateApiStatusCode } = actions;
export default reducer;
// export const { useRefreshApiQuery } = refreshApi

