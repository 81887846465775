export const Channels = {
    COLLECTIBLE: "collectible",
    COLLECTION: "collection"
}
export const Events = {
    COLLECTIBLE: {
        STATUS: "status",
        PRICE: "price",
        REFRESH: "refresh"
    },
    USER: {
        PROFILE: "profile"
    },
    COLLECTION: {
        STATUS: "status",
        REFRESH: "refresh"
    }
}