import { Biconomy } from "@biconomy/mexa";
import { CURRENT_NETWORK } from "./networks"
import { ContractDetail } from "./ContractDetail";
import {ethers} from 'ethers'
import { EnvVars } from "../constants/Env";
import { TransactionRequest } from '@ethersproject/abstract-provider'

const MarketplaceAbi = require("./contracts/Marketplace.sol/Marketplace.json");
const USDCErc20TokenAbi = require("./contracts/Erc20Token_USDC.sol/Erc20Token.json");
const USDTErc20TokenAbi = require("./contracts/Erc20Token_USDT.sol/Erc20Token.json");
const CollectionAbi = require("./contracts/Collection.sol/Collection.json");
const ERC20TokenABI = require("./contracts/Erc20Token.sol/Erc20Token.json");

const EXTRA_TIP_FOR_MINER = 1 //  gwei

export interface IContractArtifacts {
  version: string,
  address: string,
  abi: any,
  ownerKey?: string,
  ownerAddress?: string,
}

export enum ChainId {
  MAINNET = "1",
  POLYGON  = "137"
}


const getCollectionContractArtifacts = () => {
  const collectionArtifacts = ContractDetail[EnvVars.ENV]["collection"];
  return collectionArtifacts;
};

export const getCollectionContractArtifactsByName = (collectionName: string) => {
  const collectionArtifacts :any= getCollectionContractArtifacts();
  return {... collectionArtifacts[collectionName], abi: require(`./${collectionArtifacts[collectionName].abi}`) };
};
  
export const getMarketPlaceContractArtifacts = () => {
  const marketplaceArtifacts = ContractDetail[EnvVars.ENV]["Marketplace"];
    return { ...marketplaceArtifacts, abi: require(`./${marketplaceArtifacts.abi}`).abi };
  };

export const getErc20TokenContractArtifacts = (erc20Ticker :string): IContractArtifacts => {

  const address = getErc20TokenCurrencyAddress(erc20Ticker);
  const abi = getErc20TokenAbi(erc20Ticker)

  return {
    version: "v0.1",
    address,
    abi
  }
}

export enum GasTxnType {
  ERC20,
  CONTRACT
}

export const isTransactionGasless = (txnType: GasTxnType): boolean => {
  return txnType === GasTxnType.ERC20 ? 
    process.env.REACT_APP_GASLESS_ENABLED === "true" && process.env.REACT_APP_ENV === "prod" 
    : txnType === GasTxnType.CONTRACT ? process.env.REACT_APP_GASLESS_ENABLED === "true" 
    : false;
}

export const getChainId = () => {
  return CURRENT_NETWORK.id;
}

// The first argument of the Biconomy class is an EIP 1193 type provider that has to be passed. 
// If there is a type mismatch you'll have to set the type of the provider as 
// External Provider
export type ExternalProvider = {
  isMetaMask?: boolean;
  isStatus?: boolean;
  host?: string;
  path?: string;
  sendAsync?: (request: { method: string, params?: Array<any> }, callback: (error: any, response: any) => void) => void
  send?: (request: { method: string, params?: Array<any> }, callback: (error: any, response: any) => void) => void
  request?: (request: { method: string, params?: Array<any> }) => Promise<any>
}

  const getBiconomyGaslessTransactionForwardingContracts = () => {
    //TODO: These needs to be effectively changed to get dynamic
    //addresses
    let contractList :string[] = [];
    const collectionContractArtifacts = getCollectionContractArtifacts()
    
    //Contract addresses that go through Biconomy
    contractList.push(collectionContractArtifacts.AFFECT_CHANGE.address);
    contractList.push(getMarketPlaceContractArtifacts().address);
    
    //TODO: This needs to be fixed
    if(isTransactionGasless(GasTxnType.ERC20)) {
      const erc20AddressList: string[] = getErc20TokenCurrencyAddressList();
        contractList.push(...erc20AddressList);
    }

    //contractList.push("0x8a160cAd1ee63D83fc8dbACAAF791d93A46b80ba");
    return contractList;
  };

  export const getBiconomyInstance = (signer: any) => {
    const biconomy = new Biconomy((signer?.provider as any).provider, {
      apiKey: process.env.REACT_APP_BICONOMY_API_KEY,
      debug: true,
      // list of contract address you want to enable gasless on
      contractAddresses: getBiconomyGaslessTransactionForwardingContracts(), 
    });
    
    return biconomy;
  };

const getErc20TokenCurrencyAddressList = () =>{
  return Object.values(CURRENT_NETWORK.TOKEN_CONTRACTS);  
}
export const getErc20TokenCurrencyAddress = (tokenTicker: string) => {

  const erc20AddressMap:any = CURRENT_NETWORK.TOKEN_CONTRACTS;
  const erc20Address: string = erc20AddressMap[tokenTicker];
    if(!erc20Address) {
      // throw Error("Contract address for tokenTicker:" + tokenTicker + " not listed in networks.ts")
    }
    return erc20Address;
}

export const getErc20TokenAbi = (erc20Ticker: string) => {
  let abi;
  if(isTransactionGasless(GasTxnType.ERC20)) {
    abi = erc20Ticker === "USDC" ? USDCErc20TokenAbi.abi : erc20Ticker === "USDT" ? USDTErc20TokenAbi.abi : null
  }else {
    abi = ERC20TokenABI.abi;
  }

  if(!abi) {
    throw new Error("Unable to find abi artifacts for tokenTicker:" + erc20Ticker)
  }
  return abi;
}


export const getTypedData = (data: any) => {
  const { name, version, chainId, verifyingContract, nonce, from, functionSignature } = data
  return {
    types: {
      EIP712Domain: [
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'version',
          type: 'string',
        },
        {
          name: 'verifyingContract',
          type: 'address',
        },
        {
          name: 'salt',
          type: 'bytes32',
        },
      ],
      MetaTransaction: [
        {
          name: 'nonce',
          type: 'uint256',
        },
        {
          name: 'from',
          type: 'address',
        },
        {
          name: 'functionSignature',
          type: 'bytes',
        },
      ],
    },
    domain: {
      name,
      version,
      verifyingContract,
      salt: '0x' + chainId.toString(16).padStart(64, '0'),
    },
    primaryType: 'MetaTransaction',
    message: {
      nonce: parseInt(nonce),
      from,
      functionSignature,
    },
  }
}

export const signAndSendTransaction = async (transactionRequest: TransactionRequest, contract: any) => {
  try {
    const nonce  = await contract.provider.getTransactionCount(transactionRequest.from, "latest")
    //logger.info("safeMint-nonce:" + nonce);
    
    //const gasPrice = await contract.provider.getGasPrice();
    const gasPriceProperties = await getGasPricePropertiesAndValues(contract.provider);
   // logger.info("safeMint-gasPrice:", gasPriceProperties);

    const tx:TransactionRequest = {
      ... transactionRequest,
      nonce,
      ... gasPriceProperties
    };

    //logger.info("Calling transaction:",tx)

    const transaction = await contract.signer.sendTransaction(tx);
   // logger.info("Transaction:" + transaction.hash);
    await transaction.wait(5);
    const receipt = await contract.provider.waitForTransaction(transaction.hash);
    return receipt;
  }catch(error) {
    ////console.log("Error in signAndSendTransaction:", error.message)
    throw error;
  }
};

export const getGasPricePropertiesAndValues = async(rpcProvider: any) => {
  const gasPriceProperties = await getTransactionPropertiesViaInfuraRPC(rpcProvider);
  return gasPriceProperties;
}

const getRpcProvider = async() => {
  let provider = new ethers.providers.JsonRpcProvider(
    String(CURRENT_NETWORK.rpcUrl), CURRENT_NETWORK.id
  );
  return provider;
}

async function initContract(collectionName: string) {
  const provider = await getRpcProvider()
  //const contractArtifacts =  getMarketPlaceContractArtifacts()
  const collectionArtifacts = getCollectionContractArtifactsByName(collectionName);

  const contract = new ethers.Contract(collectionArtifacts.address, collectionArtifacts.abi.abi, provider);
  return contract;
}

export const getNftOwnerAddress = async(tokenId:string, collectionName: string)=>{
  const contract = await initContract(collectionName)
  const owner = await contract.ownerOf(tokenId);
  return owner;
}

// ≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖
//  Implementation methods
// ≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖≖

const getTransactionPropertiesViaInfuraRPC = async(rpcProvider:any) => {
  ////console.log('GETTING EIP-1559 GAS FROM INFURA API')

  //const rpcProvider = await getRpcProvider();
  const block = await rpcProvider.getBlock('latest')
  let block_number = block.number
  let base_fee = parseFloat(ethers.utils.formatUnits(block.baseFeePerGas, 'gwei'))

  let max_priority_fee_hex = await rpcProvider.send('eth_maxPriorityFeePerGas', [])
  let max_priority_fee_wei = ethers.BigNumber.from(max_priority_fee_hex).toNumber();
  let max_priority_fee = parseFloat(ethers.utils.formatUnits(max_priority_fee_wei, 'gwei'))
  max_priority_fee += EXTRA_TIP_FOR_MINER

  let max_fee_per_gas = base_fee + max_priority_fee

  //  In case the network gets (up to 25%) more congested
  max_fee_per_gas += (base_fee * 1.25)

  ////console.log(`block_number: ${block_number}`)
  ////console.log(`base_fee: ${base_fee.toFixed(9)} gwei`)
  ////console.log(`max_priority_fee_per_gas: ${max_priority_fee} gwei`)
  ////console.log(`max_fee_per_gas: ${max_fee_per_gas} gwei`)

  //  cast gwei numbers to wei BigNumbers for ethers
  const maxFeePerGas = ethers.utils.parseUnits(max_fee_per_gas.toFixed(8), 'gwei')
  const maxPriorityFeePerGas = ethers.utils.parseUnits(max_priority_fee.toFixed(8), 'gwei')

  //  Final object ready to feed into a transaction
  const transactionProperties = {
      maxFeePerGas,
      maxPriorityFeePerGas
  }

  return transactionProperties
}




  