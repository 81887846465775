import { useEffect, useState, useRef } from "react"
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';
import Radio from "@mui/material/Radio";
import { toArray } from "../../helpers/toArray";
import { useStyles } from "./IndividualNftCard.style";
import { CircularProgress, Modal, setRef } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { useAddMarketItem } from "../../web3/hooks/useAddMarketItem";
import { useSelector } from "react-redux";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { StateLoader } from "../Loader/StateLoader";
import { getCollectionContractArtifactsByName, getNftOwnerAddress } from "../../web3/web3";
import { useAccount, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi'
import { useDispatch } from "react-redux";
import { setMessage } from "../../slices/message.slice";
import { useHistory } from "react-router-dom";
import { CURRENT_NETWORK } from "../../web3/networks";
import { disconnect } from "@wagmi/core";
import { useRemoveMarketItem } from "../../web3/hooks/useRemoveMarketItem";

const ListForSale = (props) => {
    const [saleType, setSaleType] = useState("buynow")
    const [paymentOption, setPaymentOption] = useState("crypto")
    const [currency, setCurrency] = useState("usdc")
    const [price, setPrice] = useState(props.nftDetail.price)
    const userState = useSelector((state) => state.user)
    const { address, isConnected, connector } = useAccount()
    const { chain } = useNetwork()

    const { chains, error, isLoading, pendingChainId, switchNetwork } =
        useSwitchNetwork()
    const { openConnectModal } = useConnectModal();
    const [addMarketItem, loadingAddMarketItemState] = useAddMarketItem()
    const [removeMarketItem, loadingRemoveMarketItem] = useRemoveMarketItem()
    const [confirmListForSale, setConfirmListForSale] = useState(false)
    const [nftOwnerAddress, setNftOwnerAddress] = useState()
    const [isErrorWhileFetching, setIsErrorWhileFetching] = useState(false)
    const [refetchOwnerAddress, setRefetchOwnerAddress] = useState()
    const [isMagicInjectionCondition, setIsMagicInjectionCondition] = useState(false)
    const [removeMarketItemStatus, setRemoveMarketItemStatus] = useState()
    const [firstRender, setFirstRender] = useState(true);
    const [isListedForSale, setIsListedForSale] = useState(false)
    const [isRefetching, setIsRefetching] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const authState = useSelector((state) => state.auth)


    const handleWalletConnect = async () => {
        if (isConnected) {
            if (chain.id !== CURRENT_NETWORK.id) {
                switchNetwork(CURRENT_NETWORK.id)
            }
        }
        else if (!address) {
            try {
                openConnectModal()
            } catch (error) {
                ////console.log("error", error)
            }
        }
    }

    const getOwner = async () => {
        try {
            setIsRefetching(true)
            const address = await getNftOwnerAddress(props.nftDetail.tokenId, props.collectionName)
            if (address === authState.publicAddress) {
                setIsMagicInjectionCondition(true)
            }
            setIsErrorWhileFetching(false)
            setRefetchOwnerAddress(false)
            setIsRefetching(false)
            return address
        } catch (error) {
            setIsRefetching(false)
            setIsErrorWhileFetching(true)
        }
    }

    useEffect(() => {
        if (userState.id === props.nftDetail.ownerId && props.nftDetail.status === "IN_MARKET") {
            setIsListedForSale(true)
        }
    }, [])

    useEffect(() => {
        if (firstRender || refetchOwnerAddress) {
            getOwner()
                .then((res) => {
                    setIsRefetching(false)
                    setNftOwnerAddress(res);
                    setRefetchOwnerAddress(false);
                    if (firstRender) {
                        setFirstRender(false);
                    }
                })
                .catch((err) => {
                    setIsErrorWhileFetching(true);
                    setIsRefetching(false)
                    setRefetchOwnerAddress(false);
                    if (firstRender) {
                        setFirstRender(false);
                    }
                });
        }
    }, [refetchOwnerAddress, firstRender])
    useEffect(() => {
        let timeoutId;
        if (loadingAddMarketItemState || loadingRemoveMarketItem) {
            timeoutId = setTimeout(() => {
                window.location.reload();
            }, 40000);
        }

        return () => clearTimeout(timeoutId);
    }, [loadingAddMarketItemState, loadingRemoveMarketItem]);

    const handleListForSaleCurrency = (event) => {
        setCurrency(event.target.value)
    }
    const handleListForSale = async () => {
        //TODO call add market item
        const collectionArtifacts = getCollectionContractArtifactsByName(props.collectionName);
        console.log(collectionArtifacts)
        const res = await addMarketItem(props.nftDetail.tokenId, collectionArtifacts.address, price)
        if (res && res === "success") {
            dispatch(setMessage({ type: "success", message: "Success!", timeStamp: Date.now() }))
            history.push(`/collections/${props.nftDetail.collectionId}`)
        }
    }
    const confirmListForSaleFunction = async () => {

    }
    const supportedCurrenciesCrypto = toArray(process.env.REACT_APP_SUPPORTED_CURRENCIES_CRYPTO)
    const supportedCurrenciesFiat = toArray(process.env.REACT_APP_SUPPORTED_CURRENCIES_FIAT)
    const switchWalletFunc = () => {
        disconnect()
        if (!address || !isConnected) {
            openConnectModal()
        }
    }

    const handleRemoveFromMarket = async () => {
        try {
            const res = await removeMarketItem(props.nftDetail.marketItemId)
            setRemoveMarketItemStatus("success")
            dispatch(setMessage({ message: "NFT removed from market", timeStamp: Date.now(), type: "success" }))
        } catch (error) {
            setRemoveMarketItemStatus("error")
            dispatch(setMessage({ message: error, timeStamp: Date.now(), type: "error" }))
        }
    }


    const { classes } = useStyles();
    return (
        <>
            {(loadingAddMarketItemState) && <StateLoader text={loadingAddMarketItemState.text} />}
            {(loadingRemoveMarketItem) && <StateLoader text={loadingRemoveMarketItem.text} />}

            <div className={classes.listForSaleModal}>
                <CancelIcon sx={{ right: '0px', top: '0px', position: 'absolute', color: '#f67622', cursor: 'pointer' }} onClick={() => { props.openListForSaleFunction(false); window.location.reload() }} />
                {
                    isListedForSale ?
                        <>
                            <div >
                                <div className={classes.listForSaleComponentHeading} >Remove from market</div>
                                {
                                    !address &&
                                    <div className={classes.listForSaleButtonDiv}>
                                        <button className="btn-primary" onClick={handleWalletConnect} >Connect Wallet</button>
                                    </div>
                                }
                                {
                                    (address && nftOwnerAddress !== address)
                                    &&
                                    <div className={classes.walletAddressDivParent}>
                                        <div className={classes.walletAddressDiv}>NFT owner address : {nftOwnerAddress ? nftOwnerAddress : <CircularProgress style={{ color: '#f67622' }} />} </div>
                                        <div className={classes.walletAddressDiv}>Connected Wallet : {address} </div>
                                        <div className={classes.listForSaleButtonDiv}>
                                            <button className="btn-primary" onClick={switchWalletFunc} >Switch Wallet</button>
                                        </div>
                                    </div>
                                }
                                {
                                    address && (nftOwnerAddress === address) && !removeMarketItemStatus &&
                                    < button className="btn-primary" onClick={handleRemoveFromMarket} style={{ margin: 'auto', display: 'flex' }} >Remove</button>
                                }

                                {
                                    removeMarketItemStatus &&
                                    <div style={{ textAlign: 'center' }}>
                                        {
                                            removeMarketItemStatus === "success" ? "NFT has been removed from market!" : "Error removing NFT from market!"
                                        }
                                    </div>
                                }

                            </div>
                        </>
                        :
                        <>
                            <div className={classes.listForSaleComponentHeading}>Enter listing price</div>

                            <div>
                                <input type="text" value={price} onChange={(event) => setPrice(event.target.value)} className={classes.priceInputField} placeholder="Price" />
                            </div>
                            {
                                isErrorWhileFetching &&
                                <div >
                                    {
                                        isRefetching ?
                                            <div className={classes.errorDiv}>
                                                <CircularProgress sx={{ color: '#f67622', margin: 'auto' }} />
                                                Fetching NFT owner address
                                            </div>
                                            :
                                            <>
                                                <div className={classes.errorDiv}>
                                                    <span>Error getting owner address</span>
                                                    <button onClick={() => setRefetchOwnerAddress(true)} className="btn-primary" style={{ maxWidth: '100px', margin: 'auto' }} >Refetch</button>
                                                </div>
                                            </>
                                    }
                                </div>
                            }
                            {
                                (isMagicInjectionCondition && !isErrorWhileFetching) ?
                                    <>
                                        {
                                            price &&
                                            <div className={classes.listForSaleButtonDiv}>

                                                <button className="btn-primary" onClick={handleListForSale}>
                                                    List for Sale
                                                </button>

                                            </div>

                                        }
                                    </>
                                    :
                                    !isErrorWhileFetching &&
                                    <>
                                        <div className={classes.walletAddressDivParent}>
                                            <div className={classes.walletAddressDiv}>NFT owner address : {nftOwnerAddress ? nftOwnerAddress : <CircularProgress style={{ color: '#f67622' }} />} </div>

                                            {
                                                address && <div className={classes.walletAddressDiv}>Connected Wallet : {address} </div>
                                            }
                                        </div>
                                        {
                                            !address &&
                                            <div className={classes.listForSaleButtonDiv}>
                                                <button className="btn-primary" onClick={handleWalletConnect} >Connect Wallet</button>
                                            </div>
                                        }
                                        {
                                            (address && nftOwnerAddress !== address)
                                            &&
                                            <div className={classes.listForSaleButtonDiv}>
                                                <button className="btn-primary" onClick={switchWalletFunc} >Switch Wallet</button>
                                            </div>
                                        }

                                        {
                                            (price && address === nftOwnerAddress) &&
                                            <div className={classes.listForSaleButtonDiv}>

                                                <button className="btn-primary" onClick={handleListForSale}>
                                                    List for Sale
                                                </button>

                                            </div>

                                        }
                                    </>
                            }
                        </>
                }
            </div>

        </>
    )
}

export default ListForSale


const Dropdown = styled(Select)(({ theme }) => ({
    color: '#f67622',
    background: 'rgb(19,22,35)',
    border: '1px solid #f67622',
    height: '50px',
    fontFamily: 'Saira',
    letterSpacing: '1px',
    width: '200px'
}));

const DropdownItems = styled(MenuItem)(({ theme }) => ({
    color: '#f67622',
    background: 'rgb(19,22,35)'
}));


const SolisColorRadioButton = styled(Radio)(({ theme }) => ({
    color: '#f67622',
    '&.Mui-checked': {
        color: '#f67622'
    },
    color: "#f67522",
    "&.Mui-checked": {
        color: "#f67622",
    },
    "&.Mui-disabled": {
        color: "#f67622",
        opacity: '0.6'
    }
}));