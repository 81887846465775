import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./profile.style";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { Prompt } from "react-router-dom";
import { Modal } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ReactAvatarEditor from "react-avatar-editor";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import tooltipBlack from "../../assets/images/tooltipBlack.png";
import { pinFileToIPFS } from "../nft-minter/pinata";
import magicImg from "../../assets/images/Magic.svg";
import upload from "../../assets/images/upload.png";
import metamaskImg from "../../assets/images/metamask.svg";
import solisLogo from "../../assets/images/solis-logo.svg";
import Loader from "../../Components/Loader/Loader";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";
import { useQuery } from "react-query";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { getUserDetail, setUserDetail } from "../../slices/user.slice";
import axiosInstance, { axiosCustomInstance } from "../../services/api";
import getAPIHostName from "../../utils/utils";
import userService from "../../services/user.service";
import { useForm, Controller, useFormState } from "react-hook-form";
import { setMessage } from "../../slices/message.slice";
//import { useWeb3React } from "@web3-react/core";
import {
  useConnectModal,
  useAccountModal,
  useChainModal,
} from '@rainbow-me/rainbowkit';
import { useAccount, useConnect, useDisconnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
//import { useEagerConnect, useInactiveListener } from "../../hooks";
import {
  TransitionScreenContext,
  TransitionScreenDispatchContext,
} from "../../global/context/transitionScreenContext";
import { useContext } from "react";
import { setIsLoading } from "../../slices/auth.slice";
import { getAllCollections } from "../../slices/collection.slice";
import { checkAndAddWallet } from "../../services/web3.service";
const EditProfileForm = (props) => {
  var editor = "";
  const [imgLoading, setImgLoading] = useState();
  const [profilePictureSource, setProfilePictureSource] = useState();
  const [profilePictureChanged, setProfilePictureChanged] = useState(false);
  const [profilePictureUploadPath, setProfilePictureUploadPath] = useState();
  const [isSaveEnabled, setIsSaveEnabled] = useState(false)
  const [isScaleVisible, setIsScaleVisible] = useState(false)
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [errorModal, setErrorModal] = useState();
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [displayNameError, setDisplayNameError] = useState(false);
  const [isDisplayNameChecked, setIsDisplayNameChecked] = useState(false);
  const [isDispatch, setIsDispatch] = useState(false)
  const [fileExtension, setFileExtension] = useState()
  //form data  
  const [scale, setScale] = useState(1);
  const [remainingWords, setRemainingWords] = useState(1200);
  const history = useHistory();
  const [metamaskModalOpen, setMetamaskModalOpen] = useState(false);
  const [saveImageClicked, setSaveImageClicked] = useState(false);
  const [parentCheckbox, setParentCheckbox] = useState(false);
  const [additionalDetails, setAdditionalData] = useState({
    website: false,
    instagram: false,
    twitter: false,
    discord: false,
    tiktok: false,
    youtube: false,
    facebook: false,
    snapchat: false,
    reddit: false,
    medium: false,
  });
  //Edit profile revamp
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();

  const { address, isConnected, connector } = useAccount()
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })
  const { disconnect } = useDisconnect()
  const userState = useSelector((state) => state.user);
  const [user, setUser] = useState(userState ? userState : {});
  const authState = useSelector((state) => state.auth);
  const { isLoggedIn, isProfileComplete, isLoading } = useSelector(
    (state) => state.auth
  );
  const messageState = useSelector((state) => state.message);
  const [selectedWalletChoice, setSelectedWalletChoice] = useState(user.companyWalletAddress === authState.publicAddress ? "magic" : "metamask")

  ////console.log("message state", messageState);
  // ////console.log("userState", userState);
  // ////console.log("authState", authState)

  const dispatch = useDispatch();
  //Usually comes during add user
  const { paramsEmail } = useParams();
  const [email, setEmail] = useState(
    paramsEmail ? paramsEmail : authState.email
  );

  const [status, setStatus] = useState("connected");
  const [metamaskAddress, setMetamaskAddress] = useState();
  const [walletAddress, setWalletAddress] = useState(userState.companyWalletAddress === authState.publicAddress ? "magic" : "metamask")
  const [existingDBUser, setExistingDBUser] = useState({});
  const [activatingConnector, setActivatingConnector] = useState();
  const [preSubmitFlag, setPreSubmitFlag] = useState(
    authState.isProfileComplete
  );

  //For metamask connection with react-web3
  const [isMetamaskClicked, setIsMetamaskClicked] = useState();

  // const { active, account, library, connector, activate, deactivate, error } =
  //   useWeb3React();

  const collections = useSelector((state) => state.collections)
  const affectChangeCollectionId = collections.collectionIDs['AFFECT_CHANGE']




  const handleInputChange = (event) => {
    event.persist();
    const name = event.target.name;
    const value = event.target.value;

    setUser({ ...user, [name]: value });
    if (event.target.name === "displayName") {
      clearErrors("displayName");
      setDisplayNameError(false);
    }
  };
  const handleChange = (event) => {
    setAdditionalData({
      ...additionalDetails,
      [event.target.name]: event.target.checked,
    });
  };
  const handleParentCheckbox = (event) => {
    setParentCheckbox(event.target.checked);
    if (event.target.checked === true) {
      ////console.log("parent", event.target.checked);
      // Object.entries(additionalDetails).map((item)=>{
      //   if(item[0])
      // })
      setAdditionalData({
        website: true,
        instagram: true,
        twitter: true,
        discord: true,
        tiktok: true,
        youtube: true,
        facebook: true,
        snapchat: true,
        reddit: true,
        medium: true,
      });
    } else if (!event.target.checked) {
      setAdditionalData({
        website: false,
        instagram: false,
        twitter: false,
        discord: false,
        tiktok: false,
        youtube: false,
        facebook: false,
        snapchat: false,
        reddit: false,
        medium: false,
      });
    }
  };


  async function fetchAffectChangeCollection() {
    const res = await axiosCustomInstance.get(getAPIHostName() + `/api/collection/${affectChangeCollectionId}`)
    return res.data;
  }

  useEffect(() => {
    const promotionCodes = JSON.parse(process.env.REACT_APP_PROMOTION_CODES);


    if (isDispatch && promotionCodes.includes(localStorage.getItem("promo"))) {
      let collectibleArray = [];
      fetchAffectChangeCollection().then((collectibleList) => {
        collectibleArray = collectibleList.data.categories[0].collectibleList.map((collectible) => {
          return collectible
        })
        const randomCollectible = collectibleArray.sort(() => 0.5 - Math.random())[0];
        setIsSaveClicked(false);
        setIsSaveEnabled(false);
        setIsScaleVisible(false);
        localStorage.clear();
        history.push(`/AFFECT_CHANGE/collectible/${randomCollectible.id}`)
      })

    }
    else if (isDispatch) {
      setIsSaveClicked(false);
      setIsSaveEnabled(false);
      setIsScaleVisible(false);
      history.push("/profile");
    }
    setUser(userState);
  }, [userState]);



  useEffect(() => {
    if (userState.additionalDetails) {
      setAdditionalData(userState.additionalDetails);
    }
    setValue("companyWalletAddress", user.companyWalletAddress);
    setWalletAddress(authState.publicAddress === user.companyWalletAddress ? "magic" : "metamask")
  }, []);
  useEffect(() => {
    if (disconnect) {
      setValue("companyWalletAddress", authState.publicAddress)
      setWalletAddress("magic")
    }
    if (address && selectedWalletChoice === "metamask") {
      setValue("companyWalletAddress", address)
      setWalletAddress("metamask")
    }

  }, [address, connect, disconnect])

  const transitionScreenDetails = useContext(TransitionScreenContext);
  const setTransitionScreenDetails = useContext(
    TransitionScreenDispatchContext
  );


  //const triedEager = useEagerConnect();
  //useInactiveListener(!triedEager || !!activatingConnector);

  const saveProfilePicture = async () => {
    return await pinFileToIPFS(profilePictureSource);
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };
  const dataURLtoFile = (dataUrl, fileName) => {
    var arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  };
  const handleProfilePictureSave = async (e) => {
    setSaveImageClicked(true);
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      ////console.log(canvasScaled);
      const croppedImg = canvasScaled.toDataURL();
      ////console.log("cropped image type", typeof (croppedImg), "value  ", croppedImg)
      const fileImg = dataURLtoFile(croppedImg, "newImg")
      const formData = new FormData();
      formData.append('file', fileImg);
      formData.append('extension', fileExtension)
      const res = await axiosCustomInstance({
        method: "post",
        url: "/api/file/upload/profile",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      setProfilePictureChanged(true)
      setScale(1)
      setProfilePictureSource(res.data)
      setProfilePictureUploadPath(res.data)
      setIsSaveEnabled(false)
      ////console.log(fileImg)
    }
  }
  const handleScale = (e) => {
    if (!authState.isProfileComplete) {
      setIsSaveEnabled(true);
    }
    setScale(parseFloat(e.target.value));
  };
  const handleProfilePicture = async (e) => {
    setImgLoading(true);
    if (e.target.files[0]?.size < 1e7) {
      setProfilePictureSource(e.target.files[0]);
      setProfilePictureChanged(true);
      setIsSaveEnabled(true)
      setIsScaleVisible(true)
      const extension = "." + e.target.files[0].name.split(".").pop();
      setFileExtension(extension)
    } else {
      console.error("File should be less than 10MB");
      setImgLoading(false);
    }
  };

  const checkUserExistsInBackend = async (email) => {
    try {
      const userDetail = await userService.getUserDetail(email);
      if (userDetail != null) {
        setExistingDBUser(userDetail);
        return true;
      }
      return false;
    } catch (error) {
      ////console.log("Exception: checkUserExistsInBackend", error);
      return false;
    }
  };

  const checkDisplayName = async () => {
    setIsLoading(true);
    setIsDisplayNameChecked(true);
    try {
      await axiosInstance.post(getAPIHostName() + "/api/users/displayname", {
        displayName: getValues("displayName"),
      });
      setDisplayNameError(false);
      clearErrors("displayName");
      if (getValues("displayName") !== "")
        // dispatch(setMessage({ type: "success", message: "Display name available", timeStamp: Date.now() }))
        setIsDisplayNameChecked(true);
      setIsLoading(false);
    } catch (error) {
      setIsDisplayNameChecked(true);
      if (
        error.response.status === 400 &&
        getValues("displayName") !== userState.displayName
      ) {
        setError("displayName", {
          type: "custom",
          message: "Display name already taken *",
        });
        setDisplayNameError(true);
        dispatch(
          setMessage({
            type: "error",
            message: "Display name already taken",
            timeStamp: Date.now(),
          })
        );
      }
      setIsLoading(false);
    }
  };

  const handleSave = async (data) => {
    ////console.log(errors);
    data.additionalDetails = additionalDetails;
    let userData = { ...data };
    try {
      setIsSaveClicked(true);
      //Upload to IPFS only if profile picture is changed
      if (profilePictureChanged) {
        userData.profilePicture = profilePictureUploadPath;
      } else {
        userData.profilePicture = user.profilePicture || "#";
      }

      //TODO: was unable to handle react avatar with form controller hooK
      //hence have to revamp when we get time

      //Saving every new wallet address in db 
      let walletData = {};
      walletData.address = userData.companyWalletAddress
      walletData.walletProvider = userData.companyWalletAddress === authState.publicAddress ? "magic" : connector ? connector.name.toString().toLowerCase() : undefined
      walletData.email = user.email ? user.email : email

      let userUrl = "";
      if (
        email != null &&
        userState.email == null &&
        !(await checkUserExistsInBackend(email))
      ) {
        //TODO:Perform additional check to see if user already exists
        //some times the user state might be null even though the user is created
        //in the backend
        userData.companyWalletName =
          user.companyWalletAddress === authState.publicAddress
            ? "magic"
            : connector && connector.name.toLowerCase()
        userUrl = "/api/users/add";
      } else {
        userUrl = "/api/users/update";
        userData.companyWalletName =
          user.companyWalletAddress === authState.publicAddress
            ? "magic"
            : connector && connector.name.toLowerCase()
        userData.id = existingDBUser.id;
        userData.companyId = existingDBUser.companyId;
      }
      const postUrl = userUrl;
      userData.companyName = email;
      userData.email = email;

      //TODO
      //Once the backend is changed the below default values should be changed
      userData.lastName = userData.lastName !== "" ? userData.lastName : " ";
      userData.location = userData.location !== "" ? userData.location : " ";
      userData.bio = userData.bio !== "" ? userData.bio : " ";
      // userData.companyWalletAddress =
      //   userData.companyWalletAddress !== ""
      //     ? userData.companyWalletAddress
      //     : " "; 

      userData.companyWalletAddress = walletAddress === "magic" ? authState.publicAddress : address
      ////console.log("userData.company", userData.companyWalletAddress, "   ", address)

      try {
        dispatch(
          setUserDetail({
            postUrl,
            userData,
          })
        );
        setIsDispatch(true);
        // setIsSaveClicked(false);
        // setIsSaveEnabled(false);
        // setIsScaleVisible(false);
        // history.push("/profile");
      } catch (error) {
        setDisplayNameError(true);
        setError("displayName", {
          type: "custom",
          message: "Display Name Already Exists",
        });

        setIsSaveClicked(false);
        setIsLoading(false);
        dispatch(
          setMessage({
            type: "error",
            message: `${error}`,
            timeStamp: Date.now(),
          })
        );
      }
    } catch (error) {
      setIsSaveClicked(false);
    }
  };

  const checkNameAndSave = async (e) => {
    e.preventDefault();
    if (getValues("displayName") !== userState.displayName) {
      await checkDisplayName();
    }
    handleSubmit(handleSave)();
  };

  const fetchUser = async (email) => {
    return await axiosInstance.get(
      getAPIHostName() + '/api/users/email=' + email
    );
  }

  const handleWalletAddress = async (event) => {
    setWalletAddress(event.target.value)
    setSelectedWalletChoice(event.target.value)
    if (event.target.value === "magic") {
      setValue("companyWalletAddress", authState.publicAddress)
    }
    else {
      if (isConnected) {
        setValue("companyWalletAddress", address)
      }
      else {
        openConnectModal()
      }
    }
  }

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isSubmitting, isSubmitted },
    setValue,
    setError,
    getValues,
    clearErrors,
  } = useForm({
    defaultValues: {
      firstName: userState.firstName ? user.firstName : "",
      lastName: user.lastName ? user.lastName : "",
      displayName: user.displayName ? user.displayName : "",
      bio: user.bio ? user.bio : "",
      website: user.website ? user.website : "https://",
      instagram: user.instagram ? user.instagram : "https://instagram.com/",
      twitter: user.twitter ? user.twitter : "https://twitter.com/",
      discord: user.discord ? user.discord : "https://discordapp.com/",
      tiktok: user.tiktok ? user.tiktok : "https://tiktok.com/@",
      youtube: user.youtube ? user.youtube : "https://youtube.com/",
      facebook: user.facebook ? user.facebook : "https://facebook.com/",
      snapchat: user.snapchat ? user.snapchat : "https://snapchat.com/add/",
      reddit: user.reddit ? user.reddit : "https://reddit.com/",
      medium: user.medium ? user.medium : "https://medium.com/@",
      companyWalletAddress: !authState.isProfileComplete
        ? authState.publicAddress
        : userState.companyWalletAddress,
    },
  });
  const { dirtyFields } = useFormState({
    control,
  });
  register("displayName", {
    onChange: (e) => {
      setDisplayNameError(false);
      setIsDisplayNameChecked(false);
      clearErrors("displayName");
    },
  });

  var ConnectorNames;
  (function (ConnectorNames) {
    ConnectorNames["Injected"] = "Injected";
    ConnectorNames["Network"] = "Network";
    ConnectorNames["WalletConnect"] = "WalletConnect";
    ConnectorNames["WalletLink"] = "WalletLink";
    ConnectorNames["Ledger"] = "Ledger";
    ConnectorNames["Trezor"] = "Trezor";
    ConnectorNames["Lattice"] = "Lattice";
    ConnectorNames["Frame"] = "Frame";
    ConnectorNames["Authereum"] = "Authereum";
    ConnectorNames["Fortmatic"] = "Fortmatic";
    ConnectorNames["Magic"] = "Magic";
    ConnectorNames["Portis"] = "Portis";
    ConnectorNames["Torus"] = "Torus";
  })(ConnectorNames || (ConnectorNames = {}));

  const { classes } = useStyles();

  if (isLoading) return <Loader />
  else
    return (
      <>
        <Prompt
          when={Object.keys(dirtyFields).length > 0 && !isDispatch}
          message="discard changes? "
        />

        <main className="main-content-wrapper">
          <div className="container" style={{ padding: "20px" }}>
            <div className="menu-list">
              <div className="navbar-nav">
                <li className="nav-item">
                  {authState.isProfileComplete ? (
                    <Link to="/profile">
                      <KeyboardBackspaceIcon />
                      Back to profile
                    </Link>
                  ) : null}
                </li>
              </div>
            </div>
          </div>
          <section className="section-block main-banner">
            <div className="container">
              <div className={classes.formParentDiv}>
                <div className={classes.textFieldContainer}>
                  <input
                    className={classes.inputFields}
                    style={{ opacity: "0.6" }}
                    name="email"
                    data-testid="email"
                    placeholder="Email"
                    value={user.email ? user.email : email}
                    label="Email"
                    onChange={(event) => handleInputChange(event)}
                    {...register("email")}
                    readOnly
                  />
                  <input
                    type="text"
                    className={
                      errors.firstName
                        ? classes.errorField
                        : classes.inputFields
                    }
                    label="First Name"
                    placeholder="First Name"
                    defaultValue={user.firstName}
                    name="firstName"
                    data-testid="firstName"
                    onChange={(event) => handleInputChange(event)}
                    {...register("firstName", {
                      required: "First name is required*",
                    })}
                  />
                  <p style={{ marginTop: "none" }}>
                    {errors.firstName?.message}
                  </p>
                  <input
                    type="text"
                    className={classes.inputFields}
                    name="lastName"
                    data-testid="lastName"
                    placeholder="Last Name"
                    defaultValue={user.lastName}
                    onChange={(event) => handleInputChange(event)}
                    label="Last name"
                    {...register("lastName")}
                  />
                  <input
                    type="text"
                    className={
                      errors.displayName || displayNameError
                        ? classes.errorField
                        : classes.inputFields
                    }
                    label="Dislay Name"
                    defaultValue={user.displayName}
                    placeholder="Display Name"
                    name="displayName"
                    data-testid="displayName"
                    onChange={(event) => handleInputChange(event)}
                    {...register("displayName", {
                      required: "Display name is required*",
                    })}
                    onBlur={checkDisplayName}
                  />
                  <p className={classes.redErrorMessage}>
                    {errors.displayName?.message}{" "}
                  </p>

                  <FormControl
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      style={{ color: "#f67622", fontFamily: "Saira" }}
                    >
                      Your Wallet Choice
                      <StyledTooltip
                        placement="top-start"
                        title={
                          <>
                            <div className={classes.tooltipParent}>
                              <div className={classes.tooltipDiv}>
                                <img
                                  src={magicImg}
                                  alt="magicLogo"
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    marginRight: "10px",
                                  }}
                                />{" "}
                                <span>
                                  Magic will create a wallet and manage all
                                  NFTs.
                                </span>
                              </div>

                              <div className={classes.tooltipDiv}>
                                <img
                                  src={metamaskImg}
                                  alt="metamaskLogo"
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    marginRight: "10px",
                                  }}
                                />{" "}
                                <span>
                                  Address will be stored corresponding to your
                                  selected external wallet.
                                </span>
                              </div>
                            </div>
                          </>
                        }
                      >
                        <img
                          src={tooltipBlack}
                          alt="tooltip logo"
                          style={{
                            height: "20px",
                            width: "20px",
                            marginLeft: "10px",
                            marginRight: "30px",
                          }}
                        ></img>
                      </StyledTooltip>
                    </FormLabel>
                    <RadioGroup

                      onChange={handleWalletAddress}
                      value={walletAddress}
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      style={{
                        color: "#f67622",
                        fontFamily: "Saira",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          maxWidth: "350px",
                        }}
                      >
                        <div>
                          <img
                            src={magicImg}
                            alt="magic_img"
                            style={{
                              height: "30px",
                              width: "30px",
                              marginRight: "10px",
                            }}
                          />
                          <FormControlLabel
                            value="magic"
                            control={
                              <Radio
                                sx={{
                                  color: "#f67522",
                                  "&.Mui-checked": {
                                    color: "#f67622",
                                  },
                                }}
                              />
                            }
                            label="Magic"
                            data-testid="magic"
                          />
                        </div>
                        <div>

                          <span>
                            <img
                              src={metamaskImg}
                              alt="netamask_img"
                              style={{ height: "40px", width: "40px" }}
                            />{" "}
                            <FormControlLabel
                              value="metamask"
                              control={
                                <Radio
                                  sx={{
                                    color: "#f67522",
                                    "&.Mui-checked": {
                                      color: "#f67622",
                                    },
                                  }}
                                />
                              }
                              label="Other wallets"
                              data-testid="metamask"
                            />
                          </span>
                          {
                            address && <button onClick={disconnect} className={classes.disconnectWallet}>Disconnect</button>
                          }
                        </div>
                      </div>
                    </RadioGroup>
                  </FormControl>

                  <input
                    type="text"
                    className={
                      errors.walletAddress
                        ? classes.errorField
                        : classes.inputFields
                    }
                    name="companyWalletAddress"
                    data-testid="companyWalletAddress"
                    label="Wallet Address"
                    placeholder="Wallet Address"
                    {...register("companyWalletAddress", {
                      required: "Select one of the wallets*",
                    })}
                    autoComplete="disabled"
                    disabled="true"
                  />

                  <Modal
                    open={metamaskModalOpen}
                    // onClose={metamaskStatus !== "unavailable"}
                    onClose={() => setMetamaskModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      maxHeight: "300px",
                      maxWidth: "400px",
                      margin: "auto",
                      border: "1px solid #f67622",
                      boxShadow: "0px 0px 30px 0px #f67622",
                      background: "black",
                      borderRadius: "10px",
                    }}
                  >
                    <>

                      <div className={classes.modal}>
                        <div
                          style={{ color: "#f67622", fontFamily: "Aldrich" }}
                        >
                          {" "}
                          <h3> Action needed for Metamask </h3>{" "}
                        </div>
                        <div>
                          {" "}
                          Click on Metamask extension and connect wallet{" "}
                        </div>
                      </div>

                    </>
                  </Modal>
                  <Modal
                    open={errorModalOpen}
                    onClose={() => setErrorModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                      maxHeight: "300px",
                      maxWidth: "400px",
                      margin: "auto",
                      border: "1px solid #f67622",
                      boxShadow: "0px 0px 30px 0px #f67622",
                      background: "black",
                      borderRadius: "10px",
                    }}
                  >
                    <>
                      <div
                        style={{
                          display: "flex",
                          margin: "auto auto",
                          flexDirection: "column",
                          alignItems: "center",
                          alignSelf: "center",
                          alignContent: "center",
                        }}
                      >
                        <img
                          src={solisLogo}
                          alt="metamask image"
                          style={{
                            height: "100px",
                            width: "100px",
                            marginTop: "20px",
                          }}
                        />
                        <div style={{ color: "#f67622" }}>
                          {" "}
                          <h3>{errorModal}</h3>{" "}
                        </div>
                      </div>
                    </>
                  </Modal>
                  <textarea
                    name="bio"
                    data-testid="bio"
                    cols="40"
                    rows="5"
                    placeholder="Bio"
                    className={classes.inputFields}
                    {...register("bio", {
                      maxLength: 1200,
                    })}
                    onChange={(event) => {
                      setRemainingWords(event.target.value.length);
                    }}
                    style={{ marginBottom: "0px", whiteSpace: "pre-wrap" }}
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <p style={{ color: "#f67622" }}>
                      {1200 - remainingWords}/1200
                    </p>
                  </div>
                </div>
                <div className={classes.imageInputContainer}>
                  <div>
                    <div className="profile-picture">
                      <ReactAvatarEditor
                        ref={setEditorRef}
                        crossOrigin=""
                        scale={scale}
                        width={200}
                        height={200}
                        borderRadius={200 / (100 / 50)}
                        image={
                          profilePictureSource
                            ? profilePictureSource
                            : user.preSignedProfileUrl
                        }
                        className="editor-canvas"
                      />
                      {/* <div className="middle">
                      <div className={classes.changeAvatar}>
                        <div className="upload-btn-wrapper">
                          <div>
                            <span style={{ borderBottom: '2px solid white' }}>Change Avatar</span>
                          </div>

                          <input
                            className={classes.inputFields}
                            accept="audio/*, video/*, image/*, .html, .pdf"
                            type="file"
                            onChange={handleProfilePicture}
                          />
                        </div>
                      </div>
                    </div> */}
                    </div>

                    <p
                      style={{
                        marginTop: "20px",
                        fontFamily: "Saira",
                        textAlign: "center",
                      }}
                    >
                      Upload circle cropped jpg, png or gif. <br /> Max Size :
                      10MB
                    </p>
                    {isScaleVisible ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          margin: "auto",
                        }}
                      >
                        <div style={{ fontFamily: "Saira" }}>Zoom to crop</div>

                        <Slider
                          aria-label="Default"
                          valueLabelDisplay="auto"
                          defaultValue={1}
                          onChange={handleScale}
                          style={{ color: "#f67622", marginBottom: "20px" }}
                          min={Number(0.25)}
                          max={Number(5)}
                          step={Number(0.1)}
                        />
                      </div>
                    ) : null}
                    {isSaveEnabled && isScaleVisible ? (
                      <div className={classes.saveButton}>
                        <button
                          className="btn-primary"
                          onClick={handleProfilePictureSave}
                        >
                          Save Image
                        </button>
                      </div>
                    ) : isScaleVisible ? (
                      <div className={classes.saveButton}>
                        <button className="disabled-btn" disabled>
                          Save Image
                        </button>
                      </div>
                    ) : null}
                    <div className={classes.changeAvatar}>
                      <div className="upload-btn-wrapper">
                        <div>
                          <span style={{ borderBottom: "2px solid white" }}>
                            Change Avatar
                          </span>{" "}
                          <img
                            src={upload}
                            alt="uploadButton"
                            style={{
                              height: "30px",
                              width: "30px",
                              marginLeft: "5px",
                            }}
                          />
                        </div>

                        <input
                          data-testid="avatarFile"
                          className={classes.inputFields}
                          accept="audio/*, video/*, image/*, .html, .pdf"
                          type="file"
                          onChange={handleProfilePicture}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.formFieldContainer}>
                <div>
                  {/* <div>here we have checkbox for making all socials as public</div> */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Checkbox
                      onChange={handleParentCheckbox}
                      name="parent"
                      sx={{
                        color: "#f67622",
                        "&.Mui-checked": {
                          color: "#f67622",
                        },
                      }}
                      checked={parentCheckbox}
                    />
                    <p style={{ color: "#f67622" }}>
                      Mark all Socials to public
                    </p>
                  </div>
                  <div className={classes.socialMediaFieldDivContainer}>
                    <div className={classes.formAndCheckBoxContainer}>
                      {(userState.website &&
                        userState.website !== "https://") ||
                        dirtyFields.website ? (
                        <FormTextField
                          {...register("website", {
                            onBlur: (e) => {
                              setAdditionalData({
                                ...additionalDetails,
                                website: true,
                              });
                            },
                          })}
                          id="standard-basic"
                          label="Website"
                          variant="standard"
                          name="website"
                          className={classes.inputProps}
                        />
                      ) : (
                        <FormTextField
                          {...register("website")}
                          id="standard-basic"
                          label="Website"
                          variant="standard"
                          name="website"
                          className={classes.inputProps}
                          style={{ opacity: "0.6" }}
                        />
                      )}
                      <div className={classes.individualCheckbox}>
                        {" "}
                        <Checkbox
                          onChange={handleChange}
                          sx={{
                            color: "#f67622",
                            "&.Mui-checked": {
                              color: "#f67622",
                            },
                          }}
                          name="website"
                          checked={additionalDetails.website}
                        />{" "}
                        Public
                      </div>
                    </div>
                    <div className={classes.formAndCheckBoxContainer}>
                      {(userState.instagram &&
                        userState.instagram !== "https://instagram.com/") ||
                        dirtyFields.instagram ? (
                        <FormTextField
                          {...register("instagram", {
                            onBlur: (e) => {
                              setAdditionalData({
                                ...additionalDetails,
                                instagram: true,
                              });
                            },
                          })}
                          id="standard-basic"
                          label="Instagram"
                          variant="standard"
                          className={classes.inputProps}
                          name="instagram"
                        />
                      ) : (
                        <FormTextField
                          {...register("instagram")}
                          id="standard-basic"
                          label="Instagram"
                          variant="standard"
                          className={classes.inputProps}
                          name="instagram"
                          style={{ opacity: "0.6" }}
                        />
                      )}

                      <div className={classes.individualCheckbox}>
                        {" "}
                        <Checkbox
                          onChange={handleChange}
                          sx={{
                            color: "#f67622",
                            "&.Mui-checked": {
                              color: "#f67622",
                            },
                          }}
                          name="instagram"
                          checked={additionalDetails.instagram}
                        />{" "}
                        Public
                      </div>
                    </div>
                  </div>
                  <div className={classes.socialMediaFieldDivContainer}>
                    <div className={classes.formAndCheckBoxContainer}>
                      {(userState.twitter &&
                        userState.twitter !== "https://twitter.com/") ||
                        dirtyFields.twitter ? (
                        <FormTextField
                          {...register("twitter", {
                            onBlur: (e) => {
                              setAdditionalData({
                                ...additionalDetails,
                                twitter: true,
                              });
                            },
                          })}
                          id="standard-basic"
                          label="Twitter"
                          variant="standard"
                          className={classes.inputProps}
                          name="twitter"
                        />
                      ) : (
                        <FormTextField
                          {...register("twitter")}
                          id="standard-basic"
                          label="Twitter"
                          variant="standard"
                          className={classes.inputProps}
                          name="twitter"
                          style={{ opacity: "0.6" }}
                        />
                      )}
                      <div className={classes.individualCheckbox}>
                        {" "}
                        <Checkbox
                          onChange={handleChange}
                          sx={{
                            color: "#f67622",
                            "&.Mui-checked": {
                              color: "#f67622",
                            },
                          }}
                          name="twitter"
                          checked={additionalDetails.twitter}
                        />{" "}
                        Public
                      </div>
                    </div>
                    <div>
                      <div className={classes.formAndCheckBoxContainer}>
                        {(userState.discord &&
                          userState.discord !== "https://discordapp.com/") ||
                          dirtyFields.discord ? (
                          <FormTextField
                            {...register("discord", {
                              onBlur: (e) => {
                                setAdditionalData({
                                  ...additionalDetails,
                                  discord: true,
                                });
                              },
                            })}
                            id="standard-basic"
                            label="Discord"
                            variant="standard"
                            className={classes.inputProps}
                            name="discord"
                          />
                        ) : (
                          <FormTextField
                            {...register("discord")}
                            id="standard-basic"
                            label="Discord"
                            variant="standard"
                            className={classes.inputProps}
                            name="discord"
                            style={{ opacity: "0.6" }}
                          />
                        )}
                        <div className={classes.individualCheckbox}>
                          {" "}
                          <Checkbox
                            onChange={handleChange}
                            sx={{
                              color: "#f67622",
                              "&.Mui-checked": {
                                color: "#f67622",
                              },
                            }}
                            name="discord"
                            checked={additionalDetails.discord}
                          />{" "}
                          Public
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.socialMediaFieldDivContainer}>
                    <div className={classes.formAndCheckBoxContainer}>
                      {(userState.tiktok &&
                        userState.tiktok !== "https://tiktok.com/@") ||
                        dirtyFields.tiktok ? (
                        <FormTextField
                          {...register("tiktok", {
                            onBlur: (e) => {
                              setAdditionalData({
                                ...additionalDetails,
                                tiktok: true,
                              });
                            },
                          })}
                          id="standard-basic"
                          label="Tiktok"
                          variant="standard"
                          className={classes.inputProps}
                          name="tiktok"
                        />
                      ) : (
                        <FormTextField
                          {...register("tiktok")}
                          id="standard-basic"
                          label="Tiktok"
                          variant="standard"
                          className={classes.inputProps}
                          name="tiktok"
                          style={{ opacity: "0.6" }}
                        />
                      )}
                      <div className={classes.individualCheckbox}>
                        {" "}
                        <Checkbox
                          onChange={handleChange}
                          sx={{
                            color: "#f67622",
                            "&.Mui-checked": {
                              color: "#f67622",
                            },
                          }}
                          name="tiktok"
                          checked={additionalDetails.tiktok}
                        />{" "}
                        Public
                      </div>
                    </div>
                    <div className={classes.formAndCheckBoxContainer}>
                      {(userState.youtube &&
                        userState.youtube !== "https://youtube.com/") ||
                        dirtyFields.youtube ? (
                        <FormTextField
                          {...register("youtube", {
                            onBlur: (e) => {
                              setAdditionalData({
                                ...additionalDetails,
                                youtube: true,
                              });
                            },
                          })}
                          id="standard-basic"
                          label="Youtube"
                          variant="standard"
                          className={classes.inputProps}
                          name="youtube"
                        />
                      ) : (
                        <FormTextField
                          {...register("youtube")}
                          id="standard-basic"
                          label="Youtube"
                          variant="standard"
                          className={classes.inputProps}
                          name="youtube"
                          style={{ opacity: "0.6" }}
                        />
                      )}

                      <div className={classes.individualCheckbox}>
                        {" "}
                        <Checkbox
                          onChange={handleChange}
                          sx={{
                            color: "#f67622",
                            "&.Mui-checked": {
                              color: "#f67622",
                            },
                          }}
                          name="youtube"
                          checked={additionalDetails.youtube}
                        />{" "}
                        Public
                      </div>
                    </div>
                  </div>
                  <div className={classes.socialMediaFieldDivContainer}>
                    <div className={classes.formAndCheckBoxContainer}>
                      {(userState.facebook &&
                        userState.facebook !== "https://facebook.com/") ||
                        dirtyFields.facebook ? (
                        <FormTextField
                          {...register("facebook", {
                            onBlur: (e) => {
                              setAdditionalData({
                                ...additionalDetails,
                                facebook: true,
                              });
                            },
                          })}
                          id="standard-basic"
                          label="Facebook"
                          variant="standard"
                          className={classes.inputProps}
                          name="facebook"
                        />
                      ) : (
                        <FormTextField
                          {...register("facebook")}
                          id="standard-basic"
                          label="Facebook"
                          variant="standard"
                          className={classes.inputProps}
                          name="facebook"
                          style={{ opacity: "0.6" }}
                        />
                      )}

                      <div className={classes.individualCheckbox}>
                        {" "}
                        <Checkbox
                          onChange={handleChange}
                          sx={{
                            color: "#f67622",
                            "&.Mui-checked": {
                              color: "#f67622",
                            },
                          }}
                          name="facebook"
                          checked={additionalDetails.facebook}
                        />{" "}
                        Public
                      </div>
                    </div>
                    <div className={classes.formAndCheckBoxContainer}>
                      {(userState.snapchat &&
                        userState.snapchat !== "https://snapchat.com/add/") ||
                        dirtyFields.snapchat ? (
                        <FormTextField
                          {...register("snapchat", {
                            onBlur: (e) => {
                              setAdditionalData({
                                ...additionalDetails,
                                snapchat: true,
                              });
                            },
                          })}
                          id="standard-basic"
                          label="Snapchat"
                          variant="standard"
                          className={classes.inputProps}
                          name="snapchat"
                        />
                      ) : (
                        <FormTextField
                          {...register("snapchat")}
                          id="standard-basic"
                          label="Snapchat"
                          variant="standard"
                          className={classes.inputProps}
                          name="snapchat"
                          style={{ opacity: "0.6" }}
                        />
                      )}

                      <div className={classes.individualCheckbox}>
                        {" "}
                        <Checkbox
                          onChange={handleChange}
                          sx={{
                            color: "#f67622",
                            "&.Mui-checked": {
                              color: "#f67622",
                            },
                          }}
                          name="snapchat"
                          checked={additionalDetails.snapchat}
                        />{" "}
                        Public
                      </div>
                    </div>
                  </div>
                  <div className={classes.socialMediaFieldDivContainer}>
                    <div className={classes.formAndCheckBoxContainer}>
                      {(userState.reddit &&
                        userState.reddit !== "https://reddit.com/user/") ||
                        dirtyFields.reddit ? (
                        <FormTextField
                          {...register("reddit", {
                            onBlur: (e) => {
                              setAdditionalData({
                                ...additionalDetails,
                                reddit: true,
                              });
                            },
                          })}
                          id="standard-basic"
                          label="Reddit"
                          variant="standard"
                          className={classes.inputProps}
                          name="reddit"
                        />
                      ) : (
                        <FormTextField
                          {...register("reddit")}
                          id="standard-basic"
                          label="Reddit"
                          variant="standard"
                          className={classes.inputProps}
                          name="reddit"
                          style={{ opacity: "0.6" }}
                        />
                      )}

                      <div className={classes.individualCheckbox}>
                        {" "}
                        <Checkbox
                          onChange={handleChange}
                          sx={{
                            color: "#f67622",
                            "&.Mui-checked": {
                              color: "#f67622",
                            },
                          }}
                          name="reddit"
                          checked={additionalDetails.reddit}
                        />{" "}
                        Public
                      </div>
                    </div>
                    <div className={classes.formAndCheckBoxContainer}>
                      {(userState.medium &&
                        userState.medium !== "https://medium.com/@") ||
                        dirtyFields.medium ? (
                        <FormTextField
                          {...register("medium", {
                            onBlur: (e) => {
                              setAdditionalData({
                                ...additionalDetails,
                                medium: true,
                              });
                            },
                          })}
                          id="standard-basic"
                          label="Medium"
                          variant="standard"
                          className={classes.inputProps}
                          name="medium"
                        />
                      ) : (
                        <FormTextField
                          {...register("medium")}
                          id="standard-basic"
                          label="Medium"
                          variant="standard"
                          className={classes.inputProps}
                          name="medium"
                          style={{ opacity: "0.6" }}
                        />
                      )}

                      <div className={classes.individualCheckbox}>
                        {" "}
                        <Checkbox
                          onChange={handleChange}
                          sx={{
                            color: "#f67622",
                            "&.Mui-checked": {
                              color: "#f67622",
                            },
                          }}
                          name="medium"
                          checked={additionalDetails.medium}
                        />{" "}
                        Public
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isSaveClicked ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader />
                </div>
              ) : !displayNameError && isDisplayNameChecked ? (
                <button
                  data-testid="saveProfile"
                  style={{ display: "block", margin: "auto" }}
                  onClick={handleSubmit(handleSave)}
                  className="btn-primary"
                >
                  Save
                </button>
              ) : !isDisplayNameChecked && !displayNameError ? (
                <button
                  data-testid="saveProfile"
                  style={{ display: "block", margin: "auto" }}
                  // onClick={handleSubmit(handleSave)}
                  onClick={checkNameAndSave}
                  className="btn-primary"
                >
                  Save
                </button>
              ) : (
                <button
                  data-testid="saveProfile"
                  style={{ display: "block", margin: "auto" }}
                  // onClick={handleSubmit(handleSave)}
                  onClick={() => null}
                  className="btn-primary-disabled"
                >
                  Save
                </button>
              )}
              <hr></hr>
            </div>
          </section>
        </main>
      </>
    );
};

export default EditProfileForm;

const FormTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#F67622",
    opacity: "0.8",
  },
  "& label.Mui-completed": {
    color: "#F67622",
    opacity: "0.8",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#F67622",
    opacity: "0.8",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#F67622",
    opacity: "0.8",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#F67622",
      opacity: "0.8",
    },
    "&:hover fieldset": {
      borderColor: "#f67622",
      opacity: "0.8",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#f67622",
    },
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    "& fieldset": {
      borderColor: "#F67622",
      opacity: "0.8",
    },
    "&:hover fieldset": {
      borderColor: "#F67622",
      opacity: "0.8",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F67622",
    },
  },
  "& .MuiInputBase-root.Mui-disabled": {
    color: "#F67622",
    opacity: "0.8",
  },
  "& .MuiInputBase-root": {
    color: "#F67622",
  },
  "& .MuiFormLabel-root": {
    color: "#F67622",
    opacity: "0.8",
  },
  "& .MuiInputBase-root.Mui-active": {
    color: "#F67622",
  },
  "& .MuiInputBase-root.Mui-focused": {
    color: "#F67622",
  },
  width: "100%",
  maxWidth: 300,
});

// const StyledTooltip = ({ title, children, ...props }) => (
//   <Tooltip
//     {...props}
//     title={title}
//     placement="bottom"
//     arrow
//     componentsProps={{ tooltip: { sx: { backgroundColor: 'red', }, } }}
//   >
//     {children}
//   </Tooltip>
// );
const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: black;
    color: #f67622;
    box-shadow: 0px 0px 10px 0px #f67622;
    border: 2px solid #f67622;
    height: 200px;
    width: 400px;
    font-size: 14px;
  }
`;
