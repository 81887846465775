import React from "react";
import { useState } from "react";
import { Modal } from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import bgGif from '../../assets/images/bg-gif.gif'
const ComingSoonModal = () => {
  const [open, SetOpen] = useState(true);
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={() => SetOpen(true)} 
      >
        <main className="main-content-wrapper"  >
          <section className="section-block">
            <div className="container" style={{ textAlign: "center" }}>
              <div className="main-title">
                <h2>
                  <span className="gradient1">Coming Soon</span>
                </h2>
                <div className="container" style={{ padding: "20px" }}>
                  <div className="menu-list">
                    <div className="navbar-nav">
                      <li className="nav-item">
                        <Link to="/">
                          <KeyboardBackspaceIcon />
                          Back to home
                        </Link>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Modal>

      <main className="main-content-wrapper">
        <section className="section-block">
          <div className="container" style={{ textAlign: "center" }}>
            <div className="main-title">
              <h2>{/* <span className="gradient1">Coming Soon</span> */}</h2>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ComingSoonModal;
