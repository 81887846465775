import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { getErc20TokenContractArtifacts, getErc20TokenCurrencyAddress, getMarketPlaceContractArtifacts } from "../web3"
import { useSigner, useAccount,useNetwork, useProvider } from "wagmi";
import { getBiconomyInstance, getTypedData, isTransactionGasless, GasTxnType} from "../web3";
import { useErc20TokenContract } from "./useErc20TokenContract";
import { setMessage } from "../../slices/message.slice";
import store from "../../store";
import { getSignatureParametersEthers, sendRequestBiconomy, waitForEvent, parseTransactionError, BiconomyTxnStatus } from "../../utils/biconomy";
import {getBiconomyForwarderConfig, buildForwardTxRequest, getDomainSeperator, getDataToSignForEIP712, getDataToSignForPersonalSign} from "../../utils/biconomyHelpers";

type LoadingState = {
  text?: string
}

export const useRemoveMarketItem = () => {
  const [contract, setContract] = useState<any>();
  const [biconomy, setBiconomy] = useState<any>();
  const { data: signer } = useSigner();
  const { address } = useAccount();
  const marketArtifacts = getMarketPlaceContractArtifacts();
  const [loadingState, setLoadingState] = useState<LoadingState>(null)
  

 

  useEffect(() => {
    const initBiconomy = async () => {
      try {
        setLoadingState({})
        const biconomyObj = getBiconomyInstance(signer);
        await biconomyObj.init();

        const contractObj = new ethers.Contract(
          marketArtifacts.address,
          marketArtifacts.abi,
          biconomyObj.ethersProvider
        );
        setContract(contractObj);
        setBiconomy(biconomyObj);
      } catch (e) {
        console.error(e)
      } finally {
        setLoadingState(null)
      }
    };
    if (signer?.provider) initBiconomy();
  }, [signer?.provider]);

  const removeMarketItem = async (marketItemId: string,  ) => {
   
    try {
      setLoadingState({
        text: 'Removing from market'
      })
      

  
      const {data} = await contract.populateTransaction.removeMarketItem(marketItemId);
      let gasLimit = await contract.provider.estimateGas({
        to: marketArtifacts.address,
        from: address,
        data: data,
      });

      let receipt: any = null;
      if(isTransactionGasless(GasTxnType.CONTRACT)) {
        //receipt = await performGaslessTransactionEip712(data, gasLimit)
        //receipt = await performGaslessTransactionPersonalSign(data, gasLimit)
        receipt = await performGaslessSdkCall(data, gasLimit);
      }else {
        receipt = await performNormalTransaction(data, gasLimit)
      }
      setLoadingState({text:"Transaction completed! Waiting for settlement"})
      setLoadingState({
        text: 'Transaction confirmed'
      })
      return receipt
      // return transaction;
    } catch(error: any) {
      const errorJson = parseTransactionError(error)
      console.log("errorJson",errorJson)
      console.log("Error in buyMarketItem:" + error); 
      if(errorJson) {
        store.dispatch(setMessage({type:"error", message:errorJson.errorMessage, timeStamp:Date.now()}))
      }
      else if(error.reason) {
        store.dispatch(setMessage({type:"error", message:error.reason, timeStamp:Date.now()}))
      }
      else if(error.message.message) {
        store.dispatch(setMessage({type:"error", message:error.message.message, timeStamp:Date.now()}))
      }
      else {
        store.dispatch(setMessage({type:"error", message:"Some error occured. Please try again", timeStamp:Date.now()}))
      }
      throw error;
    } finally {
      setLoadingState(null)
    }
  };


  const performGaslessSdkCall = async(data: any, gasLimit: any) => {
    let txParams = {
      data: data,
      to: marketArtifacts.address,
      from: address,
      signatureType: "EIP712_SIGN",
    };
    

    const requestResult = await biconomy.provider.send("eth_sendTransaction", [txParams]);

    console.log("requestResult", requestResult); 
    //Transaction queued.. waiting for confirmations
    setLoadingState({
      text: 'Transaction queued.. waiting for confirmations'
    })

    //Wait for the transaction to be confirmed
    let transactionResult = null;
    while(transactionResult === null || (transactionResult !== null && transactionResult.status === BiconomyTxnStatus.PENDING)) {
      await sleep(5000)
      transactionResult = await biconomy.getTransactionStatus(requestResult.transactionId);
      console.log("transactionResult", transactionResult);
    }

    if(transactionResult.status === BiconomyTxnStatus.CONFIRMED) {
      const transactionHash = transactionResult.receipt.transactionHash;
      const receipt = await contract.provider.waitForTransaction(transactionHash, 10);
      store.dispatch(setMessage({type:"processing",message:"Transaction confirmed",timeStamp:Date.now()}))
      return receipt.transactionHash;
    }else {
      //Handle else properly
    }   
  }

  const performGaslessTransactionEip712 = async(data:any, gasLimit:any) =>{

    let forwarder = await getBiconomyForwarderConfig(80001);
    let forwarderContract = new ethers.Contract(
      forwarder.address,
      forwarder.abi,
      signer
    );

    const batchNonce = await forwarderContract.getNonce(address, 0);
    //const batchId = await forwarderContract.getBatch(userAddress);

    console.log(batchNonce);
    const to = marketArtifacts.address;
    const gasLimitNum = Number(gasLimit.toNumber().toString());
    console.log(gasLimitNum);
    const batchId = 0;
    const request = await buildForwardTxRequest({
      account: address,
      to,
      gasLimitNum,
      batchId,
      batchNonce,
      data,
    });
    console.log(request);

    const domainSeparator = await getDomainSeperator(80001);
    console.log(domainSeparator);

   const dataToSign = await getDataToSignForEIP712(request, 80001);
   const signature1 = await biconomy.provider.send("eth_signTypedData_v3", [address, dataToSign])

    // sendTransaction({
    //   userAddress:address,
    //   request:req,
    //   domainSeparator,
    //   signature1,
    //   signatureType: "EIP712_SIGN",
    // });

    setLoadingState({
      text: 'Transaction queued.. waiting for confirmations'
    })
    ////console.log("transactionWait", transactionWait); 
   // const transaction = await biconomy.provider.send("eth_sendTransaction", [txParams]);

    const metaTxBody = {
      to: marketArtifacts.address,
      apiId: "27e48f9e-0663-4529-9e6c-894ae4ca5fe6",
      params: [request, domainSeparator, signature1],
      from: address,
      signatureType: "EIP712_SIGN"
    }

    //const tx = await biconomy.provider.send("eth_sendTransaction", [txParams]);
    const tx:any = await sendRequestBiconomy(metaTxBody);
    
    console.log("tx check",tx)


  }

  const performGaslessTransactionPersonalSign = async(data:any, gasLimit:any) =>{

    let forwarder = await getBiconomyForwarderConfig(80001);
    let forwarderContract = new ethers.Contract(
      forwarder.address,
      forwarder.abi,
      signer
    );

    const batchNonce = await forwarderContract.getNonce(address, 0);
    //const batchId = await forwarderContract.getBatch(userAddress);

    console.log(batchNonce);
    const to = marketArtifacts.address;
    const gasLimitNum = Number(gasLimit.toNumber().toString());
    console.log(gasLimitNum);
    const batchId = 0;
    const request = await buildForwardTxRequest({
      account: address,
      to,
      gasLimitNum,
      batchId,
      batchNonce,
      data,
    });
    console.log(request);

    const domainSeparator = await getDomainSeperator(80001);
    console.log(domainSeparator);

   // const dataToSign = await getDataToSignForEIP712(request, 80001);
   const hashToSign = await getDataToSignForPersonalSign(request);
   const signature = await signer.signMessage(hashToSign)
   
    // sendTransaction({
    //   userAddress:address,
    //   request:req,
    //   domainSeparator,
    //   signature1,
    //   signatureType: "EIP712_SIGN",
    // });

    setLoadingState({
      text: 'Transaction queued.. waiting for confirmations'
    })
    ////console.log("transactionWait", transactionWait); 
   // const transaction = await biconomy.provider.send("eth_sendTransaction", [txParams]);

    const metaTxBody = {
      to: marketArtifacts.address,
      apiId: "27e48f9e-0663-4529-9e6c-894ae4ca5fe6",
      params: [request, signature],
      from: address,
      signatureType: "PERSONAL_SIGN"
    }

    //const tx = await biconomy.provider.send("eth_sendTransaction", [txParams]);
    const tx:any = await sendRequestBiconomy(metaTxBody);
    
    console.log("tx check",tx)


  }

  const performNormalTransaction = async(data:any, gasLimit:any) =>{
    let txParams = {
      data: data,
      to: marketArtifacts.address,
      from: address,
      signatureType: "EIP712_SIGN",
    }

    try {
      const transaction = await biconomy.provider.send("eth_sendTransaction", [txParams]);
      console.log("transaction", transaction); 
  
      return transaction;
    } catch (error) {
      throw error
    }
  }
 
  return [removeMarketItem, loadingState];
};

function sleep(ms: any) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

