import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux'
import { safeTransferNFT } from "../../web3/web3.utils";
import Modal from "@mui/material/Modal";
import { PaymentOptions } from "../../Pages/PaymentOptions/payment-options";
import { useLocation } from "react-router-dom";
import axiosInstance, { axiosCustomInstance } from "../../services/api";
import getAPIHostName from "../../utils/utils";
import { setIsLoading } from "../../slices/auth.slice";
import Loader from "../Loader/Loader";
import { openTransak } from "../../services/Vendor/Transak/transak";
import { setMessage } from "../../slices/message.slice";
import { useDispatch } from "react-redux";
import loaderGif from "../../assets/images/solid_loader_v2.gif"
import { useStyles } from "./IndividualNftCard.style";
import { CircularProgress } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount, useDisconnect, useConnect, useNetwork, useSwitchNetwork } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'

const IndividualNftCard = (props) => {
  const { isLoading } = useSelector((state) => state.auth)
  const location = useLocation();
  const [paymentOptions, setShowPaymentOptions] = React.useState(false);
  const [nftDetail, setNftDetail] = React.useState(props.nftDetail);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [claimResult, setClaimResult] = useState()
  const [paymentOptionModalOpen, setPaymentOptionsModalOpen] = useState(false)
  const [claimModalOpen, setClaimModalOpen] = useState(false)
  const [networkModalOpen, setNetworkModalOpen] = useState(false)
  const [claimError, setClaimError] = useState(false)
  const [claimCollectibleID, setClaimCollectibleID] = useState()
  const [claimErrorMessage, setClaimErrorMessage] = useState();
  const [isWalletConfirmed, setIsWalletConfirmed] = useState();
  const videoRef = useRef(null);
  const messageState = useSelector((state) => state.message)
  const userState = useSelector((state) => state.user)
  const authState = useSelector((state) => state.auth)
  const { address, isConnected, connector } = useAccount()

  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })
  //const { disconnect } = useDisconnect()

  const mediaLoaded = useRef(false)
  const dispatch = useDispatch()
  //Wagmi
  const { openConnectModal } = useConnectModal();
  console.log("wallet details", address, isConnected, connector);

  useEffect(() => {
    if (messageState.message && messageState.message === "Oops! NFT has been sold. Refreshing new NFT") {
      // setPaymentOptionsModalOpen(true)
    }
  }, [messageState.timeStamp])

  // useEffect(() => {

  // }, [address, connect, disconnect])

  const playVideo = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };
  const pauseVideo = () => {
    setIsPlaying(false);
    videoRef.current.pause();
  };


  const showPaymentOptions = async () => {
    setIsLoading(true)
    setShowPaymentOptions(true);
    setIsLoading(false)
  };

  const { classes } = useStyles()

  const paymentOptionModalFunction = (value) => {
    setPaymentOptionsModalOpen(value)
  }
  const handleClaimNft = async () => {
    
    if(props.currentCollectionName === "ISEKAI_ANIME"){
      
      if(!address) {
        dispatch(setMessage({ type: "error", message: "Please connect your wallet..", timeStamp: Date.now() }));
        return;
      }
      
      if(connector && connector.id === "magic") {
        dispatch(setMessage({ type: "error", message: "Anime Verse do not support Magic wallet. Please select another wallet", timeStamp: Date.now() }))
        return;
      }
    } 

    setClaimModalOpen(true);
    const claimObject = {
      email: userState.email,
      collectionId: props.currentCollectionID,
      buyerAddress: authState.publicAddress
    }
    try {
      const response = await axiosInstance.post(getAPIHostName() + "/api/collectible/claim", claimObject)
      if (response.data.data.error) {
        setClaimError(true)
        const message = response.data.data.error.message
        const regex = /id: ([\w-]+)/;
        const match = message.match(regex);
        let collectibleID;
        if (match && match[1]) {
          collectibleID = match[1]
          setClaimCollectibleID(collectibleID)
          setClaimErrorMessage("You have already claimed the NFT")
          dispatch(setMessage({ type: "error", message: "You have already claimed!", timeStamp: Date.now() }))
        }
        else if (message.includes("NFT has been sold already")) {
          setClaimErrorMessage("NFT has been sold already")
          dispatch(setMessage({ type: "error", message: "NFT not available", timeStamp: Date.now() }))
        }
        else {
          setClaimError("Error assigning NFT! Please try again later")
          dispatch(setMessage({ type: "error", message: "Some error occured!", timeStamp: Date.now() }))
        }
      }
      setClaimResult(response.data)
    } catch (error) {
      dispatch(setMessage({ type: "error", message: error.toString(), timeStamp: Date.now() }))
      setClaimResult("Failed")
      setClaimModalOpen(false)
    }

  }

  const handleWalletConnect = async () => {
    // setIsCryptoPaymentSelected(true)
        try {
            openConnectModal()
        } catch (error) {
            ////console.log("error", error)
        }
}


  if (isLoading) return <Loader />
  return (
    <>
      <Modal
        open={networkModalOpen}
        onClose={() => { setNetworkModalOpen(false); window.location.reload() }}
        aria-labelledby="claim-modal"
        aria-describedby="claim-modal"
      >
        <div className={classes.claimModalParent}>
          <CancelIcon sx={{ right: '0px', top: '0px', position: 'absolute', color: '#f67622', cursor: 'pointer' }} onClick={() => { setNetworkModalOpen(false); window.location.reload() }} />
          <div>
          {!isWalletConfirmed && <button className="btn-primary" onClick={handleWalletConnect} style={{ display: 'flex', margin: 'auto', marginTop: '30px' }}>Connect Wallet</button> }

          </div>

        </div>
      </Modal>
      <Modal
        open={claimModalOpen}
        onClose={() => { setClaimModalOpen(false); window.location.reload() }}
        aria-labelledby="claim-modal"
        aria-describedby="claim-modal"
      >
        <div className={classes.claimModalParent}>
          <CancelIcon sx={{ right: '0px', top: '0px', position: 'absolute', color: '#f67622', cursor: 'pointer' }} onClick={() => { setClaimModalOpen(false); window.location.reload() }} />
          <div>
            {
              (claimResult && !claimError) ?
                <>
                  <div className={classes.paymentWindowHeadingSuccess}>You have claimed</div>
                  <div className={classes.thumbnailDiv}>
                    <div className={classes.thumbnailDetailsDiv}>
                      {
                        parse(`${props.nftDetail.name}`)
                      }
                      <br></br>
                      <span style={{ color: '#f67622' }}>   {props.nftDetail.displayName}
                      </span>
                    </div>
                    <div className={classes.thumbnailImageDiv}>
                      {
                        props.nftDetail.mediaType.includes("video") ?
                          <video

                            controlsList="nodownload"
                            autoPlay
                            muted
                            loop

                          >
                            <source src={props.nftDetail.mediaLocation} />
                          </video>
                          :
                          <img src={props.nftDetail.mediaLocation} ></img>
                      }
                    </div>

                  </div>
                  <span className={classes.explorerUrlSpan}>Your NFT will be delivered shortly. Go to <Link to="/profile" style={{ textDecoration: 'underline', color: '#f67622' }} >Profile</Link> section to view your nft</span>
                </>
                :
                (!claimError && !claimResult) ?
                  <div className={classes.circularLoading}>
                    <h3>Assigning NFT</h3>
                    <CircularProgress sx={{ color: '#f67622' }} />
                  </div>
                  : null
            }
            {
              claimError &&
              <>
                {
                  claimErrorMessage === "You have already claimed the NFT" &&
                  <>
                    <div className={classes.paymentWindowHeadingError}>Error!</div>
                    <div className={classes.thumbnailDiv}>
                      <div className={classes.thumbnailDetailsDiv}>
                        {
                          parse(`${props.nftDetail.name}`)
                        }
                        <br></br>
                        <span style={{ color: '#f67622' }}>   {props.nftDetail.displayName}
                        </span>
                      </div>
                      <div className={classes.thumbnailImageDiv}>
                        {
                          props.nftDetail.mediaType.includes("video") ?
                            <video
                              controlsList="nodownload"
                              autoPlay
                              muted
                              loop

                            >
                              <source src={props.nftDetail.mediaLocation} />
                            </video>
                            :
                            <img src={props.nftDetail.mediaLocation} ></img>
                        }
                      </div>

                    </div>
                    <span>You have already claimed the NFT</span>
                    <br />
                    <span className={classes.explorerUrlSpanError}>Go to <Link to="/profile" style={{ textDecoration: 'underline', color: '#f67622' }} >Profile</Link> section to view your NFT</span>
                  </>
                }
                {
                  claimErrorMessage === "NFT has been sold already" &&
                  <div>
                    <div className={classes.paymentWindowHeadingError}>Error!</div>
                    <div className={classes.thumbnailDiv}>
                      <div className={classes.thumbnailDetailsDiv}>
                        {
                          parse(`${props.nftDetail.name}`)
                        }
                        <br></br>
                        <span style={{ color: '#f67622' }}>   {props.nftDetail.displayName}
                        </span>
                      </div>
                      <div className={classes.thumbnailImageDiv}>
                        {
                          props.nftDetail.mediaType.includes("video") ?
                            <video
                              controlsList="nodownload"
                              autoPlay
                              muted
                              loop

                            >
                              <source src={props.nftDetail.mediaLocation} />
                            </video>
                            :
                            <img src={props.nftDetail.mediaLocation} ></img>
                        }
                      </div>

                    </div>
                    <span>This NFT has been sold already.</span>
                    <span className={classes.explorerUrlSpanError}>Go to <Link to="/" style={{ textDecoration: 'underline', color: '#f67622' }} >Collections</Link>to find new NFT</span>
                  </div>
                }
                {
                  claimErrorMessage === "Error assigning NFT! Please try again later" &&
                  <div>
                    <div className={classes.paymentWindowHeadingError}>Error!</div>
                    <div className={classes.thumbnailDiv}>
                      <div className={classes.thumbnailDetailsDiv}>
                        {
                          parse(`${props.nftDetail.name}`)
                        }
                        <br></br>
                        <span style={{ color: '#f67622' }}>   {props.nftDetail.displayName}
                        </span>
                      </div>
                      <div className={classes.thumbnailImageDiv}>
                        {
                          props.nftDetail.mediaType.includes("video") ?
                            <video
                              controlsList="nodownload"
                              autoPlay
                              muted
                              loop

                            >
                              <source src={props.nftDetail.mediaLocation} />
                            </video>
                            :
                            <img src={props.nftDetail.mediaLocation} ></img>
                        }
                      </div>

                    </div>
                    <span className={classes.explorerUrlSpanError}>Error claiming NFT! Please try again later.</span>
                  </div>
                }
              </>
            }
          </div>

        </div>
      </Modal>
      <div style={{ position: "relative" }} className="individualNFTPage">
        <div className="before-shape before-shape-noHover">
          <div className="banner-slider" style={{ cursor: "pointer" }}>
            <div className="banner-item">
              {props.nftDetail.mediaDuration !== "" &&
                props.nftDetail.mediaDuration !== undefined ? (
                <div className="thumb-img-individual-nft for-video">
                  <div className="video-player individual-video-playerContainer">
                    <video
                      controls
                      style={{
                        widht: "100%"
                        // , display: mediaLoaded.current === true ? "block" : "none"
                      }}
                      controlsList="nodownload"

                      autoPlay
                      muted
                      loop
                      onLoadStart={() => mediaLoaded.current = true}

                    >
                      <source
                        src={props.nftDetail.mediaLocation}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  {/* <img src={loaderGif} style={{ display: mediaLoaded.current === true ? "none" : "flex", width: '100%', height: '100%', backgroundColor: 'rgb(20,22,35)', }} /> */}
                </div>
              ) : (
                <div className="thumb-img-individual-nft for-image">
                  <img
                    src={nftDetail.mediaLocation}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="price-buynow-btn">
          <div className="current-price flex-fill">
            {
              nftDetail.status !== "CLAIM" &&
              <>
                <span className="nftPriceLabel">
                  {nftDetail.price === '0' ? "COMING SOON" : nftDetail.price} {nftDetail.currency}
                </span>
                <span className="priceBreakLine"></span>
                <h3 className="currentPriceLabel">
                  CURRENT
                  <br />
                  PRICE
                </h3>
              </>
            }
          </div>
          <div className="right-block" style={nftDetail.status === "CLAIM" ? { padding: '10px 0px' } : {}}>
            {
              nftDetail.ownerId === userState.id ? (
                <button disabled
                  className="btn-primary nftBuyNowDisabled"
                  onClick={() => dispatch(setMessage({ type: "error", message: "Owner cannot buy his own NFT", timeStamp: Date.now() }))}
                >
                  LIST
                </button>
              ) : nftDetail.status === "CLAIM" ? (
                <button
                  className="btn-primary nftBuyNow"
                  onClick={handleClaimNft}
                >
                  CLAIM
                </button>
              ) : nftDetail.status === "ASSIGNED" ? (
                <button
                  className="btn-primary nftBuyNowDisabled"
                  onClick={() => dispatch(setMessage({ type: "error", message: "NFT has been sold", timeStamp: Date.now() }))}
                >
                  SOLD
                </button>
              ) : nftDetail.status === "PRE_ASSIGNED" ? (
                <button
                  className="btn-primary nftBuyNowDisabled"
                  onClick={() => dispatch(setMessage({ type: "error", message: "NFT has been sold", timeStamp: Date.now() }))}
                >
                  SOLD
                </button>
              ) : nftDetail.status === "COMINGSOON" || nftDetail.marketItemId === null ? (
                <button
                  className="btn-primary nftBuyNowDisabled"
                  onClick={() => null}
                >
                  COMING SOON
                </button>
              ) : (
                <button
                  className="btn-primary nftBuyNow"
                  onClick={() => paymentOptionModalFunction(true)}
                >
                  {nftDetail.listingType == "BUYNOW" ? "BUY NOW" : "BID NOW"}
                </button>
              )
            }

            <div className="timer-block d-inline-block d-block d-md-none">
              <span>
                {props.timerHours} hours {props.timerSeconds} sec
              </span>
            </div>
          </div>

        </div>
        <div className="timer-block text-end d-none d-md-block">
          <span>{props.timeRemaining}</span>
        </div>
      </div>
      <Modal
        open={paymentOptionModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: 'scroll' }}
      >
        <>
          <div className="col-lg-12 mb-5">
            <PaymentOptions
              nftDetail={props.nftDetail}
              paymentOptions={paymentOptionModalOpen}
              paymentOptionModalFunction={paymentOptionModalFunction}
              acceptibleCurrencyList={props.acceptibleCurrencies}
              userState={userState}
            />
          </div>

        </>
      </Modal>
      {/* {
      paymentOptions && openTransak()
    } */}

    </>
  );
};

export default IndividualNftCard;
