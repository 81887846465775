import axiosInstance from "./api";
import getAPIHostName from "../utils/utils";

export const approveToken = async (contractName, userAddress, tokenId) => {

  const result = await axiosInstance.put(
    getAPIHostName() + '/api/web3/approve/' + contractName + '/' + userAddress + '/' + tokenId);

  return result;
}

export const transferToken = async (contractAddress, userAddress, tokenId) => {
  const result = await axiosInstance.put(
    getAPIHostName() + '/api/web3/transfer/' + contractAddress + '/' + userAddress + '/' + tokenId);
  ////console.log("TokenTransfer result", result);
  return result.data.data;
};



export const checkAndAddWallet = async(walletData) =>{
  console.log("wallet req",walletData)
  const result = await axiosInstance.post(getAPIHostName()+"/api/wallets",walletData)
  return result
}