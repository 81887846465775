import React from 'react'

const HeaderTextModal = () => {
  return (
    <>
        <div className=' container gradientTextParent'>
        <div className="gradientText" > 
              Gain access to a universe of premium digital assets & opportunities in

              Film, Music, Television & more..
              <br />
              Curated by some of the world’s most talented & decorated

              Creators & Producers
            </div>
        </div>
    </>
  )
}

export default HeaderTextModal
