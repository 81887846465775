import axios from 'axios';
import axiosInstance, { axiosCustomInstance } from "./api";
import getAPIHostName, { isAdminEnvironment } from "../utils/utils";


const loginUser = async (didToken) => {
  try {

    const config = { headers: { 'Authorization': didToken } };
    const body = { promo: localStorage.getItem("promo") }
    const checkIsAdminEnvironment = isAdminEnvironment();
    let loginUrl;
    if (checkIsAdminEnvironment) {
      loginUrl = "/api/auth/admin/login"
    }
    else {
      loginUrl = "/api/auth/login"
    }
    const userDetail = await axiosCustomInstance.post(
      getAPIHostName() + loginUrl,
      body,
      { headers: { 'Authorization': didToken } }
    );
    return userDetail;
  } catch (error) {
    throw error
  }

}

const refreshUser = async (emailParam) => {
  try {
    let userDetail;

    userDetail = await axiosCustomInstance.get(
      getAPIHostName() + `/api/auth/refresh/?emailParam=${emailParam}`
    );

    // else
    //   userDetail = await axiosCustomInstance.get(
    //     getAPIHostName() + '/api/auth/refresh'
    //   );
    return userDetail.data;
  } catch (error) {
    throw error;
  }

}


const logoutUser = async () => {
  try {
    await axiosCustomInstance.get(
      getAPIHostName() + '/api/auth/logout',
    );
  } catch (error) {
    throw error
  }

}

const getUserDetail = async (email) => {
  try {
    const userDetail = await axiosInstance.get(
      getAPIHostName() + '/api/users/email=' + email
    );
    ////console.log("getUserDetail", email);

    return userDetail.data.data;
  } catch (error) {
    throw error
  }
};

const checkUserExits = async (email) => {
  try {
    if (email) {
      const userDetail = await getUserDetail(email);
      ////console.log("userDetail", userDetail);
      return userDetail ? true : false;
    } else {
      return false;
    }
  } catch (error) {
    ////console.log("checkUserExits-error getting userDetail", error);
    return "error";
  }

}

const setUserDetail = async ({ postUrl, userData }) => {

  try {
    ////console.log("userData",userData)
    const userDetail = await axiosCustomInstance.post(
      getAPIHostName() + postUrl, userData
    );
    return userDetail.data.data;
  } catch (error) {
    ////console.log("Error setting user profile detail", error);
    return null;
  }
};

const userService = {
  getUserDetail,
  setUserDetail,
  checkUserExits,
  loginUser,
  logoutUser,
  refreshUser
};

export default userService;
