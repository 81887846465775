import { Biconomy } from "@biconomy/mexa"

export interface ContractDetail {
    env: String
}

type ContractDetailEnvType = {
  collection?: {
    [key: string]: {
      name: string,
      version: string,
      address: string,
      abi: string
    },
  },

  Marketplace?: {
    version: string
    address: string
    abi: string
    biconomyApiId: string
  },

  TOKEN_CONTRACTS ? : {
    [key: string] : {
      [key:string] : string
    }  
  }
}

export type ContractDetailType = {
  [env: string]: ContractDetailEnvType
}

export const ContractDetail: ContractDetailType = {
  local: {
    collection: {
      AFFECT_CHANGE: {
        name: "AFFECT_CHANGE",
        version: "v0.1",
        address: "0xB617BFa1F8CB9cDF38C6c70e23453e93b07d8B01",
        abi: "contracts/Collection.sol/Collection.json"
      },
      HER_VOICE: {
        name: "HER_VOICE",
        version: "v0.1",
        address: "0x4fDC3A7938034073f8692a8238a09956D7656ca2",
        abi: "contracts/Collection.sol/Collection.json"
      },
    },
  
    Marketplace: {
      version: "v0.1",
      address: "0xE791C70f7cF9dcd257188884be2681D894D95384",
      abi: "contracts/Marketplace.sol/Marketplace.json",
      //Sourced from Biconomy dashboard under app/Dapp APIs
      biconomyApiId: "27e48f9e-0663-4529-9e6c-894ae4ca5fe6"
    }
  },
  
  dev: {},

  stage: {
    collection: {
      AFFECT_CHANGE: {
        name: "AFFECT_CHANGE",
        version: "v0.1",
        address: "0xB617BFa1F8CB9cDF38C6c70e23453e93b07d8B01",
        abi: "contracts/Collection.sol/Collection.json"
      },
      HER_VOICE: {
        name: "HER_VOICE",
        version: "v0.1",
        address: "0x4fDC3A7938034073f8692a8238a09956D7656ca2",
        abi: "contracts/Collection.sol/Collection.json"
      },
    },
  
    Marketplace: {
      version: "v0.1",
      address: "0xE791C70f7cF9dcd257188884be2681D894D95384",
      abi: "contracts/Marketplace.sol/Marketplace.json",
      biconomyApiId: "c171e5fc-1435-43b2-ac3c-8d44e6f411f7"
    },
  },

  prod: {
    collection: {
      AFFECT_CHANGE: {
        name: "AFFECT_CHANGE",
        version: "v0.1",
        address: "0x832042A91bC737B4834116066104388b49002B34",
        abi: "contracts/Collection.sol/Collection.json"
      },
      HER_VOICE: {
        name: "HER_VOICE",
        version: "v0.1",
        address: "0x956b144482836AEfeD0E712bac72805985A4280a",
        abi: "contracts/Collection.sol/Collection.json"
      },
      TEST_PROD: {
        name: "TEST_PROD",
        version: "v0.1",
        address: "0x4657628Cd043eDb6B1AF3ffeFB07C4884BB00778",
        abi: "contracts/Collection.sol/Collection.json"
      },
    },
    Marketplace: {
      version: "v0.1",
      address: "0x1DBCA3a03e55A2A370bd1190d0654FB2cDaBad00",
      abi: "contracts/Marketplace.sol/Marketplace.json",
      biconomyApiId: "f1820027-34af-4ec2-8672-698dec4946e7"
    },
  },
};
